import styled from '@emotion/styled'
import React from 'react'

import { ChevronRightSolidIcon } from '@emico/icons'
import { PrismicLink, PrismicImage } from '@emico/prismic'
import { theme } from '@emico/styles'

import { SubmenuBodyMenuVisual } from '../schema.generated'
import { pushEvent } from '../utils/analytics'

const SubmenuVisualWrapper = styled.div`
  position: relative;
  margin-bottom: ${theme.spacing.x2}px;
`
const SubmenuVisual = styled(PrismicImage)`
  display: block;
  width: 100%;
  height: auto;
`
const DummyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  color: white;
  background-color: ${theme.secondaryColor};
`
const StyledChevronRightSolidIcon = styled(ChevronRightSolidIcon)`
  width: 18px;
  height: 18px;
  flex: 1;
`

interface MobileMenuVisualProps {
  slice: SubmenuBodyMenuVisual
  analyticsName: string
  sectionId: number
}

export const MobileMenuVisual = ({
  slice,
  analyticsName,
  sectionId,
}: MobileMenuVisualProps) => (
  <>
    {slice.primary?.link && slice.primary?.image?.mobile ? (
      <SubmenuVisualWrapper>
        <PrismicLink
          external
          to={slice.primary.link}
          analyticsName=""
          analyticsContext=""
          onClick={() => {
            pushEvent({
              event: `mobileMenu banner click`,
              eventCategory: `mobileMenu`,
              eventAction: 'click',
              eventLabel: `${analyticsName} - banner - ${
                slice.primary?.image?.mobile?.alt ?? ''
              } - ${sectionId}`,
            })
          }}
        >
          <SubmenuVisual
            loading="lazy"
            image={slice.primary.image.mobile}
          ></SubmenuVisual>

          {slice.primary.button && (
            <DummyButton>
              <StyledChevronRightSolidIcon />
            </DummyButton>
          )}
        </PrismicLink>
      </SubmenuVisualWrapper>
    ) : (
      slice.primary?.image?.mobile && (
        <SubmenuVisualWrapper>
          <SubmenuVisual
            loading="lazy"
            image={slice.primary.image.mobile}
          ></SubmenuVisual>
        </SubmenuVisualWrapper>
      )
    )}
  </>
)
