import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MobileMenuModal } from '@src/components/MobileMenuModal'
import { PrismicTypeHeader, PrismicTypeSubmenu } from '@src/schema.generated'
import React, { Dispatch, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ModalBackdrop from '@emico/modal-backdrop'
import {
  PrismicLink,
  PrismicHyperlink,
  usePrismicDocument,
  usePrismicDocuments,
} from '@emico/prismic'
import { globalWindow } from '@emico/ssr-utils'
import { minWidth, theme } from '@emico/styles'
import { Container, useBreakpoints } from '@emico/ui'

import { Submenu } from './Submenu'

const StyledContainer = styled(Container)`
  box-sizing: border-box;

  @media ${minWidth('lg')} {
    max-width: 1140px;
  }
`

const MainMenu = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: ${theme.zIndex.modal};

  @media ${minWidth('md')} {
    justify-content: space-between;
  }
`

const MenuItem = styled.li`
  list-style: none;
`

const MainMenuWrapper = styled.div`
  display: none;
  background-color: ${theme.gray100};
  height: 53px;
  line-height: 53px;

  @media ${minWidth('lg')} {
    display: block;
  }
`

const MenuItemLink = styled(PrismicLink, {
  shouldForwardProp: (prop: string) =>
    !['highlighted', 'isActive'].includes(prop),
})<{ highlighted: boolean; isActive: boolean }>(
  ({ highlighted, isActive }) => css`
    color: ${theme.onBackgroundColor};
    display: block;
    text-transform: uppercase;
    font-family: ${theme.fontFamilySecondary};
    font-weight: 600;

    &:active,
    &:visited {
      color: ${theme.onBackgroundColor};
    }

    &:hover {
      text-decoration: none;
      box-shadow: inset 0 -3px 0 0 ${theme.secondaryColor};
      background-color: ${theme.gray300};
      color: ${theme.onBackgroundColor};
    }

    @media ${minWidth('md')} {
      padding: 0 14px;
      font-size: 11px;
    }

    @media ${minWidth('xl')} {
      padding: 0 24px;
      font-size: 12px;
    }

    ${highlighted &&
    css`
      color: ${theme.secondaryColor};

      &:hover {
        color: ${theme.secondaryColor};
      }
    `}

    ${isActive &&
    css`
      text-decoration: none;
      box-shadow: inset 0 -3px 0 0 ${theme.secondaryColor};
      background-color: ${theme.gray300};
      color: ${theme.onBackgroundColor};
    `}
  `,
)

const SUBMENU_ENABLED = process.env.REACT_APP_SUBMENU_ENABLED

interface HeaderMenuProps {
  menuIsOpen: boolean
  setMenuIsOpen: Dispatch<boolean>
}

export const HeaderMenu = ({ menuIsOpen, setMenuIsOpen }: HeaderMenuProps) => {
  const { isDesktop } = useBreakpoints()
  const [hoveredMenuItem, setHoveredMenuItem] = useState('')
  const [clickedMenuItem, setClickedMenuItem] = useState<string[]>([])
  const [activeMenuItem, setActiveMenuItem] = useState('')

  const { data: submenuData } = usePrismicDocuments<PrismicTypeSubmenu>({
    key: 'document.type',
    values: ['submenu'],
  })

  const { data: headerData } = usePrismicDocument<PrismicTypeHeader>({
    key: 'document.type',
    value: 'header',
  })

  const headerBodyMainMenuSlice = headerData?.body?.find(
    (slice) => slice.__typename === 'HeaderBodyMainMenu',
  )

  const { pathname } = useLocation()
  const isMenuItemActive = (title: string, key: string) =>
    title.toLowerCase() === pathname.split('/')[1] || key === activeMenuItem

  const largeTouchDeviceSubmenu = (key: string, link: PrismicHyperlink) => {
    const fakeLink: PrismicHyperlink = {
      linkType: 'Web',
      url: '#',
    }

    if (clickedMenuItem[0] === key) {
      return link
    }

    if (
      (isDesktop &&
        globalWindow?.matchMedia('(pointer: coarse)').matches &&
        clickedMenuItem.length === 0) ||
      (isDesktop &&
        globalWindow?.matchMedia('(pointer: coarse)').matches &&
        clickedMenuItem[0] !== clickedMenuItem[1])
    ) {
      return fakeLink
    }

    return link
  }

  return (
    <MainMenuWrapper>
      <ModalBackdrop
        css={css`
          background: none;
        `}
        show={Boolean(activeMenuItem)}
        onClick={() => {
          setHoveredMenuItem('')
          setClickedMenuItem([])
          setActiveMenuItem('')
        }}
        data-testid="body-scroll-lock-backdrop"
      />

      <StyledContainer>
        <MainMenu>
          {headerBodyMainMenuSlice?.fields?.map((menuItem, index) =>
            menuItem.link ? (
              <MenuItem key={index}>
                <MenuItemLink
                  external
                  to={largeTouchDeviceSubmenu(
                    menuItem.key ?? '',
                    menuItem.link,
                  )}
                  analyticsContext="header main menu"
                  analyticsName={`Main menu item: ${menuItem.title}`}
                  highlighted={Boolean(menuItem.highlighted)}
                  isActive={isMenuItemActive(
                    menuItem.title ?? '',
                    menuItem.key ?? '',
                  )}
                  onClick={() => {
                    setClickedMenuItem((clicked) => [
                      menuItem.key ?? '',
                      ...clicked,
                    ])
                    setActiveMenuItem(menuItem.key ?? '')
                  }}
                  onMouseEnter={() => setHoveredMenuItem(menuItem.key ?? '')}
                  onMouseLeave={() => setHoveredMenuItem('')}
                  data-testid={`menu.item.link.${index}`}
                >
                  {menuItem.title}
                </MenuItemLink>
              </MenuItem>
            ) : null,
          )}
        </MainMenu>
      </StyledContainer>

      {!isDesktop && (
        <MobileMenuModal
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
        />
      )}

      {SUBMENU_ENABLED &&
        headerBodyMainMenuSlice?.fields?.map((menuItem, index) => {
          const submenu = submenuData?.find(
            (submenu) => submenu?.data.key === menuItem.key,
          )
          return submenu ? (
            <Submenu
              key={index}
              activeItem={activeMenuItem || hoveredMenuItem}
              submenu={submenu.data}
            />
          ) : null
        })}
    </MainMenuWrapper>
  )
}
