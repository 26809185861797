import React, { SVGProps } from 'react'

export const StoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden="true"
    viewBox="0 0 32 28"
    width="1em"
    height="1em"
    {...props}
  >
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="1"
      y="1"
      width="30"
      height="26"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="16"
      y="13"
      width="7.5"
      height="14"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="8.5"
      y="13"
      width="7.5"
      height="14"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="1"
      y="1"
      width="5"
      height="5"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="6"
      y="1"
      width="5"
      height="5"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="11"
      y="1"
      width="5"
      height="5"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="16"
      y="1"
      width="5"
      height="5"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="21"
      y="1"
      width="5"
      height="5"
    />
    <rect
      fill="none"
      stroke="white"
      strokeWidth="1"
      x="26"
      y="1"
      width="5"
      height="5"
    />
  </svg>
)
