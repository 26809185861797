import { TypedDocumentNode, useApolloClient } from '@apollo/client'
import { pushRemoveFromCartEvent } from '@shared/analytics/main'
import { CART } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'
import gql from 'graphql-tag'

import { getAuthorizationContext } from '@emico/login-token'
import { stripMaybes } from '@emico/utils'

import { cartFragment } from './fragments'
import {
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
} from './useRemoveCartItem.generated'

const removeItemFromCartMutation = gql`
  ${cartFragment}
  mutation RemoveItemFromCart($itemUid: ID!, $guestCartId: String!) {
    removeItemFromCart(
      input: { cart_item_uid: $itemUid, cart_id: $guestCartId }
    ) {
      cart {
        ...SwissCartFragment
      }
    }
  }
` as TypedDocumentNode<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>

export const useRemoveCartItem = () => {
  const apolloClient = useApolloClient()
  const cartId = useCartId()

  return (itemUid: string) =>
    apolloClient.mutate<
      RemoveItemFromCartMutation,
      RemoveItemFromCartMutationVariables
    >({
      context: getAuthorizationContext(),
      mutation: removeItemFromCartMutation,
      variables: {
        itemUid,
        guestCartId: cartId ?? '',
      },
      update: (cache, { data }) => {
        const newCartData = data?.removeItemFromCart?.cart
        if (newCartData?.items) {
          // NEW EVENT
          pushRemoveFromCartEvent({
            items: newCartData.items?.filter(stripMaybes),
          })

          cache.evict({
            id: 'Cart',
            fieldName: 'items',
            broadcast: false,
          })

          cache.writeQuery({
            query: CART,
            data: {
              cart: newCartData,
            },
            variables: {
              cartId: cartId ?? '',
            },
          })
        }
      },
    })
}
