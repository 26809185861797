import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { useCartId as useEmicoCartId } from '@emico/cart'

export const vaimoLocalStorageKey = 'cart'

interface VaimoCart {
  id?: string
}

export const setVaimoCartId = (id: string | null) => {
  if (!id) {
    localStorage.removeItem(vaimoLocalStorageKey)
    return
  }
  localStorage.setItem(
    vaimoLocalStorageKey,
    JSON.stringify({
      id,
    }),
  )
}

export const getVaimoCartId = (): string | undefined => {
  const vaimoCartString = localStorage.getItem(vaimoLocalStorageKey)
  if (!vaimoCartString) {
    return
  }

  const vaimoCart: VaimoCart = JSON.parse(vaimoCartString)

  return vaimoCart?.id ?? undefined
}

export default function useCartId() {
  const location = useLocation()
  const urlCartId = new URLSearchParams(location.search).get('cartId')
  const emicoCartId = useEmicoCartId()
  const vaimoCartId = getVaimoCartId()
  const cartId = useMemo((): string | undefined => {
    if (urlCartId) {
      return urlCartId
    }
    if (vaimoCartId) {
      return vaimoCartId
    }
    return emicoCartId
  }, [urlCartId, emicoCartId, vaimoCartId])
  return cartId
}
