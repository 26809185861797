import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '@src/theme'

import { Link } from '@emico/link'
import { minWidth } from '@emico/styles/src/index'

export const StyledStepLink = styled(Link)<{ disabled: boolean }>`
  background: transparent;
  border: 0;
  padding: 0;
  font: inherit;

  cursor: pointer;

  display: flex;
  width: 70px;
  height: 57px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 11px;
  text-decoration: none;
  text-align: center;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  @media ${minWidth('sm')} {
    font-size: 12px;
  }

  &:hover {
    color: ${theme.colorBlack};
    text-decoration: none;
  }
`
