import styled, { StyledComponent } from '@emotion/styled'
import React, { HTMLAttributes, ReactNode } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { theme } from '@emico/styles'

export interface ModalSegueBaseProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean
  children: ReactNode
}

export const TranslateStates = {
  entering: 0,
  entered: 0,
  exited: 100,
  exiting: 100,
  unmounted: 100,
}

export const AnimatedElement = styled.div<{ state: TransitionStatus }>`
  z-index: ${theme.zIndex.modal};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`

export const ModalSegueBase = ({
  Component,
  show,
  children,
  ...props
}: ModalSegueBaseProps & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Component: StyledComponent<{ state: TransitionStatus }, {}, object>
}) => (
  <Transition in={show} timeout={theme.primaryTransitionSpeed}>
    {(state: TransitionStatus) => (
      <Component state={state} {...props}>
        {children}
      </Component>
    )}
  </Transition>
)
