import { overrideTheme } from '@emico/styles'

const rcTheme = {
  primaryColor: '#1c2125',
  onPrimaryColor: '#fff',
  secondaryColor: '#f9004c',
  onSecondaryColor: '#fff',
  errorColor: '#f00',
  greenColor: '#77bf36',
  colorGreenDark: '#3d730c',
  onBackgroundColor: '#1c2125',

  gray050: '#fafafa',
  gray100: '#f2f2f2',
  gray200: '#eeeeee',
  gray300: '#e6e6e6',
  gray400: '#bdbdbd',
  gray500: '#979797',
  gray600: '#838383',
  gray700: '#616161',
  gray800: '#424242',
  gray900: '#212121',

  white: '#fff',

  fontFamilyPrimary: 'Raleway, sans-serif',
  fontFamilySecondary: 'Nexa, sans-serif',
  fontSize: '14px',
  fontSizes: {
    xxSmall: '10px',
    xSmall: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xLarge: '20px',
    xxLarge: '24px',
    xxxLarge: '32px',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 600,
    bold: 700,
    black: 900,
  },

  spacing: {
    x1: 6,
    x2: 12,
    x3: 18,
    x4: 24,
    x5: 30,
    x6: 36,
    x7: 42,
  },
}

overrideTheme(rcTheme)

const theme = {
  ...rcTheme,
  colorGreenPrimary: '#77bf36',
  colorGreenLight: '#e9f6da',
  colorGreenMedium: '#00a424;',
  colorGreenDark: '#3d730c',
  colorGrey: '#e8e8e8',
  colorGreyLight: '#f6f6f6',
  colorGreyMedium: '#d4d0d0',
  colorGreyDark: '#787878',
  colorRed: '#ff004c',
  colorApricot: '#ef8b73',
  colorRedLight: '#ffe7ee',
  colorBlue: '#0087ca',
  colorBlueGray: '#192226',
  colorBlack: '#000000',
  colorGeyser: '#cbdae1',
}

export default theme
