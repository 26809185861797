import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { BreakpointIdentifier, minWidth } from '@emico/styles'

interface ColProps {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

const breakpoints: BreakpointIdentifier[] = [
  BreakpointIdentifier.xs,
  BreakpointIdentifier.sm,
  BreakpointIdentifier.md,
  BreakpointIdentifier.lg,
  BreakpointIdentifier.xl,
]

export const Col = styled.div<ColProps>`
  width: 100%;

  ${(props) =>
    breakpoints.map(
      (breakpoint) => css`
        @media ${minWidth(breakpoint)} {
          ${props[breakpoint] &&
          css`
            flex: ${props[breakpoint]};
          `};
        }
      `,
    )}
`
