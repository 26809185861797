import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

/**
 * Hook that returns information from the cart in regards to SAP
 */
export const useCartSAP = () => {
  const cartId = useCartId()
  const { cart } = useCart(cartId)

  return {
    delivery: {
      deliveryDate: cart?.sap.delivery.date ?? undefined,
      deliveryTimeString: cart?.sap.delivery.timeString ?? '',
      delayedDate: cart?.sap.delivery.delayedDate ?? undefined,
    },
    order: {
      down: Boolean(cart?.sap.down),
    },
    shipment: {
      nextDayDeliveryRelevant: cart?.sap.nextDayDeliveryRelevant,
    },
  }
}
