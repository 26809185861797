import { globalWindow } from '@emico/ssr-utils'

type AppType = typeof import('./ConfiguratorMounter')

async function loadConfigurator() {
  await import(/* webpackChunkName: "theme" */ '../../theme')
  return import(
    /* webpackPrefetch: true, webpackChunkName: "configurator" */ './ConfiguratorMounter'
  )
}

export const APP_ID = 'configurator'

export const FRAGMENT_SEPARATOR = '/'

export const isConfigurator = (hash: string) =>
  hash === `#${APP_ID}` || hash.startsWith(`#${APP_ID}${FRAGMENT_SEPARATOR}`)

export function initConfigurator() {
  let _app: AppType
  let isInteractive = false
  let isPageFullyLoaded = false
  let appRendered: Promise<boolean> | undefined = undefined

  const getApp = async () => {
    if (!_app) {
      _app = await loadConfigurator()
    }
    return _app
  }

  let _root: HTMLElement
  const getRoot = () => {
    if (!_root && globalWindow) {
      _root = globalWindow.document.createElement('div')
      globalWindow.document.body.appendChild(_root)
    }
    return _root
  }

  const startApp = () => {
    if (appRendered !== undefined) {
      return appRendered
    }

    appRendered = new Promise<boolean>((resolve) => {
      getApp().then((app) => {
        app.render(getRoot())
        resolve(true)
      })
    })

    return appRendered
  }

  const _pageload = async () => {
    if (isConfigurator(globalWindow?.location.hash ?? '')) {
      await startApp()
      isInteractive = true
    }
  }

  _pageload()

  globalWindow?.addEventListener('load', () => {
    isPageFullyLoaded = true
  })

  globalWindow?.addEventListener('mousemove', async () => {
    if (
      isPageFullyLoaded &&
      !isInteractive &&
      globalWindow?.configurator?.sku
    ) {
      await startApp()
      isInteractive = true
    }
  })

  const handleButtonClick = async (e: Event) => {
    e.preventDefault()
    e.stopPropagation()

    await startApp()

    if (!isConfigurator(globalWindow?.location.hash ?? '') && globalWindow) {
      globalWindow.location.hash = APP_ID
    }
  }

  let checks = 0
  const checkForButton = setInterval(() => {
    const elements =
      globalWindow?.document.querySelectorAll(
        '.product-config-btn .btn-config',
      ) ?? []

    if (checks === 100) {
      clearInterval(checkForButton)
    }

    if (elements.length > 0) {
      elements.forEach((button) => {
        const disabledReg = new RegExp('(\\s|^)disabled(\\s|$)')
        button.className = button.className.replace(disabledReg, ' ')
        button.addEventListener('click', handleButtonClick)
      })

      clearInterval(checkForButton)
    }

    checks += 1
  }, 100)
}
