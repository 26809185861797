import styled from '@emotion/styled'
import { darken, rgba } from 'polished'

import { Button, ButtonSecondary } from '@emico/ui'

import theme from '../theme'

ButtonSecondary.override(styled(Button)`
  background: ${theme.colorRed};
  color: ${(props) => (props.loading ? 'transparent' : theme.onPrimaryColor)};
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 500;

  &:hover {
    background: ${darken(0.1, theme.colorRed)};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${rgba(theme.colorRed, 0.2)};
  }

  &:active {
    background: ${darken(0.2, theme.colorRed)};
  }

  &:disabled {
    background: ${rgba(theme.colorRed, 0.5)};
  }
`)
