import './setupStoreView'
import { initConfigurator } from '@configurator/initConfigurator'
import {
  GENERIC_LEAD_FORM_ELEMENT_ID,
  GenericLeadForm,
} from '@generic-lead-form/GenericLeadForm'
import { initAlgolia } from '@shared/algolia/init'
import { de, en, nl } from 'make-plural/plurals'
import React from 'react'
import ReactDOM from 'react-dom'

import './theme'
import './overrides'
import { initializeI18n } from '@emico/i18n'
import { PortalTarget } from '@emico/portal'
import {
  registerPrelaunchJob,
  waitForPrelaunchJobs,
} from '@emico/prelaunch-jobs'
import { globalWindow } from '@emico/ssr-utils'
import { getActiveStoreView, getStoreLanguage } from '@emico/storeviews'

import App from './App'
import { DefaultCurrencyCode } from './components/DefaultCurrencyCode'
import PreferenceCenter from './features/contact-preferences'
import HeaderMenuWrapper from './features/header-menu'
import initSessionsManager from './features/omnichannel-menu'
import initShoppingCart from './features/shopping-cart'
import storeViews from './storeViews'

const PREFERENCE_CENTER_ELEMENT_ID = 'contact-preferences'
const HEADER_MENU_ELEMENT_ID = 'store-menu'
const appsList = [
  {
    id: PREFERENCE_CENTER_ELEMENT_ID,
    component: PreferenceCenter,
  },
  {
    id: GENERIC_LEAD_FORM_ELEMENT_ID,
    component: GenericLeadForm,
  },
]

if (globalWindow?.document.body.classList.contains('react-menu')) {
  appsList.push({
    id: HEADER_MENU_ELEMENT_ID,
    component: HeaderMenuWrapper,
  })
}

const activeStoreView =
  globalWindow && getActiveStoreView(storeViews, globalWindow?.location)
const storeLanguage = activeStoreView && getStoreLanguage(activeStoreView)

const loadCatalog = (lang: string) =>
  process.env.NODE_ENV !== 'production'
    ? import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */ `@lingui/loader!./locales/${lang}/messages.json`
      )
    : import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */ `./locales/${lang}/messages.js`
      )

// See the i18n.md for @emico/ui why delaying the App mounting makes sense.
registerPrelaunchJob(
  'i18n',
  initializeI18n(storeLanguage ?? '', loadCatalog, {
    my: { plurals: en },
    'nl-nl': { plurals: nl },
    'nl-be': { plurals: nl },
    'de-de': { plurals: de },
    'de-at': { plurals: de },
  }),
)

waitForPrelaunchJobs().then(() => {
  // Init the configurator, it will initialize when #configurator is added in the URL(for Magento implementation)
  initConfigurator()
  initSessionsManager()
  initShoppingCart()
  initAlgolia()

  appsList.forEach(({ id, component: Component }) => {
    const rootNode = document.getElementById(id)

    if (rootNode) {
      ReactDOM.render(
        <App disableErrorEvents isComponent componentId={id}>
          <DefaultCurrencyCode />
          <PortalTarget>
            <Component />
          </PortalTarget>
        </App>,
        rootNode,
      )
    }
  })
})
