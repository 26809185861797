import { useCartSaleCondition, useGuestCartId } from '@checkout/cart'
import { useCheckoutRoutes } from '@checkout/router'
import { useCart } from '@shared/cart/useCart'
import { useCallback } from 'react'
import { useRouteMatch } from 'react-router'

import { globalWindow } from '@emico/ssr-utils'
import { useActiveStoreView } from '@emico/storeviews'

type FlowEnhancers = {
  omnichannel: boolean
  quotation: boolean
  edit: boolean | null
  viewOrder: boolean
  zv: boolean
}

export const useFlowEnhancers = (): FlowEnhancers => {
  const routes = useCheckoutRoutes()
  const activeStoreView = useActiveStoreView()
  const guestCartId = useGuestCartId()
  const isViewOrderRoute = useRouteMatch(routes.order)
  const { shipmentCondition } = useCartSaleCondition()
  const { cart } = useCart()

  const isOmnichannel = useCallback(
    () => activeStoreView.code.includes('omnichannel'),
    [activeStoreView],
  )

  const isQuotation = useCallback(() => {
    if (!globalWindow) {
      return false
    }

    const searchParams = new URLSearchParams(globalWindow?.location.search)

    const quotationParam = searchParams.get('quotation')

    if (!quotationParam || quotationParam !== 'true') {
      return false
    }

    if (globalWindow.location.pathname.includes('success')) {
      return true
    }

    return isOmnichannel()
  }, [isOmnichannel])

  const isEdit = useCallback(() => {
    const searchParams = new URLSearchParams(globalWindow?.location.search)

    const editParam = searchParams.get('edit')

    if (!cart) {
      return null
    }

    if (
      process.env.NODE_ENV === 'development' &&
      editParam &&
      editParam === 'true'
    ) {
      return isOmnichannel()
    }

    return cart?.sap.imported && isOmnichannel()
  }, [cart, isOmnichannel])

  const isViewOrder = useCallback(
    () => Boolean(guestCartId && isViewOrderRoute && isOmnichannel()),
    [isOmnichannel, guestCartId, isViewOrderRoute],
  )

  const isZV = useCallback(
    () => shipmentCondition === 'ZV',
    [shipmentCondition],
  )

  return {
    omnichannel: isOmnichannel(),
    quotation: isQuotation(),
    edit: isEdit(),
    viewOrder: isViewOrder(),
    zv: isZV(),
  }
}
