import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'

import { getAuthorizationContext } from '@emico/login-token'
import { Unmaybe } from '@emico/utils'

import {
  AdyenPaymentDetailsMutation,
  AdyenPaymentDetailsMutationVariables,
} from './useAdyenPaymentDetails.generated'

const ADYEN_PAYMENT_DETAILS = gql`
  mutation AdyenPaymentDetails($cartId: String!, $payload: String!) {
    adyenPaymentDetails(cart_id: $cartId, payload: $payload) {
      resultCode
    }
  }
` as TypedDocumentNode<
  AdyenPaymentDetailsMutation,
  AdyenPaymentDetailsMutationVariables
>

type ResultCode = Unmaybe<
  Unmaybe<AdyenPaymentDetailsMutation, 'adyenPaymentDetails', 'resultCode'>
>

const parseData = (
  data?: AdyenPaymentDetailsMutation | null,
): ResultCode | null => {
  if (!data || !data.adyenPaymentDetails?.resultCode) {
    return null
  }

  return data.adyenPaymentDetails?.resultCode
}

export const useAdyenPaymentDetails = () => {
  const client = useApolloClient()
  const addAdyenPaymentDetails = async (cartId: string, payload: string) => {
    const { data, ...rest } = await client.mutate({
      mutation: ADYEN_PAYMENT_DETAILS,
      context: getAuthorizationContext(),
      variables: {
        cartId,
        payload,
      },
      errorPolicy: 'all',
    })

    const parsed = parseData(data)

    return {
      resultCode: parsed,
      ...rest,
    }
  }

  return {
    addAdyenPaymentDetails,
  }
}
