import { Gender } from '@checkout/gender/helpers'
import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

export type Customer = {
  gender?: Gender
  firstName?: string
  lastName?: string
  email?: string
}

export const useCartCustomer = (): Customer => {
  const cartId = useCartId()
  const { cart } = useCart(cartId)
  const { email } = cart || {}

  const { firstname, lastname } = cart?.shippingAddresses[0] || {}

  return {
    gender: undefined,
    firstName: firstname ?? undefined,
    lastName: lastname ?? undefined,
    email: email ?? undefined,
  }
}
