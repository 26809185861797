import ActiveIcon from '@checkout/assets/active.svg'
import NextIcon from '@checkout/assets/next.svg'
import PastIcon from '@checkout/assets/past.svg'
import styled from '@emotion/styled'
import React from 'react'

import { StepIconProps } from '@emico/checkout'

const icons = {
  active: ActiveIcon,
  past: PastIcon,
  next: NextIcon,
}

const Icon = styled.div<{ state: keyof typeof icons }>`
  background-image: url(${(props) => icons[props.state]});
  color: ${(props) => (props.state === 'active' ? 'white' : 'inherit')};
  width: 29px;
  height: 29px;
  background-repeat: no-repeat;
  line-height: 29px;
  text-align: center;
`

const Indicator = styled.span<{ isPast: boolean }>`
  color: ${(props) => (props.isPast ? 'transparent' : 'inherit')};
`

export const StepIcon = ({ step, currentStep }: StepIconProps) => {
  const isPast = currentStep > step

  const stepState = currentStep === step ? 'active' : isPast ? 'past' : 'next'

  return (
    <Icon state={stepState}>
      <Indicator isPast={isPast}>{step}</Indicator>
    </Icon>
  )
}
