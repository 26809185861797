import { ButtonPrimary } from '@shared/button'
import React from 'react'

interface SubmitButtonProps {
  loading?: boolean
  className?: string
  children: React.ReactNode
}

export const SubmitButton = ({
  loading,
  className,
  children,
}: SubmitButtonProps) => (
  <ButtonPrimary
    type="submit"
    analyticsContext=""
    analyticsName=""
    className={className}
    loading={loading}
  >
    {children}
  </ButtonPrimary>
)
