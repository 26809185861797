import { useCheckoutRoutes } from '@checkout/router'
import { t } from '@lingui/macro'
import { useCart } from '@shared/cart/useCart'
import useCartId, { setVaimoCartId } from '@shared/cart/useCartId'
import { useErrorMessage } from '@src/components/ErrorMessage'
import useRoutes from '@src/routes'
import useVaimoStorefront from '@src/utils/useVaimoStorefront'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

import { globalWindow } from '@emico/ssr-utils'

import { useAdyenPaymentDetails } from './useAdyenPaymentDetails'
import { useAdyenPaymentStatus } from './useAdyenPaymentStatus'

export const AdyenPaymentValidation = ({
  orderId,
  maskedOrderId,
}: {
  orderId: string
  maskedOrderId: string
}) => {
  const checkoutRoutes = useCheckoutRoutes()
  const history = useHistory()
  const routes = useRoutes()
  const { getAdyenPaymentStatus } = useAdyenPaymentStatus()
  const { addAdyenPaymentDetails } = useAdyenPaymentDetails()
  const { setMessage: setErrorMessage } = useErrorMessage()
  const isVaimoStorefront = useVaimoStorefront()
  const { cart, loading: cartLoading } = useCart()
  const cartId = useCartId()
  const location = useLocation()

  useEffect(() => {
    const redirectResult = new URLSearchParams(location.search).get(
      'redirectResult',
    )
    if (cartLoading || !cartId) {
      return
    }
    if (!cart || !redirectResult) {
      getAdyenPaymentStatus({
        orderNumber: orderId,
        cartId,
      }).then(({ adyenPaymentStatus, errors }) => {
        if (errors) {
          if (globalWindow) {
            globalWindow.location.href = routes.cart
            return
          }
        }
        if (adyenPaymentStatus?.resultCode === 'Authorised') {
          history.push(
            checkoutRoutes.success.replace(':orderId', maskedOrderId),
          )
          return
        }
      })
      return
    }
    addAdyenPaymentDetails(
      cartId,
      JSON.stringify({
        orderId,
        details: {
          redirectResult,
        },
      }),
    ).then(({ resultCode, errors }) => {
      if (resultCode === 'Authorised') {
        history.push(checkoutRoutes.success.replace(':orderId', maskedOrderId))
        return
      }
      if (isVaimoStorefront) {
        setVaimoCartId(cartId)
      }
      setErrorMessage(
        t({
          message: 'Your payment failed, Please try again later.',
        }),
      )
      if (globalWindow) {
        history.push(checkoutRoutes.overview)
        return
      }
    })
    return
  }, [
    cartLoading,
    cartId,
    cart,
    location.search,
    addAdyenPaymentDetails,
    checkoutRoutes.overview,
    checkoutRoutes.success,
    getAdyenPaymentStatus,
    history,
    isVaimoStorefront,
    maskedOrderId,
    orderId,
    setErrorMessage,
    routes.cart,
  ])
  return null
}
