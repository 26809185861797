import React, { SVGProps } from 'react'

export const BarsUnevenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 13.625"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M18.8 7.987H1.205a1.176 1.176 0 1 1 0-2.352H18.8a1.176 1.176 0 1 1 0 2.352zm-3.78 5.625H4.98a1.176 1.176 0 1 1 0-2.352h10.04a1.176 1.176 0 1 1 0 2.352zm0-11.246H4.98a1.176 1.176 0 1 1 0-2.35h10.04a1.176 1.176 0 1 1 0 2.348z"
      fill="currentColor"
    />
  </svg>
)
