import { ApolloClient } from '@apollo/client'
import gql from 'graphql-tag'

import { MuleSoftCustomer } from '../schema.generated'

const query = gql`
  query MuleSoftCustomer($customerId: Int, $email: String!) {
    muleSoftCustomer(customerId: $customerId, email: $email) {
      email
      preferences {
        communication {
          preferredChannel
          language
          subscriptions {
            subscription
            enabled
          }
        }
      }
    }
  }
`

interface Variables {
  customerId?: number | null
  email: string
}

interface Response {
  muleSoftCustomer?: MuleSoftCustomer
}

export const useGenericCustomer =
  (apolloClient: ApolloClient<unknown>) =>
  async (email: string, customerId?: number) => {
    try {
      const result = await apolloClient.query<Response, Variables>({
        query,
        variables: { email, customerId: customerId ?? null },
      })

      return result.data?.muleSoftCustomer
    } catch (error) {
      return undefined
    }
  }
