import React, { useState, useEffect, Dispatch } from 'react'

import BodyScrollLock from '@emico/body-scroll-lock'
import ModalBackdrop from '@emico/modal-backdrop'
import { ModalSegueLeft } from '@emico/modal-segue'
import Portal from '@emico/portal'
import { usePrismicDocuments } from '@emico/prismic'
import { globalWindow } from '@emico/ssr-utils'
import { Modal } from '@emico/ui'

import { PrismicTypeSubmenu } from '../schema.generated'
import usePopupsVisibility from '../utils/usePopupsVisibility'
import { MobileMenuPanel } from './MobileMenuPanel'

export const DEFAULT_MENU_PANEL = 'home'
export const PRISMIC_MOBILE_MENU_TAG = 'Mobile Menu'

interface MobileMenuModalProps {
  menuIsOpen: boolean
  setMenuIsOpen: Dispatch<boolean>
}

interface BodyScrollOptions {
  reserveScrollBarGap?: boolean
  allowTouchMove?: (el: HTMLElement | Element) => void
}

export const MobileMenuModal = ({
  menuIsOpen,
  setMenuIsOpen,
}: MobileMenuModalProps) => {
  const { showPopups, hidePopups } = usePopupsVisibility()
  const [panelOrder, setPanelOrder] = useState<string[]>([DEFAULT_MENU_PANEL])
  const closeMobileMenu = () => {
    setMenuIsOpen(false)
    setPanelOrder([DEFAULT_MENU_PANEL])
  }

  const { data: submenuData } = usePrismicDocuments<PrismicTypeSubmenu>({
    key: 'document.tags',
    values: [PRISMIC_MOBILE_MENU_TAG],
  })

  const openPanel = (key: string) =>
    setPanelOrder((panelOrder) =>
      !panelOrder.includes(key) ? [...panelOrder, key] : panelOrder,
    )

  const closePanel = () =>
    setPanelOrder((panelOrder) =>
      panelOrder.length > 1
        ? panelOrder.slice(0, panelOrder.length - 1)
        : panelOrder,
    )

  // Allow touchMove for certain elements => https://github.com/willmcpo/body-scroll-lock#allowtouchmove
  const options: BodyScrollOptions = {
    allowTouchMove: (el: HTMLElement | Element | null): void | boolean => {
      while (el && el !== globalWindow?.document.body) {
        if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
          return true
        }

        el = el.parentElement
      }
    },
  }

  useEffect(() => {
    if (menuIsOpen) {
      hidePopups()
    }
    return () => {
      if (menuIsOpen) {
        showPopups()
      }
    }
  }, [menuIsOpen, hidePopups, showPopups])

  // HACK: .sections.nav-sections has a left: -100% style applied to it which
  // prevents the menu from rendering correctly on iOS.
  useEffect(() => {
    const container = globalWindow?.document.querySelector(
      '.sections.nav-sections',
    ) as HTMLDivElement

    if (!container) {
      return
    }

    if (!menuIsOpen) {
      container.style.left = ''
      return
    }

    container.style.left = '0'
  }, [menuIsOpen])

  if (!submenuData) {
    return null
  }

  return (
    <Portal>
      <BodyScrollLock
        className="react-menu-body"
        options={options}
        lock={menuIsOpen}
      >
        <ModalBackdrop
          show={menuIsOpen}
          onClick={closeMobileMenu}
          data-testid="body-scroll-lock-backdrop"
        />
        <ModalSegueLeft show={menuIsOpen}>
          <Modal close={closeMobileMenu}>
            {submenuData.map((submenu, index) => (
              <MobileMenuPanel
                key={index}
                data={submenu.data}
                order={panelOrder.indexOf(submenu.data.key ?? '')}
                isActive={panelOrder.includes(submenu.data.key ?? '')}
                openPanel={openPanel}
                closePanel={
                  submenu.data.key === DEFAULT_MENU_PANEL
                    ? closeMobileMenu
                    : closePanel
                }
              />
            ))}
          </Modal>
        </ModalSegueLeft>
      </BodyScrollLock>
    </Portal>
  )
}
