import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { i18n } from '@emico/i18n'
import { theme } from '@emico/styles'

const Free = styled.span`
  color: ${theme.greenColor};
`

interface PriceProps {
  value: number
  className?: string
  stylizeFree?: boolean
  format?: Intl.NumberFormatOptions
}

export const Price = ({ className, value, stylizeFree }: PriceProps) => {
  const formatter = new Intl.NumberFormat(i18n.locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const formatted = formatter.format(value).replace(/00$/, '-') // Replace the last two zeroes with a dash

  return stylizeFree && value === 0 ? (
    <Free className={className}>
      <Trans id="price.free">Gratis</Trans>
    </Free>
  ) : (
    <span className={className}>{formatted}</span>
  )
}
