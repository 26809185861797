import { useGuestCartId } from '@checkout/cart'
import { useCart } from '@shared/cart/useCart'

/**
 * Hook that returns information from the cart in regard to the order information
 */
export const useCartOrderInformation = () => {
  const guestCartId = useGuestCartId()
  const { cart } = useCart(guestCartId)

  const orderInformation = cart?.orderInformation

  return {
    reference: orderInformation?.reference ?? undefined,
    wouldRefer: orderInformation?.wouldRefer ?? undefined,
    particularities: orderInformation?.particularities ?? undefined,
    foundUs: orderInformation?.foundUs ?? undefined,
    age: orderInformation?.age ?? undefined,
    headboardMovable: orderInformation?.headboardMovable ?? undefined,
  }
}
