import { DefaultContainerElementProps } from '@checkout/ui/defaultElementProps.types'
import styled from '@emotion/styled'
import theme from '@src/theme'

import { minWidth } from '@emico/styles'

export const CompactDetails = styled.div<DefaultContainerElementProps>`
  display: flex;
  align-items: center;
  padding: 21px;
  margin-bottom: 15px;
  background: ${theme.colorGrey};
  border-radius: 10px;
  font-size: 14px;
  line-height: 18px;

  @media ${minWidth('sm')} {
    padding: 21px 30px;
  }
`
