import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '@src/theme'

import { Button as ButtonComponent } from '@emico/ui'

export const resetStyles = css`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  /** Some very specific button reset styles */
  :not(.primary):active {
    box-shadow: none;
  }
`

export const Button = styled(ButtonComponent.original)`
  ${resetStyles}

  height: 40px;
  min-height: 40px;
  padding: 0 30px;
  border-radius: 20px;
  font-family: ${theme.fontFamilyPrimary};
  font-weight: normal;
  touch-action: manipulation;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  :disabled {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }
`
