import { useApolloClient } from '@apollo/client'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useErrorMessage } from '@src/components/ErrorMessage'
import { PillowLoader } from '@src/components/PillowLoader'
import { SubmitButton } from '@src/components/SubmitButton'
import { useSuccessMessage } from '@src/components/SuccessMessage'
import { useGenericCustomer } from '@src/components/useGenericCustomer'
import { MuleSoftCustomer } from '@src/schema.generated'
import React, { useEffect, useState } from 'react'

import { globalWindow } from '@emico/ssr-utils'
import { useActiveStoreView } from '@emico/storeviews'
import { maxWidth, minWidth, theme } from '@emico/styles'
import { Form } from '@emico/ui'

import { SettingType } from './constants'
import Setting from './Setting'
import useUpdateGenericCustomerPreferences from './useUpdateGenericCustomerPreferences'

const PreloaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
`

const FormWrapper = styled.div`
  background: ${theme.backgroundColor};

  @media ${minWidth('md')} {
    padding: 40px 65px;
  }
`

const SubmitButtonWrapper = styled.div`
  margin-top: 30px;

  @media ${maxWidth('sm')} {
    padding: 0 20px;
  }

  @media ${minWidth('md')} {
    margin-top: 40px;
  }
`

const StyledSubmitButton = styled(SubmitButton)`
  font-size: 20px;
  font-weight: 700;
  display: block;
  width: 100%;
  height: 60px;
  border-radius: 4px;
`

interface Props {
  customerId: number
  email: string
}

// eslint-disable-next-line
type FormValues = { [key: string]: any }

// Field values are based on MuleSoft API return values.
// As they can change at any time, we type them as type "string",
// and check for translations
const getTranslatedTitle = (field: string): string | undefined => {
  const { NEWSLETTER, PROMOTIONAL, ADVICE, PREFERRED_CHANNEL, LANGUAGE } =
    SettingType

  switch (field) {
    case NEWSLETTER:
      return t({
        id: 'contactPreferences.subscriptionTitles.newsletter',
        message: 'Newsletter subscription',
      })
    case PROMOTIONAL:
      return t({
        id: 'contactPreferences.subscriptionTitles.promotional',
        message: 'Promotional subscription',
      })
    case ADVICE:
      return t({
        id: 'contactPreferences.subscriptionTitles.advice',
        message: 'Advice subscription',
      })
    case LANGUAGE:
      return t({
        id: 'contactPreferences.subscriptionTitles.language',
        message: 'Language setting',
      })
    case PREFERRED_CHANNEL:
      return t({
        id: 'contactPreferences.subscriptionTitles.preferredChannel',
        message: 'Preferred channel setting',
      })

    default:
      console.error(
        `Could not set a title for unknown subscription type: "${field}".`,
      )
      return undefined
  }
}

const getTranslatedDescription = (field: string): string | undefined => {
  const { NEWSLETTER, PROMOTIONAL, ADVICE, LANGUAGE, PREFERRED_CHANNEL } =
    SettingType
  switch (field) {
    case NEWSLETTER:
      return t({
        id: 'contactPreferences.subscriptionDescriptions.newsletter',
        message: 'Newsletter description',
      })
    case PROMOTIONAL:
      return t({
        id: 'contactPreferences.subscriptionDescriptions.promotional',
        message: 'Promotional description',
      })
    case ADVICE:
      return t({
        id: 'contactPreferences.subscriptionDescriptions.advice',
        message: 'Advice description',
      })
    case LANGUAGE:
      return t({
        id: 'contactPreferences.subscriptionDescriptions.language',
        message: 'Language description',
      })
    case PREFERRED_CHANNEL:
      return t({
        id: 'contactPreferences.subscriptionDescriptions.preferredChannel',
        message: 'Preferred channel description',
      })
    default:
      console.error(
        `Could not set a description for unknown subscription type: "${field}".`,
      )
      return undefined
  }
}

const CustomerSubscriptionsForm = ({ customerId, email }: Props) => {
  const storeView = useActiveStoreView()
  const apolloClient = useApolloClient()
  const [isSaving, setSaving] = useState<boolean>(false)
  const [customer, setCustomer] = useState<MuleSoftCustomer | undefined>(
    undefined,
  )
  const { setMessage: setErrorMessage } = useErrorMessage()
  const { setMessage: setSuccessMessage } = useSuccessMessage()
  const getCustomerByEmail = useGenericCustomer(apolloClient)
  const updateGenericCustomerPreferences = useUpdateGenericCustomerPreferences()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const customer = await getCustomerByEmail(email, customerId)

      if (!customer && globalWindow) {
        // Redirect to email form
        globalWindow.location.href = `${globalWindow.location.origin}${globalWindow.location.pathname}`
      }

      setCustomer(customer)
    })()
  }, [getCustomerByEmail, email, customerId])

  const errorMessage = t({
    id: 'contactPreferences.couldNotSaveSettings',
    message: 'There was a problem with saving the settings.',
  })
  const successMessage = t({
    id: 'contactPreferences.settingsSaved',
    message: 'Settings have been saved.',
  })

  if (!customer) {
    return (
      <PreloaderContainer>
        <PillowLoader />
      </PreloaderContainer>
    )
  }

  const initialValues = () => {
    const preferences = customer?.preferences.communication

    const setDefaultLanguage = (language: string) => {
      const LANGUAGE: { [key: string]: string } = {
        nl: 'nl',
        be: 'nl',
        de: 'de',
        at: 'de',
      }

      return LANGUAGE[language] ?? 'nl'
    }

    return (
      {
        ...preferences?.subscriptions.reduce<FormValues>(
          (subscriptions, customer) => {
            if (customer) {
              subscriptions[customer.subscription] = customer.enabled
            }
            return subscriptions
          },
          {},
        ),
        preferredChannel: preferences?.preferredChannel,
        language:
          preferences?.language?.toLocaleLowerCase() ??
          setDefaultLanguage(storeView.language),
      } || {}
    )
  }

  const handleSubmit = async (values: FormValues) => {
    setSaving(true)

    try {
      const subscriptions = Object.entries(values)
        .map(([key, value]) => {
          const { LANGUAGE, PREFERRED_CHANNEL } = SettingType
          if (
            key !== LANGUAGE &&
            key !== PREFERRED_CHANNEL &&
            value !== undefined &&
            value !== ''
          ) {
            return {
              subscription: key,
              enabled: value,
            }
          }

          return null
        })
        .filter((subscription) => Boolean(subscription))

      await updateGenericCustomerPreferences({
        customerId,
        email,
        preferences: {
          communication: {
            subscriptions,
            language: values.language.toLowerCase(),
            preferredChannel: values.preferredChannel,
          },
        },
      })

      setSaving(false)
      setSuccessMessage(successMessage)
    } catch (e) {
      setSaving(false)
      setErrorMessage(e.message || errorMessage)
    }
  }

  return (
    <FormWrapper>
      <Form<FormValues> initialValues={initialValues()} onSubmit={handleSubmit}>
        {Object.entries(initialValues()).map(([field, value]) => (
          <Setting
            value={value ?? undefined}
            key={field}
            field={field}
            title={getTranslatedTitle(field)}
            description={getTranslatedDescription(field)}
          />
        ))}
        <SubmitButtonWrapper>
          <StyledSubmitButton className="full-width" loading={isSaving}>
            <Trans id="contactPreferences.customerSubscriptionsForm.submitLabel">
              Voorkeuren opslaan
            </Trans>
          </StyledSubmitButton>
        </SubmitButtonWrapper>
      </Form>
    </FormWrapper>
  )
}

export default CustomerSubscriptionsForm
