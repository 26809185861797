import { useGuestCartId } from '@checkout/cart'
import { useCountries } from '@checkout/country'
import { Gender, genderMap } from '@checkout/gender/helpers'
import { useCart } from '@shared/cart/useCart'

export type ShippingAddress = {
  gender?: Gender
  firstName?: string
  lastName?: string
  fullName?: string
  email?: string
  telephone?: string
  street?: string
  houseNumber?: string
  houseNumberAddition?: string
  postalCode?: string
  city?: string
  countryId?: string
  countryName?: string
  regionId?: number
  region?: string
  fullRegion?: string
  sameAsBilling?: boolean
  isDummy?: boolean
  floors?: number
  lift?: boolean
}

/**
 * Hook that returns the shipping address on the cart
 */
export const useCartShippingAddress = (): ShippingAddress => {
  const guestCartId = useGuestCartId()
  const { cart } = useCart(guestCartId)
  const { countries } = useCountries()

  const {
    firstname,
    lastname,
    telephone,
    street: streetArray,
    postcode,
    city,
    country,
    region,
    sameAsBilling,
    isDummyAddress,
    questions,
  } = cart?.shippingAddresses[0] || {}

  const { email } = cart || {}

  const { floor, elevatorPresent } = questions || {}

  const foundCountry = countries.find(
    (countryData) => countryData.id === country?.code,
  )
  const foundRegion = foundCountry?.availableRegions?.find(
    (regionData) => regionData?.code === region?.code,
  )

  return {
    gender: genderMap[cart?.shippingAddresses[0]?.genderAddress ?? ''],
    firstName: firstname ?? undefined,
    lastName: lastname ?? undefined,
    fullName:
      firstname || lastname
        ? [firstname, lastname].filter((value) => Boolean(value)).join(' ')
        : undefined,
    email: email ?? undefined,
    telephone: telephone ?? undefined,
    street: streetArray?.[0] ?? undefined,
    houseNumber: streetArray?.[1] ?? undefined,
    houseNumberAddition: streetArray?.[2] ?? undefined,
    postalCode: postcode ?? undefined,
    city: city ?? undefined,
    countryId: foundCountry?.id ?? undefined,
    countryName: foundCountry?.fullNameLocale ?? undefined,
    regionId: foundRegion?.id ?? undefined,
    region: foundRegion?.name ?? undefined,
    fullRegion: foundRegion
      ? `${foundRegion.id}|${foundRegion.name}`
      : undefined,
    sameAsBilling: sameAsBilling !== false,
    isDummy: isDummyAddress ? Boolean(sameAsBilling) : undefined,
    floors: floor ?? undefined,
    lift: elevatorPresent ?? undefined,
  }
}
