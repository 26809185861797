import styled from '@emotion/styled'
import theme from '@src/theme'
import React from 'react'

import { ChevronRightIcon } from '@emico/icons'

import { ButtonUnstyled } from './ButtonUnstyled'

const StyledButton = styled(ButtonUnstyled)`
  font-family: ${theme.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  color: ${theme.onPrimaryColor};
  background: ${theme.primaryColor};
  height: 45px;
  line-height: 45px;
  border-radius: 4px;
  position: relative;
  padding: 0 50px 0 15px;

  :hover,
  :focus,
  :active {
    background: ${theme.primaryColor};
  }
`

const IconWrapper = styled.span`
  width: 34px;
  height: 45px;
  background: ${theme.colorRed};
  border-radius: 0 4px 4px 0;
  position: absolute;
  top: 0;
  right: 0;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: ${theme.onPrimaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface ArrowButtonProps {
  analyticsName: string
  analyticsContext: string
  onClick?(e: React.MouseEvent<HTMLElement, MouseEvent>): void
  children: React.ReactNode
}

export const ArrowButton = ({
  analyticsName,
  analyticsContext,
  onClick,
  children,
}: ArrowButtonProps) => (
  <StyledButton
    analyticsName={analyticsName}
    analyticsContext={analyticsContext}
    onClick={onClick}
  >
    {children}
    <IconWrapper>
      <StyledChevronRightIcon />
    </IconWrapper>
  </StyledButton>
)
