import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { ExternalLink } from '@emico/link'
import { globalWindow } from '@emico/ssr-utils'
import { theme, minWidth, maxWidth } from '@emico/styles'
import { Button, PageWrapper } from '@emico/ui'

import { SwissSenseLogo } from './SwissSenseLogo'

const pageWrapperCss = css`
  background: ${theme.primaryColor};
  position: relative;
`

const Container = styled.div`
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.onPrimaryColor};
  max-width: 1440px;
  padding-top: 250px;
  margin: 0 auto;

  @media ${minWidth('md')} {
    padding-top: 325px;
  }
`

const Main = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
  z-index: 2;

  @media ${minWidth('md')} {
    padding-left: calc((870 / 1440) * 100%);
  }
`

const StyledSwissSenseLogo = styled(SwissSenseLogo)`
  max-width: 252px;
`

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 20px 0 15px;

  @media ${minWidth('md')} {
    font-size: 60px;
    margin-top: 30px;
  }
`

const Content = styled.p`
  font-size: 20px;
  line-height: 1.3;

  @media ${minWidth('md')} {
    font-size: 32px;
  }
`

const StyledButton = styled(Button)`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.onSecondaryVariantColor};
  border-radius: 2px;
  padding: 9px 0;

  @media ${maxWidth('sm')} {
    width: 100%;
  }

  @media ${minWidth('md')} {
    font-size: 24px;
    padding: 11px 65px;
  }
`

const ImageWrapper = styled.div`
  width: 324px;
  height: 220px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media ${minWidth('md')} {
    width: 825px;
    height: 1022px;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url('/media/error-boundary.jpg') no-repeat center;
  background-size: cover;
`

const ImageMask = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='325px' height='220px' viewBox='0 0 325 220' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ERectangle 2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Background-asset-4' transform='translate(-110.684000, -38.895300)' fill='%231C2125' fill-rule='nonzero'%3E%3Cpath d='M435.684,38.8953 L435.684,258.8953 L110.684,258.8953 L110.682833,134.431195 C142.552006,171.007128 188.208116,216.843341 243.459,254.0773 C245.639,255.1683 246.729,255.1683 248.909,254.0773 C266.351,242.0863 362.28,173.4103 429.866,73.1206 C430.957,70.9404 430.957,69.8503 429.866,67.67 C426.660639,63.0076176 419.404753,52.7367751 408.577233,38.8980843 L435.684,38.8953 Z' id='Rectangle-2'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @media ${minWidth('md')} {
    background-image: url("data:image/svg+xml,%3Csvg width='830px' height='1023px' viewBox='0 0 830 1023' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape + Background%3C/title%3E%3Cdefs%3E%3Cpath d='M830,0 L830,1023 L1.02318154e-12,1023 L-0.000343710184,1021.91089 C0.749826934,1021.91735 1.54398083,1021.89976 2.38937399,1021.86156 C72.9044099,1008.1713 464.506781,923.943779 812.210388,694.872038 C818.597025,689.33709 820.105599,685.772325 819.750477,677.04821 C808.886448,614.464027 751.065348,312.748517 604.769506,0.000415092797 L830,0 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Shape-+-Background'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Rectangle-2' fill='%231C2125' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
`

export const FatalErrorPage = () => {
  if (globalWindow) {
    globalWindow.onpopstate = () => {
      globalWindow?.location.reload()
    }
  }

  return (
    <PageWrapper
      pageType="N/A"
      header={null}
      footer={null}
      padding={false}
      css={pageWrapperCss}
    >
      <Container>
        <Main>
          <StyledSwissSenseLogo />
          <Heading>
            <Trans id="errorBoundary.errorPage.heading">
              There is something wrong.
            </Trans>
          </Heading>
          <Content>
            <Trans id="errorBoundary.errorPage.text">
              Perhaps the website has been adjusted or we fell asleep for a
              while...
            </Trans>
          </Content>
          <ExternalLink to="/" analyticsName="" analyticsContext="">
            <StyledButton analyticsName="" analyticsContext="">
              <Trans id="errorBoundary.errorPage.buttonLabel">
                Back to home
              </Trans>
            </StyledButton>
          </ExternalLink>
        </Main>
        <ImageWrapper>
          <Image />
          <ImageMask />
        </ImageWrapper>
      </Container>
    </PageWrapper>
  )
}
