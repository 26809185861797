import aa from 'search-insights'

const appId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY

export const initAlgolia = () => {
  if (!appId || !apiKey) {
    console.error('No APP ID or API KEY found for Algolia')
    return
  }

  aa('init', {
    appId,
    apiKey,
    useCookie: true,
    cookieDuration: 7_776_000_000, // 3 months
  })
}
