import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { PrismicLink } from '@emico/prismic'
import { minWidth } from '@emico/styles'

import { SubmenuBodySubmenuSection } from '../schema.generated'
import theme from '../theme'
import { pushEvent } from '../utils/analytics'

const SubmenuList = styled.ul`
  padding: 0;
  margin: 0;
`

const SubmenuListItem = styled.li`
  display: block;
`

const SubmenuTitle = styled.span`
  font-family: ${theme.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${theme.onBackgroundColor};
  margin: 0 0 10px 0;
`

const SubmenuListItemLink = styled(PrismicLink, {
  shouldForwardProp: (prop: string) => !['highlighted'].includes(prop),
})<{ highlighted: boolean }>(
  ({ highlighted }) => css`
    font-family: ${theme.fontFamilySecondary};
    color: ${theme.onBackgroundColor};
    display: block;
    padding: 0;
    line-height: 28px;

    &:hover {
      color: ${theme.onBackgroundColor};
      text-decoration: underline;
    }
    &:active,
    &:visited {
      color: ${theme.onBackgroundColor} !important;
    }

    @media ${minWidth('lg')} {
      font-weight: 400;
      font-size: 14px;
    }

    ${highlighted &&
    css`
      color: ${theme.secondaryColor};

      @media ${minWidth('lg')} {
        font-weight: 600;
      }

      &:hover {
        color: ${theme.secondaryColor};
        font-weight: 600;
      }
      &:active,
      &:visited {
        color: ${theme.secondaryColor} !important;
      }
    `}
  `,
)

interface PrismicSliceSubmenuSectionProps {
  slice: SubmenuBodySubmenuSection
  submenuTitle: string
  columnId: number
}

export const PrismicSliceSubmenuSection = ({
  slice,
  submenuTitle,
  columnId,
}: PrismicSliceSubmenuSectionProps) => (
  <>
    <SubmenuTitle> {slice.primary?.title}</SubmenuTitle>

    <SubmenuList>
      {slice?.fields?.map((item, index) =>
        item.link ? (
          <SubmenuListItem key={index}>
            <SubmenuListItemLink
              external
              to={item.link}
              analyticsName=""
              analyticsContext=""
              onClick={() => {
                pushEvent({
                  event: `menu click`,
                  eventCategory: `menu`,
                  eventAction: 'click',
                  eventLabel: `${submenuTitle} - ${slice?.primary?.title} - ${
                    item.linkTitle
                  } - ${columnId}.${index + 1}`,
                })
              }}
              highlighted={Boolean(item.linkHighlighted)}
              data-testid={`submenu.section.link.${index}`}
            >
              {item.linkTitle}
            </SubmenuListItemLink>
          </SubmenuListItem>
        ) : null,
      )}
    </SubmenuList>
  </>
)
