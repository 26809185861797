import { useApolloClient } from '@apollo/client'
import { MuleSoftCustomerPreferencesInput } from '@src/schema.generated'
import gql from 'graphql-tag'

const mutation = gql`
  mutation updateMuleSoftCustomerPreferences(
    $customerId: Int!
    $email: String!
    $preferences: MuleSoftCustomerPreferencesInput!
  ) {
    updateMuleSoftCustomerPreferences(
      customerId: $customerId
      email: $email
      preferences: $preferences
    )
  }
`

interface Variables {
  customerId: number
  email: string
  preferences: MuleSoftCustomerPreferencesInput
}

const useUpdateGenericCustomerPreferences = () => {
  const apolloClient = useApolloClient()

  return async ({ customerId, email, preferences }: Variables) => {
    await apolloClient.mutate<boolean, Variables>({
      mutation,
      variables: {
        customerId,
        email,
        preferences,
      },
    })

    return true
  }
}

export default useUpdateGenericCustomerPreferences
