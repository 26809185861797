import styled from '@emotion/styled'
import React from 'react'

import { theme } from '@emico/styles'

const Dots = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
`

const Dot = styled.button<{ active: boolean }>`
  border: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 8px;
  padding: 0;
  cursor: pointer;
  background: ${theme.onPrimaryColor};
  opacity: ${(props) => (props.active ? 0.8 : 0.2)};
`

interface SliderDotsProps {
  slides: number
  currentSlide: number
  onDotClick(index: number): void
}

export const SliderDots = ({
  slides,
  currentSlide,
  onDotClick,
}: SliderDotsProps) => {
  const dots = []
  for (let i = 0; i < slides; i += 1) {
    dots.push(
      <Dot key={i} active={currentSlide === i} onClick={() => onDotClick(i)} />,
    )
  }

  return <Dots>{dots}</Dots>
}
