import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useShoppingCartContext } from '@shared/cart/ShoppingCartContext'
import React, { Dispatch, useEffect } from 'react'

import BodyScrollLock from '@emico/body-scroll-lock'
import ModalBackdrop from '@emico/modal-backdrop'
import { globalWindow } from '@emico/ssr-utils'

import usePopupsVisibility from '../../utils/usePopupsVisibility'
import { ShoppingCartFooter } from './ShoppingCartFooter'
import { ShoppingCartHeader } from './ShoppingCartHeader'
import { ShoppingCartItems } from './ShoppingCartItems'

const StyledModal = styled(ModalBackdrop)`
  z-index: 99998;
`

const ShoppingCartWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})<{
  isOpen: boolean
}>`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : `-470px`)};
  width: 470px;
  height: 100%;
  transition: right 0.3s ease-in-out;
`

interface Props {
  isOpen: boolean
  setOpen: Dispatch<boolean>
}

interface BodyScrollOptions {
  allowTouchMove?: (el: HTMLElement | Element) => void
}

export const ShoppingCart = ({ isOpen, setOpen }: Props) => {
  const { setCart, refreshCart } = useShoppingCartContext()
  const { showPopups, hidePopups } = usePopupsVisibility()

  // Allow touchMove for certain elements => https://github.com/willmcpo/body-scroll-lock#allowtouchmove
  const options: BodyScrollOptions = {
    allowTouchMove: (el: HTMLElement | Element | null): void | boolean => {
      while (el && el !== globalWindow?.document.body) {
        if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
          return true
        }

        el = el.parentElement
      }
    },
  }

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (!globalWindow) {
        return
      }

      // disabled on cart page
      if (globalWindow.location.href.includes('cart')) {
        return
      }

      e.stopPropagation()
      e.preventDefault()
      setOpen(true)
    }

    const openCart = globalWindow?.document.querySelector(
      '.minicart-wrapper .showcart',
    )

    openCart?.addEventListener('click', handleClick)

    return () => openCart?.removeEventListener('click', handleClick)
  }, [setOpen])

  useEffect(() => {
    // Reload on open
    if (isOpen) {
      refreshCart()
    }
  }, [isOpen, refreshCart])

  useEffect(() => {
    // Reload on magento cart event
    globalWindow?.document?.addEventListener('addtocart:success', () =>
      setCart(null),
    )

    return () => {
      globalWindow?.document?.removeEventListener('addtocart:success', () =>
        setCart(null),
      )
    }
  }, [isOpen, refreshCart, setCart])

  useEffect(() => {
    if (isOpen) {
      hidePopups()
    }

    return () => {
      if (isOpen && globalWindow?.Intercom) {
        showPopups()
      }
    }
  }, [isOpen, hidePopups, showPopups])

  return (
    <>
      {isOpen && (
        <Global
          styles={css`
            // Disable magento loading overlay
            .loading-mask .loader > img {
              display: none;
            }
          `}
        />
      )}

      <BodyScrollLock options={options} lock={isOpen} id="react-shopping-cart">
        <StyledModal
          show={isOpen}
          onClick={() => {
            setOpen(false)
          }}
          data-testid="body-scroll-lock-backdrop"
        />

        <ShoppingCartWrapper isOpen={isOpen}>
          <ShoppingCartHeader setOpen={setOpen} />

          <ShoppingCartItems setOpen={setOpen} />

          <ShoppingCartFooter />
        </ShoppingCartWrapper>
      </BodyScrollLock>
    </>
  )
}
