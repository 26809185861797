import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useShoppingCartContext } from '@shared/cart/ShoppingCartContext'
import theme from '@src/theme'
import React, { Dispatch } from 'react'

import { ShoppingCartIcon } from '@emico/icons'
import { ExternalLink } from '@emico/link'
import { useActiveStoreView } from '@emico/storeviews/src/index'
import { minWidth, maxWidth } from '@emico/styles'
import { useBreakpoints } from '@emico/ui'

import useRoutes from '../../routes'
import isOmnichannelStoreView from '../../utils/isOmnichannelStoreView'
import { ActionButton, ActionLabel } from './Header'
export const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  color: ${theme.secondaryColor};
  vertical-align: bottom;
  order: 2;
  height: 18px;

  @media ${minWidth('md')} {
    margin-left: 1em;
    height: initial;
  }
`

const Quantity = styled.span`
  position: absolute;
  top: -10px;
  right: -9px;
  line-height: 16px;
  height: 14px;
  min-width: 10px;
  margin: 0;
  background: ${theme.secondaryColor};
  color: ${theme.onSecondaryColor};
  border-radius: 2px;
  display: inline-block;
  overflow: hidden;
  padding: 0 3px;
  text-align: center;
  white-space: normal;
  font-size: 0.75rem;
  font-family: ${theme.fontFamilySecondary};
  font-weight: 400;

  @media ${maxWidth('md')} {
    top: -6px;
    right: 0;
  }
`

const SLIDEOUT_CART_ENABLED = process.env.REACT_APP_SLIDEOUT_CART_ENABLED

interface HeaderCartProps {
  setShoppingCartOpen?: Dispatch<boolean>
}

export const HeaderCart = ({ setShoppingCartOpen }: HeaderCartProps) => {
  const { cart } = useShoppingCartContext()
  const routes = useRoutes()
  const { isDesktop } = useBreakpoints()
  const storeView = useActiveStoreView()

  if (
    SLIDEOUT_CART_ENABLED === 'true' &&
    isDesktop &&
    setShoppingCartOpen &&
    !isOmnichannelStoreView(storeView)
  ) {
    return (
      <ActionButton
        analyticsContext="header"
        analyticsName="Shopping cart"
        onClick={() => setShoppingCartOpen(true)}
      >
        <ActionLabel>
          <Trans id="header.cart">Winkelmand</Trans>
        </ActionLabel>
        {cart?.totalQuantity ? <Quantity>{cart.totalQuantity}</Quantity> : null}
        <StyledShoppingCartIcon />
      </ActionButton>
    )
  }

  return (
    <ActionButton
      analyticsContext="header"
      analyticsName="Shopping cart"
      as={(props) => <ExternalLink {...props} to={routes.cart} />}
    >
      <ActionLabel>
        <Trans id="header.cart">Winkelmand</Trans>
      </ActionLabel>
      {cart?.totalQuantity ? <Quantity>{cart.totalQuantity}</Quantity> : null}
      <StyledShoppingCartIcon />
    </ActionButton>
  )
}
