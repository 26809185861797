import { TypedDocumentNode, useQuery } from '@apollo/client'
import useCartId from '@shared/cart/useCartId'
import gql from 'graphql-tag'

import { getAuthorizationContext } from '@emico/login-token'
import { Unmaybe } from '@emico/utils'

import {
  CartPaymentMethodsQuery,
  CartPaymentMethodsQueryVariables,
} from './usePaymentMethods.generated'

export const CART_PAYMENT_METHODS = gql`
  query CartPaymentMethods($cartId: String!) {
    cart(cart_id: $cartId) {
      availablePaymentMethods {
        code
        title
      }
    }
  }
` as TypedDocumentNode<
  CartPaymentMethodsQuery,
  CartPaymentMethodsQueryVariables
>

export type PaymentMethod = Unmaybe<
  Unmaybe<CartPaymentMethodsQuery, 'cart', 'availablePaymentMethods'>
>

const parseData = (
  data: CartPaymentMethodsQuery | undefined,
): PaymentMethod[] => {
  if (!data) {
    return []
  }

  const typeGuardedData = data?.cart?.availablePaymentMethods?.filter(
    (paymentMethod): paymentMethod is PaymentMethod => paymentMethod !== null,
  )

  if (!typeGuardedData) {
    return []
  }

  return typeGuardedData
}

export const usePaymentMethods = (useCache: boolean = true) => {
  const cartId = useCartId()

  const { data, refetch, ...rest } = useQuery(CART_PAYMENT_METHODS, {
    query: CART_PAYMENT_METHODS,
    context: getAuthorizationContext(),
    fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      cartId: cartId ?? '',
    },
  })

  return {
    paymentMethods: parseData(data),
    getPaymentMethods: refetch,
    ...rest,
  }
}
