import { QueryResult, TypedDocumentNode, useQuery } from '@apollo/client'
import { useShoppingCartContext } from '@shared/cart/ShoppingCartContext'
import useCartId from '@shared/cart/useCartId'
import gql from 'graphql-tag'
import { useMemo } from 'react'

import { getAuthorizationContext } from '@emico/login-token'
import { Unmaybe } from '@emico/utils'

import { cartFragment } from './fragments'
import { CartQuery, CartQueryVariables } from './useCart.generated'

export const CART = gql`
  ${cartFragment}
  query Cart($cartId: String!) {
    cart(cart_id: $cartId) {
      ...SwissCartFragment
    }
  }
` as TypedDocumentNode<CartQuery, CartQueryVariables>

export type Cart = Unmaybe<CartQuery, 'cart'>

export type CartItem = Unmaybe<Unmaybe<CartQuery, 'cart', 'items'>>
export type CartCoupon = Unmaybe<Unmaybe<CartQuery, 'cart', 'appliedCoupons'>>
export type CartShippingAddress = Unmaybe<
  Unmaybe<CartQuery, 'cart', 'shippingAddresses'>
>
export type CartBillingAddress = Unmaybe<CartQuery, 'cart', 'billingAddress'>
export type CartAddress = CartShippingAddress | CartBillingAddress

export type TotalSegment = Unmaybe<CartQuery, 'cart', 'totalSegments'>

const cleanData = (data?: CartQuery): Cart => {
  const cart: Cart | null = data?.cart ?? null

  if (!cart) {
    return null
  }

  return {
    ...cart,
    __typename: undefined,
  }
}

type UseCartReturn = {
  cart: Cart | null
} & Pick<
  QueryResult<CartQuery, CartQueryVariables>,
  'loading' | 'refetch' | 'called'
>

type UseContextCartReturn = {
  cart: Cart | null
  refetch: () => Promise<void>
  loading: boolean
}

export const useCart = (id?: string): UseContextCartReturn => {
  const { cart, cartLoading, refreshCart } = useShoppingCartContext()

  return {
    cart,
    refetch: refreshCart,
    loading: cartLoading,
  }
}

export const useCartActual = (id?: string): UseCartReturn => {
  const cartId = useCartId()
  const { data, refetch, loading, called } = useQuery(CART, {
    query: CART,
    variables: {
      cartId: cartId ?? '',
    },
    skip: !cartId,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    context: getAuthorizationContext(),
  })

  const cart = useMemo(() => cleanData(data), [data])

  return {
    cart,
    refetch,
    loading,
    called,
  }
}
