import { push } from '@emico/google-tag-manager'
import { globalWindow } from '@emico/ssr-utils'

import capitalize from '../utils/capitalize'

export interface DLVPromotion {
  // A unique identifier of the promotion.
  id: string
  // The name of the promotion.
  name: string
  // The category of the promotion.
  creative: string
  // The position of the promotion on the page.
  position: number
}
// Promotion impressions are impressions of a promotional banner of a particular product, product group or any other content.
// These promotion impressions are sent by pushing a promoView object containing one or more promotion(s).
// The dataLayer.push needs to be pushed every time a page with promotions is viewed.
export interface DLVPromotionView {
  event: 'promotionView'
  ecommerce: {
    promoView: {
      promotions: DLVPromotion[]
    }
  }
}

// Promotion clicks are clicks on promotion impressions.
// These promotion clicks are sent by pushing a promoClick object containing one promotion.
// The dataLayer.push needs to be pushed every time a promotion impression is clicked.
export interface DLVPromotionClick {
  event: 'promotionClick'
  ecommerce: {
    promoClick: {
      promotions: DLVPromotion[]
    }
  }
}

export interface AnalyticsEvent {
  type: string
  category?: string
  action?: string
  label?: string
}

interface DataLayerEvent {
  event: string
  eventCategory: string
  eventAction?: string
  eventLabel?: string
}

export function pushEvent(dataLayerEvent: DataLayerEvent) {
  const { event, eventCategory } = dataLayerEvent

  if (!event || !eventCategory) {
    return
  }

  const capitalizedEventCategory = eventCategory
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')

  push({
    ...dataLayerEvent,
    eventCategory: capitalizedEventCategory,
  })
}

export function pushStepEvent(event: AnalyticsEvent) {
  const { type, category, action, label } = event

  if (!type || !action) {
    return
  }

  pushEvent({
    event: `${type}_step`,
    eventCategory: `${category || type} Steps`,
    eventAction: action,
    eventLabel: label,
  })
}

export function pushSelectionEvent(event: AnalyticsEvent) {
  const { type, category, action, label } = event

  if (!type || !action) {
    return
  }

  pushEvent({
    event: `${type}_selection`,
    eventCategory: `${category || type} Selection`,
    eventAction: action,
    eventLabel: label,
  })
}

export function pushPromotionViewEvent(promotionEvent: DLVPromotionView) {
  const { event, ecommerce } = promotionEvent

  if (!event || !ecommerce) {
    return
  }

  push({
    ...promotionEvent,
  })
}

export function promotionViewEvent(events: DLVPromotion[]) {
  if (!events) {
    return
  }

  pushPromotionViewEvent({
    event: 'promotionView',
    ecommerce: {
      promoView: {
        promotions: events,
      },
    },
  })
}

export function pushPromotionClickEvent(promotionEvent: DLVPromotionClick) {
  const { event, ecommerce } = promotionEvent

  if (!event || !ecommerce) {
    return
  }

  push({
    ...promotionEvent,
  })
}

export function promotionClickEvent(event: DLVPromotion) {
  const { id } = event

  if (!id) {
    return
  }

  pushPromotionClickEvent({
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [event],
      },
    },
  })
}

export function getGoogleClientId(): Promise<string | undefined> {
  return new Promise((resolve) => {
    // has user accepted marketing cookies
    const marketing: boolean | undefined =
      globalWindow?.Cookiebot?.consent?.marketing

    if (!marketing || typeof ga !== 'function') {
      return resolve(undefined)
    }

    ga((tracker) => {
      if (!tracker) {
        return resolve(undefined)
      }

      const clientId: string = tracker.get('clientId')
      resolve(clientId)
    })
  })
}
