import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

/**
 * Hook that returns information from the cart in regards to Mulesoft
 */
export const useCartMulesoft = () => {
  const cartId = useCartId()
  const { cart } = useCart(cartId)

  return {
    customerId:
      typeof cart?.mulesoftCustomerId === 'string'
        ? cart.mulesoftCustomerId
        : undefined,
  }
}
