import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'

import { theme, minWidth } from '@emico/styles'

import { PrismicTypeSubmenu, SubmenuBody } from '../schema.generated'
import { MobileMenuCategory } from './MobileMenuCategory'
import { MobileMenuHeader } from './MobileMenuHeader'
import { MobileMenuVisual } from './MobileMenuVisual'

export const Panel = styled('div', {
  shouldForwardProp: (prop: string) => !['isOpen', 'order'].includes(prop),
})<{ isOpen: boolean; order: number }>(
  ({ isOpen, order }) => css`
    width: calc(100% - 48px);
    height: 100%;
    overflow-y: auto;
    position: absolute;
    background-color: ${theme.backgroundColor};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: transform ${theme.primaryTransitionSpeed}ms
      ${theme.primaryTransitionEase};
    z-index: ${order};
    letter-spacing: 0.03em;

    ${!isOpen &&
    css`
      transform: translateX(-100%);
    `}

    @media ${minWidth('sm')} {
      width: calc(100% - 96px);
    }
  `,
)

function renderSlice(
  slice: SubmenuBody,
  analyticsName: string,
  sectionId: number,
  openPanel: (key: string) => void,
) {
  switch (slice.__typename) {
    case 'SubmenuBodySubmenuSection':
      return (
        <MobileMenuCategory
          slice={slice}
          analyticsName={analyticsName}
          sectionId={sectionId}
          openPanel={openPanel}
        />
      )
    case 'SubmenuBodyMenuVisual':
      return (
        <MobileMenuVisual
          slice={slice}
          analyticsName={analyticsName}
          sectionId={sectionId}
        />
      )
    default:
      // Every option should be implemented, so slice should be a `never`
      console.warn(`Slice type "${slice.__typename}" not implemented`)
      return null
  }
}

interface MobileMenuPanelProps {
  data: PrismicTypeSubmenu
  order: number
  isActive: boolean
  openPanel: (key: string) => void
  closePanel: () => void
}

export const MobileMenuPanel = ({
  data,
  order,
  isActive,
  openPanel,
  closePanel,
}: MobileMenuPanelProps) => {
  const [panelOrder, setPanelOrder] = useState(-1)

  useEffect(() => {
    if (panelOrder === -1) {
      setPanelOrder(order)
    } else {
      setTimeout(() => {
        if (order !== panelOrder) {
          setPanelOrder(order)
        }
      }, theme.primaryTransitionSpeed)
    }
  }, [order, panelOrder])

  return (
    <Panel
      data-body-scroll-lock-ignore="true"
      isOpen={isActive}
      order={panelOrder}
    >
      <MobileMenuHeader
        uid={data.key ?? ''}
        title={data.title}
        image={data.image}
        close={closePanel}
      />

      {data.body?.map((slice, index) => (
        <div key={index}>
          {renderSlice(slice, data.title ?? '', index + 1, openPanel)}
        </div>
      ))}
    </Panel>
  )
}
