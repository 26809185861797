import styled from '@emotion/styled'
import theme from '@src/theme'
import { rgba, darken } from 'polished'

import { Button } from './Button'

export const ButtonPink = styled(Button)`
  font-family: ${theme.fontFamilyPrimary};
  font-weight: ${theme.fontWeights.black};

  &,
  &:hover,
  &:focus,
  &:active {
    background: ${theme.colorApricot};
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${darken(0.2, theme.colorApricot)};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${rgba(theme.colorApricot, 0.2)};
  }
`
