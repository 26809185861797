import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { CheckIcon, CrossIcon } from '@emico/icons'
import { Checkbox } from '@emico/ui'

import theme from '../theme'

export interface ToggleSwitchProps extends ComponentProps<typeof Checkbox> {
  value: string | number
  field: string
  colorChecked?: string
  colorUnchecked?: string
  colorHoverChecked?: string
  colorHoverUnchecked?: string
  colorIcon?: string
  colorBorderChecked?: string
  colorBorderUnchecked?: string
  handleChange?(): unknown
}

const StyledLabel = styled.label`
  width: 67px;
  height: 31px;
  cursor: pointer;
  position: relative;
  border-radius: 22px;
  transition: background-color 0.25s ease;
`

const StyledSpan = styled.span`
  content: '';
  width: 21px;
  height: 21px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: margin-left 0.25s ease;
`

const baseIconStyles = css`
  color: ${theme.onPrimaryColor};
  width: 8px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledCheckIcon = styled(CheckIcon)`
  ${baseIconStyles}
`

const StyledCrossIcon = styled(CrossIcon)`
  ${baseIconStyles}
`

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop: string) =>
    ![
      'colorUnchecked',
      'colorHoverUnchecked',
      'colorChecked',
      'colorHoverChecked',
      'colorBorderChecked',
      'colorBorderUnchecked',
    ].includes(prop),
})<ToggleSwitchProps>`
  margin: 0;
  height: 16px;
  width: 16px;
  opacity: 0;

  & + ${StyledLabel} {
    background-color: ${(props) => props.colorUnchecked};
    border: 1px solid ${(props) => props.colorBorderUnchecked};
    box-sizing: border-box;

    &:before {
      display: none;
    }

    ${StyledSpan} {
      background-color: ${(props) => props.colorBorderUnchecked};
    }

    ${StyledCrossIcon} {
      opacity: 1;
    }
  }

  &:checked {
    & + ${StyledLabel} {
      background-color: ${(props) => props.colorChecked};
      border: 1px solid ${(props) => props.colorBorderChecked};

      ${StyledSpan} {
        background-color: ${(props) => props.colorBorderChecked};
        margin-left: calc(100% - 30px);
      }

      ${StyledCheckIcon} {
        opacity: 1;
      }

      ${StyledCrossIcon} {
        opacity: 0;
      }
    }
  }
`

export const ToggleSwitch = ({
  value,
  field,
  colorChecked,
  colorUnchecked,
  colorHoverChecked,
  colorHoverUnchecked,
  colorIcon,
  colorBorderChecked,
  colorBorderUnchecked,
  handleChange,
  ...props
}: ToggleSwitchProps) => (
  <>
    <StyledCheckbox
      id={`${String(value)}-${field}`}
      value={value}
      name={field}
      field={field}
      colorChecked={colorChecked || theme.colorGreenLight}
      colorUnchecked={colorUnchecked || theme.colorRedLight}
      colorHoverChecked={colorHoverChecked || theme.colorGreenLight}
      colorHoverUnchecked={colorHoverUnchecked || theme.colorRedLight}
      colorBorderChecked={colorBorderChecked || theme.colorGreenPrimary}
      colorBorderUnchecked={colorBorderUnchecked || theme.colorRed}
      onChange={handleChange}
      {...props}
    />

    <StyledLabel htmlFor={`${String(value)}-${field}`}>
      <StyledSpan>
        <StyledCheckIcon />
        <StyledCrossIcon />
      </StyledSpan>
    </StyledLabel>
  </>
)
