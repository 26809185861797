import { TypedDocumentNode, useQuery } from '@apollo/client'
import {
  ConfigurationQuery,
  ConfigurationQueryVariables,
} from '@shared/configuration/useConfiguration.generated'
import gql from 'graphql-tag'
import { useMemo } from 'react'

import { getCacheableContext } from '@emico/apollo'
import { Unmaybe } from '@emico/utils'

const CONFIGURATION = gql`
  query Configuration {
    storeConfig {
      defaultDisplayCurrencyCode
      locale
      teleconnectShowFromPrice
      generalCountryDefault
      ga4PageData
      checkoutOptOutEnabled
    }
  }
` as TypedDocumentNode<ConfigurationQuery, ConfigurationQueryVariables>

type Configuration = Unmaybe<Unmaybe<ConfigurationQuery, 'storeConfig'>>

const cleanData = (data?: ConfigurationQuery): Configuration | undefined => {
  if (!data?.storeConfig) {
    return
  }
  return data.storeConfig
}

type UseConfigurationResult = {
  configuration: Configuration | undefined
  loading: boolean
}

export const useConfiguration = (): UseConfigurationResult => {
  const { data, loading } = useQuery(CONFIGURATION, {
    query: CONFIGURATION,
    errorPolicy: 'all',
    context: getCacheableContext(),
  })

  return useMemo(
    () => ({
      configuration: cleanData(data),
      loading,
    }),
    [data, loading],
  )
}
