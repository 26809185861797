import { useEffect } from 'react'

import { globalWindow } from '@emico/ssr-utils'

type UseScriptProps = {
  type?: string
  src?: string
  async?: boolean
}

// State was removed since it would trigger a rerender of the entire app even if it wasn't used
function useScript({ src, type, async = true }: UseScriptProps) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  // const [status, setStatus] = useState(src ? 'loading' : 'idle')

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src || !globalWindow) {
        // setStatus('idle')
        return
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = globalWindow.document.querySelector<HTMLScriptElement>(
        `script[src="${src}"]`,
      )

      if (!script) {
        // Create script
        script = globalWindow.document.createElement('script')
        script.src = src
        script.async = async
        script.type = type ?? script.type
        script.setAttribute('data-status', 'loading')
        // Add script to globalWindow?.document body
        globalWindow.document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          script?.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          )
        }

        script.addEventListener('load', setAttributeFromEvent)
        script.addEventListener('error', setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // setStatus(script.getAttribute('data-status')!)
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        // setStatus(event.type === 'load' ? 'ready' : 'error')
      }

      // Add event listeners
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent)
          script.removeEventListener('error', setStateFromEvent)
        }
      }
    },
    [src, type, async], // Only re-run effect if script src, type or async changes
  )
}

export default useScript
