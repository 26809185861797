import { useHistory } from 'react-router'

interface SearchParam {
  key: string
  value: string
}

export const usePush = () => {
  const history = useHistory()

  const buildSearchParams = (searchParams?: SearchParam[]) => {
    if (!searchParams) {
      return ''
    }

    const mapped = searchParams.map((param) => `&${param.key}=${param.value}`)

    return mapped.join('')
  }

  return (route: string, searchParams?: SearchParam[]) => {
    history.push(
      route + history.location.search + buildSearchParams(searchParams),
    )
  }
}
