import { useFlowEnhancers } from '@checkout/flow'
import { useCart } from '@shared/cart/useCart'
import { OrderConditionEnum } from '@src/schema.generated'

export const useFlow = () => {
  const { omnichannel } = useFlowEnhancers()
  const { cart } = useCart()

  const isWeb = () => cart?.orderCondition === OrderConditionEnum.WEBSHOP

  const isDelivery = () =>
    (cart?.orderCondition === OrderConditionEnum.DELIVERY ||
      cart?.orderCondition === OrderConditionEnum.WEBSHOP) &&
    omnichannel

  const isPickup = () =>
    cart?.orderCondition === OrderConditionEnum.PICKUP && omnichannel

  const isCashSale = () => {
    const orderConditionCashSales =
      cart?.orderCondition === OrderConditionEnum.CASH_SALES

    const cashSaleCustomerDataEnabled =
      process.env.REACT_APP_CASHSALES_CUSTOMER_DATA === 'true'

    return orderConditionCashSales && cashSaleCustomerDataEnabled && omnichannel
  }

  const isRMA = () =>
    cart?.orderCondition === OrderConditionEnum.RMA && omnichannel

  const getFlow = ():
    | 'web'
    | 'delivery'
    | 'pickup'
    | 'cash_sale'
    | 'rma'
    | 'unknown' => {
    if (isWeb()) {
      return 'web'
    }
    if (isDelivery()) {
      return 'delivery'
    }

    if (isPickup()) {
      return 'pickup'
    }

    if (isCashSale()) {
      return 'cash_sale'
    }

    if (isRMA()) {
      return 'rma'
    }

    return 'unknown'
  }

  return getFlow()
}
