import styled from '@emotion/styled'
import React from 'react'

import { ChevronRightSolidIcon } from '@emico/icons'
import { PrismicImage, PrismicLink } from '@emico/prismic'

import { SubmenuBodyAccessoryTile } from '../schema.generated'
import theme from '../theme'
import { pushEvent } from '../utils/analytics'

const TileTitle = styled.span`
  font-family: ${theme.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${theme.onBackgroundColor};
  margin: 0 0 10px 0;
`

const TileLinkTitle = styled.span`
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.secondaryColor};
  display: block;
  padding: 0;
  margin: 10px 0 0;
  line-height: 28px;
  font-weight: 600;
`

const StyledChevronRightSolidIcon = styled(ChevronRightSolidIcon)`
  width: 10px;
  height: 10px;
  margin-left: 4px;
  flex: 1;
`

const TileLink = styled(PrismicLink)`
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.onBackgroundColor};
  display: block;
  padding: 0;
  line-height: 28px;

  &:hover {
    text-decoration: none;

    > span {
      text-decoration: underline;
    }
  }
`

const SubmenuVisual = styled(PrismicImage)`
  display: block;
  width: auto;
  height: auto;
  max-width: 160px;
  max-height: 160px;
`

interface PrismicSliceSubmenuAccessoryTileProps {
  slice: SubmenuBodyAccessoryTile
  submenuTitle: string
  columnId: number
}

export const PrismicSliceSubmenuAccessoryTile = ({
  slice,
  submenuTitle,
  columnId,
}: PrismicSliceSubmenuAccessoryTileProps) =>
  slice.primary ? (
    <TileLink
      external
      to={slice.primary.tileLink}
      analyticsName=""
      analyticsContext=""
      onClick={() => {
        pushEvent({
          event: `menu click`,
          eventCategory: `menu`,
          eventAction: 'click',
          eventLabel: `${submenuTitle} - ${slice.primary?.tileTitle} - ${columnId}`,
        })
      }}
      data-testid={`submenu.section.link.${slice.primary.tileTitle}`}
    >
      <TileTitle> {slice.primary.tileTitle}</TileTitle>

      {slice.primary.tileImage && (
        <SubmenuVisual loading="lazy" image={slice.primary.tileImage} />
      )}

      <TileLinkTitle>
        {slice.primary.tileLinkTitle}
        <StyledChevronRightSolidIcon />
      </TileLinkTitle>
    </TileLink>
  ) : null
