import { defineMessage } from '@lingui/macro'

import { globalWindow } from '@emico/ssr-utils'
import { StoreView } from '@emico/storeviews'

export const vaimoStorefrontLocalStorageKey = 'vaimoStorefront'

const makeUrl = (domainExtension: string) => (relativePath: string) => {
  const origin = globalWindow?.location.origin
  // const newOrigin = origin?.replace(/.[a-z+]$/gi, domainExtension)

  return `${origin}${relativePath}`
}

export interface SwissStoreView extends StoreView {
  vaimoStorefront: boolean
}

export const isSwissStoreView = (
  storeView: StoreView,
): storeView is SwissStoreView => 'vaimoStorefront' in storeView

const storeViews: SwissStoreView[] = [
  // Omni channel storeviews
  {
    code: 'nl_nl_omnichannel',
    group: 'omnichannel',
    language: 'nl',
    locale: 'nl-nl',
    name: defineMessage({
      id: 'storeViews.dutchOmnichannel',
      message: 'NL Omnichannel',
    }),
    basename: '',
    location: /omnichannel\.swisssense\.nl|\/omnichannel\.swiss-sense\.test/,
    makeUrl: makeUrl('nl'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: false,
  },
  {
    code: 'be_nl_omnichannel',
    group: 'omnichannel',
    language: 'be',
    locale: 'nl-be',
    name: defineMessage({
      id: 'storeViews.belgiumOmnichannel',
      message: 'BE Omnichannel',
    }),
    basename: '',
    location: /omnichannel\.swisssense\.be|be\.omnichannel\.swiss-sense\.test/,
    makeUrl: makeUrl('be'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: false,
  },
  {
    code: 'de_de_omnichannel',
    group: 'omnichannel',
    language: 'de',
    locale: 'de-de',
    name: defineMessage({
      id: 'storeViews.germanOmnichannel',
      message: 'DE Omnichannel',
    }),
    basename: '',
    location: /omnichannel\.swisssense\.de|de\.omnichannel\.swiss-sense\.test/,
    makeUrl: makeUrl('de'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: false,
  },
  {
    code: 'at_de_omnichannel',
    group: 'omnichannel',
    language: 'at',
    locale: 'de-at',
    name: defineMessage({
      id: 'storeViews.austriaOmnichannel',
      message: 'AT Omnichannel',
    }),
    basename: '',
    location: /omnichannel\.swisssense\.at|at\.omnichannel\.swiss-sense\.test/,
    makeUrl: makeUrl('at'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: false,
  },
  {
    code: 'da_dk_omnichannel',
    group: 'denmark',
    language: 'dk',
    locale: 'da-dk',
    name: defineMessage({
      id: 'storeViews.denmarkOmnichannel',
      message: 'DK Omnichannel',
    }),
    basename: '',
    location: /omnichannel\.swisssense\.dk|dk\.omnichannel\.swiss-sense\.test/,
    makeUrl: makeUrl('dk'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: false,
  },

  // Store storeviews
  {
    code: 'nl_nl',
    group: 'store',
    language: 'nl',
    locale: 'nl-nl',
    name: defineMessage({
      id: 'storeViews.dutch',
      message: 'NL',
    }),
    basename: '',
    location: /\.swisssense\.nl|\/swiss-sense\.test/,
    makeUrl: makeUrl('nl'),
    default: true,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: true,
  },
  {
    code: 'be_nl',
    group: 'store',
    language: 'be',
    locale: 'nl-be',
    name: defineMessage({
      id: 'storeViews.belgium',
      message: 'BE',
    }),
    basename: '',
    location: /\.swisssense\.be|be\.swiss-sense\.test/,
    makeUrl: makeUrl('be'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: true,
  },
  {
    code: 'de_de',
    group: 'store',
    language: 'de',
    locale: 'de-de',
    name: defineMessage({
      id: 'storeViews.german',
      message: 'DE',
    }),
    basename: '',
    location: /\.swisssense\.de|de\.swiss-sense\.test/,
    makeUrl: makeUrl('de'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: true,
  },
  {
    code: 'at_de',
    group: 'store',
    language: 'at',
    locale: 'de-at',
    name: defineMessage({
      id: 'storeViews.austria',
      message: 'AT',
    }),
    basename: '',
    location: /\.swisssense\.at|at\.swiss-sense\.test/,
    makeUrl: makeUrl('at'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: true,
  },
  {
    code: 'da_dk',
    group: 'denmark',
    language: 'dk',
    locale: 'da-dk',
    name: defineMessage({
      id: 'storeViews.denmark',
      message: 'DK',
    }),
    basename: '',
    location: /\.swisssense\.dk|dk\.swiss-sense\.test/,
    makeUrl: makeUrl('dk'),
    default: false,
    storeId: 1,
    websiteId: 1,
    vaimoStorefront: true,
  },
]
export default storeViews
