import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { ChevronRightSolidIcon } from '@emico/icons'
import { PrismicLink } from '@emico/prismic'
import { theme, minWidth } from '@emico/styles'
import { H3, ButtonLink } from '@emico/ui'

import { SubmenuBodySubmenuSection } from '../schema.generated'
import localTheme from '../theme'
import { pushEvent } from '../utils/analytics'

const MenuList = styled.ul`
  padding: 0;
  margin: 0 0 ${theme.spacing.x5}px;
`
const MenuListItem = styled.li`
  padding: 0 ${theme.spacing.x3}px;
  border-bottom: thin solid ${localTheme.colorGrey};
  margin: 0;
`
const MenuTitle = styled(H3)`
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  color: ${theme.onBackgroundColor};
  margin: 0 0 ${theme.spacing.x1}px;
  padding: 0 ${theme.spacing.x4}px;
  font-family: ${theme.fontFamilySecondary};
  letter-spacing: 0.016em;

  @media ${minWidth('md')} {
    font-size: 25px;
    padding: ${theme.spacing.x1}px 0 0 80px;
    margin: 0 0 ${theme.spacing.x3}px;
  }
`
const linkStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.x1}px 0 0 ${theme.spacing.x3}px;
  width: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  text-align: left;
  text-decoration: none;
  color: ${theme.onBackgroundColor};
  font-family: ${theme.fontFamilySecondary};
  font-weight: 400;

  &:hover {
    background: ${theme.onPrimaryColor};
    border: 0;
    outline: 0;
    text-decoration: none;
    color: ${theme.onBackgroundColor};
  }
  &:active,
  &:visited {
    color: ${theme.onBackgroundColor};
  }

  @media ${minWidth('md')} {
    font-size: 18px;
    line-height: 54px;
    padding: ${theme.spacing.x1}px 0 0 88px;
  }
`
const ListItemLink = styled(PrismicLink, {
  shouldForwardProp: (prop: string) => !['highlighted'].includes(prop),
})<{ highlighted: boolean }>(
  ({ highlighted }) => css`
    ${linkStyles}
    ${highlighted &&
    css`
      color: ${theme.secondaryColor};
      font-weight: 600;

      &:hover,
      &:active,
      &:visited {
        color: ${theme.secondaryColor};
      }
    `}
    width: initial;
    font-size: 14px;
  `,
)
const ListItemButton = styled(ButtonLink, {
  shouldForwardProp: (prop: string) => !['highlighted'].includes(prop),
})<{ highlighted: boolean }>(
  ({ highlighted }) => css`
    ${linkStyles}
    ${highlighted &&
    css`
      color: ${theme.secondaryColor};
      font-weight: 600;

      &:hover,
      &:active,
      &:visited {
        color: ${theme.secondaryColor};
      }
    `}
    font-weight: 600;
  `,
)
const StyledChevronRightSolidIcon = styled(ChevronRightSolidIcon)`
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
`

interface MobileMenuCategoryProps {
  slice: SubmenuBodySubmenuSection
  analyticsName: string
  sectionId: number
  openPanel: (key: string) => void
}

export const MobileMenuCategory = ({
  slice,
  analyticsName,
  sectionId,
  openPanel,
}: MobileMenuCategoryProps) => (
  <>
    {slice.primary?.title && <MenuTitle>{slice.primary.title}</MenuTitle>}

    {slice.fields && slice.fields.length && (
      <MenuList>
        {slice.fields.map((item, index) =>
          item.submenuKey ? (
            <MenuListItem key={index}>
              <ListItemButton
                highlighted={Boolean(item.linkHighlighted)}
                analyticsName=""
                analyticsContext=""
                onClick={() => {
                  openPanel(item.submenuKey ?? '')
                  pushEvent({
                    event: `mobileMenu click`,
                    eventCategory: `mobileMenu`,
                    eventAction: 'click',
                    eventLabel: `${analyticsName} - ${
                      item.linkTitle
                    } - ${sectionId}.${index + 1}`,
                  })
                }}
              >
                {item.linkTitle}
                <StyledChevronRightSolidIcon />
              </ListItemButton>
            </MenuListItem>
          ) : (
            <MenuListItem key={index}>
              <ListItemLink
                external
                to={item.link}
                highlighted={Boolean(item.linkHighlighted)}
                analyticsName=""
                analyticsContext=""
                onClick={() => {
                  pushEvent({
                    event: `mobileMenu click`,
                    eventCategory: `mobileMenu`,
                    eventAction: 'click',
                    eventLabel: `${analyticsName} - ${
                      item.linkTitle
                    } - ${sectionId}.${index + 1}`,
                  })
                }}
              >
                {item.linkTitle}
                <StyledChevronRightSolidIcon />
              </ListItemLink>
            </MenuListItem>
          ),
        )}
      </MenuList>
    )}
  </>
)
