import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

/**
 * Hook that returns information from the cart in regards to permissions
 */
export const useCartPermissions = () => {
  const cartId = useCartId()
  const { cart } = useCart(cartId)

  const permissions = cart?.quotePermissions

  return {
    address: Boolean(permissions?.addressEdit.allowed),
    addressMessages: permissions?.addressEdit?.messages,
    any: Boolean(permissions?.anyPermission),
    giftCard: Boolean(permissions?.applyGiftCard.allowed),
    deliveryDate: Boolean(permissions?.deliveryDateEdit.allowed),
    deliveryDateMessages: permissions?.deliveryDateEdit?.messages,
    edit: Boolean(permissions?.edit.allowed),
    editMessages: permissions?.edit?.messages,
  }
}
