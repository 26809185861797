import React, { SVGProps } from 'react'

function Check(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={98}
      height={76}
      viewBox="0 0 98 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M91 7L38.5 69 7 39"
        stroke="currentColor"
        strokeWidth={14}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Check
