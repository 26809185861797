import styled from '@emotion/styled'
import { Button } from '@shared/button'
import { darken } from 'polished'

export const ButtonGreen = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: #77bf36;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: ${darken(0.2, '#77bf36')};
  }
`
