import styled from '@emotion/styled'
import { SwissSenseLogo } from '@src/components/SwissSenseLogo'

import { minWidth } from '@emico/styles'

export const HeaderLogo = styled(SwissSenseLogo)`
  color: white;
  width: 80px;

  // iPhone se
  @media screen and (min-width: 321px) {
    width: 107px;
  }

  @media ${minWidth('md')} {
    width: 189px;
    margin: 0 0 0 20px;
  }
`
