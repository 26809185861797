import styled from '@emotion/styled'

import { Button } from './Button'

export const ButtonUnstyled = styled(Button)`
  background: transparent;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
`
