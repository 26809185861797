import useCartId, { getVaimoCartId } from '@shared/cart/useCartId'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { cartIdVar } from '@emico/cart'
import loginTokenVar from '@emico/login-token'

let cached: Promise<MagentoSession>

export function getMagentoSession(useCache = true) {
  const vaimoCartId = getVaimoCartId()
  if (vaimoCartId) {
    return
  }
  if (cached && useCache) {
    return cached
  }
  cached = fetch(`/react/index/getsession`).then((res) => res.json())
  return cached
}

interface ContextInterface {
  session?: MagentoSession | null
  refreshSession?: () => Promise<void>
}

const Context = React.createContext<ContextInterface>({})

interface MagentoSession {
  baseURL: string
  storeCode: string
  storeLocale: string
  cartId: string
  cartIdMasked: string
  customerId: string
  customerToken: string
  guest: number
  sessionId: string
}

interface MagentoSessionProviderProps {
  children: ReactNode
}

export const MagentoSessionProvider = ({
  children,
}: MagentoSessionProviderProps) => {
  const [session, setSession] = useState<MagentoSession>()
  const cartId = useCartId()

  useEffect(() => {
    setSession((session) => {
      if (!session || !cartId) {
        return session
      }

      if (session.cartId) {
        return session
      }

      return {
        ...session,
        cartId,
      }
    })
  }, [cartId])

  const refreshSession = useCallback(async (useCache = false) => {
    const session = await getMagentoSession(useCache)
    setSession(session)

    // Cart ID should always be up-to-date with Magento. But while developing
    // we are not on the same host, this causes the session id to not be
    // consistent, and losing the cart id. So while in development, we just
    // remember the cart ID and hope it won't be removed while developing.
    if (process.env.NODE_ENV !== 'development' && session) {
      cartIdVar(session.cartIdMasked)
      loginTokenVar(session.customerToken)
    }
    return
  }, [])

  useEffect(() => {
    refreshSession(true)
  }, [refreshSession])

  return (
    <Context.Provider value={{ session, refreshSession }}>
      {children}
    </Context.Provider>
  )
}

export const useMagentoSession = () => useContext(Context)
