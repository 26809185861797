import { makeVar, useReactiveVar } from '@apollo/client'
import { v4 as uuid } from 'uuid'

import { syncReactiveVar } from '@emico/reactive-var-utils'

const localStorageKey = 'sessionKey'

export type SessionKey = string
export const sessionKeyVar = syncReactiveVar(
  makeVar<SessionKey | undefined>(undefined),
  localStorageKey,
)

export default function useSessionKey(): string {
  const sessionKeyStorageValue = useReactiveVar(sessionKeyVar)
  if (sessionKeyStorageValue) {
    return sessionKeyStorageValue
  }
  const sessionKey = uuid()
  sessionKeyVar(sessionKey)
  return sessionKey
}
