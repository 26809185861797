import { useMemo } from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import useVaimoStorefront from './utils/useVaimoStorefront'

export const routes = {
  search: {
    nl: '/catalogsearch/result',
    be: '/catalogsearch/result',
    de: '/catalogsearch/result',
    at: '/catalogsearch/result',
  },
  stores: {
    nl: '/winkels_v2',
    be: '/winkels_v2',
    de: '/filialen_v2',
    at: '/filialen_v2',
  },
  storeDetail: {
    nl: '/winkels_v2/:name',
    be: '/winkels_v2/:name',
    de: '/filialen_v2/:name',
    at: '/filialen_v2/:name',
  },
  storeAppointment: {
    nl: '/winkels_v2/:name/maak-afspraak',
    be: '/winkels_v2/:name/maak-afspraak',
    de: '/filialen_v2/:name/termin-vereinbaren',
    at: '/filialen_v2/:name/termin-vereinbaren',
  },
  advisors: {
    nl: '/advieswijzers',
    be: '/advieswijzers',
    de: '/online-ratgeber',
    at: '/online-ratgeber',
  },
  advisorMattress: {
    nl: '/matraswijzer',
    be: '/matraswijzer',
    de: '/matratzenfinder',
    at: '/matratzenfinder',
  },
  advisorTopper: {
    nl: '/topmatraswijzer',
    be: '/topmatraswijzer',
    de: '/topperfinder',
    at: '/topperfinder',
  },
  advisorPillow: {
    nl: '/kussenwijzer',
    be: '/kussenwijzer',
    de: '/kopfkissenfinder',
    at: '/kopfkissenfinder',
  },
  advisorDuvet: {
    nl: '/dekbedwijzer',
    be: '/dekbedwijzer',
    de: '/bettdeckenfinder',
    at: '/bettdeckenfinder',
  },
  contactPreferences: {
    nl: '/contact-voorkeuren',
    be: '/contact-voorkeuren',
    de: '/contact-voorkeuren',
    at: '/contact-voorkeuren',
  },
  genericLeadForm: {
    nl: '/generic-lead-form',
    be: '/generic-lead-form',
    de: '/generic-lead-form',
    at: '/generic-lead-form',
  },
  giftCardBalance: {
    nl: '/gift-card-balance',
    be: '/gift-card-balance',
    de: '/gift-card-balance',
    at: '/gift-card-balance',
  },
  checkout: {
    nl: '/checkout',
    be: '/checkout',
    de: '/checkout',
    at: '/checkout',
  },
  cart: {
    nl: '/checkout/cart',
    be: '/checkout/cart',
    de: '/checkout/cart',
    at: '/checkout/cart',
    vaimo_nl: '/cart',
    vaimo_be: '/cart',
    vaimo_de: '/cart',
    vaimo_at: '/cart',
  },
  configurator: {
    nl: '/configurator',
    be: '/configurator',
    de: '/configurator',
    at: '/configurator',
  },
  configuratorocp: {
    nl: '/configuratorocp',
    be: '/configuratorocp',
    de: '/configuratorocp',
    at: '/configuratorocp',
  },
}

type RouteKeys = keyof typeof routes

const useRoutes = () => {
  const storeView = useActiveStoreView()
  const vaimoLanguage = useVaimoStorefront()
    ? `vaimo_${storeView.language}`
    : storeView.code
  return useMemo(() => {
    let extractRoutes: {
      [key in RouteKeys]: string | { [key: string]: string }
    } = {
      ...routes,
    }

    for (const [key, value] of Object.entries(
      routes as {
        [key: string]: {
          [key: string]: string
        }
      },
    )) {
      extractRoutes = {
        ...extractRoutes,
        [key]: value[vaimoLanguage] ?? value[storeView.language],
      }
    }

    return extractRoutes as { [key in RouteKeys]: string }
  }, [storeView, vaimoLanguage])
}

export default useRoutes
