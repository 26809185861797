import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { Form, ButtonPrimary } from '@emico/ui'

import theme from '../theme'
import { validateEmail } from '../utils/formValidation'
import Checkbox from './Checkbox'
import { FormField } from './FormField'
import { Input } from './Input'

export type EmailFormValues = { email: string; optin?: boolean }

interface EmailFormProps {
  emailLabel: string
  submitLabel: ReactNode
  optinLabel?: string
  onSubmit(values: EmailFormValues): void
  description?: ReactNode
  loading?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
`

const SubmitButton = styled(ButtonPrimary)`
  display: block;
  font-weight: 500;
  font-size: 20px;
  height: 65px;
  width: 200px;
  text-align: center;
  border-radius: 4px;
  padding: 0 ${theme.spacing.x2}px;
  margin: 10px 0 0 ${theme.spacing.x3}px;
  background: ${theme.colorGreenMedium};

  &:hover {
    background: ${theme.colorGreenMedium};
  }
`

const StyledCheckbox = styled(Checkbox)`
  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5px;
    border-radius: 0;
    flex: 0 0 25px;
    width: 25px;
    height: 25px;

    svg {
      width: 20px;
      height: 16px;
    }
  }
  > div:last-of-type {
    font-size: 20px;
    line-height: 30px;
  }
`

const StyledFormField = styled(FormField)`
  flex: 1;
  min-height: 65px;
  border-radius: 0;
  box-shadow: none;
  border: thin solid ${theme.gray500};

  label > span {
    color: ${theme.gray500};
    font-size: 12px;
    font-weight: 700;
  }
`
const Description = styled.p`
  color: ${theme.gray600};
`

export const EmailForm = ({
  emailLabel,
  submitLabel,
  optinLabel,
  onSubmit,
  description,
  loading = false,
}: EmailFormProps) => (
  <Form<EmailFormValues> onSubmit={onSubmit}>
    {description && <Description>{description}</Description>}

    {optinLabel && (
      <StyledCheckbox field="optin" label={optinLabel} className="checkbox" />
    )}

    <Wrapper>
      <StyledFormField label={emailLabel} field="email">
        <Input
          data-testid="advisor.emailForm.input"
          field="email"
          type="email"
          validate={validateEmail()}
          validateOnBlur
          validateOnChange
          autoComplete="home email"
        />
      </StyledFormField>

      <SubmitButton
        data-testid="advisor.emailForm.submit"
        type="submit"
        disabled={loading}
        analyticsContext="advisor.emailForm"
        analyticsName="submit"
        loading={loading}
      >
        {submitLabel}
      </SubmitButton>
    </Wrapper>
  </Form>
)
