import { QueryResult, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  AvailableCountriesQuery,
  AvailableCountriesQueryVariables,
} from '@checkout/country/useCountries.generated'
import gql from 'graphql-tag'

import { getCacheableContext } from '@emico/apollo'
import { stripMaybes, Unmaybe } from '@emico/utils'

const AVAILABLE_COUNTRIES = gql`
  query AvailableCountries {
    availableCountries {
      id
      fullNameLocale
      availableRegions {
        id
        code
        name
      }
    }
  }
` as TypedDocumentNode<
  AvailableCountriesQuery,
  AvailableCountriesQueryVariables
>

type Country = Unmaybe<Unmaybe<AvailableCountriesQuery, 'availableCountries'>>

const cleanData = (data?: AvailableCountriesQuery): Country[] =>
  data?.availableCountries?.filter(stripMaybes) ?? []

type UseCountriesReturn = {
  countries: Country[]
} & Omit<
  QueryResult<AvailableCountriesQuery, AvailableCountriesQueryVariables>,
  'data'
>

export const useCountries = (): UseCountriesReturn => {
  const { data, ...rest } = useQuery(AVAILABLE_COUNTRIES, {
    query: AVAILABLE_COUNTRIES,
    errorPolicy: 'all',
    context: getCacheableContext(),
  })

  return {
    countries: cleanData(data),
    ...rest,
  }
}
