import { ChatType } from '@advisor/mattress/ChatContext'
// Import GA4-related modules
import { Product } from '@configurator/product'

import { globalWindow } from '@emico/ssr-utils'

import {
  EcommerceItem,
  Event,
  GA4Error,
  mapCartEventItemsToItems,
  parseGA4Data,
  pushEvent as pushGA4Event,
} from './ga4'
// Import Universal Analytics-related modules
import { pushEvent as pushUAEvent } from './ua'

/**
 * Custom Error Class for Analytics Errors
 *
 * This error class is used to represent and handle errors related to analytics events and interactions.
 * It extends the built-in Error class and provides a more specific name for these errors.
 * It is used when both GA4 and UA should throw an error.
 *
 * @param {string} message - A descriptive error message that explains the cause of the analytics error.
 *
 * @example
 * throw new AnalyticsError('Missing step or answer for configurator interaction.');
 */
class AnalyticsError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AnalyticsError'
  }
}

/**
 * Enumerates various types of interactions for tracking and analytics purposes.
 *
 * @enum {string} Interaction
 *
 * - `BED_CONFIGURATOR`: Represents interactions related to a bed configurator.
 * - `PILLOW_ADVISOR_SHOWN`: Indicates the display of a pillow advisor interaction.
 * - `PILLOW_ADVISOR_ANSWERED`: Indicates a user's response or answer within the pillow advisor interaction.
 * - `MATTRESS_ADVISOR_SHOWN`: Indicates the display of a mattress advisor interaction.
 * - `MATTRESS_ADVISOR_ANSWERED`: Indicates a user's response or answer within the mattress advisor interaction.
 * - `MATTRESS_TOPPER_ADVISOR_SHOWN`: Indicates the display of a mattress topper advisor interaction.
 * - `MATTRESS_TOPPER_ADVISOR_ANSWERED`: Indicates a user's response or answer within the mattress topper advisor interaction.
 * - `DUVET_ADVISOR_SHOWN`: Indicates the display of a duvet advisor interaction.
 * - `DUVET_ADVISOR_ANSWERED`: Indicates a user's response or answer within the duvet advisor interaction.
 */
export enum Interaction {
  BED_CONFIGURATOR = 'bed_configurator',
  PILLOW_ADVISOR_SHOWN = 'pillow_advisor_shown',
  PILLOW_ADVISOR_ANSWERED = 'pillow_advisor_answered',
  MATTRESS_ADVISOR_SHOWN = 'mattress_advisor_shown',
  MATTRESS_ADVISOR_ANSWERED = 'mattress_advisor_answered',
  MATTRESS_TOPPER_ADVISOR_SHOWN = 'mattress_topper_advisor_shown',
  MATTRESS_TOPPER_ADVISOR_ANSWERED = 'mattress_topper_advisor_answered',
  DUVET_ADVISOR_SHOWN = 'duvet_advisor_shown',
  DUVET_ADVISOR_ANSWERED = 'duvet_advisor_answered',
}

/**
 * This payload is used to record and analyze interactions within a configurator, such as a product customization tool. It may include details about the configurator step, the selected item, and the user's response.
 *
 * @property {string | undefined} step - The name or identifier of the configurator step during which the interaction occurred.
 * @property {string | undefined} item - The selected item or option within the configurator step, if applicable.
 * @property {string | undefined} answer - The user's answer or response to the configurator step, if applicable.
 */
type ConfiguratorInteractionPayload = {
  step?: string
  item?: string
  answer?: string
  product?: Product
}

/**
 * This function is used to track user interactions with a bed configurator when the user selects an answer. It sends events to both Google Analytics 4 (GA4) and Universal Analytics (UA) for tracking and analysis.
 *
 * @param {ConfiguratorInteractionPayload} payload - The payload containing information about the interaction, including the configurator step, selected item, and user's answer.
 *
 * @throws {AnalyticsError | GA4Error} Throws an AnalyticsError or GA4Error if the global `dataLayer` object or `globalWindow` is not available when attempting to push the GA4 event.
 *
 * @example
 * pushConfiguratorInteractionEvent({
 *   step: 'mattress-leftmattress-rightfirmness',
 *   item: 'Boxspring Web-Only Sparkle Deluxe',
 *   answer: '140x200',
 * });
 */
export const pushConfiguratorInteractionEvent = (
  payload: ConfiguratorInteractionPayload,
) => {
  const { step, item, answer, product } = payload

  if (!step || !answer) {
    throw new AnalyticsError(
      'No step or answer found for pushConfiguratorInteractionEvent',
    )
  }

  pushUAEvent({
    event: 'configurator_selection',
    eventCategory: 'Bed Configurator Selection',
    eventAction: step,
    eventLabel: answer,
  })

  if (!item) {
    throw new GA4Error(
      `No item found for pushConfiguratorInteractionEvent, with step: ${step} and answer: ${answer}`,
    )
  }

  const productData = parseGA4Data<EcommerceItem>(product?.ga4Data ?? null)

  pushGA4Event(Event.INTERACTION, {
    event_params: {
      items: [productData],
      event_name: 'bed_configurator',
      step_name: step,
      selected_value: answer ?? '(not set)',
      configurator_item: item,
    },
  })
}
/**
 * Represents a payload for a configurator step event, which includes information
 * about user actions, labels, and additional interaction data.
 *
 * @property {string} action - The action or user interaction associated with this step.
 * @property {string} label - A label describing the step or interaction.
 * @property {string} [step] - The name of the configurator step (optional).
 * @property {string} [item] - The configurator item associated with the step (optional).
 */
type ConfiguratorStepPayload = {
  action: string
  label: string
} & Omit<ConfiguratorInteractionPayload, 'answer'>

/**
 * Pushes a configurator step event to analytics services, including UA and GA4, based on the provided payload.
 *
 * @param payload - The payload containing information about the configurator step event.
 *
 * @throws {AnalyticsError} If 'step,' 'label,' or 'action' is missing in the payload.
 * @throws {GA4Error} If 'item' is missing in the payload.
 */
export const pushConfiguratorStepEvent = (payload: ConfiguratorStepPayload) => {
  const { step, label, item, action, product } = payload
  if (!step || !label || !action) {
    throw new AnalyticsError(
      'No step, label or action found for pushConfiguratorStepEvent',
    )
  }

  pushUAEvent({
    event: 'configurator_step',
    eventCategory: 'Bed Configurator Selection',
    eventAction: action,
    eventLabel: label,
  })

  if (!item) {
    throw new GA4Error(
      `No item found for pushConfiguratorStepEvent, with step: ${step} and label: ${label}`,
    )
  }

  const productData = parseGA4Data<EcommerceItem>(product?.ga4Data ?? null)

  pushGA4Event(Event.INTERACTION, {
    event_params: {
      items: [productData],
      event_name: 'bed_configurator',
      step_name: step,
      selected_value: '(not set)',
      configurator_item: item,
    },
  })
}

/**
 * An array of questions that are excluded from tracking in the advisor interaction events.
 *
 * This array contains specific question identifiers that should not be tracked in the advisor interactions.
 * When a user's question matches any question in this list, the event tracking for that interaction is skipped.
 * You can modify this array to add or remove excluded questions as needed.
 */
const EXCLUDED_QUESTIONS = [
  'question_dust_mites',
  'question_physical_complaints',
]

/**
 * This function compares the provided question against a list of excluded questions and
 * determines whether the question should be excluded from event tracking.
 *
 * @param {string} question - The question to be checked for exclusion.
 * @returns {boolean} True if the question is excluded; otherwise, false.
 *
 * @example
 * const excluded = isQuestionExcluded('question_dust_mites'); // Returns true if 'question_dust_mites' is excluded.
 */
const isQuestionExcluded = (question: string): boolean =>
  EXCLUDED_QUESTIONS.includes(question)

/**
 * This payload is used to record and analyze user interactions with advisors. It contains information about the type of advisor interaction and the specific question or content displayed to the user.
 *
 * @property {ChatType} type - The type of advisor interaction, such as "MATTRESS," "PILLOW," "TOPPER," or "DUVET."
 * @property {string} question - The question or content associated with the advisor interaction, prompting the user's response or interaction.
 *
 */
type AdvisorShownInteractionPayload = {
  type: ChatType
  question: string
}

/**
 * This function is used to track and log advisor interactions when they are displayed to users. It sends events to Google Analytics 4 (GA4) for tracking and analysis.
 *
 * @param {AdvisorShownInteractionPayload} payload - The payload containing information about the shown advisor interaction, including the type and question.
 *
 * @throws {GA4Error} Throws a GA4Error if the global `dataLayer` object or `globalWindow` is not available when attempting to push the GA4 event, or if no interaction is found for the given type and question combination.
 *
 * @example
 * pushAdvisorShownInteractionEvent({
 *   type: ChatType.MATTRESS,
 *   question: 'mattress-weight'
 * });
 */
export const pushAdvisorShownInteractionEvent = ({
  type,
  question,
}: AdvisorShownInteractionPayload) => {
  if (isQuestionExcluded(question)) {
    return // Skip tracking
  }

  const interaction = getInteractionForAdvisorShown(type)

  if (!interaction) {
    throw new GA4Error(
      `No interaction found for type ${type} with question: ${question}`,
    )
  }

  pushGA4Event(Event.INTERACTION, {
    event_params: {
      event_name: interaction,
      question,
    },
  })
}

/**
 * Mapping of advisor types to corresponding interactions.
 * Modify this object to add or change mappings as needed.
 */
const advisorTypeToInteractionMap: Record<ChatType, Interaction | null> = {
  [ChatType.MATTRESS]: Interaction.MATTRESS_ADVISOR_SHOWN,
  [ChatType.PILLOW]: Interaction.PILLOW_ADVISOR_SHOWN,
  [ChatType.TOPPER]: Interaction.MATTRESS_TOPPER_ADVISOR_SHOWN,
  [ChatType.DUVET]: Interaction.DUVET_ADVISOR_SHOWN,
  // You can add more mappings if needed
  // [ChatType.SOMETHING_ELSE]: Interaction.SOMETHING_ELSE_ADVISOR_SHOWN,
}

/**
 * Get the interaction for the given advisor type.
 * @param {ChatType} advisorType - The type of advisor.
 * @returns {Interaction | null} The corresponding interaction or null if not found.
 */
const getInteractionForAdvisorShown = (
  advisorType: ChatType,
): Interaction | null => advisorTypeToInteractionMap[advisorType] || null

/**
 * This payload is used when tracking and logging user interactions with an advisor, including the user's responses. It includes information about the type of advisor interaction, the associated question, and the user's answer.
 *
 * @property {string} answer - The user's answer or response to an advisor's question or interaction.
 * @property {ChatType} type - The type of advisor interaction, such as "MATTRESS," "PILLOW," etc.
 * @property {string} question - The question or content associated with the advisor interaction.
 */
type AdvisorAnsweredInteractionPayload = {
  answer: string
} & AdvisorShownInteractionPayload

/**
 * This function is used to track and log advisor interactions when users provide answers or responses. It sends events to Google Analytics 4 (GA4) for tracking and analysis.
 *
 * @param {AdvisorAnsweredInteractionPayload} payload - The payload containing information about the answered advisor interaction, including the type, question, and user's answer.
 *
 * @throws {GA4Error} Throws a GA4Error if the global `dataLayer` object or `globalWindow` is not available when attempting to push the GA4 event, or if no interaction is found for the given type, question, and answer combination.
 *
 * @example
 * pushAdvisorAnsweredInteractionEvent({
 *   type: ChatType.MATTRESS,
 *   question: 'mattrass-size',
 *   answer: 'big'
 * });
 */
export const pushAdvisorAnsweredInteractionEvent = ({
  type,
  question,
  answer,
}: AdvisorAnsweredInteractionPayload) => {
  if (isQuestionExcluded(question)) {
    return // Skip tracking
  }

  const interaction = getInteractionForAdvisorAnswered(type)

  if (!interaction) {
    throw new GA4Error(
      `No interaction found for type ${type} with question: ${question} and answer: ${answer}`,
    )
  }

  pushGA4Event(Event.INTERACTION, {
    event_params: {
      event_name: interaction,
      question,
      answer,
    },
  })
}

/**
 * Mapping of advisor types to corresponding answered interactions.
 * Modify this object to add or change mappings as needed.
 */
const advisorTypeToAnsweredInteractionMap: Record<
  ChatType,
  Interaction | null
> = {
  [ChatType.MATTRESS]: Interaction.MATTRESS_ADVISOR_ANSWERED,
  [ChatType.PILLOW]: Interaction.PILLOW_ADVISOR_ANSWERED,
  [ChatType.TOPPER]: Interaction.MATTRESS_TOPPER_ADVISOR_ANSWERED,
  [ChatType.DUVET]: Interaction.DUVET_ADVISOR_ANSWERED,
  // You can add more mappings if needed
  // [ChatType.SOMETHING_ELSE]: Interaction.SOMETHING_ELSE_ADVISOR_ANSWERED,
}

/**
 * Get the answered interaction for the given advisor type.
 *
 * @param {ChatType} advisorType - The type of advisor.
 * @returns {Interaction | null} The corresponding answered interaction or null if not found.
 */
export const getInteractionForAdvisorAnswered = (
  advisorType: ChatType,
): Interaction | null =>
  advisorTypeToAnsweredInteractionMap[advisorType] || null

type SharePayload = {
  platform: string
  url: string
}

/**
 * Pushes a share event for GA4 only.
 * This event should be fired when a configuration is shared.
 *
 * @param {SharePayload} payload - The payload containing the platform and URL.
 */
export const pushShareEvent = (payload: SharePayload) => {
  const { platform, url } = payload

  if (!platform || !url) {
    throw new GA4Error(`Missing platform or URL in pushShareEvent`)
  }

  pushGA4Event(Event.INTERACTION, {
    event_params: {
      event_name: 'share',
      share_platform: platform,
      share_url: url,
    },
  })
}

/**
 * Represents the payload for a single item in a cart event.
 *
 * @property {string | null} ga4Data - The GA4 data associated with the item in JSON format, or null if not available.
 * @property {string | number | null} id - The unique identifier of the item, which can be a string or a number, or null if not available.
 */
export type CartEventItemPayload = {
  ga4Data: string | null
  id: string | number | null
}

/**
 * Represents the payload for a cart event containing multiple products or items.
 *
 * @property {CartEventItemPayload[]} products - An array of CartEventItemPayload objects, each representing a product or item in the cart.
 */
type CartEventPayload = {
  items: CartEventItemPayload[]
}

/**
 * Pushes a cart event to Google Analytics 4 (GA4) with the specified event type and payload.
 *
 * @param {Event} eventType - The type of cart event (e.g., Event.ADD_TO_CART, Event.REMOVE_FROM_CART).
 * @param {CartEventPayload} payload - The payload containing product information.
 *
 * @throws {GA4Error} Throws a GA4Error if an invalid eventType is provided.
 *
 * @description
 * This function is used to push a cart-related event to Google Analytics 4 (GA4) with the specified event type and payload.
 * It validates the event type to ensure it's either 'Event.ADD_TO_CART' or 'Event.REMOVE_FROM_CART'. If an invalid event type
 * is provided, it throws a `GA4Error`. Otherwise, it constructs and sends the event data to GA4 using the `pushGA4Event` function.
 */
const pushCartEvent = (eventType: Event, payload: CartEventPayload): void => {
  const { items } = payload

  if (eventType !== Event.ADD_TO_CART && eventType !== Event.REMOVE_FROM_CART) {
    throw new GA4Error(`Invalid eventType: ${eventType} for pushCartEvent`)
  }

  pushGA4Event(eventType, {
    ecommerce: {
      items: mapCartEventItemsToItems(items),
    },
  })
}

/**
 * Pushes an "Add to Cart" event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CartEventPayload} payload - The payload containing product information.
 */
export const pushAddToCartEvent = (payload: CartEventPayload): void => {
  pushCartEvent(Event.ADD_TO_CART, payload)
}

/**
 * Pushes a "Remove from Cart" event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CartEventPayload} payload - The payload containing product information.
 */
export const pushRemoveFromCartEvent = (payload: CartEventPayload): void => {
  pushCartEvent(Event.REMOVE_FROM_CART, payload)
}

type CheckoutEventPayload = {
  value?: number | null
  items: CartEventItemPayload[]
}

/**
 * Pushes a checkout-related event to Google Analytics 4 (GA4) with the specified event type and payload.
 *
 * @param {Event} eventType - The type of checkout event (e.g., Event.BEGIN_CHECKOUT, Event.ADD_SHIPPING_INFO, Event.ADD_PAYMENT_INFO).
 * @param {CheckoutEventPayload} payload - The payload containing checkout information.
 *
 * @throws {GA4Error} Throws a GA4Error if required variables are missing or if an invalid eventType is provided.
 *
 * @description
 * This function is used to push a checkout-related event to Google Analytics 4 (GA4) with the specified event type and payload.
 * It validates the event type and ensures that required variables like 'value' are provided. If any required variable is missing or
 * if an invalid event type is provided, it throws a `GA4Error`. Otherwise, it constructs and sends the event data to GA4 using the
 * `pushGA4Event` function.
 */
export const pushCheckoutEvent = (
  eventType: Event,
  payload: CheckoutEventPayload,
): void => {
  const { items, value } = payload

  if (typeof value !== 'number') {
    throw new GA4Error(
      `Missing value variable for eventType: ${eventType}, currently of type: ${typeof value}`,
    )
  }

  if (
    eventType !== Event.BEGIN_CHECKOUT &&
    eventType !== Event.ADD_SHIPPING_INFO &&
    eventType !== Event.ADD_PAYMENT_INFO &&
    eventType !== Event.VIEW_ORDER_OVERVIEW
  ) {
    throw new GA4Error(`Invalid eventType: ${eventType} for pushCheckoutEvent`)
  }

  pushGA4Event(eventType, {
    ecommerce: {
      value,
      currency: 'EUR',
      payment_type:
        eventType === Event.ADD_PAYMENT_INFO ? '(not set)' : undefined,
      items: mapCartEventItemsToItems(items),
    },
  })
}

/**
 * Pushes a 'BEGIN_CHECKOUT' event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CheckoutEventPayload} payload - The payload containing checkout information.
 *
 * @throws {GA4Error} Throws a GA4Error if required variables are missing or if the payload is invalid.
 *
 * @description
 * This function is used to push a 'BEGIN_CHECKOUT' event to Google Analytics 4 (GA4) with the provided payload.
 * It ensures that the required 'value' variable is provided in the payload. If 'value' is missing or if the payload
 * is invalid, it throws a `GA4Error`. Otherwise, it constructs and sends the 'BEGIN_CHECKOUT' event data to GA4 using
 * the `pushCheckoutEvent` function.
 */
export const pushBeginCheckoutEvent = (payload: CheckoutEventPayload) => {
  pushCheckoutEvent(Event.BEGIN_CHECKOUT, payload)
}

/**
 * Pushes an 'ADD_SHIPPING_INFO' event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CheckoutEventPayload} payload - The payload containing shipping information.
 *
 * @throws {GA4Error} Throws a GA4Error if required variables are missing or if the payload is invalid.
 *
 * @description
 * This function is used to push an 'ADD_SHIPPING_INFO' event to Google Analytics 4 (GA4) with the provided payload.
 * It ensures that the required 'value' variable is provided in the payload. If 'value' is missing or if the payload
 * is invalid, it throws a `GA4Error`. Otherwise, it constructs and sends the 'ADD_SHIPPING_INFO' event data to GA4 using
 * the `pushCheckoutEvent` function.
 */
export const pushAddShippingInfoEvent = (payload: CheckoutEventPayload) => {
  pushCheckoutEvent(Event.ADD_SHIPPING_INFO, payload)
}

/**
 * Pushes an 'ADD_PAYMENT_INFO' event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CheckoutEventPayload} payload - The payload containing payment information.
 *
 * @throws {GA4Error} Throws a GA4Error if required variables are missing or if the payload is invalid.
 *
 * @description
 * This function is used to push an 'ADD_PAYMENT_INFO' event to Google Analytics 4 (GA4) with the provided payload.
 * It ensures that the required 'value' and 'paymentType' variables are provided in the payload. If either of these
 * variables is missing or if the payload is invalid, it throws a `GA4Error`. Otherwise, it constructs and sends the
 * 'ADD_PAYMENT_INFO' event data to GA4 using the `pushCheckoutEvent` function.
 */
export const pushAddPaymentInfoEvent = (payload: CheckoutEventPayload) => {
  pushCheckoutEvent(Event.ADD_PAYMENT_INFO, payload)
}

/**
 * Pushes an 'VIEW_ORDER_OVERVIEW' event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param {CheckoutEventPayload} payload - The payload containing payment information.
 *
 * @throws {GA4Error} Throws a GA4Error if required variables are missing or if the payload is invalid.
 *
 * @description
 * This function is used to push an 'VIEW_ORDER_OVERVIEW' event to Google Analytics 4 (GA4) with the provided payload.
 * It ensures that the required 'value' is provided in the payload. If the
 * variable is missing or if the payload is invalid, it throws a `GA4Error`. Otherwise, it constructs and sends the
 * 'VIEW_ORDER_OVERVIEW' event data to GA4 using the `pushCheckoutEvent` function.
 */
export const pushOverviewCheckoutEvent = (payload: CheckoutEventPayload) => {
  pushCheckoutEvent(Event.VIEW_ORDER_OVERVIEW, payload)
}

export const pushAlgoliaUserToken = () => {
  const algoliaUserToken = localStorage?.getItem('algolia_userToken')

  if (algoliaUserToken) {
    globalWindow?.dataLayer.push({
      algoliaUserToken: algoliaUserToken,
    })
  }
}

/**
 * Represents the payload for a purchase event in Google Analytics 4 (GA4).
 */
type PurchaseEventPayload = {
  /**
   * The monetary value of the purchase event. Required and must be a non-null number.
   */
  value: number
  /**
   * The shipping cost associated with the purchase event. Optional.
   */
  shipping?: number
  /**
   * The tax amount associated with the purchase event. Optional.
   */
  tax?: number
  /**
   * An array of items included in the purchase event.
   */
  items: CartEventItemPayload[]
  /**
   * The order number or identifier for the purchase event. Required and must be a string.
   */
  orderNumber: string
  /**
   * A coupon code associated with the purchase event. Optional.
   */
  coupon?: string
}

/**
 * Pushes a purchase event to Google Analytics 4 (GA4) with the specified payload.
 *
 * @param payload - The payload containing information about the purchase event.
 * @throws {Error} If the 'value' field is missing or undefined in the payload.
 */
export const pushPurchaseEvent = (payload: PurchaseEventPayload): void => {
  const {
    items,
    value,
    tax = '(not set)',
    shipping = '(not set)',
    orderNumber,
    coupon = '(not set)',
  } = payload

  if (typeof value !== 'number') {
    throw new GA4Error(`Missing value variable for pushPurchaseEvent`)
  }

  pushGA4Event(Event.PURCHASE, {
    ecommerce: {
      currency: 'EUR',
      transaction_id: orderNumber,
      value,
      tax,
      shipping,
      coupon,
      items: mapCartEventItemsToItems(items),
    },
  })
}

/**
 * Represents the payload structure for a page event in Google Analytics 4.
 */
type PageEventPayload = {
  /**
   * A JSON string containing GA4 page data.
   */
  ga4PageData: string
  /**
   * The type of the event.
   */
  type: string
}

/**
 * Represents the structure of GA4 page data.
 */
type GA4PageData = {
  /**
   * The environment of the page, e.g. DEV, ACC or PROD
   */
  environment: string
  /**
   * The country of the page in uppercase.
   */
  country: string
  /**
   * The language of the page in lowercase.
   */
  language: string
  /**
   * The type of the page.
   */
  type: string
  /**
   * The brand of the page.
   */
  brand: string
}

/**
 * Pushes a page event to Google Analytics 4.
 *
 * @param payload - The payload containing information about the page event.
 * @throws {Error} If the 'type' field is invalid in the payload.
 */
export const pushPageEvent = (payload: PageEventPayload): void => {
  if (['Checkout', 'Configurator', 'Advisor'].indexOf(payload.type) < 0) {
    throw new GA4Error(`Invalid type parameter for pushPageEvent`)
  }
  const pageData = parseGA4Data<{ page: GA4PageData }>(payload.ga4PageData)

  pushGA4Event(Event.EMPTY, {
    page: {
      ...pageData.page,
      type: payload.type,
    },
  })
}
