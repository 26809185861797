import React, { SVGProps } from 'react'

import { ShoppingCartIcon as RCShoppingCartIcon } from '@emico/icons'

const ShoppingCartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="22" viewBox="0 0 20 22" aria-hidden="true" {...props}>
    <path
      d="M1.49 6.02L0 20.767a1.173 1.173 0 00.29.86 1.106 1.106 0 00.82.367h17.78A1.12 1.12 0 0020 20.87a.875.875 0 00-.01-.163L18.51 6.02a1.112 1.112 0 00-1.1-1.023h-3.34V4.13a4.07 4.07 0 10-8.14 0V5H2.59a1.112 1.112 0 00-1.1 1.02zm11.47 2.727a1.133 1.133 0 01.79.33 1.15 1.15 0 010 1.59 1.13 1.13 0 01-.79.33 1.09 1.09 0 01-.78-.33 1.125 1.125 0 010-1.59 1.093 1.093 0 01.78-.33zM8.15 9.873a1.13 1.13 0 01-.33.8 1.09 1.09 0 01-.78.33 1.126 1.126 0 010-2.252 1.093 1.093 0 01.78.33 1.133 1.133 0 01.33.793zm0-5.74a1.85 1.85 0 113.7 0V5h-3.7v-.87z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

RCShoppingCartIcon.override(ShoppingCartIcon)
