import { makeVar } from '@apollo/client'
import { HeadsetIcon } from '@checkout/icon/HeadsetIcon'
import { CompactDetails } from '@checkout/ui/CompactDetails'
import styled from '@emotion/styled'
import { HTMLSerializer } from 'prismic-reactjs'
import React, { ReactNode } from 'react'
import { InView } from 'react-intersection-observer'

import {
  PrismicImage,
  PrismicRichText,
  getPrismicText,
  usePrismicDocument,
} from '@emico/prismic'
import { globalWindow } from '@emico/ssr-utils'
import { minWidth, theme } from '@emico/styles'
import { Container, Footer, H4, useBreakpoints } from '@emico/ui'

import { FooterPaymentMethods } from '../components/FooterPaymentMethods'
import { PrismicTypeHeader } from '../schema.generated'

export const ctaFooterVar = makeVar<boolean | undefined>(false)
const Wrapper = styled.footer``

const HEADER_HEIGHT = 24

const SupportBar = styled.div`
  padding: 10px 0;
  background: ${theme.gray100};
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Left = styled.div`
  flex: 1;
  order: 2;
  text-align: center;

  @media ${minWidth('md')} {
    text-align: left;
  }
`

const Right = styled.div`
  text-align: right;
  order: 1;
  @media ${minWidth('md')} {
    order: 3;
    flex: 1;
  }
`

const SubFooter = styled.div`
  display: grid;
  padding: 8px 0 20px;

  @media ${minWidth('md')} {
    grid-template-columns: [empty] 2fr [footer-menu] 3fr [footer-logos] 2fr;
  }
`

const LinkList = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  grid-column-start: footer-menu;

  text-align: center;
  @media ${minWidth('md')} {
    text-align: left;
  }
`

const LinkListItem = styled.li`
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  margin-right: 20px;
  color: ${theme.gray400};
  a {
    color: inherit;
    text-decoration: none;
  }
`

const InlinePrismicRichText = styled(PrismicRichText)`
  display: inline;
  p {
    display: inline;
  }
`

const TrustPilotText = styled(InlinePrismicRichText)`
  display: inline-block;
  font-size: 12px;
`

const StyledHeadsetIcon = styled(HeadsetIcon)`
  width: 36px;
  height: 37px;
  margin-right: 15px;

  @media ${minWidth('md')} {
    width: 29px;
    height: 30px;
  }
`

const StyledH4 = styled(H4)`
  display: inline-block;
  font-weight: 600;
`

const MobileContactInfo = styled.div`
  text-align: center;
  flex: 1;
`

const StyledCompactDetails = styled(CompactDetails)`
  margin: ${theme.spacing.x3}px;
`

const ContactInfoHeader = styled.div`
  margin-bottom: ${theme.spacing.x2}px;
`

const ChatLink = styled.strong`
  color: ${theme.secondaryColor};
  cursor: pointer;
`

const SimpleFooter = () => {
  const { data } = usePrismicDocument<PrismicTypeHeader>({
    key: 'document.type',
    value: 'header',
  })
  const { isMobile } = useBreakpoints()

  const openChat = () => {
    if (globalWindow?.Intercom) {
      globalWindow.Intercom('boot')
      globalWindow.Intercom('show')
    }
  }

  // eslint-disable-next-line react/display-name
  const handleContactInfoLinks = (): HTMLSerializer<ReactNode> =>
    function ContactLink(type, element, content, children, index) {
      if (!element.data.url.includes('#chat')) {
        return
      }

      return (
        <ChatLink key={index} onClick={openChat}>
          {content}
        </ChatLink>
      )
    }

  return (
    <Wrapper>
      {isMobile && (
        <>
          <FooterPaymentMethods paymentMethods={data?.paymentMethods} />
          <StyledCompactDetails>
            <MobileContactInfo>
              <ContactInfoHeader>
                <StyledHeadsetIcon />
                <StyledH4>{getPrismicText(data?.contactInfoTitle)}</StyledH4>
              </ContactInfoHeader>
              <InlinePrismicRichText
                render={data?.contactInfo}
                analyticsContext="footer"
                serializeHyperlink={handleContactInfoLinks()}
              />
            </MobileContactInfo>
          </StyledCompactDetails>
        </>
      )}
      <SupportBar>
        <Container>
          <Flex>
            <Left>
              <PrismicImage image={data?.trustpilotScore} height={25} />
              <TrustPilotText
                render={data?.trustpilotText}
                analyticsContext="footer"
              />
            </Left>
            {!isMobile && (
              <Right>
                <StyledHeadsetIcon />
                <InlinePrismicRichText
                  render={data?.contactInfo}
                  analyticsContext="footer"
                  serializeHyperlink={handleContactInfoLinks()}
                />
              </Right>
            )}
          </Flex>
        </Container>
      </SupportBar>

      <InView
        onChange={(inView) => {
          ctaFooterVar(!inView)
        }}
        rootMargin={`${HEADER_HEIGHT}px`} // header height
      >
        <Container>
          <SubFooter>
            <LinkList>
              {data?.legal?.map((item, i) => (
                <LinkListItem key={i}>
                  <InlinePrismicRichText
                    render={item.text}
                    analyticsContext="footer"
                  />
                </LinkListItem>
              ))}
            </LinkList>

            {!isMobile && (
              <FooterPaymentMethods paymentMethods={data?.paymentMethods} />
            )}
          </SubFooter>
        </Container>
      </InView>
    </Wrapper>
  )
}
export default Footer

Footer.override(SimpleFooter)
