import styled from '@emotion/styled'

import { FormFieldLabelWrapper } from '@emico/ui/src/FormField'

const StyledFormFieldLabelWrapper = styled.label`
  display: block;
  margin: 0;
`

FormFieldLabelWrapper.override(StyledFormFieldLabelWrapper)
