import { TypedDocumentNode, useApolloClient } from '@apollo/client'
import { useGuestCartId } from '@checkout/cart'
import { useCart } from '@shared/cart/useCart'
import gql from 'graphql-tag'

import {
  RemoveCouponFromCartMutation,
  RemoveCouponFromCartMutationVariables,
} from './useRemoveCouponFromCart.generated'

const REMOVE_COUPON_FROM_CART = gql`
  mutation RemoveCouponFromCart($input: RemoveCouponFromCartInput) {
    removeCouponFromCart(input: $input) {
      cart {
        appliedCoupons {
          code
        }
      }
    }
  }
` as TypedDocumentNode<
  RemoveCouponFromCartMutation,
  RemoveCouponFromCartMutationVariables
>

export const useRemoveCouponFromCart = () => {
  const guestCartId = useGuestCartId()
  const { cart } = useCart(guestCartId)
  const client = useApolloClient()

  const removeCouponFromCart = async (id?: string) => {
    if (!id || !cart) {
      return {
        deleted: false,
      }
    }

    const currentAppliedCoupons = cart.appliedCoupons ?? []

    if (currentAppliedCoupons.length === 0) {
      return {
        deleted: false,
      }
    }

    const { data, ...rest } = await client.mutate({
      mutation: REMOVE_COUPON_FROM_CART,
      variables: {
        input: {
          cart_id: id,
        },
      },
    })

    const newAppliedCoupons = data?.removeCouponFromCart?.cart?.appliedCoupons

    return {
      deleted: newAppliedCoupons
        ? currentAppliedCoupons.length < newAppliedCoupons?.length
        : true,
      ...rest,
    }
  }

  return {
    removeCouponFromCart,
  }
}
