import { SVGProps } from 'react'
import React from 'react'

export const BinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 200.933 200.933"
    enableBackground="new 0 0 200.933 200.933"
    {...props}
  >
    <g>
      <path
        d="M140.068,5.942h-18.604C121.432,2.656,118.76,0,115.466,0h-30c-3.294,0-5.966,2.656-5.997,5.942H60.865
c-16.542,0-29.999,13.458-29.999,29.999V46.44c0,3.313,2.687,6,6,6h6.168l7.694,142.815c0.171,3.184,2.803,5.677,5.991,5.677
h87.495c3.188,0,5.82-2.494,5.991-5.677l7.694-142.815h6.168c3.313,0,6-2.687,6-6V35.941
C170.067,19.399,156.609,5.942,140.068,5.942z M128.133,188.933V84.667c0-3.313-2.686-6-6-6s-6,2.687-6,6v104.266h-9.667V84.667
c0-3.313-2.686-6-6-6s-6,2.687-6,6v104.266H84.8V84.667c0-3.313-2.686-6-6-6s-6,2.687-6,6v104.266H62.404L55.051,52.44h90.831
l-7.354,136.493H128.133z M140.068,17.942c9.925,0,17.999,8.074,17.999,17.999v4.499h-5.853H48.719h-5.853v-4.499
c0-9.925,8.074-17.999,17.999-17.999H140.068z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)
