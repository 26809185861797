import { makeVar, useReactiveVar } from '@apollo/client'
import {
  ADYEN_PAYMENT_METHOD_MAP,
  ADYEN_PREFIX,
  AdyenPaymentMethod,
} from '@checkout/adyen'
import { PaymentMethodInput } from '@src/schema.generated'

export interface SelectedPaymentMethod extends PaymentMethodInput {
  title: string
  brandCode?: string
}

export interface SelectedAdyenAdditionalData {
  stateData: AdyenStateData
  ccType?: string
}

export interface PaymentMethodVarState {
  selectedPaymentMethod?: SelectedPaymentMethod
}

interface AdyenStateDataPaymentMethod {
  type?: string
  issuer?: string
}

export interface AdyenStateData {
  paymentMethod?: AdyenStateDataPaymentMethod
}

export const paymentMethodState = makeVar<PaymentMethodVarState>({})

export const usePaymentMethodVar = () => {
  const paymentMethodVar = useReactiveVar(paymentMethodState)

  const getPaymentMethodVar = () => paymentMethodState()

  const setPaymentMethodVarState = (
    stateValue: Partial<PaymentMethodVarState>,
  ) => {
    paymentMethodState({
      ...paymentMethodState(),
      ...stateValue,
    })
  }

  const setPaymentMethodVarValue = (
    key: keyof PaymentMethodVarState,
    value: SelectedPaymentMethod | null,
  ) => {
    if (paymentMethodVar[key] === value) {
      return
    }
    paymentMethodState({
      ...paymentMethodState(),
      [key as string]: value,
    })
  }

  const setSelectedPaymentMethodData = (value: SelectedPaymentMethod) => {
    setPaymentMethodVarValue('selectedPaymentMethod', value)
  }

  const addAdyenAdditionalData = (value: Partial<SelectedPaymentMethod>) => {
    if (!getPaymentMethodVar().selectedPaymentMethod) {
      return
    }
    setPaymentMethodVarValue('selectedPaymentMethod', {
      ...(getPaymentMethodVar().selectedPaymentMethod as SelectedPaymentMethod),
      ...value,
    })
  }

  const setSelectedPaymentMethodAdyen = (
    selectedAdyenPaymentMethod: AdyenPaymentMethod,
    title: string,
  ) => {
    const magentoMethodCode =
      ADYEN_PREFIX +
      (ADYEN_PAYMENT_METHOD_MAP.find(
        (map) => map.adyen === selectedAdyenPaymentMethod.type,
      )?.magento ?? selectedAdyenPaymentMethod.type)

    setPaymentMethodVarValue('selectedPaymentMethod', {
      title: title,
      code: magentoMethodCode,
      brandCode: selectedAdyenPaymentMethod.type,
    })
    setPaymentAdyenAdditionalData({ stateData: { paymentMethod: {} } })
  }

  const setPaymentAdyenAdditionalData = (
    adyenAdditionalData: SelectedAdyenAdditionalData,
  ) => {
    const brandCode = getPaymentMethodVar()?.selectedPaymentMethod?.brandCode
    if (!brandCode) {
      return
    }

    if (adyenAdditionalData.stateData.paymentMethod) {
      adyenAdditionalData.stateData.paymentMethod.type = brandCode
    }

    addAdyenAdditionalData({
      adyen_additional_data: {
        brand_code: brandCode,
        ...adyenAdditionalData,
        stateData: JSON.stringify(adyenAdditionalData.stateData),
      },
    })
  }

  const setPaymentAdyenIssuer = (issuer: string) => {
    setPaymentAdyenAdditionalData({
      stateData: {
        paymentMethod: {
          issuer: issuer,
        },
      },
    })
  }

  return {
    paymentMethodVar,
    getPaymentMethodVar,
    setPaymentMethodVarState,
    setPaymentMethodVarValue,
    setSelectedPaymentMethodData,
    setSelectedPaymentMethodAdyen,
    setPaymentAdyenIssuer,
    setPaymentAdyenAdditionalData,
  }
}
