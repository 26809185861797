import { useMemo } from 'react'

import { useActiveStoreView } from '@emico/storeviews'

const CHECKOUT_ROUTES = {
  checkout: {
    nl: '/checkout',
    be: '/checkout',
    de: '/checkout',
    at: '/checkout',
  },
  login: {
    nl: '/checkout/login',
    be: '/checkout/login',
    de: '/checkout/login',
    at: '/checkout/login',
  },
  address: {
    nl: '/checkout/address',
    be: '/checkout/address',
    de: '/checkout/address',
    at: '/checkout/address',
  },
  paymentMethod: {
    nl: '/checkout/payment-method',
    be: '/checkout/payment-method',
    de: '/checkout/payment-method',
    at: '/checkout/payment-method',
  },
  overview: {
    nl: '/checkout/overview',
    be: '/checkout/overview',
    de: '/checkout/overview',
    at: '/checkout/overview',
  },
  success: {
    nl: '/checkout/success/:orderId',
    be: '/checkout/success/:orderId',
    de: '/checkout/success/:orderId',
    at: '/checkout/success/:orderId',
  },
  order: {
    nl: '/checkout/order',
    be: '/checkout/order',
    de: '/checkout/order',
    at: '/checkout/order',
  },
  adyenReturn: {
    nl: '/checkout/adyen/return/:orderId/:maskedOrderId',
    be: '/checkout/adyen/return/:orderId/:maskedOrderId',
    de: '/checkout/adyen/return/:orderId/:maskedOrderId',
    at: '/checkout/adyen/return/:orderId/:maskedOrderId',
  },
}

export interface CheckoutRoutes {
  checkout: string
  login: string
  address: string
  paymentMethod: string
  overview: string
  success: string
  order: string
  adyenReturn: string
}

type RouteKeys = keyof typeof CHECKOUT_ROUTES

export const useCheckoutRoutes = () => {
  const storeView = useActiveStoreView()

  return useMemo(() => {
    let extractRoutes: {
      [key in RouteKeys]: string | { [key: string]: string }
    } = {
      ...CHECKOUT_ROUTES,
    }

    for (const [key, value] of Object.entries(
      CHECKOUT_ROUTES as {
        [key: string]: {
          [key: string]: string
        }
      },
    )) {
      extractRoutes = {
        ...extractRoutes,
        [key]: value[storeView.language],
      }
    }

    return extractRoutes as { [key in RouteKeys]: string }
  }, [storeView])
}
