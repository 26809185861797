import styled from '@emotion/styled'

import { theme } from '@emico/styles'
import {
  createVisualCheckbox,
  StyledCheckIcon,
  StyledLabel as StyledCheckboxLabel,
  StyledVisualCheckbox,
} from '@emico/ui'

import Check from './Check'

const SwissCheckIcon = styled(Check)`
  color: black;
  width: 14px;
  height: 14px;
  margin: 2px 0 0 2px;
`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
StyledCheckIcon.override(SwissCheckIcon)

StyledVisualCheckbox.override(
  styled(createVisualCheckbox({ size: 20, borderSize: 1 }))`
    margin-left: 0;
    margin-right: 10px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 #f3efeb, 0 2px 3px 0 #f3efeb, 0 1px 2px 0 #f3efeb;
    background: white;
    border-color: rgba(0, 0, 0, 0);

    input[type='checkbox']:checked + & {
      background: white;
      border-color: ${theme.gray500};
    }

    input[type='checkbox']:not([disabled]):checked:active + & {
      background: white;
    }

    input[type='checkbox']:focus + & {
      border-color: #787878;
      box-shadow: 0 0 3px 0 #f3efeb, 0 2px 3px 0 #f3efeb, 0 1px 2px 0 #f3efeb;
    }
  ` as typeof StyledVisualCheckbox.original,
)

const SwissCheckboxLabel = styled(StyledCheckboxLabel.original)`
  display: flex;
  align-items: center;
  font-weight: bold;
`
StyledCheckboxLabel.override(
  SwissCheckboxLabel as typeof StyledCheckboxLabel.original,
)
