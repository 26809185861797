import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React, { ComponentProps, MouseEvent } from 'react'

import { CrossIcon } from '@emico/icons'
import {
  SearchOrCancelButton as BaseSearchOrCancelButton,
  StyledButton,
  StyledSearchIcon,
  useCancelSearch,
} from '@emico/search-bar'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled, useBreakpoints } from '@emico/ui'

import theme from '../theme'

export const CancelButton = styled(StyledButton)`
  width: 24px;
  height: 24px;
  background: ${theme.gray200};
  border-radius: 12px;
  right: 65px;

  &:hover {
    background: ${theme.gray200};
  }

  @media ${minWidth('md')} {
    right: 45px;
    left: auto;
    transform: translateY(-50%);
  }
`

export const CancelTextButton = styled(ButtonUnstyled)`
  font-weight: 500;
  color: ${theme.colorBlue};
  order: 1;
  flex: 0 0 60px;
  margin-left: 15px;
`

export const SearchButton = styled(StyledButton)`
  left: auto;
  right: 10px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  color: ${theme.secondaryColor};

  @media ${minWidth('md')} {
    right: 0;
    left: auto;
    transform: translateY(-50%);
  }
`

const SearchIcon = styled(StyledSearchIcon)`
  width: 20px;
  height: 20px;
`

const StyledCrossIcon = styled(CrossIcon)`
  width: 11px;
  height: 11px;
  color: ${theme.gray600};
`

export const SearchOrCancelButton = ({
  currentSearch,
  onCancel,
  onClose,
}: ComponentProps<typeof BaseSearchOrCancelButton>) => {
  const { isMobile, isTablet } = useBreakpoints()
  const cancelHandler = useCancelSearch(onCancel)

  const handleClose = <T extends HTMLElement>(e: MouseEvent<T>) => {
    cancelHandler(e)

    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {(isMobile || isTablet) && (
        <CancelTextButton
          analyticsContext=""
          analyticsName=""
          onClick={handleClose}
        >
          <Trans id="searchBar.cancelLink">Cancel</Trans>
        </CancelTextButton>
      )}

      {currentSearch && (
        <CancelButton
          type="button"
          analyticsContext="searchBar"
          analyticsName="searchCancel"
          tabIndex={-1}
          onClick={cancelHandler}
        >
          <StyledCrossIcon
            aria-label={t({ id: 'searchBar.cancel', message: 'Cancel' })}
          />
        </CancelButton>
      )}

      <SearchButton
        type="submit"
        analyticsName="submit search query"
        analyticsContext="search link"
        tabIndex={-1}
      >
        <SearchIcon aria-label={t({ message: 'Search' })} />
      </SearchButton>
    </>
  )
}
