import styled from '@emotion/styled'

import { theme } from '@emico/styles'

export const Loader = styled.div`
  border: 3px solid ${theme.gray300};
  border-top: 3px solid ${theme.secondaryColor};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
