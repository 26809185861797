import { PrismicHyperlink, PrismicImageType } from '@emico/prismic'
export type { PrismicHyperlink, PrismicImageType }

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** DateTime */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** Raw JSON value */
  Json: any;
}


/** Defines the bundle products to add to the cart. */
export interface AddBundleProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of bundle products to add. */
  cart_items: Array<Maybe<BundleProductCartItemInput>>;
}

/** Contains details about the cart after adding bundle products. */
export interface AddBundleProductsToCartOutput {
  __typename?: 'AddBundleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

/** Defines the configurable products to add to the cart. */
export interface AddConfigurableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of configurable products to add. */
  cart_items: Array<Maybe<ConfigurableProductCartItemInput>>;
}

/** Contains details about the cart after adding configurable products. */
export interface AddConfigurableProductsToCartOutput {
  __typename?: 'AddConfigurableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

export interface AddDownloadableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of downloadable products to add. */
  cart_items: Array<Maybe<DownloadableProductCartItemInput>>;
}

/** Contains details about the cart after adding downloadable products. */
export interface AddDownloadableProductsToCartOutput {
  __typename?: 'AddDownloadableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

/** Defines an item to add to the gift registry. */
export interface AddGiftRegistryItemInput {
  /** An array of options the customer has entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** A brief note about the item. */
  note?: Maybe<Scalars['String']>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The quantity of the product to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer has selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The SKU of the product to add to the gift registry. */
  sku: Scalars['String'];
}

/** Defines a new registrant. */
export interface AddGiftRegistryRegistrantInput {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: Maybe<Array<Maybe<GiftRegistryDynamicAttributeInput>>>;
  /** The email address of the registrant. */
  email: Scalars['String'];
  /** The first name of the registrant. */
  firstname: Scalars['String'];
  /** The last name of the registrant. */
  lastname: Scalars['String'];
}

/** Contains the results of a request to add registrants. */
export interface AddGiftRegistryRegistrantsOutput {
  __typename?: 'AddGiftRegistryRegistrantsOutput';
  /** The gift registry after adding registrants. */
  giftRegistry: Maybe<GiftRegistry>;
}

export interface AdditionalOption {
  __typename?: 'AdditionalOption';
  label: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
}

/** Contains details about the cart after adding products to it. */
export interface AddProductsToCartOutput {
  __typename?: 'AddProductsToCartOutput';
  /** The cart after products have been added. */
  cart: Cart;
  /** Contains errors encountered while adding an item to the cart. */
  userErrors: Array<Maybe<CartUserInputError>>;
}

/** Contains products to add to an existing compare list. */
export interface AddProductsToCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
}

/** Contains the customer's wish list and any errors encountered. */
export interface AddProductsToWishlistOutput {
  __typename?: 'AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
}

export interface AddressDetails {
  __typename?: 'AddressDetails';
  address: Maybe<AddressDetailsAddress>;
  country: Maybe<AddressDetailsCountry>;
  language: Maybe<Scalars['String']>;
  location: Maybe<AddressDetailsLocation>;
  mailLines: Maybe<Array<Maybe<Scalars['String']>>>;
  xAutocompleteSession: Maybe<Scalars['String']>;
}

export interface AddressDetailsAddress {
  __typename?: 'AddressDetailsAddress';
  building: Maybe<Scalars['String']>;
  buildingNumber: Maybe<Scalars['Int']>;
  buildingNumberAddition: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  locality: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
}

export interface AddressDetailsCountry {
  __typename?: 'AddressDetailsCountry';
  iso3Code: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}

export interface AddressDetailsLocation {
  __typename?: 'AddressDetailsLocation';
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
}

export interface AddressQuestions {
  __typename?: 'AddressQuestions';
  elevatorPresent: Maybe<Scalars['Boolean']>;
  floor: Maybe<Scalars['Int']>;
}

/** Defines a return comment. */
export interface AddReturnCommentInput {
  /** The text added to the return request. */
  comment_text: Scalars['String'];
  /** The unique ID for a `Return` object. */
  return_uid: Scalars['ID'];
}

/** Contains details about the return request. */
export interface AddReturnCommentOutput {
  __typename?: 'AddReturnCommentOutput';
  /** The modified return. */
  return: Maybe<Return>;
}

/** Defines tracking information to be added to the return. */
export interface AddReturnTrackingInput {
  /** The unique ID for a `ReturnShippingCarrier` object. */
  carrier_uid: Scalars['ID'];
  /** The unique ID for a `Returns` object. */
  return_uid: Scalars['ID'];
  /** The shipping tracking number for this return request. */
  tracking_number: Scalars['String'];
}

/** Contains the response after adding tracking information. */
export interface AddReturnTrackingOutput {
  __typename?: 'AddReturnTrackingOutput';
  /** Details about the modified return. */
  return: Maybe<Return>;
  /** Details about shipping for a return. */
  returnShippingTracking: Maybe<ReturnShippingTracking>;
}

/** Defines the simple and group products to add to the cart. */
export interface AddSimpleProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of simple and group items to add. */
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
}

/** Contains details about the cart after adding simple or group products. */
export interface AddSimpleProductsToCartOutput {
  __typename?: 'AddSimpleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

/** Defines the TeleConnect products to add to the cart. */
export interface AddTeleConnectProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String'];
  /** An array of TeleConnect items to add. */
  cartItems: Array<Maybe<TeleConnectProductCartItemInput>>;
}

/** Contains details about the cart after adding TeleConnect products. */
export interface AddTeleConnectProductsToCartOutput {
  __typename?: 'AddTeleConnectProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

/** Defines the virtual products to add to the cart. */
export interface AddVirtualProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of virtual products to add. */
  cart_items: Array<Maybe<VirtualProductCartItemInput>>;
}

/** Contains details about the cart after adding virtual products. */
export interface AddVirtualProductsToCartOutput {
  __typename?: 'AddVirtualProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
}

/** Contains the resultant wish list and any error information. */
export interface AddWishlistItemsToCartOutput {
  __typename?: 'AddWishlistItemsToCartOutput';
  /** An array of errors encountered while adding products to the customer's cart. */
  addWishlistItemsToCartUserErrors: Array<Maybe<WishlistCartUserInputError>>;
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean'];
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
}

export interface AdminCustomerSession {
  __typename?: 'AdminCustomerSession';
  customerId: Maybe<Scalars['ID']>;
  customer: Maybe<M2RestCustomer>;
  quoteId: Maybe<Scalars['ID']>;
  adminUserId: Maybe<Scalars['String']>;
  isPrimary: Maybe<Scalars['Boolean']>;
  isActive: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['String']>;
  seller: Maybe<Scalars['String']>;
  time: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  products: Maybe<Scalars['String']>;
}

export type AdvisorBody = AdvisorBodyAlternativeAdvisor;

export interface AdvisorBodyAlternativeAdvisor {
  __typename?: 'AdvisorBodyAlternativeAdvisor';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<AdvisorBodyAlternativeAdvisorPrimary>;
}

export interface AdvisorBodyAlternativeAdvisorPrimary {
  __typename?: 'AdvisorBodyAlternativeAdvisorPrimary';
  altAdvisorTitle: Maybe<Scalars['String']>;
  altAdvisorLink: Maybe<PrismicHyperlink>;
  altAdvisorLinkText: Maybe<Scalars['String']>;
  altAdvisorImage: Maybe<Scalars['Json']>;
}

export interface AdyenAdditionalData {
  /** Type of alternative payment method. */
  brand_code: Scalars['String'];
  /** Ratepay device identification token. */
  df_value?: Maybe<Scalars['String']>;
  /** Email address if customer is guest. */
  guestEmail?: Maybe<Scalars['String']>;
  /** Recurring processing model to tokenize the payment method. */
  recurringProcessingModel?: Maybe<Scalars['String']>;
  /**
   * The URL to return to in case of a redirection. The format depends on the
   * channel. This URL can have a maximum of 1024 characters. It can include a
   * placeholder `:merchantReference` to identify the order e.g.
   * `https://your-company.com/checkout?shopperOrder=:merchantReference`.
   */
  returnUrl?: Maybe<Scalars['String']>;
  /** JSON string of filled fields. */
  stateData: Scalars['String'];
}

export interface AdyenAdditionalDataCc {
  /** Credit card brand. */
  cc_type?: Maybe<Scalars['String']>;
  /** Debit or Credit card. */
  combo_card_type?: Maybe<Scalars['String']>;
  /** Email address if customer is guest. */
  guestEmail?: Maybe<Scalars['String']>;
  /** If combo_card_type is credit, Number of installments for the payment. */
  number_of_installments?: Maybe<Scalars['Int']>;
  /** Recurring processing model to tokenize the payment method. */
  recurringProcessingModel?: Maybe<Scalars['String']>;
  /**
   * The URL to return to in case of a redirection. The format depends on the
   * channel. This URL can have a maximum of 1024 characters. It can include a
   * placeholder `:merchantReference` to identify the order e.g.
   * `https://your-company.com/checkout?shopperOrder=:merchantReference`.
   */
  returnUrl?: Maybe<Scalars['String']>;
  /** JSON string of filled fields. */
  stateData?: Maybe<Scalars['String']>;
}

export interface AdyenAdditionalDataPosCloud {
  /** Number of installments for the payment. */
  number_of_installments?: Maybe<Scalars['Int']>;
  /** Terminal ID of selected terminal. */
  terminal_id?: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodIcon {
  __typename?: 'AdyenPaymentMethodIcon';
  /** Height of the icon in pixels. */
  height: Maybe<Scalars['Int']>;
  /** URL of the icon. */
  url: Maybe<Scalars['String']>;
  /** Width of the icon in pixels. */
  width: Maybe<Scalars['Int']>;
}

export interface AdyenPaymentMethods {
  __typename?: 'AdyenPaymentMethods';
  /** Payment method's additional details. */
  paymentMethodsExtraDetails: Maybe<Array<Maybe<AdyenPaymentMethodsExtraDetails>>>;
  /** API response from Adyen with payment methods. */
  paymentMethodsResponse: Maybe<AdyenPaymentMethodsResponse>;
}

export interface AdyenPaymentMethodsArray {
  __typename?: 'AdyenPaymentMethodsArray';
  /** Brand for the selected gift card. For example: plastix, hmclub. */
  brand: Maybe<Scalars['String']>;
  /** List of possible brands. For example: visa, mc. */
  brands: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The configuration of the payment method. */
  configuration: Maybe<AdyenPaymentMethodsConfiguration>;
  /** All input details to be provided to complete the payment with this payment method. */
  details: Maybe<Array<Maybe<AdyenPaymentMethodsDetails>>>;
  /** Payment method issuer list. */
  issuers: Maybe<Array<Maybe<AdyenPaymentMethodsIssuers>>>;
  /** The displayable name of this payment method. */
  name: Maybe<Scalars['String']>;
  /** The unique payment method code. */
  type: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsConfiguration {
  __typename?: 'AdyenPaymentMethodsConfiguration';
  /** Name of the merchant for Google Pay. */
  gatewayMerchantId: Maybe<Scalars['String']>;
  /** ID of the merchant. */
  merchantId: Maybe<Scalars['String']>;
  /** Name of the merchant. */
  merchantName: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsDetails {
  __typename?: 'AdyenPaymentMethodsDetails';
  /** The items to choose from in case that the payment method includes a selection list. */
  items: Maybe<Array<Maybe<AdyenPaymentMethodsDetailsItems>>>;
  /** The value to provide in the result. */
  key: Maybe<Scalars['String']>;
  /** True if this input is optional. */
  optional: Maybe<Scalars['String']>;
  /** The type of the required input. */
  type: Maybe<Scalars['String']>;
  /** The value can be pre-filled, if available. */
  value: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsDetailsItems {
  __typename?: 'AdyenPaymentMethodsDetailsItems';
  /** The value to provide in the result. */
  id: Maybe<Scalars['String']>;
  /** The display name. */
  name: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsExtraDetails {
  __typename?: 'AdyenPaymentMethodsExtraDetails';
  /** Extra configuration settings. */
  configuration: Maybe<AdyenPaymentMethodsExtraDetailsConfiguration>;
  /** Icon for the payment method. */
  icon: Maybe<AdyenPaymentMethodIcon>;
  /** True if the payment method is Open Invoice. */
  isOpenInvoice: Maybe<Scalars['Boolean']>;
  /** The unique payment method code. */
  type: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsExtraDetailsConfiguration {
  __typename?: 'AdyenPaymentMethodsExtraDetailsConfiguration';
  /** Current order amount in minor units. */
  amount: Maybe<Money>;
  /** Current order currency. */
  currency: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsIssuers {
  __typename?: 'AdyenPaymentMethodsIssuers';
  /** Issuer ID. */
  id: Maybe<Scalars['String']>;
  /** Issuer name. */
  name: Maybe<Scalars['String']>;
}

export interface AdyenPaymentMethodsResponse {
  __typename?: 'AdyenPaymentMethodsResponse';
  paymentMethods: Maybe<Array<Maybe<AdyenPaymentMethodsArray>>>;
  storedPaymentMethods: Maybe<Array<Maybe<AdyenStoredPaymentMethodsArray>>>;
}

export interface AdyenPaymentStatus {
  __typename?: 'AdyenPaymentStatus';
  /** Object containing information about the payment's next step. */
  action: Maybe<Scalars['String']>;
  /** Additional data required for the next step in the payment process. */
  additionalData: Maybe<Scalars['String']>;
  /** If True, no further action is required and customer should be redirect to success page. */
  isFinal: Maybe<Scalars['Boolean']>;
  /** Current state of the order in Adyen. */
  resultCode: Maybe<Scalars['String']>;
}

export interface AdyenStoredPaymentMethodsArray {
  __typename?: 'AdyenStoredPaymentMethodsArray';
  /** The brand of the card. */
  brand: Maybe<Scalars['String']>;
  /** The month the card expires. */
  expiryMonth: Maybe<Scalars['String']>;
  /** The year the card expires. */
  expiryYear: Maybe<Scalars['String']>;
  /** The unique payment method code. */
  holderName: Maybe<Scalars['String']>;
  /** The IBAN of the bank account. */
  iban: Maybe<Scalars['String']>;
  /** A unique identifier of this stored payment method. */
  id: Maybe<Scalars['String']>;
  /** The last four digits of the PAN. */
  lastFour: Maybe<Scalars['String']>;
  /** The display name of the stored payment method. */
  name: Maybe<Scalars['String']>;
  /**
   * Returned in the response if you are not tokenizing with Adyen and are using
   * the Merchant-initiated transactions (MIT) framework from Mastercard or Visa.
   * This contains either the Mastercard Trace ID or the Visa Transaction ID.
   */
  networkTxReference: Maybe<Scalars['String']>;
  /** The name of the bank account holder. */
  ownerName: Maybe<Scalars['String']>;
  /** The shopper’s email address. */
  shopperEmail: Maybe<Scalars['String']>;
  /** The supported shopper interactions for this stored payment method. */
  supportedShopperInteractions: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The type of payment method. */
  type: Maybe<Scalars['String']>;
}

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export interface Aggregation {
  __typename?: 'Aggregation';
  /** Attribute code of the aggregation group. */
  attributeCode: Scalars['String'];
  /** The number of options in the aggregation group. */
  count: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options: Maybe<Array<Maybe<AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block. */
  position: Maybe<Scalars['Int']>;
}

/** An implementation of `AggregationOptionInterface`. */
export interface AggregationOption extends AggregationOptionInterface {
  __typename?: 'AggregationOption';
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
}

/** Defines aggregation option fields. */
export interface AggregationOptionInterface {
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
}

/** Filter category aggregations in layered navigation. */
export interface AggregationsCategoryFilterInput {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: Maybe<Scalars['Boolean']>;
}

/** An input object that specifies the filters used in product aggregations. */
export interface AggregationsFilterInput {
  /** Filter category aggregations in layered navigation. */
  category?: Maybe<AggregationsCategoryFilterInput>;
}

/** Contains the applied coupon code. */
export interface AppliedCoupon {
  __typename?: 'AppliedCoupon';
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String'];
}

/** Contains an applied gift card with applied and remaining balance. */
export interface AppliedGiftCard {
  __typename?: 'AppliedGiftCard';
  /** The amount applied to the current cart. */
  appliedBalance: Maybe<Money>;
  /** The gift card account code. */
  code: Maybe<Scalars['String']>;
  /** The remaining balance on the gift card. */
  currentBalance: Maybe<Money>;
  /** The expiration date of the gift card. */
  expirationDate: Maybe<Scalars['String']>;
}

/** Contains the applied and current balances. */
export interface AppliedStoreCredit {
  __typename?: 'AppliedStoreCredit';
  /** The applied store credit balance to the current cart. */
  appliedBalance: Maybe<Money>;
  /** The current balance remaining on store credit. */
  currentBalance: Maybe<Money>;
  /** Indicates whether store credits are enabled. If the feature is disabled, then the current balance will not be returned. */
  enabled: Maybe<Scalars['Boolean']>;
}

/** Specifies the coupon code to apply to the cart. */
export interface ApplyCouponToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** A valid coupon code. */
  coupon_code: Scalars['String'];
}

/** Contains details about the cart after applying a coupon. */
export interface ApplyCouponToCartOutput {
  __typename?: 'ApplyCouponToCartOutput';
  /** The cart after applying a coupon. */
  cart: Cart;
}

/** Defines the input required to run the `applyGiftCardToCart` mutation. */
export interface ApplyGiftCardToCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The gift card code to be applied to the cart. */
  gift_card_code: Scalars['String'];
}

/** Defines the possible output for the `applyGiftCardToCart` mutation. */
export interface ApplyGiftCardToCartOutput {
  __typename?: 'ApplyGiftCardToCartOutput';
  /** Describes the contents of the specified shopping cart. */
  cart: Cart;
}

/** Contains the customer cart. */
export interface ApplyRewardPointsToCartOutput {
  __typename?: 'ApplyRewardPointsToCartOutput';
  /** The customer cart after reward points are applied. */
  cart: Cart;
}

/** Defines the input required to run the `applyStoreCreditToCart` mutation. */
export interface ApplyStoreCreditToCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
}

/** Defines the possible output for the `applyStoreCreditToCart` mutation. */
export interface ApplyStoreCreditToCartOutput {
  __typename?: 'ApplyStoreCreditToCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Cart;
}

/** Contains the results of the request to assign a compare list. */
export interface AssignCompareListToCustomerOutput {
  __typename?: 'AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>;
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean'];
}

/** Contains details about the attribute, including the code and type. */
export interface Attribute {
  __typename?: 'Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attributeOptions: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute. */
  attributeType: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entityType: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute. */
  inputType: Maybe<Scalars['String']>;
  /** Details about the storefront properties configured for the attribute. */
  storefrontProperties: Maybe<StorefrontProperties>;
}

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export interface AttributeInput {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: Maybe<Scalars['String']>;
}

/** Defines an attribute option. */
export interface AttributeOption {
  __typename?: 'AttributeOption';
  /** The label assigned to the attribute option. */
  label: Maybe<Scalars['String']>;
  /** The attribute option value. */
  value: Maybe<Scalars['String']>;
}

export interface AutocompleteMatch {
  __typename?: 'AutocompleteMatch';
  context: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  highlights: Maybe<Array<Maybe<Highlight>>>;
  label: Maybe<Scalars['String']>;
  precision: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
}

export interface AutocompleteMatches {
  __typename?: 'AutocompleteMatches';
  matches: Maybe<Array<Maybe<AutocompleteMatch>>>;
  xAutocompleteSession: Maybe<Scalars['String']>;
}

/** Describes a payment method that the shopper can use to pay for the order. */
export interface AvailablePaymentMethod {
  __typename?: 'AvailablePaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** If the payment method is an online integration */
  isDeferred: Scalars['Boolean'];
  /** The payment method title. */
  title: Scalars['String'];
}

/** Contains details about the possible shipping methods and carriers. */
export interface AvailableShippingMethod {
  __typename?: 'AvailableShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String'];
  /** Describes an error condition. */
  errorMessage: Maybe<Scalars['String']>;
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  methodCode: Maybe<Scalars['String']>;
  /** The label for the shipping method code. The value could be null if no method is available. */
  methodTitle: Maybe<Scalars['String']>;
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money;
}

export enum BatchMutationStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  MIXED_RESULTS = 'MIXED_RESULTS'
}

export interface BestsellersProductOutput {
  __typename?: 'BestsellersProductOutput';
  /** Currency */
  currency: Maybe<Scalars['String']>;
  /** Period. */
  period: Maybe<Scalars['String']>;
  /** Product ID */
  productId: Maybe<Scalars['String']>;
  /** Product Image URL */
  productImageUrl: Maybe<Scalars['String']>;
  /** Product Name */
  productName: Maybe<Scalars['String']>;
  /** Product Price */
  productPrice: Maybe<Scalars['String']>;
  /** Product SKU */
  productSku: Maybe<Scalars['String']>;
  /** Product URL */
  productUrl: Maybe<Scalars['String']>;
  /** Order Quantity. */
  qtyOrdered: Maybe<Scalars['Int']>;
}

/** Defines the billing address. */
export interface BillingAddressInput {
  /** Defines a billing address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: Maybe<Scalars['Boolean']>;
}

/** Contains details about the billing address. */
export interface BillingCartAddress extends CartAddressInterface {
  __typename?: 'BillingCartAddress';
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** @deprecated The field is used only in shipping address. */
  customerNotes: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The gender_address attribute value. */
  genderAddress: Maybe<Scalars['Int']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']>;
}

/** Contains details about an individual category that comprises a breadcrumb. */
export interface Breadcrumb {
  __typename?: 'Breadcrumb';
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  categoryId: Maybe<Scalars['Int']>;
  /** The category level. */
  categoryLevel: Maybe<Scalars['Int']>;
  /** The display name of the category. */
  categoryName: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  categoryUid: Scalars['ID'];
  /** The URL key of the category. */
  categoryUrlKey: Maybe<Scalars['String']>;
  /** The URL path of the category. */
  categoryUrlPath: Maybe<Scalars['String']>;
}

/** An implementation for bundle product cart items. */
export interface BundleCartItem extends CartItemInterface {
  __typename?: 'BundleCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>;
  /** An array containing the bundle options the shopper selected. */
  bundleOptions: Array<Maybe<SelectedBundleOption>>;
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Defines bundle product options for `CreditMemoItemInterface`. */
export interface BundleCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'BundleCreditMemoItem';
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
}

/** Defines bundle product options for `InvoiceItemInterface`. */
export interface BundleInvoiceItem extends InvoiceItemInterface {
  __typename?: 'BundleInvoiceItem';
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
}

/** Defines an individual item within a bundle product. */
export interface BundleItem {
  __typename?: 'BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options: Maybe<Array<Maybe<BundleItemOption>>>;
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position: Maybe<Scalars['Int']>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** Indicates whether the item must be included in the bundle. */
  required: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid: Maybe<Scalars['ID']>;
}

/** Defines the characteristics that comprise a specific bundle item and its options. */
export interface BundleItemOption {
  __typename?: 'BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  canChangeQuantity: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  isDefault: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty: Maybe<Scalars['Float']>;
  /** The quantity of this specific bundle item. */
  quantity: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
}

/** Defines the input for a bundle option. */
export interface BundleOptionInput {
  /** The ID of the option. */
  id: Scalars['Int'];
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float'];
  /** An array with the chosen value of the option. */
  value: Array<Maybe<Scalars['String']>>;
}

/** Defines bundle product options for `OrderItemInterface`. */
export interface BundleOrderItem extends OrderItemInterface {
  __typename?: 'BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  discountPercent: Maybe<Scalars['Float']>;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  priceInclTax: Money;
  product: Maybe<ProductInterface>;
  productParentSku: Maybe<Scalars['String']>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']>;
  rowTotal: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']>;
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProduct extends ProductInterface, RoutableInterface, PhysicalProductInterface, CustomizableProductInterface {
  __typename?: 'BundleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamicPrice: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamicSku: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamicWeight: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** An array containing information about individual bundle items. */
  items: Maybe<Array<Maybe<BundleItem>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  priceView: Maybe<PriceViewEnum>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** Indicates whether to ship bundle items together or individually. */
  shipBundleItems: Maybe<ShipBundleItemsEnum>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Defines a single bundle product. */
export interface BundleProductCartItemInput {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<Maybe<BundleOptionInput>>;
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput;
}

/** Defines bundle product options for `ShipmentItemInterface`. */
export interface BundleShipmentItem extends ShipmentItemInterface {
  __typename?: 'BundleShipmentItem';
  /** A list of bundle options that are assigned to a shipped product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
}

/** Defines bundle product options for `WishlistItemInterface`. */
export interface BundleWishlistItem extends WishlistItemInterface {
  __typename?: 'BundleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** An array containing information about the selected bundle items. */
  bundleOptions: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

/** Contains the contents and other details about a guest or customer cart. */
export interface Cart {
  __typename?: 'Cart';
  /** @deprecated Use `applied_coupons` instead. */
  appliedCoupon: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** An array of gift card items applied to the cart. */
  appliedGiftCards: Maybe<Array<Maybe<AppliedGiftCard>>>;
  /** The amount of reward points applied to the cart. */
  appliedRewardPoints: Maybe<RewardPointsAmount>;
  /** Store credit information applied to the cart. */
  appliedStoreCredit: Maybe<AppliedStoreCredit>;
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** The list of available gift wrapping options for the cart. */
  availableGiftWrappings: Array<Maybe<GiftWrapping>>;
  /** An array of available payment methods. */
  availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  /** The billing address assigned to the cart. */
  billingAddress: Maybe<BillingCartAddress>;
  /** The email address of the guest or customer. */
  email: Maybe<Scalars['String']>;
  /** The entered gift message for the cart */
  giftMessage: Maybe<GiftMessage>;
  /** Indicates whether the shopper requested gift receipt for the cart. */
  giftReceiptIncluded: Scalars['Boolean'];
  /** The selected gift wrapping for the cart. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID'];
  /** Indicates whether the cart contains only virtual products. */
  isVirtual: Scalars['Boolean'];
  /** An array of products that have been added to the cart. */
  items: Maybe<Array<Maybe<CartItemInterface>>>;
  /** Messages related to the cart */
  messages: Maybe<Array<Maybe<CartMessage>>>;
  /** Swiss Sense Mulesoft customer id */
  mulesoftCustomerId: Maybe<Scalars['String']>;
  /** The OCP order information */
  ocpOrderInformation: Maybe<OcpOrderInformation>;
  /** Order condition */
  orderCondition: Maybe<OrderConditionEnum>;
  /** Quote Swiss Sense order information */
  orderInformation: OrderInformation;
  /** Returns quote payment information */
  paymentInformation: Maybe<PaymentInformation>;
  /** Pricing details for the quote. */
  prices: Maybe<CartPrices>;
  /** Indicates whether the shopper requested a printed card for the cart. */
  printedCardIncluded: Scalars['Boolean'];
  /** The url for a quotation pdf. */
  quotationUrl: Maybe<Scalars['String']>;
  /** Quote OCP permissions */
  quotePermissions: QuotePermissions;
  /** The SAP quote information */
  sap: QuoteSapInformation;
  /** Indicates which payment method was applied to the cart. */
  selectedPaymentMethod: Maybe<SelectedPaymentMethod>;
  /** Shipment condition */
  shipmentCondition: Maybe<Scalars['String']>;
  /** An array of shipping addresses assigned to the cart. */
  shippingAddresses: Array<Maybe<ShippingCartAddress>>;
  /** Messages related to the cart */
  totalSegments: Maybe<Array<Maybe<TotalSegment>>>;
  /** The total number of items in the cart. */
  totalQuantity: Scalars['Float'];
}

/** Contains details the country in a billing or shipping address. */
export interface CartAddressCountry {
  __typename?: 'CartAddressCountry';
  /** The country code. */
  code: Scalars['String'];
  /** The display label for the country. */
  label: Scalars['String'];
}

/** Defines the billing or shipping address to be applied to the cart. */
export interface CartAddressInput {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String'];
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** A string that defines the state or province of the billing or shipping address. */
  region?: Maybe<Scalars['String']>;
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: Maybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: Maybe<Scalars['Boolean']>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The VAT company number for billing or shipping address. */
  vat_id?: Maybe<Scalars['String']>;
}

export interface CartAddressInterface {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']>;
}

/** Contains details about the region in a billing or shipping address. */
export interface CartAddressRegion {
  __typename?: 'CartAddressRegion';
  /** The state or province code. */
  code: Maybe<Scalars['String']>;
  /** The display label for the region. */
  label: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']>;
}

/** Contains information about discounts applied to the cart. */
export interface CartDiscount {
  __typename?: 'CartDiscount';
  /** The amount of the discount applied to the item. */
  amount: Money;
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']>>;
}

export interface CartItemError {
  __typename?: 'CartItemError';
  /** An error code that describes the error encountered */
  code: CartItemErrorType;
  /** A localized error message */
  message: Scalars['String'];
}

export enum CartItemErrorType {
  UNDEFINED = 'UNDEFINED',
  ITEM_QTY = 'ITEM_QTY',
  ITEM_INCREMENTS = 'ITEM_INCREMENTS'
}

/** Defines an item to be added to the cart. */
export interface CartItemInput {
  /** NOT IMPLEMENTED YET! */
  custom_image?: Maybe<CustomImageInput>;
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For a child product, the SKU of its parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float'];
  /**
   * The selected options for the base product, such as color or size, using the
   * unique ID for an object such as `CustomizableRadioOption`,
   * `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`.
   */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product. */
  sku: Scalars['String'];
}

/** An interface for products in a cart. */
export interface CartItemInterface {
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Contains details about the price of the item, including taxes and discounts. */
export interface CartItemPrices {
  __typename?: 'CartItemPrices';
  /** An array of discounts to be applied to the cart item. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** An array of FPTs applied to the cart item. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>;
  originalPriceInclTax: Money;
  /**
   * The price of the item before any discounts were applied. The price that might
   * include tax, depending on the configured display settings for cart.
   */
  price: Money;
  priceInclTax: Money;
  /**
   * The price of the item before any discounts were applied. The price that might
   * include tax, depending on the configured display settings for cart.
   */
  priceIncludingTax: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The value of the price multiplied by the quantity of the item. */
  rowTotal: Money;
  /** The value of `row_total` plus the tax applied to the item. */
  rowTotalIncludingTax: Money;
  /** The total of all discounts applied to the item. */
  totalItemDiscount: Maybe<Money>;
}

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export interface CartItemQuantity {
  __typename?: 'CartItemQuantity';
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cartItemId: Scalars['Int'];
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float'];
}

/** Contains details about the price of a selected customizable value. */
export interface CartItemSelectedOptionValuePrice {
  __typename?: 'CartItemSelectedOptionValuePrice';
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum;
  /** A string that describes the unit of the value. */
  units: Scalars['String'];
  /** A price value. */
  value: Scalars['Float'];
}

/** A single item to be updated. */
export interface CartItemUpdateInput {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  gift_message?: Maybe<GiftMessageInput>;
  /** The unique ID for a `GiftWrapping` object to be used for the cart item. */
  gift_wrapping_id?: Maybe<Scalars['ID']>;
  /** The new quantity of the item. */
  quantity?: Maybe<Scalars['Float']>;
}

export interface CartMessage {
  __typename?: 'CartMessage';
  code: Scalars['String'];
  message: Scalars['String'];
  /** Message type enum */
  type: CartMessageType;
}

export enum CartMessageType {
  ERROR = 'ERROR'
}

/** Contains details about the final price of items in the cart, including discount and tax information. */
export interface CartPrices {
  __typename?: 'CartPrices';
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  appliedTaxes: Maybe<Array<Maybe<CartTaxItem>>>;
  /** @deprecated Use discounts instead. */
  discount: Maybe<CartDiscount>;
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The list of prices for the selected gift options. */
  giftOptions: Maybe<GiftOptionsPrices>;
  /** The total, including discounts, taxes, shipping, and other fees. */
  grandTotal: Maybe<Money>;
  /** The subtotal without any applied taxes. */
  subtotalExcludingTax: Maybe<Money>;
  /** The subtotal including any applied taxes. */
  subtotalIncludingTax: Maybe<Money>;
  /** The subtotal with any discounts applied, but not taxes. */
  subtotalWithDiscountExcludingTax: Maybe<Money>;
}

export interface CartRuleDiscountBreakdown {
  __typename?: 'CartRuleDiscountBreakdown';
  /** The configured value for the cart rule applied */
  configuredValue: Scalars['Int'];
  name: Scalars['String'];
  ruleId: Scalars['ID'];
  /** The discount type of the cart rule applied */
  type: CartRuleDiscountType;
}

export enum CartRuleDiscountType {
  BY_PERCENT = 'BY_PERCENT',
  BY_FIXED = 'BY_FIXED'
}

/** Contains tax information about an item in the cart. */
export interface CartTaxItem {
  __typename?: 'CartTaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The description of the tax. */
  label: Scalars['String'];
}

/** An error encountered while adding an item to the the cart. */
export interface CartUserInputError {
  __typename?: 'CartUserInputError';
  /** A cart-specific error code. */
  code: CartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
}

export enum CartUserInputErrorType {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  NOT_SALABLE = 'NOT_SALABLE',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  UNDEFINED = 'UNDEFINED',
  PERMISSION_DENIED = 'PERMISSION_DENIED'
}

/**
 * Defines the filters to be used in the search. A filter contains at least one
 * attribute, a comparison operator, and the value that is being searched for.
 */
export interface CategoryFilterInput {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: Maybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: Maybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: Maybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: Maybe<FilterEqualTypeInput>;
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterface {
  amExcludeFromFilter: Maybe<Scalars['Int']>;
  automaticSorting: Maybe<Scalars['String']>;
  availableSortBy: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Categories' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  categoryCmsBanner: Maybe<Scalars['Int']>;
  channableCatDisableExport: Maybe<Scalars['Int']>;
  childrenCount: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  customLayoutUpdateFile: Maybe<Scalars['String']>;
  defaultHighlightProductTiles: Maybe<Scalars['Int']>;
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description: Maybe<Scalars['String']>;
  displayMode: Maybe<Scalars['String']>;
  displayPriority: Maybe<Scalars['String']>;
  faqSchema: Maybe<Scalars['String']>;
  filterPriceRange: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']>;
  image: Maybe<Scalars['String']>;
  includeInMenu: Maybe<Scalars['Int']>;
  isAnchor: Maybe<Scalars['Int']>;
  landingPage: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']>;
  metaDescription: Maybe<Scalars['String']>;
  metaKeywords: Maybe<Scalars['String']>;
  metaTitle: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name: Maybe<Scalars['String']>;
  /** The full category path. */
  path: Maybe<Scalars['String']>;
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  productCount: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>;
  quickFilterTitle: Maybe<Scalars['String']>;
  /** Indicates whether the category is staged for a future campaign. */
  staged: Scalars['Boolean'];
  thumbnail: Maybe<Scalars['String']>;
  thumbnailApp: Maybe<Scalars['String']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
}


/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceProductsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort: Maybe<ProductAttributeSortInput>;
}

/** Contains details about the products assigned to a category. */
export interface CategoryProducts {
  __typename?: 'CategoryProducts';
  /** An array of products that are assigned to the category. */
  items: Maybe<Array<Maybe<ProductInterface>>>;
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  totalCount: Maybe<Scalars['Int']>;
}

/** Contains a collection of `CategoryTree` objects and pagination information. */
export interface CategoryResult {
  __typename?: 'CategoryResult';
  /** A list of categories that match the filter criteria. */
  items: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  totalCount: Maybe<Scalars['Int']>;
}

/** Contains the hierarchy of categories. */
export interface CategoryTree extends CategoryInterface, RoutableInterface {
  __typename?: 'CategoryTree';
  amExcludeFromFilter: Maybe<Scalars['Int']>;
  automaticSorting: Maybe<Scalars['String']>;
  availableSortBy: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Categories' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  categoryCmsBanner: Maybe<Scalars['Int']>;
  channableCatDisableExport: Maybe<Scalars['Int']>;
  /** A tree of child categories. */
  children: Maybe<Array<Maybe<CategoryTree>>>;
  childrenCount: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  customLayoutUpdateFile: Maybe<Scalars['String']>;
  defaultHighlightProductTiles: Maybe<Scalars['Int']>;
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description: Maybe<Scalars['String']>;
  displayMode: Maybe<Scalars['String']>;
  displayPriority: Maybe<Scalars['String']>;
  faqSchema: Maybe<Scalars['String']>;
  filterPriceRange: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']>;
  image: Maybe<Scalars['String']>;
  includeInMenu: Maybe<Scalars['Int']>;
  isAnchor: Maybe<Scalars['Int']>;
  landingPage: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']>;
  metaDescription: Maybe<Scalars['String']>;
  metaKeywords: Maybe<Scalars['String']>;
  metaTitle: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name: Maybe<Scalars['String']>;
  /** The full category path. */
  path: Maybe<Scalars['String']>;
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']>;
  /**
   * The number of products in the category that are marked as visible. By default,
   * in complex products, parent products are visible, but their child products are not.
   */
  productCount: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>;
  quickFilterTitle: Maybe<Scalars['String']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** Indicates whether the category is staged for a future campaign. */
  staged: Scalars['Boolean'];
  thumbnail: Maybe<Scalars['String']>;
  thumbnailApp: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
}


/** Contains the hierarchy of categories. */
export interface CategoryTreeProductsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort: Maybe<ProductAttributeSortInput>;
}

/** Defines details about an individual checkout agreement. */
export interface CheckoutAgreement {
  __typename?: 'CheckoutAgreement';
  /** The ID for a checkout agreement. */
  agreementId: Scalars['Int'];
  /** The checkbox text for the checkout agreement. */
  checkboxText: Scalars['String'];
  /** Required. The text of the agreement. */
  content: Scalars['String'];
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  contentHeight: Maybe<Scalars['String']>;
  /** Indicates whether the `content` text is in HTML format. */
  isHtml: Scalars['Boolean'];
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode;
  /** The name given to the condition. */
  name: Scalars['String'];
}

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /** Conditions are automatically accepted upon checkout. */
  AUTO = 'AUTO',
  /** Shoppers must manually accept the conditions to place an order. */
  MANUAL = 'MANUAL'
}

/** An error encountered while adding an item to the cart. */
export interface CheckoutUserInputError {
  __typename?: 'CheckoutUserInputError';
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes;
  /** A localized error message. */
  message: Scalars['String'];
  /**
   * The path to the input field that caused an error. See the GraphQL
   * specification about path errors for details:
   * http://spec.graphql.org/draft/#sec-Errors
   */
  path: Array<Maybe<Scalars['String']>>;
}

export enum CheckoutUserInputErrorCodes {
  REORDER_NOT_AVAILABLE = 'REORDER_NOT_AVAILABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  NOT_SALABLE = 'NOT_SALABLE',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  UNDEFINED = 'UNDEFINED'
}

/** Contains details about a specific CMS block. */
export interface CmsBlock {
  __typename?: 'CmsBlock';
  /** The content of the CMS block in raw HTML. */
  content: Maybe<Scalars['String']>;
  /** The CMS block identifier. */
  identifier: Maybe<Scalars['String']>;
  /** The title assigned to the CMS block. */
  title: Maybe<Scalars['String']>;
}

/** Contains an array CMS block items. */
export interface CmsBlocks {
  __typename?: 'CmsBlocks';
  /** An array of CMS blocks. */
  items: Maybe<Array<Maybe<CmsBlock>>>;
}

/** Contains details about a CMS page. */
export interface CmsPage extends RoutableInterface {
  __typename?: 'CmsPage';
  /** The content of the CMS page in raw HTML. */
  content: Maybe<Scalars['String']>;
  /** The heading that displays at the top of the CMS page. */
  contentHeading: Maybe<Scalars['String']>;
  /** The ID of a CMS page. */
  identifier: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  metaDescription: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  metaKeywords: Maybe<Scalars['String']>;
  /** A page title that is indexed by search engines and appears in search results listings. */
  metaTitle: Maybe<Scalars['String']>;
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  pageLayout: Maybe<Scalars['String']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  urlKey: Maybe<Scalars['String']>;
}

export interface ColorSwatchData extends SwatchDataInterface {
  __typename?: 'ColorSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']>;
}

/** Contains an attribute code that is used for product comparisons. */
export interface ComparableAttribute {
  __typename?: 'ComparableAttribute';
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String'];
  /** The label of the attribute code. */
  label: Scalars['String'];
}

/** Defines an object used to iterate through items for product comparisons. */
export interface ComparableItem {
  __typename?: 'ComparableItem';
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Details about a product in a compare list. */
  product: ProductInterface;
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID'];
}

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export interface CompareList {
  __typename?: 'CompareList';
  /** An array of attributes that can be used for comparing products. */
  attributes: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list. */
  itemCount: Scalars['Int'];
  /** An array of products to compare. */
  items: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID'];
}

export interface ComplexTextValue {
  __typename?: 'ComplexTextValue';
  /** Text that can contain HTML tags. */
  html: Scalars['String'];
}

/** Contains details about a configurable product attribute option. */
export interface ConfigurableAttributeOption {
  __typename?: 'ConfigurableAttributeOption';
  /** The ID assigned to the attribute. */
  code: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option. */
  label: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option. */
  valueIndex: Maybe<Scalars['Int']>;
}

/** An implementation for configurable product cart items. */
export interface ConfigurableCartItem extends CartItemInterface {
  __typename?: 'ConfigurableCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>;
  /** An array containing the configuranle options the shopper selected. */
  configurableOptions: Array<Maybe<SelectedConfigurableOption>>;
  /** Product details of the cart item. */
  configuredVariant: ProductInterface;
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export interface ConfigurableOptionAvailableForSelection {
  __typename?: 'ConfigurableOptionAvailableForSelection';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String'];
  /** An array of selectable option value IDs. */
  optionValueUids: Array<Maybe<Scalars['ID']>>;
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProduct extends ProductInterface, RoutableInterface, PhysicalProductInterface, CustomizableProductInterface {
  __typename?: 'ConfigurableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** An array of options for the configurable product. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /**
   * An array of media gallery items and other details about selected configurable
   * product options as well as details about remaining selectable options.
   */
  configurableProductOptionsSelection: Maybe<ConfigurableProductOptionsSelection>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /** An array of simple product variants. */
  variants: Maybe<Array<Maybe<ConfigurableVariant>>>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}


/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductConfigurableProductOptionsSelectionArgs {
  configurableOptionValueUids: Maybe<Array<Scalars['ID']>>;
}


/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

export interface ConfigurableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput;
  /** The SKU of the parent configurable product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: Maybe<Scalars['String']>;
}

/** Contains details about configurable product options. */
export interface ConfigurableProductOption {
  __typename?: 'ConfigurableProductOption';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String'];
  /** The display name of the option. */
  label: Scalars['String'];
  /** The unique ID of the configurable option. */
  uid: Scalars['ID'];
  /** An array of values that are applicable for this option. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>;
}

/** Defines configurable attributes for the specified product. */
export interface ConfigurableProductOptions {
  __typename?: 'ConfigurableProductOptions';
  /** A string that identifies the attribute. */
  attributeCode: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeId: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeIdV2: Maybe<Scalars['Int']>;
  /** The unique ID for an `Attribute` object. */
  attributeUid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']>;
  /** A displayed string that describes the configurable product option. */
  label: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed. */
  position: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  productId: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default. */
  useDefault: Maybe<Scalars['Boolean']>;
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
}

/** Contains metadata corresponding to the selected configurable options. */
export interface ConfigurableProductOptionsSelection {
  __typename?: 'ConfigurableProductOptionsSelection';
  /** An array of all possible configurable options. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** The configurable options available for further selection based on the current selection. */
  optionsAvailableForSelection: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /**
   * A variant represented by the specified configurable options selection. The
   * value is expected to be null until selections are made for each configurable option.
   */
  variant: Maybe<SimpleProduct>;
}

/** Contains the index number assigned to a configurable product option. */
export interface ConfigurableProductOptionsValues {
  __typename?: 'ConfigurableProductOptionsValues';
  /** The label of the product on the default store. */
  defaultLabel: Maybe<Scalars['String']>;
  /** The label of the product. */
  label: Maybe<Scalars['String']>;
  /** The label of the product on the current store. */
  storeLabel: Maybe<Scalars['String']>;
  /** Swatch data for a configurable product option. */
  swatchData: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label. */
  useDefaultValue: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  valueIndex: Maybe<Scalars['Int']>;
}

/** Defines a value for a configurable product option. */
export interface ConfigurableProductOptionValue {
  __typename?: 'ConfigurableProductOptionValue';
  /** Indicates whether the product is available with this selected option. */
  isAvailable: Scalars['Boolean'];
  /** Indicates whether the value is the default. */
  isUseDefault: Scalars['Boolean'];
  /** The display name of the value. */
  label: Scalars['String'];
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch: Maybe<SwatchDataInterface>;
  /** The unique ID of the value. */
  uid: Scalars['ID'];
}

/** Contains all the simple product variants of a configurable product. */
export interface ConfigurableVariant {
  __typename?: 'ConfigurableVariant';
  /** An array of configurable attribute options. */
  attributes: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  /** An array of linked simple products. */
  product: Maybe<SimpleProduct>;
}

/** A configurable product wish list item. */
export interface ConfigurableWishlistItem extends WishlistItemInterface {
  __typename?: 'ConfigurableWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  childSku: Scalars['String'];
  /** An array of selected configurable options. */
  configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configuredVariant: Maybe<ProductInterface>;
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

export enum ConfiguratorLanguages {
  NL = 'nl',
  DE = 'de'
}

/** Contains the source and target wish lists after copying products. */
export interface CopyProductsBetweenWishlistsOutput {
  __typename?: 'CopyProductsBetweenWishlistsOutput';
  /** The destination wish list containing the copied products. */
  destinationWishlist: Wishlist;
  /** The wish list that the products were copied from. */
  sourceWishlist: Wishlist;
  /** An array of errors encountered while copying products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
}

export interface Country {
  __typename?: 'Country';
  /** An array of regions within a particular country. */
  availableRegions: Maybe<Array<Maybe<Region>>>;
  /** The name of the country in English. */
  fullNameEnglish: Maybe<Scalars['String']>;
  /** The name of the country in the current locale. */
  fullNameLocale: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id: Maybe<Scalars['String']>;
  /** The three-letter abbreviation of the country, such as USA. */
  threeLetterAbbreviation: Maybe<Scalars['String']>;
  /** The two-letter abbreviation of the country, such as US. */
  twoLetterAbbreviation: Maybe<Scalars['String']>;
}

/** The list of country codes. */
export enum CountryCodeEnum {
  /** Afghanistan */
  AF = 'AF',
  /** Åland Islands */
  AX = 'AX',
  /** Albania */
  AL = 'AL',
  /** Algeria */
  DZ = 'DZ',
  /** American Samoa */
  AS = 'AS',
  /** Andorra */
  AD = 'AD',
  /** Angola */
  AO = 'AO',
  /** Anguilla */
  AI = 'AI',
  /** Antarctica */
  AQ = 'AQ',
  /** Antigua & Barbuda */
  AG = 'AG',
  /** Argentina */
  AR = 'AR',
  /** Armenia */
  AM = 'AM',
  /** Aruba */
  AW = 'AW',
  /** Australia */
  AU = 'AU',
  /** Austria */
  AT = 'AT',
  /** Azerbaijan */
  AZ = 'AZ',
  /** Bahamas */
  BS = 'BS',
  /** Bahrain */
  BH = 'BH',
  /** Bangladesh */
  BD = 'BD',
  /** Barbados */
  BB = 'BB',
  /** Belarus */
  BY = 'BY',
  /** Belgium */
  BE = 'BE',
  /** Belize */
  BZ = 'BZ',
  /** Benin */
  BJ = 'BJ',
  /** Bermuda */
  BM = 'BM',
  /** Bhutan */
  BT = 'BT',
  /** Bolivia */
  BO = 'BO',
  /** Bosnia & Herzegovina */
  BA = 'BA',
  /** Botswana */
  BW = 'BW',
  /** Bouvet Island */
  BV = 'BV',
  /** Brazil */
  BR = 'BR',
  /** British Indian Ocean Territory */
  IO = 'IO',
  /** British Virgin Islands */
  VG = 'VG',
  /** Brunei */
  BN = 'BN',
  /** Bulgaria */
  BG = 'BG',
  /** Burkina Faso */
  BF = 'BF',
  /** Burundi */
  BI = 'BI',
  /** Cambodia */
  KH = 'KH',
  /** Cameroon */
  CM = 'CM',
  /** Canada */
  CA = 'CA',
  /** Cape Verde */
  CV = 'CV',
  /** Cayman Islands */
  KY = 'KY',
  /** Central African Republic */
  CF = 'CF',
  /** Chad */
  TD = 'TD',
  /** Chile */
  CL = 'CL',
  /** China */
  CN = 'CN',
  /** Christmas Island */
  CX = 'CX',
  /** Cocos (Keeling) Islands */
  CC = 'CC',
  /** Colombia */
  CO = 'CO',
  /** Comoros */
  KM = 'KM',
  /** Congo-Brazzaville */
  CG = 'CG',
  /** Congo-Kinshasa */
  CD = 'CD',
  /** Cook Islands */
  CK = 'CK',
  /** Costa Rica */
  CR = 'CR',
  /** Côte d’Ivoire */
  CI = 'CI',
  /** Croatia */
  HR = 'HR',
  /** Cuba */
  CU = 'CU',
  /** Cyprus */
  CY = 'CY',
  /** Czech Republic */
  CZ = 'CZ',
  /** Denmark */
  DK = 'DK',
  /** Djibouti */
  DJ = 'DJ',
  /** Dominica */
  DM = 'DM',
  /** Dominican Republic */
  DO = 'DO',
  /** Ecuador */
  EC = 'EC',
  /** Egypt */
  EG = 'EG',
  /** El Salvador */
  SV = 'SV',
  /** Equatorial Guinea */
  GQ = 'GQ',
  /** Eritrea */
  ER = 'ER',
  /** Estonia */
  EE = 'EE',
  /** Ethiopia */
  ET = 'ET',
  /** Falkland Islands */
  FK = 'FK',
  /** Faroe Islands */
  FO = 'FO',
  /** Fiji */
  FJ = 'FJ',
  /** Finland */
  FI = 'FI',
  /** France */
  FR = 'FR',
  /** French Guiana */
  GF = 'GF',
  /** French Polynesia */
  PF = 'PF',
  /** French Southern Territories */
  TF = 'TF',
  /** Gabon */
  GA = 'GA',
  /** Gambia */
  GM = 'GM',
  /** Georgia */
  GE = 'GE',
  /** Germany */
  DE = 'DE',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Greece */
  GR = 'GR',
  /** Greenland */
  GL = 'GL',
  /** Grenada */
  GD = 'GD',
  /** Guadeloupe */
  GP = 'GP',
  /** Guam */
  GU = 'GU',
  /** Guatemala */
  GT = 'GT',
  /** Guernsey */
  GG = 'GG',
  /** Guinea */
  GN = 'GN',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Haiti */
  HT = 'HT',
  /** Heard &amp; McDonald Islands */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Hong Kong SAR China */
  HK = 'HK',
  /** Hungary */
  HU = 'HU',
  /** Iceland */
  IS = 'IS',
  /** India */
  IN = 'IN',
  /** Indonesia */
  ID = 'ID',
  /** Iran */
  IR = 'IR',
  /** Iraq */
  IQ = 'IQ',
  /** Ireland */
  IE = 'IE',
  /** Isle of Man */
  IM = 'IM',
  /** Israel */
  IL = 'IL',
  /** Italy */
  IT = 'IT',
  /** Jamaica */
  JM = 'JM',
  /** Japan */
  JP = 'JP',
  /** Jersey */
  JE = 'JE',
  /** Jordan */
  JO = 'JO',
  /** Kazakhstan */
  KZ = 'KZ',
  /** Kenya */
  KE = 'KE',
  /** Kiribati */
  KI = 'KI',
  /** Kuwait */
  KW = 'KW',
  /** Kyrgyzstan */
  KG = 'KG',
  /** Laos */
  LA = 'LA',
  /** Latvia */
  LV = 'LV',
  /** Lebanon */
  LB = 'LB',
  /** Lesotho */
  LS = 'LS',
  /** Liberia */
  LR = 'LR',
  /** Libya */
  LY = 'LY',
  /** Liechtenstein */
  LI = 'LI',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Macau SAR China */
  MO = 'MO',
  /** Macedonia */
  MK = 'MK',
  /** Madagascar */
  MG = 'MG',
  /** Malawi */
  MW = 'MW',
  /** Malaysia */
  MY = 'MY',
  /** Maldives */
  MV = 'MV',
  /** Mali */
  ML = 'ML',
  /** Malta */
  MT = 'MT',
  /** Marshall Islands */
  MH = 'MH',
  /** Martinique */
  MQ = 'MQ',
  /** Mauritania */
  MR = 'MR',
  /** Mauritius */
  MU = 'MU',
  /** Mayotte */
  YT = 'YT',
  /** Mexico */
  MX = 'MX',
  /** Micronesia */
  FM = 'FM',
  /** Moldova */
  MD = 'MD',
  /** Monaco */
  MC = 'MC',
  /** Mongolia */
  MN = 'MN',
  /** Montenegro */
  ME = 'ME',
  /** Montserrat */
  MS = 'MS',
  /** Morocco */
  MA = 'MA',
  /** Mozambique */
  MZ = 'MZ',
  /** Myanmar (Burma) */
  MM = 'MM',
  /** Namibia */
  NA = 'NA',
  /** Nauru */
  NR = 'NR',
  /** Nepal */
  NP = 'NP',
  /** Netherlands */
  NL = 'NL',
  /** Netherlands Antilles */
  AN = 'AN',
  /** New Caledonia */
  NC = 'NC',
  /** New Zealand */
  NZ = 'NZ',
  /** Nicaragua */
  NI = 'NI',
  /** Niger */
  NE = 'NE',
  /** Nigeria */
  NG = 'NG',
  /** Niue */
  NU = 'NU',
  /** Norfolk Island */
  NF = 'NF',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** North Korea */
  KP = 'KP',
  /** Norway */
  NO = 'NO',
  /** Oman */
  OM = 'OM',
  /** Pakistan */
  PK = 'PK',
  /** Palau */
  PW = 'PW',
  /** Palestinian Territories */
  PS = 'PS',
  /** Panama */
  PA = 'PA',
  /** Papua New Guinea */
  PG = 'PG',
  /** Paraguay */
  PY = 'PY',
  /** Peru */
  PE = 'PE',
  /** Philippines */
  PH = 'PH',
  /** Pitcairn Islands */
  PN = 'PN',
  /** Poland */
  PL = 'PL',
  /** Portugal */
  PT = 'PT',
  /** Qatar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Romania */
  RO = 'RO',
  /** Russia */
  RU = 'RU',
  /** Rwanda */
  RW = 'RW',
  /** Samoa */
  WS = 'WS',
  /** San Marino */
  SM = 'SM',
  /** São Tomé & Príncipe */
  ST = 'ST',
  /** Saudi Arabia */
  SA = 'SA',
  /** Senegal */
  SN = 'SN',
  /** Serbia */
  RS = 'RS',
  /** Seychelles */
  SC = 'SC',
  /** Sierra Leone */
  SL = 'SL',
  /** Singapore */
  SG = 'SG',
  /** Slovakia */
  SK = 'SK',
  /** Slovenia */
  SI = 'SI',
  /** Solomon Islands */
  SB = 'SB',
  /** Somalia */
  SO = 'SO',
  /** South Africa */
  ZA = 'ZA',
  /** South Georgia & South Sandwich Islands */
  GS = 'GS',
  /** South Korea */
  KR = 'KR',
  /** Spain */
  ES = 'ES',
  /** Sri Lanka */
  LK = 'LK',
  /** St. Barthélemy */
  BL = 'BL',
  /** St. Helena */
  SH = 'SH',
  /** St. Kitts & Nevis */
  KN = 'KN',
  /** St. Lucia */
  LC = 'LC',
  /** St. Martin */
  MF = 'MF',
  /** St. Pierre & Miquelon */
  PM = 'PM',
  /** St. Vincent & Grenadines */
  VC = 'VC',
  /** Sudan */
  SD = 'SD',
  /** Suriname */
  SR = 'SR',
  /** Svalbard & Jan Mayen */
  SJ = 'SJ',
  /** Swaziland */
  SZ = 'SZ',
  /** Sweden */
  SE = 'SE',
  /** Switzerland */
  CH = 'CH',
  /** Syria */
  SY = 'SY',
  /** Taiwan */
  TW = 'TW',
  /** Tajikistan */
  TJ = 'TJ',
  /** Tanzania */
  TZ = 'TZ',
  /** Thailand */
  TH = 'TH',
  /** Timor-Leste */
  TL = 'TL',
  /** Togo */
  TG = 'TG',
  /** Tokelau */
  TK = 'TK',
  /** Tonga */
  TO = 'TO',
  /** Trinidad & Tobago */
  TT = 'TT',
  /** Tunisia */
  TN = 'TN',
  /** Turkey */
  TR = 'TR',
  /** Turkmenistan */
  TM = 'TM',
  /** Turks & Caicos Islands */
  TC = 'TC',
  /** Tuvalu */
  TV = 'TV',
  /** Uganda */
  UG = 'UG',
  /** Ukraine */
  UA = 'UA',
  /** United Arab Emirates */
  AE = 'AE',
  /** United Kingdom */
  GB = 'GB',
  /** United States */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** U.S. Outlying Islands */
  UM = 'UM',
  /** U.S. Virgin Islands */
  VI = 'VI',
  /** Uzbekistan */
  UZ = 'UZ',
  /** Vanuatu */
  VU = 'VU',
  /** Vatican City */
  VA = 'VA',
  /** Venezuela */
  VE = 'VE',
  /** Vietnam */
  VN = 'VN',
  /** Wallis & Futuna */
  WF = 'WF',
  /** Western Sahara */
  EH = 'EH',
  /** Yemen */
  YE = 'YE',
  /** Zambia */
  ZM = 'ZM',
  /** Zimbabwe */
  ZW = 'ZW'
}

/** Contains an array of product IDs to use for creating a compare list. */
export interface CreateCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
}

export interface CreateCustomerInput {
  email: Scalars['String'];
  subscriptions: Array<CustomerSubscriptionInput>;
}

/** Assigns a specific `cart_id` to the empty cart. */
export interface CreateEmptyCartInput {
  /** The ID to assign to the cart. */
  cart_id?: Maybe<Scalars['String']>;
}

/** Create Gift Card Account Output */
export interface CreateGiftCardAccountOutput {
  __typename?: 'CreateGiftCardAccountOutput';
  allStores: Scalars['Boolean'];
  balance: Scalars['Float'];
  code: Scalars['String'];
  dateExpires: Maybe<Scalars['String']>;
  giftcardaccountId: Scalars['ID'];
  isRedeemable: Maybe<Scalars['Int']>;
  recipients: Maybe<Array<Maybe<RecipientsGiftCardAccount>>>;
  status: Scalars['Int'];
  websiteId: Maybe<Scalars['Int']>;
}

/** Defines a new gift registry. */
export interface CreateGiftRegistryInput {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: Maybe<Array<Maybe<GiftRegistryDynamicAttributeInput>>>;
  /** The name of the event. */
  event_name: Scalars['String'];
  /** The ID of the selected event type. */
  gift_registry_type_uid: Scalars['ID'];
  /** A message describing the event. */
  message: Scalars['String'];
  /** Indicates whether the registry is PRIVATE or PUBLIC. */
  privacy_settings: GiftRegistryPrivacySettings;
  /** The list of people who receive notifications about the registry. */
  registrants: Array<Maybe<AddGiftRegistryRegistrantInput>>;
  /** The shipping address for all gift registry items. */
  shipping_address?: Maybe<GiftRegistryShippingAddressInput>;
  /** Indicates whether the registry is ACTIVE or INACTIVE. */
  status: GiftRegistryStatus;
}

/** Contains the results of a request to create a gift registry. */
export interface CreateGiftRegistryOutput {
  __typename?: 'CreateGiftRegistryOutput';
  /** The newly-created gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface CreatePayflowProTokenOutput {
  __typename?: 'CreatePayflowProTokenOutput';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String'];
}

/** Defines a new product review. */
export interface CreateProductReviewInput {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<Maybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product. */
  sku: Scalars['String'];
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
}

/** Contains the completed product review. */
export interface CreateProductReviewOutput {
  __typename?: 'CreateProductReviewOutput';
  /** Product review details. */
  review: ProductReview;
}

/** Defines the name and visibility of a new wish list. */
export interface CreateWishlistInput {
  /** The name of the new wish list. */
  name: Scalars['String'];
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum;
}

/** Contains the wish list. */
export interface CreateWishlistOutput {
  __typename?: 'CreateWishlistOutput';
  /** The newly-created wish list */
  wishlist: Wishlist;
}

export interface Credentials {
  __typename?: 'Credentials';
  customerId: Scalars['ID'];
  customer: M2RestCustomer;
  autologinHash: Scalars['String'];
}

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export interface CreditCardDetailsInput {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int'];
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int'];
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int'];
  /** The credit card type. */
  cc_type: Scalars['String'];
}

/** Contains credit memo details. */
export interface CreditMemo {
  __typename?: 'CreditMemo';
  /** Comments on the credit memo. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID'];
  /** An array containing details about refunded items. */
  items: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number. */
  number: Scalars['String'];
  /** Details about the total refunded amount. */
  total: Maybe<CreditMemoTotal>;
}

export interface CreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'CreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
}

/** Credit memo item details. */
export interface CreditMemoItemInterface {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
}

/** Contains credit memo price details. */
export interface CreditMemoTotal {
  __typename?: 'CreditMemoTotal';
  /** An adjustment manually applied to the order. */
  adjustment: Money;
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the credit memo. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the credit memo. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The credit memo tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo. */
  totalShipping: Money;
  /** The amount of tax applied to the credit memo. */
  totalTax: Money;
}

export interface Currency {
  __typename?: 'Currency';
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  availableCurrencyCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The base currency set for the store, such as USD. */
  baseCurrencyCode: Maybe<Scalars['String']>;
  /** The symbol for the specified base currency, such as $. */
  baseCurrencySymbol: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecyCode: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecySymbol: Maybe<Scalars['String']>;
  /** The currency that is displayed by default, such as USD. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']>;
  /** The currency symbol that is displayed by default, such as $. */
  defaultDisplayCurrencySymbol: Maybe<Scalars['String']>;
  /** An array of exchange rates for currencies defined in the store. */
  exchangeRates: Maybe<Array<Maybe<ExchangeRate>>>;
}

/** The list of available currency codes. */
export enum CurrencyEnum {
  AFN = 'AFN',
  ALL = 'ALL',
  AZN = 'AZN',
  DZD = 'DZD',
  AOA = 'AOA',
  ARS = 'ARS',
  AMD = 'AMD',
  AWG = 'AWG',
  AUD = 'AUD',
  BSD = 'BSD',
  BHD = 'BHD',
  BDT = 'BDT',
  BBD = 'BBD',
  BYN = 'BYN',
  BZD = 'BZD',
  BMD = 'BMD',
  BTN = 'BTN',
  BOB = 'BOB',
  BAM = 'BAM',
  BWP = 'BWP',
  BRL = 'BRL',
  GBP = 'GBP',
  BND = 'BND',
  BGN = 'BGN',
  BUK = 'BUK',
  BIF = 'BIF',
  KHR = 'KHR',
  CAD = 'CAD',
  CVE = 'CVE',
  CZK = 'CZK',
  KYD = 'KYD',
  GQE = 'GQE',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  KMF = 'KMF',
  CDF = 'CDF',
  CRC = 'CRC',
  HRK = 'HRK',
  CUP = 'CUP',
  DKK = 'DKK',
  DJF = 'DJF',
  DOP = 'DOP',
  XCD = 'XCD',
  EGP = 'EGP',
  SVC = 'SVC',
  ERN = 'ERN',
  EEK = 'EEK',
  ETB = 'ETB',
  EUR = 'EUR',
  FKP = 'FKP',
  FJD = 'FJD',
  GMD = 'GMD',
  GEK = 'GEK',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GTQ = 'GTQ',
  GNF = 'GNF',
  GYD = 'GYD',
  HTG = 'HTG',
  HNL = 'HNL',
  HKD = 'HKD',
  HUF = 'HUF',
  ISK = 'ISK',
  INR = 'INR',
  IDR = 'IDR',
  IRR = 'IRR',
  IQD = 'IQD',
  ILS = 'ILS',
  JMD = 'JMD',
  JPY = 'JPY',
  JOD = 'JOD',
  KZT = 'KZT',
  KES = 'KES',
  KWD = 'KWD',
  KGS = 'KGS',
  LAK = 'LAK',
  LVL = 'LVL',
  LBP = 'LBP',
  LSL = 'LSL',
  LRD = 'LRD',
  LYD = 'LYD',
  LTL = 'LTL',
  MOP = 'MOP',
  MKD = 'MKD',
  MGA = 'MGA',
  MWK = 'MWK',
  MYR = 'MYR',
  MVR = 'MVR',
  LSM = 'LSM',
  MRO = 'MRO',
  MUR = 'MUR',
  MXN = 'MXN',
  MDL = 'MDL',
  MNT = 'MNT',
  MAD = 'MAD',
  MZN = 'MZN',
  MMK = 'MMK',
  NAD = 'NAD',
  NPR = 'NPR',
  ANG = 'ANG',
  YTL = 'YTL',
  NZD = 'NZD',
  NIC = 'NIC',
  NGN = 'NGN',
  KPW = 'KPW',
  NOK = 'NOK',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PGK = 'PGK',
  PYG = 'PYG',
  PEN = 'PEN',
  PHP = 'PHP',
  PLN = 'PLN',
  QAR = 'QAR',
  RHD = 'RHD',
  RON = 'RON',
  RUB = 'RUB',
  RWF = 'RWF',
  SHP = 'SHP',
  STD = 'STD',
  SAR = 'SAR',
  RSD = 'RSD',
  SCR = 'SCR',
  SLL = 'SLL',
  SGD = 'SGD',
  SKK = 'SKK',
  SBD = 'SBD',
  SOS = 'SOS',
  ZAR = 'ZAR',
  KRW = 'KRW',
  LKR = 'LKR',
  SDG = 'SDG',
  SRD = 'SRD',
  SZL = 'SZL',
  SEK = 'SEK',
  CHF = 'CHF',
  SYP = 'SYP',
  TWD = 'TWD',
  TJS = 'TJS',
  TZS = 'TZS',
  THB = 'THB',
  TOP = 'TOP',
  TTD = 'TTD',
  TND = 'TND',
  TMM = 'TMM',
  USD = 'USD',
  UGX = 'UGX',
  UAH = 'UAH',
  AED = 'AED',
  UYU = 'UYU',
  UZS = 'UZS',
  VUV = 'VUV',
  VEB = 'VEB',
  VEF = 'VEF',
  VND = 'VND',
  CHE = 'CHE',
  CHW = 'CHW',
  XOF = 'XOF',
  WST = 'WST',
  YER = 'YER',
  ZMK = 'ZMK',
  ZWD = 'ZWD',
  TRY = 'TRY',
  AZM = 'AZM',
  ROL = 'ROL',
  TRL = 'TRL',
  XPF = 'XPF'
}

/** Defines an array of custom attributes. */
export interface CustomAttributeMetadata {
  __typename?: 'CustomAttributeMetadata';
  /** An array of attributes. */
  items: Maybe<Array<Maybe<Attribute>>>;
}

/** Defines the customer name, addresses, and other details. */
export interface Customer {
  __typename?: 'Customer';
  /** An array containing the customer's shipping and billing addresses. */
  addresses: Maybe<Array<Maybe<CustomerAddress>>>;
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>;
  /** Timestamp indicating when the account was created. */
  createdAt: Maybe<Scalars['String']>;
  /** The customer's date of birth. */
  dateOfBirth: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address. */
  defaultBilling: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address. */
  defaultShipping: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob: Maybe<Scalars['String']>;
  /** The customer's email address. Required. */
  email: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender: Maybe<Scalars['Int']>;
  /** Details about all of the customer's gift registries. */
  giftRegistries: Maybe<Array<Maybe<GiftRegistry>>>;
  /** Details about a specific gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  groupId: Maybe<Scalars['Int']>;
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename: Maybe<Scalars['String']>;
  orders: Maybe<CustomerOrders>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']>;
  /** Details about the specified return request from the unique ID for a `Return` object. */
  return: Maybe<Return>;
  /** Information about the customer's return requests. */
  returns: Maybe<Returns>;
  /** Contains the customer's product reviews. */
  reviews: ProductReviews;
  /** Customer reward points details. */
  rewardPoints: Maybe<RewardPoints>;
  /** Store credit information applied for the logged in customer. */
  storeCredit: Maybe<CustomerStoreCredit>;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat: Maybe<Scalars['String']>;
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist;
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlistV2: Maybe<Wishlist>;
  /**
   * An array of wishlists. In Magento Open Source, customers are limited to one
   * wish list. The number of wish lists is configurable for Adobe Commerce.
   */
  wishlists: Array<Maybe<Wishlist>>;
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerGiftRegistryArgs {
  giftRegistryUid: Scalars['ID'];
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerOrdersArgs {
  filter: Maybe<CustomerOrdersFilterInput>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort: Maybe<CustomerOrderSortInput>;
  scope: Maybe<ScopeTypeEnum>;
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerReturnArgs {
  uid: Scalars['ID'];
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerReturnsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistV2Args {
  id: Scalars['ID'];
}


/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddress {
  __typename?: 'CustomerAddress';
  /** The customer's city or town. */
  city: Maybe<Scalars['String']>;
  /** The customer's company. */
  company: Maybe<Scalars['String']>;
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>;
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  countryId: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into a container. */
  customAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customerId: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the customer's default billing address. */
  defaultBilling: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the customer's default shipping address. */
  defaultShipping: Maybe<Scalars['Boolean']>;
  /** Contains any extension attributes for the address. */
  extensionAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Maybe<Scalars['String']>;
  /** The ID of a `CustomerAddress` object. */
  id: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name. */
  street: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']>;
}

/** Specifies the attribute code and value of a customer address attribute. */
export interface CustomerAddressAttribute {
  __typename?: 'CustomerAddressAttribute';
  /** The name assigned to the customer address attribute. */
  attributeCode: Maybe<Scalars['String']>;
  /** The valuue assigned to the customer address attribute. */
  value: Maybe<Scalars['String']>;
}

/** Specifies the attribute code and value of a customer attribute. */
export interface CustomerAddressAttributeInput {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String'];
  /** The value assigned to the attribute. */
  value: Scalars['String'];
}

/** Contains details about a billing or shipping address. */
export interface CustomerAddressInput {
  /** The customer's city or town. */
  city?: Maybe<Scalars['String']>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** The two-letter code representing the customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  country_id?: Maybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>;
  /** Indicates whether the address is the default billing address. */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address. */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** The customer's fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: Maybe<Scalars['String']>;
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: Maybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name. */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegion {
  __typename?: 'CustomerAddressRegion';
  /** The state or province name. */
  region: Maybe<Scalars['String']>;
  /** The address region code. */
  regionCode: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']>;
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegionInput {
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The address region code. */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
}

/** An input object for creating a customer. */
export interface CustomerCreateInput {
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. */
  email: Scalars['String'];
  /** The customer's first name. */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname: Scalars['String'];
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
}

/** Contains details about a single downloadable product. */
export interface CustomerDownloadableProduct {
  __typename?: 'CustomerDownloadableProduct';
  /** The date and time the purchase was made. */
  date: Maybe<Scalars['String']>;
  /** The fully qualified URL to the download file. */
  downloadUrl: Maybe<Scalars['String']>;
  /** The unique ID assigned to the item. */
  orderIncrementId: Maybe<Scalars['String']>;
  /** The remaining number of times the customer can download the product. */
  remainingDownloads: Maybe<Scalars['String']>;
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status: Maybe<Scalars['String']>;
}

/** Contains a list of downloadable products. */
export interface CustomerDownloadableProducts {
  __typename?: 'CustomerDownloadableProducts';
  /** An array of purchased downloadable items. */
  items: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
}

/** An input object that assigns or updates customer attributes. */
export interface CustomerInput {
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required when creating a customer. */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
}

/** Contains details about each of the customer's orders. */
export interface CustomerOrder {
  __typename?: 'CustomerOrder';
  /** The billing address for the order. */
  billingAddress: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery. */
  carrier: Maybe<Scalars['String']>;
  /** Comments about the order. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** @deprecated Use the `order_date` field instead. */
  createdAt: Maybe<Scalars['String']>;
  /** A list of credit memos. */
  creditMemos: Maybe<Array<Maybe<CreditMemo>>>;
  customerEmail: Scalars['String'];
  discountDescription: Maybe<Scalars['String']>;
  /** The entered gift message for the order */
  giftMessage: Maybe<GiftMessage>;
  /** Indicates whether the customer requested a gift receipt for the order. */
  giftReceiptIncluded: Scalars['Boolean'];
  /** The selected gift wrapping for the order. */
  giftWrapping: Maybe<GiftWrapping>;
  /** @deprecated Use the `totals.grand_total` field instead. */
  grandTotal: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID'];
  /** @deprecated Use the `id` field instead. */
  incrementId: Maybe<Scalars['String']>;
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>;
  /** An array containing the items purchased in this order. */
  items: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** A list of order items eligible to be in a return request. */
  itemsEligibleForReturn: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** The order number. */
  number: Scalars['String'];
  /** The date the order was placed. */
  orderDate: Scalars['String'];
  /** @deprecated Use the `number` field instead. */
  orderNumber: Scalars['String'];
  /** Payment details for the order. */
  paymentMethods: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** Indicates whether the customer requested a printed card for the order. */
  printedCardIncluded: Scalars['Boolean'];
  /** Return requests associated with this order. */
  returns: Maybe<Returns>;
  /** A list of shipments for the order. */
  shipments: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order. */
  shippingAddress: Maybe<OrderAddress>;
  /** The delivery method for the order. */
  shippingMethod: Maybe<Scalars['String']>;
  /** The current status of the order. */
  status: Scalars['String'];
  /** Details about the calculated totals for this order. */
  total: Maybe<OrderTotal>;
}


/** Contains details about each of the customer's orders. */
export interface CustomerOrderReturnsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** The collection of orders that match the conditions defined in the filter. */
export interface CustomerOrders {
  __typename?: 'CustomerOrders';
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>;
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders. */
  totalCount: Maybe<Scalars['Int']>;
}

/** Identifies the filter to use for filtering orders. */
export interface CustomerOrdersFilterInput {
  /** Filters by order number. */
  number?: Maybe<FilterStringTypeInput>;
}

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
  /** Sorts customer orders by number */
  NUMBER = 'NUMBER',
  /** Sorts customer orders by created_at field */
  CREATED_AT = 'CREATED_AT'
}

/**
 * CustomerOrderSortInput specifies the field to use for sorting search results and
 * indicates whether the results are sorted in ascending or descending order.
 */
export interface CustomerOrderSortInput {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sort_direction: SortEnum;
  /** Specifies the field to use for sorting */
  sort_field: CustomerOrderSortableField;
}

/** Contains details about a newly-created or updated customer. */
export interface CustomerOutput {
  __typename?: 'CustomerOutput';
  /** Customer details after creating or updating a customer. */
  customer: Customer;
}

/** Contains payment tokens stored in the customer's vault. */
export interface CustomerPaymentTokens {
  __typename?: 'CustomerPaymentTokens';
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>;
}

/** Contains store credit information with balance and history. */
export interface CustomerStoreCredit {
  __typename?: 'CustomerStoreCredit';
  /**
   * Contains the customer's store credit balance history. If the history or store
   * credit feature is disabled, then a null value will be returned.
   */
  balanceHistory: Maybe<CustomerStoreCreditHistory>;
  /** The current balance of store credit. */
  currentBalance: Maybe<Money>;
  /** Indicates whether store credits are enabled. If the feature is disabled, then the balance will not be returned. */
  enabled: Maybe<Scalars['Boolean']>;
}


/** Contains store credit information with balance and history. */
export interface CustomerStoreCreditBalanceHistoryArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Lists changes to the amount of store credit available to the customer. */
export interface CustomerStoreCreditHistory {
  __typename?: 'CustomerStoreCreditHistory';
  /** An array containing information about changes to the store credit available to the customer. */
  items: Maybe<Array<Maybe<CustomerStoreCreditHistoryItem>>>;
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The number of items returned. */
  totalCount: Maybe<Scalars['Int']>;
}

/** Contains store credit history information. */
export interface CustomerStoreCreditHistoryItem {
  __typename?: 'CustomerStoreCreditHistoryItem';
  /** The action that was made on the store credit. */
  action: Maybe<Scalars['String']>;
  /** The store credit available to the customer as a result of this action.  */
  actualBalance: Maybe<Money>;
  /** The amount added to or subtracted from the store credit as a result of this action. */
  balanceChange: Maybe<Money>;
  /** The date and time when the store credit change was made. */
  dateTimeChanged: Maybe<Scalars['String']>;
}

export interface CustomerSubscription {
  __typename?: 'CustomerSubscription';
  enabled: Scalars['Boolean'];
  subscription: Scalars['String'];
}

export interface CustomerSubscriptionInput {
  enabled: Scalars['Boolean'];
  subscription: Scalars['String'];
}

export interface CustomerSubscriptionsInput {
  customerId: Scalars['Int'];
  subscriptions: Array<CustomerSubscriptionInput>;
}

/** Contains a customer authorization token. */
export interface CustomerToken {
  __typename?: 'CustomerToken';
  /** The customer authorization token. */
  token: Maybe<Scalars['String']>;
}

/** An input object for updating a customer. */
export interface CustomerUpdateInput {
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
}

export interface CustomImage {
  __typename?: 'CustomImage';
  type: CustomImageType;
  url: Scalars['String'];
}

export interface CustomImageInput {
  type: CustomImageType;
  url: Scalars['String'];
}

export enum CustomImageType {
  CYLINDO = 'CYLINDO'
}

/** Contains information about a text area that is defined as part of a customizable option. */
export interface CustomizableAreaOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableAreaOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text area. */
  value: Maybe<CustomizableAreaValue>;
}

/** Defines the price and sku of a product whose page contains a customized text area. */
export interface CustomizableAreaValue {
  __typename?: 'CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export interface CustomizableCheckboxOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableCheckboxOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of checkbox values. */
  value: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
}

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export interface CustomizableCheckboxValue {
  __typename?: 'CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a date picker that is defined as part of a customizable option. */
export interface CustomizableDateOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableDateOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a date field in a customizable option. */
  value: Maybe<CustomizableDateValue>;
}

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME'
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export interface CustomizableDateValue {
  __typename?: 'CustomizableDateValue';
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** DATE, DATE_TIME or TIME */
  type: Maybe<CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export interface CustomizableDropDownOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableDropDownOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a drop down menu. */
  value: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
}

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export interface CustomizableDropDownValue {
  __typename?: 'CustomizableDropDownValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a text field that is defined as part of a customizable option. */
export interface CustomizableFieldOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFieldOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text field. */
  value: Maybe<CustomizableFieldValue>;
}

/** Defines the price and sku of a product whose page contains a customized text field. */
export interface CustomizableFieldValue {
  __typename?: 'CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a file picker that is defined as part of a customizable option. */
export interface CustomizableFileOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFileOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a file value. */
  value: Maybe<CustomizableFileValue>;
}

/** Defines the price and sku of a product whose page contains a customized file picker. */
export interface CustomizableFileValue {
  __typename?: 'CustomizableFileValue';
  /** The file extension to accept. */
  fileExtension: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  imageSizeX: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  imageSizeY: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
}

/** Contains information about a multiselect that is defined as part of a customizable option. */
export interface CustomizableMultipleOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableMultipleOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a multiselect. */
  value: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
}

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export interface CustomizableMultipleValue {
  __typename?: 'CustomizableMultipleValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
}

/** Defines a customizable option. */
export interface CustomizableOptionInput {
  /** The customizable option ID of the product. */
  id?: Maybe<Scalars['Int']>;
  /** The string value of the option. */
  value_string: Scalars['String'];
}

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export interface CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
}

/** Contains information about customizable product options. */
export interface CustomizableProductInterface {
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
}

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export interface CustomizableRadioOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableRadioOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of radio buttons. */
  value: Maybe<Array<Maybe<CustomizableRadioValue>>>;
}

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export interface CustomizableRadioValue {
  __typename?: 'CustomizableRadioValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
}


/** Contains the results of the request to delete a compare list. */
export interface DeleteCompareListOutput {
  __typename?: 'DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean'];
}

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export interface DeletePaymentTokenOutput {
  __typename?: 'DeletePaymentTokenOutput';
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>;
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean'];
}

/** Contains the status of the request to delete a wish list and an array of the customer's remaining wish lists. */
export interface DeleteWishlistOutput {
  __typename?: 'DeleteWishlistOutput';
  /** Indicates whether the wish list was deleted. */
  status: Scalars['Boolean'];
  /** A list of undeleted wish lists. */
  wishlists: Array<Maybe<Wishlist>>;
}

/** Delivery time result */
export interface DeliveryTime {
  __typename?: 'DeliveryTime';
  deliveryTime: Maybe<Scalars['String']>;
  deliveryTimeString: Maybe<Scalars['String']>;
  shippingCondition: Maybe<Scalars['String']>;
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export interface Discount {
  __typename?: 'Discount';
  /** The amount of the discount. */
  amount: Money;
  /** A description of the discount. */
  label: Scalars['String'];
}

/** An implementation for downloadable product cart items. */
export interface DownloadableCartItem extends CartItemInterface {
  __typename?: 'DownloadableCartItem';
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** An array containing information about the links for the downloadable product added to the cart. */
  links: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** An array containing information about samples of the selected downloadable product. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export interface DownloadableCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'DownloadableCreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
}

export enum DownloadableFileTypeEnum {
  FILE = 'FILE',
  URL = 'URL'
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export interface DownloadableInvoiceItem extends InvoiceItemInterface {
  __typename?: 'DownloadableInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
}

/** Defines characteristics of the links for downloadable product. */
export interface DownloadableItemsLinks {
  __typename?: 'DownloadableItemsLinks';
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
}

/** Defines downloadable product options for `OrderItemInterface`. */
export interface DownloadableOrderItem extends OrderItemInterface {
  __typename?: 'DownloadableOrderItem';
  discountPercent: Maybe<Scalars['Float']>;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  priceInclTax: Money;
  product: Maybe<ProductInterface>;
  productParentSku: Maybe<Scalars['String']>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']>;
  rowTotal: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']>;
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProduct extends ProductInterface, RoutableInterface, CustomizableProductInterface {
  __typename?: 'DownloadableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** An array containing information about the links for this downloadable product. */
  downloadableProductLinks: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadableProductSamples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  linksPurchasedSeparately: Maybe<Scalars['Int']>;
  /** The heading above the list of downloadable products. */
  linksTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
}


/** Defines a product that the shopper downloads. */
export interface DownloadableProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Defines a single downloadable product. */
export interface DownloadableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput;
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinksInput>>>;
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductLinks {
  __typename?: 'DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend. */
  isShareable: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  linkType: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend. */
  numberOfDownloads: Maybe<Scalars['Int']>;
  /** The price of the downloadable product. */
  price: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
}

/** Contains the link ID for the downloadable product. */
export interface DownloadableProductLinksInput {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int'];
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductSamples {
  __typename?: 'DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']>;
  /** The display name of the sample. */
  title: Maybe<Scalars['String']>;
}

/** A downloadable product wish list item. */
export interface DownloadableWishlistItem extends WishlistItemInterface {
  __typename?: 'DownloadableWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** An array containing information about the selected links. */
  linksV2: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
  /** An array containing information about the selected samples. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
}

/** Contains a single dynamic block. */
export interface DynamicBlock {
  __typename?: 'DynamicBlock';
  /** The renderable HTML code of the dynamic block. */
  content: ComplexTextValue;
  /** The unique ID of a `DynamicBlock` object. */
  uid: Scalars['ID'];
}

/** Indicates the locations the dynamic block can be placed. If this field is not specified, the query returns all locations. */
export enum DynamicBlockLocationEnum {
  CONTENT = 'CONTENT',
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

/** Contains an array of dynamic blocks. */
export interface DynamicBlocks {
  __typename?: 'DynamicBlocks';
  /** An array containing individual dynamic blocks. */
  items: Array<Maybe<DynamicBlock>>;
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The number of returned dynamic blocks. */
  totalCount: Scalars['Int'];
}

/** Defines the dynamic block filter. The filter can identify the block type, location and IDs to return. */
export interface DynamicBlocksFilterInput {
  /** An array of dynamic block UIDs to filter on. */
  dynamic_block_uids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** An array indicating the locations the dynamic block can be placed. */
  locations?: Maybe<Array<Maybe<DynamicBlockLocationEnum>>>;
  /** A value indicating the type of dynamic block to filter on. */
  type: DynamicBlockTypeEnum;
}

/** Indicates the selected Dynamic Blocks Rotator inline widget. */
export enum DynamicBlockTypeEnum {
  SPECIFIED = 'SPECIFIED',
  CART_PRICE_RULE_RELATED = 'CART_PRICE_RULE_RELATED',
  CATALOG_PRICE_RULE_RELATED = 'CATALOG_PRICE_RULE_RELATED'
}

export interface EmailInput {
  email: Scalars['String'];
  url: Scalars['String'];
  /** When not present, customer is unknown */
  contactId?: Maybe<Scalars['String']>;
  /** Defaults to 'Website' */
  source?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** The Google Client ID of the client side customer */
  googleClientId?: Maybe<Scalars['String']>;
}

/** Contains details about a custom text attribute that the buyer entered. */
export interface EnteredCustomAttributeInput {
  /** A string that identifies the entered custom attribute. */
  attribute_code: Scalars['String'];
  /** The text or other entered value. */
  value: Scalars['String'];
}

/** Defines a customer-entered option. */
export interface EnteredOptionInput {
  /**
   * The unique ID for a `CustomizableOptionInterface` object, such as a
   * `CustomizableFieldOption`, `CustomizableFileOption`, or
   * `CustomizableAreaOption` object.
   */
  uid: Scalars['ID'];
  /** Text the customer entered. */
  value: Scalars['String'];
}

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export interface EntityUrl {
  __typename?: 'EntityUrl';
  /** @deprecated Use `relative_url` instead. */
  canonicalUrl: Maybe<Scalars['String']>;
  /**
   * The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or
   * similar object associated with the specified URL. This could be a product,
   * category, or CMS page UID.
   */
  entityUid: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id: Maybe<Scalars['Int']>;
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
}

export interface ErrorInterface {
  /** The returned error message. */
  message: Scalars['String'];
}

/** Lists the exchange rate. */
export interface ExchangeRate {
  __typename?: 'ExchangeRate';
  /** Specifies the store’s default currency to exchange to. */
  currencyTo: Maybe<Scalars['String']>;
  /** The exchange rate for the store’s default currency. */
  rate: Maybe<Scalars['Float']>;
}

/** Defines a filter that matches the input exactly. */
export interface FilterEqualTypeInput {
  /**
   * Use this attribute to exactly match the specified string. For example, to
   * filter on a specific category ID, specify a value such as `5`.
   */
  eq?: Maybe<Scalars['String']>;
  /**
   * Use this attribute to filter on an array of values. For example, to filter on
   * category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`.
   */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
}

/** Defines a filter that performs a fuzzy search. */
export interface FilterMatchTypeInput {
  /**
   * Use this attribute to exactly match the specified string. For example, to
   * filter on a specific SKU, specify a value such as `24-MB01`.
   */
  match?: Maybe<Scalars['String']>;
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export interface FilterRangeTypeInput {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: Maybe<Scalars['String']>;
  /** Use this attribute to specify the highest possible value in the range. */
  to?: Maybe<Scalars['String']>;
}

/** Defines a filter for an input string. */
export interface FilterStringTypeInput {
  /** Filters items that are exactly the same as the specified string. */
  eq?: Maybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: Maybe<Scalars['String']>;
}

/** Defines the comparison operators that can be used in a filter. */
export interface FilterTypeInput {
  /** Equals. */
  eq?: Maybe<Scalars['String']>;
  finset?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** From. Must be used with the `to` field. */
  from?: Maybe<Scalars['String']>;
  /** Greater than. */
  gt?: Maybe<Scalars['String']>;
  /** Greater than or equal to. */
  gteq?: Maybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Less than. */
  lt?: Maybe<Scalars['String']>;
  /** Less than or equal to. */
  lteq?: Maybe<Scalars['String']>;
  /** More than or equal to. */
  moreq?: Maybe<Scalars['String']>;
  /** Not equal to. */
  neq?: Maybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not null. */
  notnull?: Maybe<Scalars['String']>;
  /** Is null. */
  null?: Maybe<Scalars['String']>;
  /** To. Must be used with the `from` field. */
  to?: Maybe<Scalars['String']>;
}

/** A single FPT that can be applied to a product price. */
export interface FixedProductTax {
  __typename?: 'FixedProductTax';
  /** The amount of the Fixed Product Tax. */
  amount: Maybe<Money>;
  /** The display label assigned to the Fixed Product Tax. */
  label: Maybe<Scalars['String']>;
}

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
  /**
   * The displayed price includes the FPT amount without displaying the
   * `ProductPrice.fixed_product_taxes` values. This value corresponds to
   * 'Including FPT only'.
   */
  INCLUDE_FPT_WITHOUT_DETAILS = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /**
   * The displayed price includes the FPT amount while displaying the values of
   * `ProductPrice.fixed_product_taxes` separately. This value corresponds to
   * 'Including FPT and FPT description'.
   */
  INCLUDE_FPT_WITH_DETAILS = 'INCLUDE_FPT_WITH_DETAILS',
  /**
   * The displayed price does not include the FPT amount. The values of
   * `ProductPrice.fixed_product_taxes` and the price including the FPT are
   * displayed separately. This value corresponds to 'Excluding FPT, Including FPT
   * description and final price.'
   */
  EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /**
   * The displayed price does not include the FPT amount. The values from
   * `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds
   * to 'Excluding FPT'.
   */
  EXCLUDE_FPT_WITHOUT_DETAILS = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
  FPT_DISABLED = 'FPT_DISABLED'
}

/** Contains details about the gift card account. */
export interface GiftCardAccount {
  __typename?: 'GiftCardAccount';
  /** The balance remaining on the gift card. */
  balance: Maybe<Money>;
  /** The gift card account code. */
  code: Maybe<Scalars['String']>;
  /** The expiration date of the gift card. */
  expirationDate: Maybe<Scalars['String']>;
}

/** Contains the gift card code. */
export interface GiftCardAccountInput {
  /** The applied gift card code. */
  gift_card_code: Scalars['String'];
}

/** Contains the value of a gift card, the website that generated the card, and related information. */
export interface GiftCardAmounts {
  __typename?: 'GiftCardAmounts';
  /** An internal attribute ID. */
  attributeId: Maybe<Scalars['Int']>;
  /** The unique ID for a `GiftCardAmounts` object. */
  uid: Scalars['ID'];
  /** The value of the gift card. */
  value: Maybe<Scalars['Float']>;
  /**
   * An ID that is assigned to each unique gift card amount.
   * @deprecated Use `uid` instead
   */
  valueId: Maybe<Scalars['Int']>;
  /** The ID of the website that generated the gift card. */
  websiteId: Maybe<Scalars['Int']>;
  /** The value of the gift card. */
  websiteValue: Maybe<Scalars['Float']>;
}

/** Contains details about a gift card that has been added to a cart. */
export interface GiftCardCartItem extends CartItemInterface {
  __typename?: 'GiftCardCartItem';
  /** The amount and currency of the gift card. */
  amount: Money;
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array of customizations applied to the gift card. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** The message from the sender to the recipient. */
  message: Maybe<Scalars['String']>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The email address of the person receiving the gift card. */
  recipientEmail: Maybe<Scalars['String']>;
  /** The name of the person receiving the gift card. */
  recipientName: Scalars['String'];
  rejectionReason: Maybe<Scalars['String']>;
  /** The email address of the sender. */
  senderEmail: Maybe<Scalars['String']>;
  /** The name of the sender. */
  senderName: Scalars['String'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

export interface GiftCardCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'GiftCardCreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Selected gift card properties for a credit memo item. */
  giftCard: Maybe<GiftCardItem>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
}

export interface GiftCardInvoiceItem extends InvoiceItemInterface {
  __typename?: 'GiftCardInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Selected gift card properties for an invoice item. */
  giftCard: Maybe<GiftCardItem>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
}

/** Contains details about a gift card. */
export interface GiftCardItem {
  __typename?: 'GiftCardItem';
  /** The message from the sender to the recipient. */
  message: Maybe<Scalars['String']>;
  /** The email address of the receiver of a virtual gift card. */
  recipientEmail: Maybe<Scalars['String']>;
  /** The name of the receiver of a physical or virtual gift card. */
  recipientName: Maybe<Scalars['String']>;
  /** The email address of the sender of a virtual gift card. */
  senderEmail: Maybe<Scalars['String']>;
  /** The name of the sender of a physical or virtual gift card. */
  senderName: Maybe<Scalars['String']>;
}

/** Contains details about the sender, recipient, and amount of a gift card. */
export interface GiftCardOptions {
  __typename?: 'GiftCardOptions';
  /** The amount and currency of the gift card. */
  amount: Maybe<Money>;
  /** The custom amount and currency of the gift card. */
  customGiftcardAmount: Maybe<Money>;
  /** The message from the sender to the recipient. */
  message: Maybe<Scalars['String']>;
  /** The email address of the person receiving the gift card. */
  recipientEmail: Maybe<Scalars['String']>;
  /** The name of the person receiving the gift card. */
  recipientName: Maybe<Scalars['String']>;
  /** The email address of the sender. */
  senderEmail: Maybe<Scalars['String']>;
  /** The name of the sender. */
  senderName: Maybe<Scalars['String']>;
}

export interface GiftCardOrderItem extends OrderItemInterface {
  __typename?: 'GiftCardOrderItem';
  discountPercent: Maybe<Scalars['Float']>;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** Selected gift card properties for an order item. */
  giftCard: Maybe<GiftCardItem>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  priceInclTax: Money;
  product: Maybe<ProductInterface>;
  productParentSku: Maybe<Scalars['String']>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']>;
  rowTotal: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']>;
}

/** Defines properties of a gift card. */
export interface GiftCardProduct extends ProductInterface, PhysicalProductInterface, CustomizableProductInterface, RoutableInterface {
  __typename?: 'GiftCardProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** Indicates whether the customer can provide a message to accompany the gift card. */
  allowMessage: Maybe<Scalars['Boolean']>;
  /** Indicates whether shoppers have the ability to set the value of the gift card. */
  allowOpenAmount: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** An array of customizable gift card options. */
  giftCardOptions: Array<Maybe<CustomizableOptionInterface>>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** An array that contains information about the values and ID of a gift card. */
  giftcardAmounts: Maybe<Array<Maybe<GiftCardAmounts>>>;
  /** An enumeration that specifies the type of gift card. */
  giftcardType: Maybe<GiftCardTypeEnum>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the customer can redeem the value on the card for cash. */
  isRedeemable: Maybe<Scalars['Boolean']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** The number of days after purchase until the gift card expires. A null value means there is no limit. */
  lifetime: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** The maximum number of characters the gift message can contain. */
  messageMaxLength: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** The maximum acceptable value of an open amount gift card. */
  openAmountMax: Maybe<Scalars['Float']>;
  /** The minimum acceptable value of an open amount gift card. */
  openAmountMin: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}


/** Defines properties of a gift card. */
export interface GiftCardProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

export interface GiftCardShipmentItem extends ShipmentItemInterface {
  __typename?: 'GiftCardShipmentItem';
  /** Selected gift card properties for a shipment item. */
  giftCard: Maybe<GiftCardItem>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
}

/** Specifies the gift card type. */
export enum GiftCardTypeEnum {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
  COMBINED = 'COMBINED'
}

/** A single gift card added to a wish list. */
export interface GiftCardWishlistItem extends WishlistItemInterface {
  __typename?: 'GiftCardWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** Details about a gift card. */
  giftCardOptions: GiftCardOptions;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

export interface GiftDataObjectInput {
  all_stores: Scalars['Boolean'];
  balance: Scalars['Float'];
  date_expires?: Maybe<Scalars['String']>;
  is_redeemable?: Maybe<Scalars['Int']>;
  recipients?: Maybe<Array<Maybe<RecipientsGiftCardAccountInput>>>;
  status: Scalars['Int'];
  website_id?: Maybe<Scalars['Int']>;
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessage {
  __typename?: 'GiftMessage';
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
}

/** Defines a gift message. */
export interface GiftMessageInput {
  /** The name of the sender. */
  from: Scalars['String'];
  /** The text of the gift message. */
  message: Scalars['String'];
  /** The name of the recepient. */
  to: Scalars['String'];
}

/** Contains prices for gift wrapping options. */
export interface GiftOptionsPrices {
  __typename?: 'GiftOptionsPrices';
  /** Price of the gift wrapping for all individual order items. */
  giftWrappingForItems: Maybe<Money>;
  /** Price of the gift wrapping for the whole order. */
  giftWrappingForOrder: Maybe<Money>;
  /** Price for the printed card. */
  printedCard: Maybe<Money>;
}

/** Contains details about a gift registry. */
export interface GiftRegistry {
  __typename?: 'GiftRegistry';
  /** The date on which the gift registry was created. Only the registry owner can access this attribute. */
  createdAt: Scalars['String'];
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributes: Maybe<Array<Maybe<GiftRegistryDynamicAttribute>>>;
  /** The name of the event. */
  eventName: Scalars['String'];
  /** An array of products added to the gift registry. */
  items: Maybe<Array<Maybe<GiftRegistryItemInterface>>>;
  /** The message text the customer entered to describe the event. */
  message: Scalars['String'];
  /** The customer who created the gift registry. */
  ownerName: Scalars['String'];
  /** An enum that states whether the gift registry is PRIVATE or PUBLIC. Only the registry owner can access this attribute. */
  privacySettings: GiftRegistryPrivacySettings;
  /** Contains details about each registrant for the event. */
  registrants: Maybe<Array<Maybe<GiftRegistryRegistrant>>>;
  /** Contains the customer's shipping address. Only the registry owner can access this attribute. */
  shippingAddress: Maybe<CustomerAddress>;
  /** An enum that states whether the gift registry is ACTIVE or INACTIVE. Only the registry owner can access this attribute. */
  status: GiftRegistryStatus;
  /** The type of gift registry. */
  type: Maybe<GiftRegistryType>;
  /** The unique ID assigned to the gift registry. */
  uid: Scalars['ID'];
}

export interface GiftRegistryDynamicAttribute extends GiftRegistryDynamicAttributeInterface {
  __typename?: 'GiftRegistryDynamicAttribute';
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** Indicates which group the dynamic attribute is a member of. */
  group: GiftRegistryDynamicAttributeGroup;
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** A corresponding value for the code. */
  value: Scalars['String'];
}

/** Defines the group type of a gift registry dynamic attribute. */
export enum GiftRegistryDynamicAttributeGroup {
  EVENT_INFORMATION = 'EVENT_INFORMATION',
  PRIVACY_SETTINGS = 'PRIVACY_SETTINGS',
  REGISTRANT = 'REGISTRANT',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  DETAILED_INFORMATION = 'DETAILED_INFORMATION',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS'
}

/** Defines a dynamic attribute. */
export interface GiftRegistryDynamicAttributeInput {
  /** A unique key for an additional attribute of the event. */
  code: Scalars['ID'];
  /** A string that describes a dynamic attribute. */
  value: Scalars['String'];
}

export interface GiftRegistryDynamicAttributeInterface {
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** A corresponding value for the code. */
  value: Scalars['String'];
}

export interface GiftRegistryDynamicAttributeMetadata extends GiftRegistryDynamicAttributeMetadataInterface {
  __typename?: 'GiftRegistryDynamicAttributeMetadata';
  /** Indicates which group the dynamic attribute a member of. */
  attributeGroup: Scalars['String'];
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
  inputType: Scalars['String'];
  /** Indicates whether the dynamic attribute is required. */
  isRequired: Scalars['Boolean'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** The order in which to display the dynamic attribute. */
  sortOrder: Maybe<Scalars['Int']>;
}

export interface GiftRegistryDynamicAttributeMetadataInterface {
  /** Indicates which group the dynamic attribute a member of. */
  attributeGroup: Scalars['String'];
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types. */
  inputType: Scalars['String'];
  /** Indicates whether the dynamic attribute is required. */
  isRequired: Scalars['Boolean'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** The order in which to display the dynamic attribute. */
  sortOrder: Maybe<Scalars['Int']>;
}

export interface GiftRegistryItem extends GiftRegistryItemInterface {
  __typename?: 'GiftRegistryItem';
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String'];
  /** A brief message about the gift registry item. */
  note: Maybe<Scalars['String']>;
  /** Details about the gift registry item. */
  product: Maybe<ProductInterface>;
  /** The requested quantity of the product. */
  quantity: Scalars['Float'];
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float'];
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID'];
}

export interface GiftRegistryItemInterface {
  /** The date the product was added to the gift registry. */
  createdAt: Scalars['String'];
  /** A brief message about the gift registry item. */
  note: Maybe<Scalars['String']>;
  /** Details about the gift registry item. */
  product: Maybe<ProductInterface>;
  /** The requested quantity of the product. */
  quantity: Scalars['Float'];
  /** The fulfilled quantity of the product. */
  quantityFulfilled: Scalars['Float'];
  /** The unique ID of a gift registry item. */
  uid: Scalars['ID'];
}

/** Contains details about an error that occurred when processing a gift registry item. */
export interface GiftRegistryItemsUserError {
  __typename?: 'GiftRegistryItemsUserError';
  /** An error code that describes the error encountered. */
  code: GiftRegistryItemsUserErrorType;
  /** The unique ID of the gift registry item containing an error. */
  giftRegistryItemUid: Maybe<Scalars['ID']>;
  /** The unique ID of the `GiftRegistry` object containing an error. */
  giftRegistryUid: Maybe<Scalars['ID']>;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the product containing an error. */
  productUid: Maybe<Scalars['ID']>;
}

/** Defines the error type. */
export enum GiftRegistryItemsUserErrorType {
  /** Used for handling out of stock products. */
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  /** Used for exceptions like EntityNotFound. */
  NOT_FOUND = 'NOT_FOUND',
  /** Used for other exceptions, such as database connection failures. */
  UNDEFINED = 'UNDEFINED'
}

/** Contains the status and any errors that encountered with the customer's gift register item. */
export interface GiftRegistryItemUserErrorInterface {
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean'];
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>;
}

/** Contains error information. */
export interface GiftRegistryItemUserErrors extends GiftRegistryItemUserErrorInterface {
  __typename?: 'GiftRegistryItemUserErrors';
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean'];
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>;
}

/** Contains details about the gift registry. */
export interface GiftRegistryOutput extends GiftRegistryOutputInterface {
  __typename?: 'GiftRegistryOutput';
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Contains the customer's gift registry. */
export interface GiftRegistryOutputInterface {
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Defines the privacy setting of the gift registry. */
export enum GiftRegistryPrivacySettings {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

/** Contains details about a registrant. */
export interface GiftRegistryRegistrant {
  __typename?: 'GiftRegistryRegistrant';
  /** An array of dynamic attributes assigned to the registrant. */
  dynamicAttributes: Maybe<Array<Maybe<GiftRegistryRegistrantDynamicAttribute>>>;
  /** The email address of the registrant. Only the registry owner can access this attribute. */
  email: Scalars['String'];
  /** The first name of the registrant. */
  firstname: Scalars['String'];
  /** The last name of the registrant. */
  lastname: Scalars['String'];
  /** The unique ID assigned to the registrant. */
  uid: Scalars['ID'];
}

export interface GiftRegistryRegistrantDynamicAttribute extends GiftRegistryDynamicAttributeInterface {
  __typename?: 'GiftRegistryRegistrantDynamicAttribute';
  /** The internal ID of the dynamic attribute. */
  code: Scalars['ID'];
  /** The display name of the dynamic attribute. */
  label: Scalars['String'];
  /** A corresponding value for the code. */
  value: Scalars['String'];
}

/** Contains the results of a gift registry search. */
export interface GiftRegistrySearchResult {
  __typename?: 'GiftRegistrySearchResult';
  /** The date of the event. */
  eventDate: Maybe<Scalars['String']>;
  /** The title given to the event. */
  eventTitle: Scalars['String'];
  /** The URL key of the gift registry. */
  giftRegistryUid: Scalars['ID'];
  /** The location of the event. */
  location: Maybe<Scalars['String']>;
  /** The name of the gift registry owner. */
  name: Scalars['String'];
  /** The type of event being held. */
  type: Maybe<Scalars['String']>;
}

/**
 * Defines a shipping address for a gift registry. Specify either `address_data` or
 * the `address_id`. If both are provided, validation will fail.
 */
export interface GiftRegistryShippingAddressInput {
  /** Defines the shipping address for this gift registry. */
  address_data?: Maybe<CustomerAddressInput>;
  /** The ID assigned to this customer address. */
  address_id?: Maybe<Scalars['ID']>;
}

/** Defines the status of the gift registry. */
export enum GiftRegistryStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

/** Contains details about a gift registry type. */
export interface GiftRegistryType {
  __typename?: 'GiftRegistryType';
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair. */
  dynamicAttributesMetadata: Maybe<Array<Maybe<GiftRegistryDynamicAttributeMetadataInterface>>>;
  /** The label assigned to the gift registry type on the Admin. */
  label: Scalars['String'];
  /** The unique ID assigned to the gift registry type. */
  uid: Scalars['ID'];
}

/** Contains details about the selected or available gift wrapping options. */
export interface GiftWrapping {
  __typename?: 'GiftWrapping';
  /** The name of the gift wrapping design. */
  design: Scalars['String'];
  /**
   * The unique ID for a `GiftWrapping` object.
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The preview image for a gift wrapping option. */
  image: Maybe<GiftWrappingImage>;
  /** The gift wrapping price. */
  price: Money;
  /** The unique ID for a `GiftWrapping` object. */
  uid: Scalars['ID'];
}

/** Points to an image associated with a gift wrapping option. */
export interface GiftWrappingImage {
  __typename?: 'GiftWrappingImage';
  /** The gift wrapping preview image label. */
  label: Scalars['String'];
  /** The gift wrapping preview image URL. */
  url: Scalars['String'];
}

export interface GoogleAccount {
  __typename?: 'GoogleAccount';
  name: Scalars['String'];
  accountName: Scalars['String'];
  accountNumber: Scalars['ID'];
}

export interface GoogleLocationAddress {
  __typename?: 'GoogleLocationAddress';
  regionCode: Maybe<Scalars['String']>;
  languageCode: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  locality: Maybe<Scalars['String']>;
  sublocality: Maybe<Scalars['String']>;
  addressLines: Maybe<Array<Scalars['String']>>;
  recipients: Maybe<Array<Scalars['String']>>;
  organization: Maybe<Scalars['String']>;
}

export interface GoogleLocationAssociation {
  __typename?: 'GoogleLocationAssociation';
  category: Maybe<GoogleLocationAssociationCategory>;
  priceListItemId: Maybe<Scalars['String']>;
}

export enum GoogleLocationAssociationCategory {
  CATEGORY_UNSPECIFIED = 'CATEGORY_UNSPECIFIED',
  COVER = 'COVER',
  PROFILE = 'PROFILE',
  LOGO = 'LOGO',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  PRODUCT = 'PRODUCT',
  AT_WORK = 'AT_WORK',
  FOOD_AND_DRINK = 'FOOD_AND_DRINK',
  MENU = 'MENU',
  COMMON_AREA = 'COMMON_AREA',
  ROOMS = 'ROOMS',
  TEAMS = 'TEAMS',
  ADDITIONAL = 'ADDITIONAL'
}

export interface GoogleLocationCategory {
  __typename?: 'GoogleLocationCategory';
  displayName: Maybe<Scalars['String']>;
  categoryId: Maybe<Scalars['String']>;
  serviceTypes: Maybe<Array<GoogleLocationServiceType>>;
}

export interface GoogleLocationDate {
  __typename?: 'GoogleLocationDate';
  year: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  day: Maybe<Scalars['Int']>;
}

export enum GoogleLocationDayOfWeek {
  DAY_OF_WEEK_UNSPECIFIED = 'DAY_OF_WEEK_UNSPECIFIED',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export interface GoogleLocationInfo {
  __typename?: 'GoogleLocationInfo';
  /**
   * The Unique location name belonging to the GMB User/Group
   * This is also used for get location queries
   */
  name: Scalars['ID'];
  languageCode: Scalars['String'];
  /** Internal Google StoreCode */
  storeCode: Maybe<Scalars['ID']>;
  /** The read-only location name specified by the business owner */
  locationName: Scalars['String'];
  /** Contains info about the Google Places API location */
  primaryPhone: Maybe<Scalars['String']>;
  additionalPhones: Maybe<Array<Scalars['String']>>;
  address: Maybe<GoogleLocationAddress>;
  primaryCategory: Maybe<GoogleLocationCategory>;
  additionalCategories: Maybe<Array<GoogleLocationCategory>>;
  websiteUrl: Maybe<Scalars['String']>;
  regularHours: Maybe<GoogleLocationRegularHours>;
  specialHours: Maybe<GoogleLocationSpecialHours>;
  locationKey: Maybe<GoogleLocationKey>;
  labels: Maybe<Array<Scalars['String']>>;
  latlng: Maybe<GoogleLocationLatLong>;
  openInfo: Maybe<GoogleLocationOpenInfo>;
  locationState: Maybe<GoogleLocationState>;
  metadata: Maybe<GoogleLocationMetaData>;
  profile: Maybe<GoogleLocationProfile>;
  relationshipData: Maybe<GoogleLocationRelationshipData>;
  /** Retrieves the COVER image url from the Location media */
  coverUrl: Maybe<Scalars['String']>;
  /** Retrieves the COVER thumbnail image url from the Location media */
  coverThumbnailUrl: Maybe<Scalars['String']>;
  /** Retrieves all Location media */
  media: GoogleLocationMedia;
}

export interface GoogleLocationKey {
  __typename?: 'GoogleLocationKey';
  plusPageId: Maybe<Scalars['String']>;
  placeId: Maybe<Scalars['ID']>;
  explicitNoPlaceId: Maybe<Scalars['Boolean']>;
  requestId: Scalars['ID'];
}

export interface GoogleLocationLatLong {
  __typename?: 'GoogleLocationLatLong';
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
}

export interface GoogleLocationMedia {
  __typename?: 'GoogleLocationMedia';
  mediaItems: Maybe<Array<GoogleLocationMediaItem>>;
  totalMediaItemCount: Maybe<Scalars['Int']>;
}

export interface GoogleLocationMediaAttribution {
  __typename?: 'GoogleLocationMediaAttribution';
  profileName: Maybe<Scalars['String']>;
  profilePhotoUrl: Maybe<Scalars['String']>;
  takedownUrl: Maybe<Scalars['String']>;
  profileUrl: Maybe<Scalars['String']>;
}

export interface GoogleLocationMediaDimensions {
  __typename?: 'GoogleLocationMediaDimensions';
  widthPixels: Maybe<Scalars['Int']>;
  heightPixels: Maybe<Scalars['Int']>;
}

export enum GoogleLocationMediaFormat {
  MEDIA_FORMAT_UNSPECIFIED = 'MEDIA_FORMAT_UNSPECIFIED',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export interface GoogleLocationMediaInsights {
  __typename?: 'GoogleLocationMediaInsights';
  viewCount: Maybe<Scalars['String']>;
}

export interface GoogleLocationMediaItem {
  __typename?: 'GoogleLocationMediaItem';
  name: Maybe<Scalars['String']>;
  mediaFormat: Maybe<GoogleLocationMediaFormat>;
  locationAssociation: Maybe<GoogleLocationAssociation>;
  googleUrl: Maybe<Scalars['String']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  createTime: Maybe<Scalars['String']>;
  dimensions: Maybe<GoogleLocationMediaDimensions>;
  insights: Maybe<GoogleLocationMediaInsights>;
  attribution: Maybe<GoogleLocationMediaAttribution>;
  description: Maybe<Scalars['String']>;
  sourceUrl: Maybe<Scalars['String']>;
}

export interface GoogleLocationMetaData {
  __typename?: 'GoogleLocationMetaData';
  mapsUrl: Maybe<Scalars['String']>;
  newReviewUrl: Maybe<Scalars['String']>;
}

export enum GoogleLocationOpenForBusiness {
  OPEN_FOR_BUSINESS_UNSPECIFIED = 'OPEN_FOR_BUSINESS_UNSPECIFIED',
  OPEN = 'OPEN',
  CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY',
  CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY'
}

export interface GoogleLocationOpenInfo {
  __typename?: 'GoogleLocationOpenInfo';
  status: Maybe<GoogleLocationOpenForBusiness>;
  canReopen: Maybe<Scalars['Boolean']>;
  openingDate: Maybe<GoogleLocationDate>;
}

export interface GoogleLocationProfile {
  __typename?: 'GoogleLocationProfile';
  description: Maybe<Scalars['String']>;
}

export interface GoogleLocationRegularHours {
  __typename?: 'GoogleLocationRegularHours';
  periods: Maybe<Array<GoogleLocationTimePeriod>>;
}

export interface GoogleLocationRelationshipData {
  __typename?: 'GoogleLocationRelationshipData';
  parentChain: Maybe<Scalars['String']>;
}

export interface GoogleLocationServiceType {
  __typename?: 'GoogleLocationServiceType';
  serviceTypeId: Maybe<Scalars['String']>;
  displayName: Maybe<Scalars['String']>;
}

export interface GoogleLocationSpecialHourPeriod {
  __typename?: 'GoogleLocationSpecialHourPeriod';
  startDate: Maybe<GoogleLocationDate>;
  openTime: Maybe<Scalars['String']>;
  endDate: Maybe<GoogleLocationDate>;
  closeTime: Maybe<Scalars['String']>;
  isClosed: Maybe<Scalars['Boolean']>;
}

export interface GoogleLocationSpecialHours {
  __typename?: 'GoogleLocationSpecialHours';
  specialHourPeriods: Maybe<Array<GoogleLocationSpecialHourPeriod>>;
}

export interface GoogleLocationState {
  __typename?: 'GoogleLocationState';
  isGoogleUpdated: Maybe<Scalars['Boolean']>;
  isSuspended: Maybe<Scalars['Boolean']>;
  isDuplicate: Maybe<Scalars['Boolean']>;
  canUpdate: Maybe<Scalars['Boolean']>;
  canDelete: Maybe<Scalars['Boolean']>;
  isVerified: Maybe<Scalars['Boolean']>;
  needsReverification: Maybe<Scalars['Boolean']>;
  isPendingReview: Maybe<Scalars['Boolean']>;
  isDisabled: Maybe<Scalars['Boolean']>;
  isPublished: Maybe<Scalars['Boolean']>;
  isDisconnected: Maybe<Scalars['Boolean']>;
  isLocalPostApiDisabled: Maybe<Scalars['Boolean']>;
  canModifyServiceList: Maybe<Scalars['Boolean']>;
  canHaveFoodMenus: Maybe<Scalars['Boolean']>;
  hasPendingEdits: Maybe<Scalars['Boolean']>;
  hasPendingVerification: Maybe<Scalars['Boolean']>;
}

export interface GoogleLocationTimePeriod {
  __typename?: 'GoogleLocationTimePeriod';
  openDay: Maybe<GoogleLocationDayOfWeek>;
  openTime: Maybe<Scalars['String']>;
  closeDay: Maybe<GoogleLocationDayOfWeek>;
  closeTime: Maybe<Scalars['String']>;
}

export interface GoogleMapsDistanceDestination {
  __typename?: 'GoogleMapsDistanceDestination';
  destination: Maybe<Scalars['String']>;
  distances: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>;
}

export interface GoogleMapsDistanceDestinationInput {
  destination: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
}

export interface GoogleMapsDistanceDestinationsInput {
  origin: GoogleMapsGeocodeLocationInput;
  destinations: Array<GoogleMapsDistanceDestinationInput>;
}

export interface GoogleMapsGeocodeGeometry {
  __typename?: 'GoogleMapsGeocodeGeometry';
  location: Maybe<GoogleMapsGeocodeLocation>;
}

export interface GoogleMapsGeocodeLocation {
  __typename?: 'GoogleMapsGeocodeLocation';
  lat: Maybe<Scalars['Float']>;
  lng: Maybe<Scalars['Float']>;
}

export interface GoogleMapsGeocodeLocationInput {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
}

export interface GoogleMapsGeocodeResult {
  __typename?: 'GoogleMapsGeocodeResult';
  geometry: Maybe<GoogleMapsGeocodeGeometry>;
  formattedAddress: Maybe<Scalars['String']>;
  placeId: Maybe<Scalars['String']>;
}

export interface GoogleMapsTravelDistance {
  __typename?: 'GoogleMapsTravelDistance';
  elements: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>;
}

export interface GoogleMapsTravelDistanceDuration {
  __typename?: 'GoogleMapsTravelDistanceDuration';
  value: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
}

export interface GoogleMapsTravelDistanceElements {
  __typename?: 'GoogleMapsTravelDistanceElements';
  status: Maybe<Scalars['String']>;
  duration: Maybe<GoogleMapsTravelDistanceDuration>;
  distance: Maybe<GoogleMapsTravelDistanceLength>;
}

export interface GoogleMapsTravelDistanceLength {
  __typename?: 'GoogleMapsTravelDistanceLength';
  value: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProduct extends ProductInterface, RoutableInterface, PhysicalProductInterface {
  __typename?: 'GroupedProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** An array containing grouped product items. */
  items: Maybe<Array<Maybe<GroupedProductItem>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Contains information about an individual grouped product item. */
export interface GroupedProductItem {
  __typename?: 'GroupedProductItem';
  /** The relative position of this item compared to the other group items. */
  position: Maybe<Scalars['Int']>;
  /** Details about this product option. */
  product: Maybe<ProductInterface>;
  /** The quantity of this grouped product item. */
  qty: Maybe<Scalars['Float']>;
}

/** A grouped product wish list item. */
export interface GroupedProductWishlistItem extends WishlistItemInterface {
  __typename?: 'GroupedProductWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

export type HeaderBody = HeaderBodyMainMenu;

export interface HeaderBodyMainMenu {
  __typename?: 'HeaderBodyMainMenu';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  fields: Maybe<Array<HeaderBodyMainMenuFields>>;
}

export interface HeaderBodyMainMenuFields {
  __typename?: 'HeaderBodyMainMenuFields';
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  link: Maybe<PrismicHyperlink>;
  highlighted: Maybe<Scalars['Boolean']>;
}

export interface Highlight {
  __typename?: 'Highlight';
  end: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Int']>;
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payments Pro Hosted Solution payment method.
 */
export interface HostedProInput {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. For example, if
   * the full URL to this page is
   * https://www.example.com/paypal/action/cancel.html, the relative URL is
   * paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. For example, if the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
}

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export interface HostedProUrl {
  __typename?: 'HostedProUrl';
  /** The secure URL generated by PayPal. */
  secureFormUrl: Maybe<Scalars['String']>;
}

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export interface HostedProUrlInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
}

/** Contains target path parameters. */
export interface HttpQueryParameter {
  __typename?: 'HttpQueryParameter';
  /** A parameter name. */
  name: Maybe<Scalars['String']>;
  /** A parameter value. */
  value: Maybe<Scalars['String']>;
}

export interface ImageSwatchData extends SwatchDataInterface {
  __typename?: 'ImageSwatchData';
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail: Maybe<Scalars['String']>;
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']>;
}

/** Contains an error message when an internal error occurred. */
export interface InternalError extends ErrorInterface {
  __typename?: 'InternalError';
  /** The returned error message. */
  message: Scalars['String'];
}

/** Contains invoice details. */
export interface Invoice {
  __typename?: 'Invoice';
  /** Comments on the invoice. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID'];
  /** Invoiced product details. */
  items: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number. */
  number: Scalars['String'];
  /** Invoice total amount details. */
  total: Maybe<InvoiceTotal>;
}

export interface InvoiceItem extends InvoiceItemInterface {
  __typename?: 'InvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
}

/** Contains detailes about invoiced items. */
export interface InvoiceItemInterface {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
}

/** Contains price details from an invoice. */
export interface InvoiceTotal {
  __typename?: 'InvoiceTotal';
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the invoice. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the invoice. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The invoice tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice. */
  totalShipping: Money;
  /** The amount of tax applied to the invoice. */
  totalTax: Money;
}

/** Contains the result of the `isEmailAvailable` query. */
export interface IsEmailAvailableOutput {
  __typename?: 'IsEmailAvailableOutput';
  /** Indicates whether the specified email address can be used to create a customer. */
  isEmailAvailable: Maybe<Scalars['Boolean']>;
}

/** A list of options of the selected bundle product. */
export interface ItemSelectedBundleOption {
  __typename?: 'ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The label of the option. */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option. */
  values: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
}

/** A list of values for the selected bundle product. */
export interface ItemSelectedBundleOptionValue {
  __typename?: 'ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The price of the child bundle product. */
  price: Money;
  /** The name of the child bundle product. */
  productName: Scalars['String'];
  /** The SKU of the child bundle product. */
  productSku: Scalars['String'];
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID'];
}



/** Contains a key-value pair. */
export interface KeyValue {
  __typename?: 'KeyValue';
  /** The name part of the key/value pair. */
  name: Maybe<Scalars['String']>;
  /** The value part of the key/value pair. */
  value: Maybe<Scalars['String']>;
}

/** Contains information for rendering layered navigation. */
export interface LayerFilter {
  __typename?: 'LayerFilter';
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filterItems: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filterItemsCount: Maybe<Scalars['Int']>;
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name: Maybe<Scalars['String']>;
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  requestVar: Maybe<Scalars['String']>;
}

export interface LayerFilterItem extends LayerFilterItemInterface {
  __typename?: 'LayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']>;
}

export interface LayerFilterItemInterface {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']>;
}

export interface LoginAsInput {
  customerId?: Maybe<Scalars['ID']>;
  cartId?: Maybe<Scalars['ID']>;
}


export interface M2RestAdditionalPaymentMethodData {
  rmaIbanName?: Maybe<Scalars['String']>;
  rmaIbanNumber?: Maybe<Scalars['String']>;
  /** Adyen: name of payment method */
  brandCode?: Maybe<Scalars['String']>;
  /** Adyen: credit card provider */
  ccType?: Maybe<Scalars['String']>;
  /** Adyen: form state data */
  stateData?: Maybe<Scalars['String']>;
}

export interface M2RestAddress {
  __typename?: 'M2RestAddress';
  id: Scalars['ID'];
  customerId: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  regionCode: Maybe<Scalars['String']>;
  regionId: Maybe<Scalars['Int']>;
  countryId: Maybe<Scalars['String']>;
  street: Maybe<Array<Maybe<Scalars['String']>>>;
  company: Maybe<Scalars['String']>;
  telephone: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  lastname: Maybe<Scalars['String']>;
  middlename: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  suffix: Maybe<Scalars['String']>;
  vatId: Maybe<Scalars['String']>;
  defaultShipping: Maybe<Scalars['Boolean']>;
  defaultBilling: Maybe<Scalars['Boolean']>;
  sameAsBilling: Maybe<Scalars['Int']>;
  customAttributes: Maybe<Array<Maybe<M2RestCustomAttribute>>>;
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestAddressCheckRequest {
  countryId: Scalars['String'];
  postcode: Scalars['String'];
  houseNumber: Scalars['Int'];
  houseNumberAddition: Scalars['String'];
}

export interface M2RestAddressCheckResponse {
  __typename?: 'M2RestAddressCheckResponse';
  street: Maybe<Scalars['String']>;
  houseNumber: Maybe<Scalars['Int']>;
  houseNumberAddition: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  municipality: Maybe<Scalars['String']>;
  province: Maybe<Scalars['String']>;
  rdX: Maybe<Scalars['Float']>;
  rdY: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  bagNumberDesignationId: Maybe<Scalars['String']>;
  bagAddressableObjectId: Maybe<Scalars['String']>;
  addressType: Maybe<Scalars['String']>;
  purposes: Maybe<Array<Maybe<Scalars['String']>>>;
  surfaceArea: Maybe<Scalars['Int']>;
  houseNumberAdditions: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface M2RestAddressInput {
  customerAddressId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  company?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  middlename?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  vatId?: Maybe<Scalars['String']>;
  sameAsBilling?: Maybe<Scalars['Int']>;
  saveInAddressBook?: Maybe<Scalars['Int']>;
  customAttributes?: Maybe<Array<Maybe<M2RestCustomAttributeDataInput>>>;
  extensionAttributes?: Maybe<Scalars['JSON']>;
}

export interface M2RestAddTeleconnectToCartInput {
  configuratorConfigId: Scalars['String'];
  qty: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
}

export interface M2RestAddToCartInput {
  sku: Scalars['String'];
  qty: Scalars['Int'];
  configuratorEan?: Maybe<Scalars['String']>;
  configuratorConfigId?: Maybe<Scalars['String']>;
}

export interface M2RestAdyenPaymentAction {
  __typename?: 'M2RestAdyenPaymentAction';
  type: Maybe<M2RestAdyenPaymentActionsType>;
  subtype: Maybe<Scalars['String']>;
  paymentMethodType: Maybe<Scalars['String']>;
  paymentData: Maybe<Scalars['String']>;
  authorisationToken: Maybe<Scalars['String']>;
  method: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  alternativeReference: Maybe<Scalars['String']>;
  downloadUrl: Maybe<Scalars['String']>;
  entity: Maybe<Scalars['String']>;
  expiresAt: Maybe<Scalars['String']>;
  instructionsUrl: Maybe<Scalars['String']>;
  issuer: Maybe<Scalars['String']>;
  maskedTelephoneNumber: Maybe<Scalars['String']>;
  merchantName: Maybe<Scalars['String']>;
  merchantReference: Maybe<Scalars['String']>;
  reference: Maybe<Scalars['String']>;
  shopperEmail: Maybe<Scalars['String']>;
  shopperName: Maybe<Scalars['String']>;
  qrCodeData: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  data: Maybe<Scalars['JSON']>;
}

export enum M2RestAdyenPaymentActionsType {
  REDIRECT = 'redirect',
  THREE_DS2_CHALLENGE = 'threeDS2Challenge',
  THREE_DS2_FINGERPRINT = 'threeDS2Fingerprint',
  THREE_DS2 = 'threeDS2',
  VOUCHER = 'voucher',
  QR_CODE = 'qrCode',
  AWAIT = 'await',
  BANK_TRANSFER = 'bankTransfer',
  SDK = 'sdk'
}

export interface M2RestAdyenPaymentAdditionalDetails {
  __typename?: 'M2RestAdyenPaymentAdditionalDetails';
  isFinal: Maybe<Scalars['Boolean']>;
  resultCode: Maybe<M2RestAdyenPaymentStatusResultCode>;
  refusalReason: Maybe<Scalars['String']>;
  action: Maybe<M2RestAdyenPaymentAction>;
}

export interface M2RestAdyenPaymentMethod {
  __typename?: 'M2RestAdyenPaymentMethod';
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  brands: Maybe<Array<Maybe<Scalars['String']>>>;
  details: Maybe<Array<Maybe<M2RestAdyenPaymentMethodDetail>>>;
  configuration: Maybe<M2RestAdyenPaymentMethodConfiguration>;
}

export interface M2RestAdyenPaymentMethodConfiguration {
  __typename?: 'M2RestAdyenPaymentMethodConfiguration';
  merchantId: Maybe<Scalars['String']>;
  merchantName: Maybe<Scalars['String']>;
  intent: Maybe<Scalars['String']>;
}

export interface M2RestAdyenPaymentMethodDetail {
  __typename?: 'M2RestAdyenPaymentMethodDetail';
  type: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  optional: Maybe<Scalars['Boolean']>;
  items: Maybe<Array<Maybe<M2RestAdyenPaymentMethodDetailItem>>>;
}

export interface M2RestAdyenPaymentMethodDetailItem {
  __typename?: 'M2RestAdyenPaymentMethodDetailItem';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}

export interface M2RestAdyenPaymentMethods {
  __typename?: 'M2RestAdyenPaymentMethods';
  paymentMethods: Maybe<Array<Maybe<M2RestAdyenPaymentMethod>>>;
}

export interface M2RestAdyenPaymentMethodsResponse {
  __typename?: 'M2RestAdyenPaymentMethodsResponse';
  paymentMethodsResponse: Maybe<M2RestAdyenPaymentMethods>;
}

export interface M2RestAdyenPaymentStatus {
  __typename?: 'M2RestAdyenPaymentStatus';
  orderId: Maybe<Scalars['String']>;
  isFinal: Maybe<Scalars['Boolean']>;
  resultCode: Maybe<M2RestAdyenPaymentStatusResultCode>;
  refusalReason: Maybe<Scalars['String']>;
  action: Maybe<M2RestAdyenPaymentAction>;
}

export enum M2RestAdyenPaymentStatusResultCode {
  AUTHENTICATION_FINISHED = 'AuthenticationFinished',
  AUTHENTICATION_NOT_REQUIRED = 'AuthenticationNotRequired',
  CHALLENGE_SHOPPER = 'ChallengeShopper',
  IDENTIFY_SHOPPER = 'IdentifyShopper',
  AUTHORISED = 'Authorised',
  RECEIVED = 'Received',
  PRESENT_TO_SHOPPER = 'PresentToShopper',
  REDIRECT_SHOPPER = 'RedirectShopper',
  REFUSED = 'Refused',
  ERROR = 'Error',
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
  UNHANDLED = 'Unhandled'
}

export interface M2RestApplyCouponCodeResult {
  __typename?: 'M2RestApplyCouponCodeResult';
  cart: M2RestCart;
  response: Maybe<Scalars['String']>;
}

export interface M2RestCart {
  __typename?: 'M2RestCart';
  id: Maybe<Scalars['ID']>;
  coupons: Array<Scalars['String']>;
  customer: Maybe<M2RestCustomer>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  convertedAt: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  items: Array<M2RestCartItem>;
  itemsCount: Scalars['Int'];
  itemsQty: Scalars['Int'];
  reservedOrderId: Maybe<Scalars['String']>;
  origOrderId: Maybe<Scalars['String']>;
  customerIsGuest: Maybe<Scalars['String']>;
  customerNote: Maybe<Scalars['String']>;
  customerNoteNotify: Maybe<Scalars['String']>;
  customerTaxClassId: Maybe<Scalars['Int']>;
  storeId: Scalars['Int'];
  grandTotal: Maybe<Scalars['Float']>;
  baseGrandTotal: Maybe<Scalars['Float']>;
  subtotal: Maybe<Scalars['Float']>;
  baseSubtotal: Maybe<Scalars['Float']>;
  discountAmount: Maybe<Scalars['Float']>;
  baseDiscountAmount: Maybe<Scalars['Float']>;
  subtotalWithDiscount: Maybe<Scalars['Float']>;
  baseSubtotalWithDiscount: Maybe<Scalars['Float']>;
  shippingAmount: Maybe<Scalars['Float']>;
  baseShippingAmount: Maybe<Scalars['Float']>;
  shippingDiscountAmount: Maybe<Scalars['Float']>;
  baseShippingDiscountAmount: Maybe<Scalars['Float']>;
  taxAmount: Maybe<Scalars['Float']>;
  baseTaxAmount: Maybe<Scalars['Float']>;
  weeeTaxAppliedAmount: Maybe<Scalars['Float']>;
  shippingTaxAmount: Maybe<Scalars['Float']>;
  baseShippingTaxAmount: Maybe<Scalars['Float']>;
  subtotalInclTax: Maybe<Scalars['Float']>;
  shippingInclTax: Maybe<Scalars['Float']>;
  baseShippingInclTax: Maybe<Scalars['Float']>;
  baseCurrencyCode: Maybe<Scalars['String']>;
  quoteCurrencyCode: Maybe<Scalars['String']>;
  paymentMethods: Maybe<Array<M2RestPaymentMethod>>;
  totalSegments: Maybe<Array<M2RestTotalSegment>>;
  SAPDeliveryData: Maybe<M2RestSapDeliveryData>;
  shippingMethod: Maybe<M2RestCartShippingMethod>;
  billingAddress: Maybe<M2RestAddress>;
  shippingAddress: Maybe<M2RestAddress>;
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestCartItem {
  __typename?: 'M2RestCartItem';
  itemId: Scalars['ID'];
  sku: Scalars['String'];
  qty: Scalars['Int'];
  name: Scalars['String'];
  image: Maybe<M2RestCartItemImage>;
  price: Scalars['Float'];
  productType: Scalars['String'];
  quoteId: Maybe<Scalars['String']>;
  basePrice: Maybe<Scalars['Float']>;
  rowTotal: Maybe<Scalars['Float']>;
  baseRowTotal: Maybe<Scalars['Float']>;
  rowTotalWithDiscount: Maybe<Scalars['Float']>;
  taxAmount: Maybe<Scalars['Float']>;
  baseTaxAmount: Maybe<Scalars['Float']>;
  taxPercent: Maybe<Scalars['Float']>;
  discountAmount: Maybe<Scalars['Float']>;
  baseDiscountAmount: Maybe<Scalars['Float']>;
  discountPercent: Maybe<Scalars['Float']>;
  priceInclTax: Maybe<Scalars['Float']>;
  basePriceInclTax: Maybe<Scalars['Float']>;
  rowTotalInclTax: Maybe<Scalars['Float']>;
  baseRowTotalInclTax: Maybe<Scalars['Float']>;
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestCartItemImage {
  __typename?: 'M2RestCartItemImage';
  url: Scalars['String'];
  label: Maybe<Scalars['String']>;
}

export interface M2RestCartShippingMethod {
  __typename?: 'M2RestCartShippingMethod';
  method: Scalars['String'];
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestCartUpdateResult {
  __typename?: 'M2RestCartUpdateResult';
  success: Scalars['Boolean'];
  error: Maybe<Scalars['String']>;
}

export interface M2RestCustomAttribute {
  __typename?: 'M2RestCustomAttribute';
  attributeCode: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
}

export interface M2RestCustomAttributeDataInput {
  attributeCode: Scalars['String'];
  value: Scalars['String'];
}

export interface M2RestCustomer {
  __typename?: 'M2RestCustomer';
  id: Scalars['ID'];
  groupId: Maybe<Scalars['Int']>;
  defaultBilling: Maybe<Scalars['String']>;
  defaultShipping: Maybe<Scalars['String']>;
  confirmation: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  createdIn: Scalars['String'];
  dob: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  lastname: Maybe<Scalars['String']>;
  middlename: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  suffix: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['Int']>;
  storeId: Scalars['Int'];
  texvat: Maybe<Scalars['String']>;
  websiteId: Scalars['Int'];
  disableAutoGroupChange: Scalars['Int'];
  addresses: Maybe<Array<Maybe<M2RestCustomerAddress>>>;
}

export interface M2RestCustomerAddress {
  __typename?: 'M2RestCustomerAddress';
  id: Scalars['ID'];
  customerId: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
  region: Maybe<M2RestRegion>;
  regionId: Maybe<Scalars['Int']>;
  countryId: Maybe<Scalars['String']>;
  street: Maybe<Array<Maybe<Scalars['String']>>>;
  company: Maybe<Scalars['String']>;
  telephone: Maybe<Scalars['String']>;
  fax: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  lastname: Maybe<Scalars['String']>;
  middlename: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  suffix: Maybe<Scalars['String']>;
  vatId: Maybe<Scalars['String']>;
  defaultShipping: Maybe<Scalars['Boolean']>;
  defaultBilling: Maybe<Scalars['Boolean']>;
  sameAsBilling: Maybe<Scalars['Int']>;
  customAttributes: Maybe<Array<Maybe<M2RestCustomAttribute>>>;
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestGiftCard {
  __typename?: 'M2RestGiftCard';
  code: Scalars['String'];
  amount: Scalars['Float'];
}

export enum M2RestGiftCardBalanceError {
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN'
}

export interface M2RestGiftCardBalanceResponse {
  __typename?: 'M2RestGiftCardBalanceResponse';
  error: Maybe<M2RestGiftCardBalanceError>;
  balance: Maybe<Scalars['Float']>;
}

export enum M2RestOrderCondition {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
  CASH_SALES = 'cash_sales',
  RMA = 'rma',
  WEBSHOP = 'webshop'
}

export interface M2RestPaymentMethod {
  __typename?: 'M2RestPaymentMethod';
  title: Maybe<Scalars['String']>;
  code: Scalars['String'];
}

export interface M2RestPaymentMethodInput {
  poNumber?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  additionalData?: Maybe<M2RestAdditionalPaymentMethodData>;
}

export interface M2RestPlaceOrderResult {
  __typename?: 'M2RestPlaceOrderResult';
  incrementId: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['Int']>;
  maskedId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  error: Maybe<Scalars['String']>;
}

export interface M2RestProductOption {
  __typename?: 'M2RestProductOption';
  label: Scalars['String'];
  value: Scalars['String'];
}

export interface M2RestRegion {
  __typename?: 'M2RestRegion';
  regionCode: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  regionId: Maybe<Scalars['Int']>;
}

export interface M2RestRegionInput {
  regionCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
}

export interface M2RestSapDeliveryData {
  __typename?: 'M2RestSAPDeliveryData';
  time: Maybe<Scalars['String']>;
  timeString: Maybe<Scalars['String']>;
  shippingCondition: Maybe<Scalars['String']>;
}

export interface M2RestSetAddressInformationResult {
  __typename?: 'M2RestSetAddressInformationResult';
  cart: M2RestCart;
  paymentMethods: Maybe<Array<M2RestPaymentMethod>>;
}

export interface M2RestShippingMethod {
  __typename?: 'M2RestShippingMethod';
  carrierCode: Maybe<Scalars['String']>;
  methodCode: Maybe<Scalars['String']>;
  carrierTitle: Maybe<Scalars['String']>;
  methodTitle: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['Float']>;
  baseAmount: Maybe<Scalars['Float']>;
  available: Maybe<Scalars['Boolean']>;
  errorMessage: Maybe<Scalars['String']>;
  priceExclTax: Maybe<Scalars['Float']>;
  priceInclTax: Maybe<Scalars['Float']>;
  extensionAttributes: Maybe<Scalars['JSON']>;
}

export interface M2RestStoreConfig {
  __typename?: 'M2RestStoreConfig';
  cmsHomePage: Maybe<Scalars['String']>;
  cmsNoRoute: Maybe<Scalars['String']>;
  cms_home_page: Maybe<Scalars['String']>;
  cms_no_route: Maybe<Scalars['String']>;
  default_display_currency_code: Maybe<Scalars['String']>;
  copyright: Maybe<Scalars['String']>;
  defaultDisplayCurrencyCode: Maybe<Scalars['String']>;
  gtmContainerIds: Maybe<Scalars['String']>;
  requiredCharacterClassesNumber: Maybe<Scalars['String']>;
  minimumPasswordLength: Maybe<Scalars['String']>;
}

export interface M2RestTeleconnectOption {
  __typename?: 'M2RestTeleconnectOption';
  label: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
}

export interface M2RestTotalExtensionAttributes {
  __typename?: 'M2RestTotalExtensionAttributes';
  giftCards: Maybe<Array<M2RestGiftCard>>;
}

export interface M2RestTotalSegment {
  __typename?: 'M2RestTotalSegment';
  code: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Float']>;
  area: Maybe<Scalars['String']>;
  extensionAttributes: Maybe<M2RestTotalExtensionAttributes>;
}

/** Defines characteristics about images and videos associated with a specific product. */
export interface MediaGalleryEntry {
  __typename?: 'MediaGalleryEntry';
  /** Details about the content of the media gallery item. */
  content: Maybe<ProductMediaGalleryEntriesContent>;
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The alt text displayed on the storefront when the user points to the image. */
  label: Maybe<Scalars['String']>;
  /** Either `image` or `video`. */
  mediaType: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Details about the content of a video item. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>;
}

/** Contains basic information about a product image or video. */
export interface MediaGalleryInterface {
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']>;
}

/** Defines a monetary value, including a numeric value and a currency code. */
export interface Money {
  __typename?: 'Money';
  /** A three-letter currency code, such as USD or EUR. */
  currency: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value. */
  value: Maybe<Scalars['Float']>;
}

/** Contains the customer's gift registry and any errors encountered. */
export interface MoveCartItemsToGiftRegistryOutput extends GiftRegistryOutputInterface, GiftRegistryItemUserErrorInterface {
  __typename?: 'MoveCartItemsToGiftRegistryOutput';
  /** The gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
  /** Indicates whether the attempt to move the cart items to the gift registry was successful. */
  status: Scalars['Boolean'];
  /** An array of errors encountered while moving items from the cart to the gift registry. */
  userErrors: Array<Maybe<GiftRegistryItemsUserError>>;
}

/** Contains the source and target wish lists after moving products. */
export interface MoveProductsBetweenWishlistsOutput {
  __typename?: 'MoveProductsBetweenWishlistsOutput';
  /** The destination wish list after receiving products moved from the source wish list. */
  destinationWishlist: Wishlist;
  /** The source wish list after moving products from it. */
  sourceWishlist: Wishlist;
  /** An array of errors encountered while moving products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
}

export interface MpFilters {
  /** Filter From Date. */
  from: Scalars['String'];
  /** Period. */
  period_type?: Maybe<Scalars['String']>;
  /** Show Empty Rows. */
  show_empty_rows?: Maybe<Scalars['Boolean']>;
  /** Filter Store Id. */
  store_id?: Maybe<Scalars['Int']>;
  /** Filter To Date. */
  to: Scalars['String'];
}

export interface MuleSoftCustomer {
  __typename?: 'MuleSoftCustomer';
  email: Scalars['String'];
  preferences: MuleSoftCustomerPreferences;
}

export interface MuleSoftCustomerCommunication {
  __typename?: 'MuleSoftCustomerCommunication';
  subscriptions: Array<Maybe<MuleSoftCustomerSubscription>>;
  preferredChannel: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
}

export interface MuleSoftCustomerCommunicationInput {
  subscriptions: Array<Maybe<MuleSoftCustomerSubscriptionInput>>;
  preferredChannel?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
}

export interface MuleSoftCustomerInterestsAddressInput {
  street?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberSuffix?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
}

export interface MuleSoftCustomerInterestsInput {
  source: MuleSoftCustomerInterestsSourceInput;
  salutation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  language: Scalars['String'];
  preferredChannel?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<MuleSoftCustomerInterestsAddressInput>>;
  phoneNumbers?: Maybe<Array<MuleSoftCustomerInterestsPhoneNumberInput>>;
  interestData?: Maybe<Array<MuleSoftCustomerInterestsInterestDataInput>>;
  productData?: Maybe<Array<MuleSoftCustomerInterestsProductDataInput>>;
  subscriptions?: Maybe<Array<MuleSoftCustomerInterestsSubscriptionsInput>>;
}

export interface MuleSoftCustomerInterestsInterestDataInput {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface MuleSoftCustomerInterestsPhoneNumberInput {
  number: Scalars['String'];
  type: Scalars['String'];
}

export interface MuleSoftCustomerInterestsProductDataInput {
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  discountedPrice: Scalars['Float'];
  matchingPercentage: Scalars['Int'];
}

export interface MuleSoftCustomerInterestsSourceInput {
  type: Scalars['String'];
  name: Scalars['String'];
  campaign: Scalars['String'];
}

export interface MuleSoftCustomerInterestsSubscriptionsInput {
  subscription: Scalars['String'];
  enabled: Scalars['Boolean'];
}

export interface MuleSoftCustomerPreferences {
  __typename?: 'MuleSoftCustomerPreferences';
  communication: MuleSoftCustomerCommunication;
}

export interface MuleSoftCustomerPreferencesInput {
  communication: MuleSoftCustomerCommunicationInput;
}

export interface MuleSoftCustomerSubscription {
  __typename?: 'MuleSoftCustomerSubscription';
  enabled: Scalars['Boolean'];
  subscription: Scalars['String'];
}

export interface MuleSoftCustomerSubscriptionInput {
  enabled: Scalars['Boolean'];
  subscription: Scalars['String'];
}

export interface MuleSoftOmnichannelAddress {
  __typename?: 'MuleSoftOmnichannelAddress';
  addressType: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  houseNumber: Maybe<Scalars['Int']>;
  houseNumberSuffix: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  stateCode: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
}

export interface MuleSoftOmnichannelCustomer {
  __typename?: 'MuleSoftOmnichannelCustomer';
  globalCustomerId: Maybe<Scalars['String']>;
  salesforceCustomerId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  accountUrl: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  phoneNumbers: Maybe<Array<Maybe<MuleSoftOmnichannelCustomerPhoneNumber>>>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  addresses: Maybe<Array<Maybe<MuleSoftOmnichannelAddress>>>;
}

export interface MuleSoftOmnichannelCustomerPhoneNumber {
  __typename?: 'MuleSoftOmnichannelCustomerPhoneNumber';
  number: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
}

export interface MuleSoftSimpleCustomer {
  __typename?: 'MuleSoftSimpleCustomer';
  email: Scalars['String'];
  globalCustomerId: Scalars['Int'];
  lastModifiedDate: Scalars['DateTime'];
}

export interface Mutation {
  __typename?: 'Mutation';
  storeData: Scalars['String'];
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart: Maybe<AddBundleProductsToCartOutput>;
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart: Maybe<AddConfigurableProductsToCartOutput>;
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart: Maybe<AddDownloadableProductsToCartOutput>;
  /** Add registrants to the specified gift registry. */
  addGiftRegistryRegistrants: Maybe<AddGiftRegistryRegistrantsOutput>;
  /** Add any type of product to the cart. */
  addProductsToCart: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list. */
  addProductsToCompareList: Maybe<CompareList>;
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist: Maybe<AddProductsToWishlistOutput>;
  /** Add a comment to an existing return. */
  addReturnComment: Maybe<AddReturnCommentOutput>;
  /** Add tracking information to the return. */
  addReturnTracking: Maybe<AddReturnTrackingOutput>;
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart: Maybe<AddSimpleProductsToCartOutput>;
  /** Add one or more TeleConnect products to the specified cart. */
  addTeleConnectProductsToCart: Maybe<AddTeleConnectProductsToCartOutput>;
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart: Maybe<AddVirtualProductsToCartOutput>;
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart: Maybe<AddWishlistItemsToCartOutput>;
  adyenPaymentDetails: Maybe<AdyenPaymentStatus>;
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart: Maybe<ApplyCouponToCartOutput>;
  /** Apply a pre-defined gift card code to the specified cart. */
  applyGiftCardToCart: Maybe<ApplyGiftCardToCartOutput>;
  /** Apply all available points, up to the cart total. Partial redemption is not available. */
  applyRewardPointsToCart: Maybe<ApplyRewardPointsToCartOutput>;
  /** Apply store credit to the specified cart. */
  applyStoreCreditToCart: Maybe<ApplyStoreCreditToCartOutput>;
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer: Maybe<AssignCompareListToCustomerOutput>;
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart;
  /** Change the password for the logged-in customer. */
  changeCustomerPassword: Maybe<Customer>;
  /** Copy products from one wish list to another. The original wish list is unchanged. */
  copyProductsBetweenWishlists: Maybe<CopyProductsBetweenWishlistsOutput>;
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList: Maybe<CompareList>;
  /** Use `createCustomerV2` instead. */
  createCustomer: Maybe<CustomerOutput>;
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress: Maybe<CustomerAddress>;
  /** Create a customer account. */
  createCustomerV2: Maybe<CustomerOutput>;
  /** Create an empty shopping cart for a guest or logged in user */
  createEmptyCart: Maybe<Scalars['String']>;
  /** Create Gift Card Account. */
  createGiftCardAccount: Maybe<CreateGiftCardAccountOutput>;
  /** Create a gift registry on behalf of the customer. */
  createGiftRegistry: Maybe<CreateGiftRegistryOutput>;
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken: Maybe<CreatePayflowProTokenOutput>;
  /**
   * Initiate an Express Checkout transaction and receive a token. Use this
   * mutation for Express Checkout and Payments Standard payment methods.
   */
  createPaypalExpressToken: Maybe<PaypalExpressTokenOutput>;
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput;
  /** Create a new wish list. */
  createWishlist: Maybe<CreateWishlistOutput>;
  /** Delete the specified compare list. */
  deleteCompareList: Maybe<DeleteCompareListOutput>;
  /** Delete customer account */
  deleteCustomer: Maybe<Scalars['Boolean']>;
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress: Maybe<Scalars['Boolean']>;
  /** Delete a customer's payment token. */
  deletePaymentToken: Maybe<DeletePaymentTokenOutput>;
  /** Delete the specified wish list. You cannot delete the customer's default (first) wish list. */
  deleteWishlist: Maybe<DeleteWishlistOutput>;
  /** Get delayed options */
  deliveryTimeDelayedOption: Scalars['Boolean'];
  /** Generate a token for specified customer. */
  generateCustomerToken: Maybe<CustomerToken>;
  /**
   * Handle a payment response and save the payment in Quote. Use this mutation for
   * Payflow Pro and Payments Pro payment methods.
   */
  handlePayflowProResponse: Maybe<PayflowProResponseOutput>;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart;
  /** Move all items from the cart to a gift registry. */
  moveCartItemsToGiftRegistry: Maybe<MoveCartItemsToGiftRegistryOutput>;
  /** Move products from one wish list to another. */
  moveProductsBetweenWishlists: Maybe<MoveProductsBetweenWishlistsOutput>;
  /** Convert the quote into an order. */
  placeOrder: Maybe<PlaceOrderOutput>;
  /** The quotations query that finishes the quotation and returns latest quote (does not place an order) */
  quotationFinish: Maybe<QuotationFinishOutput>;
  /** Redeem a gift card for store credit. */
  redeemGiftCardBalanceAsStoreCredit: Maybe<GiftCardAccount>;
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart: Maybe<RemoveCouponFromCartOutput>;
  /** Removes a gift card from the cart. */
  removeGiftCardFromCart: Maybe<RemoveGiftCardFromCartOutput>;
  /** Delete the specified gift registry. */
  removeGiftRegistry: Maybe<RemoveGiftRegistryOutput>;
  /** Delete the specified items from a gift registry. */
  removeGiftRegistryItems: Maybe<RemoveGiftRegistryItemsOutput>;
  /** Removes registrants from a gift registry. */
  removeGiftRegistryRegistrants: Maybe<RemoveGiftRegistryRegistrantsOutput>;
  /**
   * Delete the entire quantity of a specified item from the cart. If you remove
   * all items from the cart, the cart continues to exist.
   */
  removeItemFromCart: Maybe<RemoveItemFromCartOutput>;
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList: Maybe<CompareList>;
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist: Maybe<RemoveProductsFromWishlistOutput>;
  /** Remove a tracked shipment from a return. */
  removeReturnTracking: Maybe<RemoveReturnTrackingOutput>;
  /** Cancel the application of reward points to the cart. */
  removeRewardPointsFromCart: Maybe<RemoveRewardPointsFromCartOutput>;
  /** Remove store credit that has been applied to the specified cart. */
  removeStoreCreditFromCart: Maybe<RemoveStoreCreditFromCartOutput>;
  /** Add all products from a customer's previous order to the cart. */
  reorderItems: Maybe<ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail: Maybe<Scalars['Boolean']>;
  /** Initiates a buyer's request to return items for replacement or refund. */
  requestReturn: Maybe<RequestReturnOutput>;
  /**
   * Reset a customer's password using the reset password token that the customer
   * received in an email after requesting it using `requestPasswordResetEmail`.
   */
  resetPassword: Maybe<Scalars['Boolean']>;
  /** Revoke the customer token. */
  revokeCustomerToken: Maybe<RevokeCustomerTokenOutput>;
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend: Maybe<SendEmailToFriendOutput>;
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart: Maybe<SetBillingAddressOnCartOutput>;
  /** Set gift options, including gift messages, gift wrapping, gift receipts, and printed cards. */
  setGiftOptionsOnCart: Maybe<SetGiftOptionsOnCartOutput>;
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart: Maybe<SetGuestEmailOnCartOutput>;
  /** Returns quote payment information */
  setPaymentInformation: Maybe<PaymentInformation>;
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder: Maybe<PlaceOrderOutput>;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart: Maybe<SetPaymentMethodOnCartOutput>;
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart: Maybe<SetShippingAddressesOnCartOutput>;
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart: Maybe<SetShippingMethodsOnCartOutput>;
  /** Send an email about the gift registry to a list of invitees. */
  shareGiftRegistry: Maybe<ShareGiftRegistryOutput>;
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter: Maybe<SubscribeEmailToNewsletterOutput>;
  /** Changes the password for the logged-in customer */
  testPermission: Maybe<Scalars['Boolean']>;
  /** Modify items in the cart. */
  updateCartItems: Maybe<UpdateCartItemsOutput>;
  /** Use `updateCustomerV2` instead. */
  updateCustomer: Maybe<CustomerOutput>;
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress: Maybe<CustomerAddress>;
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail: Maybe<CustomerOutput>;
  /** Update the customer's personal information. */
  updateCustomerV2: Maybe<CustomerOutput>;
  /** Update Gift Card Account */
  updateGiftCardAccount: Maybe<CreateGiftCardAccountOutput>;
  /** Update Gift Card Account Balance */
  updateGiftCardAccountBalance: Maybe<Scalars['Float']>;
  /** Update the specified gift registry. */
  updateGiftRegistry: Maybe<UpdateGiftRegistryOutput>;
  /** Update the specified items in the gift registry. */
  updateGiftRegistryItems: Maybe<UpdateGiftRegistryItemsOutput>;
  /** Modify the properties of one or more gift registry registrants. */
  updateGiftRegistryRegistrants: Maybe<UpdateGiftRegistryRegistrantsOutput>;
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist: Maybe<UpdateProductsInWishlistOutput>;
  /** Change the name and visibility of the specified wish list. */
  updateWishlist: Maybe<UpdateWishlistOutput>;
  m2RestAddTeleconnectCartItem: Maybe<M2RestCart>;
  m2RestCreateGuestCart: Scalars['String'];
  m2RestSetAddressInformation: M2RestSetAddressInformationResult;
  m2RestSetPaymentInformation: M2RestPlaceOrderResult;
  m2RestSetOrderCondition: M2RestCart;
  updateMuleSoftCustomerPreferences: Scalars['Boolean'];
  createMuleSoftCustomerInterests: Scalars['Boolean'];
  createCustomerWithSubscriptions: Scalars['Boolean'];
  saveCustomerSubscriptions: Scalars['Boolean'];
  sendConfiguratorShareEmail: Scalars['Boolean'];
  sendMattressAdvisorShareEmail: Scalars['Boolean'];
  sendPillowAdvisorShareEmail: Scalars['Boolean'];
  sendContactPreferencesLinkEmail: Scalars['Boolean'];
  /** Returns an url which can be used to login as the specified customer via Magento 2 */
  loginAs: Scalars['String'];
  /** Generates credentials which can be used to autologin */
  generateCredentials: Maybe<Credentials>;
  /** Destroys a customer session */
  destroySession: Scalars['Boolean'];
  /** Connect an employee to guest cart */
  setCartManagedBy: Maybe<Scalars['Boolean']>;
  /** Connect an employee to customer */
  setCustomerManagedBy: Maybe<Scalars['Boolean']>;
  createNewConfigurator: TeleConnectConfigurator;
  createNewConfiguratorWithPreset: TeleConnectConfigurator;
  loadConfiguration: TeleConnectConfigurator;
  setConfiguratorPreset: Maybe<Scalars['String']>;
  setConfiguratorAnswer: Scalars['Boolean'];
  saveConfiguration: Maybe<TeleConnectConfiguration>;
}


export interface MutationStoreDataArgs {
  data: Scalars['JSON'];
}


export interface MutationAddBundleProductsToCartArgs {
  input: Maybe<AddBundleProductsToCartInput>;
}


export interface MutationAddConfigurableProductsToCartArgs {
  input: Maybe<AddConfigurableProductsToCartInput>;
}


export interface MutationAddDownloadableProductsToCartArgs {
  input: Maybe<AddDownloadableProductsToCartInput>;
}


export interface MutationAddGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID'];
  registrants: Array<AddGiftRegistryRegistrantInput>;
}


export interface MutationAddProductsToCartArgs {
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput>;
}


export interface MutationAddProductsToCompareListArgs {
  input: Maybe<AddProductsToCompareListInput>;
}


export interface MutationAddProductsToWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput>;
}


export interface MutationAddReturnCommentArgs {
  input: AddReturnCommentInput;
}


export interface MutationAddReturnTrackingArgs {
  input: AddReturnTrackingInput;
}


export interface MutationAddSimpleProductsToCartArgs {
  input: Maybe<AddSimpleProductsToCartInput>;
}


export interface MutationAddTeleConnectProductsToCartArgs {
  input: Maybe<AddTeleConnectProductsToCartInput>;
}


export interface MutationAddVirtualProductsToCartArgs {
  input: Maybe<AddVirtualProductsToCartInput>;
}


export interface MutationAddWishlistItemsToCartArgs {
  wishlistId: Scalars['ID'];
  wishlistItemIds: Maybe<Array<Scalars['ID']>>;
}


export interface MutationAdyenPaymentDetailsArgs {
  payload: Scalars['String'];
  cart_id: Scalars['String'];
}


export interface MutationApplyCouponToCartArgs {
  input: Maybe<ApplyCouponToCartInput>;
}


export interface MutationApplyGiftCardToCartArgs {
  input: Maybe<ApplyGiftCardToCartInput>;
}


export interface MutationApplyRewardPointsToCartArgs {
  cartId: Scalars['ID'];
}


export interface MutationApplyStoreCreditToCartArgs {
  input: ApplyStoreCreditToCartInput;
}


export interface MutationAssignCompareListToCustomerArgs {
  uid: Scalars['ID'];
}


export interface MutationAssignCustomerToGuestCartArgs {
  cart_id: Scalars['String'];
}


export interface MutationChangeCustomerPasswordArgs {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}


export interface MutationCopyProductsBetweenWishlistsArgs {
  sourceWishlistUid: Scalars['ID'];
  destinationWishlistUid: Scalars['ID'];
  wishlistItems: Array<WishlistItemCopyInput>;
}


export interface MutationCreateCompareListArgs {
  input: Maybe<CreateCompareListInput>;
}


export interface MutationCreateCustomerArgs {
  input: CustomerInput;
}


export interface MutationCreateCustomerAddressArgs {
  input: CustomerAddressInput;
}


export interface MutationCreateCustomerV2Args {
  input: CustomerCreateInput;
}


export interface MutationCreateEmptyCartArgs {
  input: Maybe<CreateEmptyCartInput>;
}


export interface MutationCreateGiftCardAccountArgs {
  input: GiftDataObjectInput;
}


export interface MutationCreateGiftRegistryArgs {
  giftRegistry: CreateGiftRegistryInput;
}


export interface MutationCreatePayflowProTokenArgs {
  input: PayflowProTokenInput;
}


export interface MutationCreatePaypalExpressTokenArgs {
  input: PaypalExpressTokenInput;
}


export interface MutationCreateProductReviewArgs {
  input: CreateProductReviewInput;
}


export interface MutationCreateWishlistArgs {
  input: CreateWishlistInput;
}


export interface MutationDeleteCompareListArgs {
  uid: Scalars['ID'];
}


export interface MutationDeleteCustomerAddressArgs {
  id: Scalars['Int'];
}


export interface MutationDeletePaymentTokenArgs {
  public_hash: Scalars['String'];
}


export interface MutationDeleteWishlistArgs {
  wishlistId: Scalars['ID'];
}


export interface MutationDeliveryTimeDelayedOptionArgs {
  cart_id: Scalars['String'];
  week_mode: Maybe<Scalars['Boolean']>;
  delayed_option: Maybe<Scalars['String']>;
}


export interface MutationGenerateCustomerTokenArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}


export interface MutationHandlePayflowProResponseArgs {
  input: PayflowProResponseInput;
}


export interface MutationMergeCartsArgs {
  source_cart_id: Scalars['String'];
  destination_cart_id: Maybe<Scalars['String']>;
}


export interface MutationMoveCartItemsToGiftRegistryArgs {
  cartUid: Scalars['ID'];
  giftRegistryUid: Scalars['ID'];
}


export interface MutationMoveProductsBetweenWishlistsArgs {
  sourceWishlistUid: Scalars['ID'];
  destinationWishlistUid: Scalars['ID'];
  wishlistItems: Array<WishlistItemMoveInput>;
}


export interface MutationPlaceOrderArgs {
  input: Maybe<PlaceOrderInput>;
}


export interface MutationQuotationFinishArgs {
  cart_id: Scalars['String'];
}


export interface MutationRedeemGiftCardBalanceAsStoreCreditArgs {
  input: GiftCardAccountInput;
}


export interface MutationRemoveCouponFromCartArgs {
  input: Maybe<RemoveCouponFromCartInput>;
}


export interface MutationRemoveGiftCardFromCartArgs {
  input: Maybe<RemoveGiftCardFromCartInput>;
}


export interface MutationRemoveGiftRegistryArgs {
  giftRegistryUid: Scalars['ID'];
}


export interface MutationRemoveGiftRegistryItemsArgs {
  giftRegistryUid: Scalars['ID'];
  itemsUid: Array<Scalars['ID']>;
}


export interface MutationRemoveGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID'];
  registrantsUid: Array<Scalars['ID']>;
}


export interface MutationRemoveItemFromCartArgs {
  input: Maybe<RemoveItemFromCartInput>;
}


export interface MutationRemoveProductsFromCompareListArgs {
  input: Maybe<RemoveProductsFromCompareListInput>;
}


export interface MutationRemoveProductsFromWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
}


export interface MutationRemoveReturnTrackingArgs {
  input: RemoveReturnTrackingInput;
}


export interface MutationRemoveRewardPointsFromCartArgs {
  cartId: Scalars['ID'];
}


export interface MutationRemoveStoreCreditFromCartArgs {
  input: RemoveStoreCreditFromCartInput;
}


export interface MutationReorderItemsArgs {
  orderNumber: Scalars['String'];
}


export interface MutationRequestPasswordResetEmailArgs {
  email: Scalars['String'];
}


export interface MutationRequestReturnArgs {
  input: RequestReturnInput;
}


export interface MutationResetPasswordArgs {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  newPassword: Scalars['String'];
}


export interface MutationSendEmailToFriendArgs {
  input: Maybe<SendEmailToFriendInput>;
}


export interface MutationSetBillingAddressOnCartArgs {
  input: Maybe<SetBillingAddressOnCartInput>;
}


export interface MutationSetGiftOptionsOnCartArgs {
  input: Maybe<SetGiftOptionsOnCartInput>;
}


export interface MutationSetGuestEmailOnCartArgs {
  input: Maybe<SetGuestEmailOnCartInput>;
}


export interface MutationSetPaymentInformationArgs {
  cart_id: Scalars['String'];
  input: PaymentInformationDataObjectInput;
}


export interface MutationSetPaymentMethodAndPlaceOrderArgs {
  input: Maybe<SetPaymentMethodAndPlaceOrderInput>;
}


export interface MutationSetPaymentMethodOnCartArgs {
  input: Maybe<SetPaymentMethodOnCartInput>;
}


export interface MutationSetShippingAddressesOnCartArgs {
  input: Maybe<SetShippingAddressesOnCartInput>;
}


export interface MutationSetShippingMethodsOnCartArgs {
  input: Maybe<SetShippingMethodsOnCartInput>;
}


export interface MutationShareGiftRegistryArgs {
  giftRegistryUid: Scalars['ID'];
  sender: ShareGiftRegistrySenderInput;
  invitees: Array<ShareGiftRegistryInviteeInput>;
}


export interface MutationSubscribeEmailToNewsletterArgs {
  email: Scalars['String'];
}


export interface MutationUpdateCartItemsArgs {
  input: Maybe<UpdateCartItemsInput>;
}


export interface MutationUpdateCustomerArgs {
  input: CustomerInput;
}


export interface MutationUpdateCustomerAddressArgs {
  id: Scalars['Int'];
  input: Maybe<CustomerAddressInput>;
}


export interface MutationUpdateCustomerEmailArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}


export interface MutationUpdateCustomerV2Args {
  input: CustomerUpdateInput;
}


export interface MutationUpdateGiftCardAccountArgs {
  id: Scalars['Int'];
  input: GiftDataObjectInput;
}


export interface MutationUpdateGiftCardAccountBalanceArgs {
  code: Scalars['String'];
  amount: Scalars['Float'];
}


export interface MutationUpdateGiftRegistryArgs {
  giftRegistryUid: Scalars['ID'];
  giftRegistry: UpdateGiftRegistryInput;
}


export interface MutationUpdateGiftRegistryItemsArgs {
  giftRegistryUid: Scalars['ID'];
  items: Array<UpdateGiftRegistryItemInput>;
}


export interface MutationUpdateGiftRegistryRegistrantsArgs {
  giftRegistryUid: Scalars['ID'];
  registrants: Array<UpdateGiftRegistryRegistrantInput>;
}


export interface MutationUpdateProductsInWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemUpdateInput>;
}


export interface MutationUpdateWishlistArgs {
  wishlistId: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  visibility: Maybe<WishlistVisibilityEnum>;
}


export interface MutationM2RestAddTeleconnectCartItemArgs {
  item: M2RestAddTeleconnectToCartInput;
  guestCartId: Maybe<Scalars['String']>;
}


export interface MutationM2RestSetAddressInformationArgs {
  shippingAddress: M2RestAddressInput;
  billingAddress: Maybe<M2RestAddressInput>;
  guestCartId: Maybe<Scalars['ID']>;
}


export interface MutationM2RestSetPaymentInformationArgs {
  paymentMethod: M2RestPaymentMethodInput;
  email: Scalars['String'];
  guestCartId: Maybe<Scalars['String']>;
}


export interface MutationM2RestSetOrderConditionArgs {
  orderCondition: M2RestOrderCondition;
  guestCartId: Maybe<Scalars['ID']>;
}


export interface MutationUpdateMuleSoftCustomerPreferencesArgs {
  customerId: Scalars['Int'];
  email: Scalars['String'];
  preferences: MuleSoftCustomerPreferencesInput;
}


export interface MutationCreateMuleSoftCustomerInterestsArgs {
  interests: MuleSoftCustomerInterestsInput;
}


export interface MutationCreateCustomerWithSubscriptionsArgs {
  customer: CreateCustomerInput;
}


export interface MutationSaveCustomerSubscriptionsArgs {
  subscriptions: CustomerSubscriptionsInput;
}


export interface MutationSendConfiguratorShareEmailArgs {
  input: EmailInput;
}


export interface MutationSendMattressAdvisorShareEmailArgs {
  input: EmailInput;
}


export interface MutationSendPillowAdvisorShareEmailArgs {
  input: EmailInput;
}


export interface MutationSendContactPreferencesLinkEmailArgs {
  input: EmailInput;
}


export interface MutationLoginAsArgs {
  input: LoginAsInput;
}


export interface MutationGenerateCredentialsArgs {
  customerId: Scalars['ID'];
}


export interface MutationDestroySessionArgs {
  orderId: Scalars['ID'];
}


export interface MutationSetCartManagedByArgs {
  maskedCartId: Maybe<Scalars['String']>;
  adminUserId: Maybe<Scalars['String']>;
}


export interface MutationSetCustomerManagedByArgs {
  customerId: Maybe<Scalars['String']>;
  adminId: Maybe<Scalars['String']>;
}


export interface MutationCreateNewConfiguratorArgs {
  articleId: Scalars['ID'];
  language?: Maybe<ConfiguratorLanguages>;
}


export interface MutationCreateNewConfiguratorWithPresetArgs {
  articleId: Scalars['ID'];
  configurationEan: Scalars['String'];
  language?: Maybe<ConfiguratorLanguages>;
}


export interface MutationLoadConfigurationArgs {
  configurationEan: Scalars['String'];
  language?: Maybe<ConfiguratorLanguages>;
}


export interface MutationSetConfiguratorPresetArgs {
  configuratorId: Scalars['ID'];
  configurationEan: Scalars['String'];
}


export interface MutationSetConfiguratorAnswerArgs {
  configuratorId: Scalars['ID'];
  questionOrder: Scalars['String'];
  answerId: Scalars['Int'];
  parameter: Maybe<Scalars['Int']>;
}


export interface MutationSaveConfigurationArgs {
  configuratorId: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
}

/** Contains an error message when an invalid UID was specified. */
export interface NoSuchEntityUidError extends ErrorInterface {
  __typename?: 'NoSuchEntityUidError';
  /** The returned error message. */
  message: Scalars['String'];
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID'];
}

export interface OcpOrderInformation {
  __typename?: 'OcpOrderInformation';
  /** Returns whether giftcard should be enabled for quote in OCP. */
  giftcardEnabled: Maybe<Scalars['Boolean']>;
  /** Original order date when in editing mode. */
  originalOrderDate: Maybe<Scalars['String']>;
}

export interface OcpPaymentAdditionalData {
  /** RMA iban name. */
  rmaIbanName?: Maybe<Scalars['String']>;
  /** RMA iban number. */
  rmaIbanNumber?: Maybe<Scalars['String']>;
}

/** Contains the order ID. */
export interface Order {
  __typename?: 'Order';
  adyenPaymentStatus: Maybe<AdyenPaymentStatus>;
  /** Cart ID. */
  cartId: Scalars['String'];
  maskedOrderId: Maybe<Scalars['String']>;
  /** @deprecated Use `order_number` instead. */
  orderId: Maybe<Scalars['String']>;
  /** The unique ID for an `Order` object. */
  orderNumber: Scalars['String'];
}

/** Contains detailed information about an order's billing and shipping addresses. */
export interface OrderAddress {
  __typename?: 'OrderAddress';
  /** The city or town. */
  city: Scalars['String'];
  /** The customer's company. */
  company: Maybe<Scalars['String']>;
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>;
  /** The fax number. */
  fax: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String'];
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']>;
  /** The state or province name. */
  region: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region. */
  regionId: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']>;
  /** The telephone number. */
  telephone: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']>;
}

/** Defines the order condition type. */
export enum OrderConditionEnum {
  WEBSHOP = 'webshop',
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
  CASH_SALES = 'cash_sales',
  RMA = 'rma'
}

export interface OrderInformation {
  __typename?: 'OrderInformation';
  age: Maybe<Scalars['String']>;
  foundUs: Maybe<Scalars['String']>;
  headboardMovable: Maybe<Scalars['Boolean']>;
  /** Opt out field for newsletter subscription */
  optOut: Maybe<Scalars['Boolean']>;
  particularities: Maybe<Scalars['String']>;
  reference: Maybe<Scalars['String']>;
  wouldRefer: Maybe<Scalars['String']>;
}

export interface OrderItem extends OrderItemInterface {
  __typename?: 'OrderItem';
  discountPercent: Maybe<Scalars['Float']>;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  priceInclTax: Money;
  product: Maybe<ProductInterface>;
  productParentSku: Maybe<Scalars['String']>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']>;
  rowTotal: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']>;
}

/** Order item details. */
export interface OrderItemInterface {
  discountPercent: Maybe<Scalars['Float']>;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** Indicates whether the order item is eligible to be in a return request. */
  eligibleForReturn: Maybe<Scalars['Boolean']>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the order item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  priceInclTax: Money;
  product: Maybe<ProductInterface>;
  productParentSku: Maybe<Scalars['String']>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']>;
  rowTotal: Money;
  rowTotalWithDiscountInclTax: Money;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']>;
}

/** Represents order item options like selected or entered. */
export interface OrderItemOption {
  __typename?: 'OrderItemOption';
  /** The name of the option. */
  label: Scalars['String'];
  /** The value of the option. */
  value: Scalars['String'];
}

/** Contains details about the payment method used to pay for the order. */
export interface OrderPaymentMethod {
  __typename?: 'OrderPaymentMethod';
  /** Additional data per payment method type. */
  additionalData: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method. */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String'];
}

/** Contains order shipment details. */
export interface OrderShipment {
  __typename?: 'OrderShipment';
  /** Comments added to the shipment. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID'];
  /** An array of items included in the shipment. */
  items: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number. */
  number: Scalars['String'];
  /** An array of shipment tracking details. */
  tracking: Maybe<Array<Maybe<ShipmentTracking>>>;
}

export interface OrderStatus {
  __typename?: 'OrderStatus';
  exported: Maybe<Scalars['Boolean']>;
  successful: Maybe<Scalars['Boolean']>;
  status: Maybe<Scalars['Int']>;
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
}

/** Contains details about the sales total amounts used to calculate the final price. */
export interface OrderTotal {
  __typename?: 'OrderTotal';
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the order. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the order. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The order tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The gift card balance applied to the order. */
  totalGiftcard: Maybe<Money>;
  /** The shipping amount for the order. */
  totalShipping: Money;
  /** The amount of tax applied to the order. */
  totalTax: Money;
}

/** Contains required input for Payflow Express Checkout payments. */
export interface PayflowExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String'];
}

/**
 * A set of relative URLs that PayPal uses in response to various actions during
 * the authorization process. Adobe Commerce prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods.
 */
export interface PayflowLinkInput {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the transaction error page that PayPal redirects to upon
   * payment error. If the full URL to this page is
   * https://www.example.com/paypal/action/error.html, the relative URL is
   * paypal/action/error.html.
   */
  error_url: Scalars['String'];
  /**
   * The relative URL of the order confirmation page that PayPal redirects to when
   * the payment is successful and additional confirmation is not needed. If the
   * full URL to this page is https://www.example.com/paypal/action/return.html,
   * the relative URL is paypal/action/return.html.
   */
  return_url: Scalars['String'];
}

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  TEST = 'TEST',
  LIVE = 'LIVE'
}

/**
 * Contains information used to generate PayPal iframe for transaction. Applies to
 * Payflow Link and Payments Advanced payment methods.
 */
export interface PayflowLinkToken {
  __typename?: 'PayflowLinkToken';
  /** The mode for the Payflow transaction. */
  mode: Maybe<PayflowLinkMode>;
  /** The PayPal URL used for requesting a Payflow form. */
  paypalUrl: Maybe<Scalars['String']>;
  /** The secure token generated by PayPal. */
  secureToken: Maybe<Scalars['String']>;
  /** The secure token ID generated by PayPal. */
  secureTokenId: Maybe<Scalars['String']>;
}

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
}

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProInput {
  /** Required input for credit card related information. */
  cc_details: CreditCardDetailsInput;
  /**
   * Indicates whether details about the shopper's credit/debit card should be
   * tokenized for later usage. Required only if Vault is enabled for the PayPal
   * Payflow Pro payment integration.
   */
  is_active_payment_token_enabler?: Maybe<Scalars['Boolean']>;
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProResponseInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String'];
}

export interface PayflowProResponseOutput {
  __typename?: 'PayflowProResponseOutput';
  /** The cart with the updated selected payment method. */
  cart: Cart;
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProToken {
  __typename?: 'PayflowProToken';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String'];
}

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProTokenInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods.
 */
export interface PayflowProUrlInput {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the transaction error page that PayPal redirects to upon
   * payment error. If the full URL to this page is
   * https://www.example.com/paypal/action/error.html, the relative URL is
   * paypal/action/error.html.
   */
  error_url: Scalars['String'];
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. If the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
}

export interface PaymentInformation {
  __typename?: 'PaymentInformation';
  /** Ga Identifier condition */
  gaIdentifier: Maybe<Scalars['String']>;
}

export interface PaymentInformationDataObjectInput {
  /** Google analytics _ga identifier */
  ga_identifier?: Maybe<Scalars['String']>;
}

/** Defines the payment method. */
export interface PaymentMethodInput {
  /** Required input for Adyen alternative payment methods. */
  adyen_additional_data?: Maybe<AdyenAdditionalData>;
  /** Required input for Adyen CC payments. */
  adyen_additional_data_cc?: Maybe<AdyenAdditionalDataCc>;
  /** Required input for Adyen POS Cloud payments. */
  adyen_additional_data_pos_cloud?: Maybe<AdyenAdditionalDataPosCloud>;
  /** The internal name for the payment method. */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: Maybe<HostedProInput>;
  /** Required input for OCP payment methods. */
  ocpPaymentAdditionalData?: Maybe<OcpPaymentAdditionalData>;
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: Maybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: Maybe<PayflowLinkInput>;
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: Maybe<PayflowProInput>;
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: Maybe<VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: Maybe<PaypalExpressInput>;
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: Maybe<Scalars['String']>;
}

/** The stored payment method available to the customer. */
export interface PaymentToken {
  __typename?: 'PaymentToken';
  /** A description of the stored account details. */
  details: Maybe<Scalars['String']>;
  /** The payment method code associated with the token. */
  paymentMethodCode: Scalars['String'];
  /** The public hash of the token. */
  publicHash: Scalars['String'];
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum;
}

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  CARD = 'card',
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  ACCOUNT = 'account'
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export interface PaypalExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String'];
}

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export interface PaypalExpressToken {
  __typename?: 'PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypalUrls: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token: Maybe<Scalars['String']>;
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The payment method code. */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: Maybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: Maybe<Scalars['Boolean']>;
}

/**
 * Contains the token returned by PayPal and a set of URLs that allow the buyer to
 * authorize payment and adjust checkout details. Applies to Express Checkout and
 * Payments Standard payment methods.
 */
export interface PaypalExpressTokenOutput {
  __typename?: 'PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypalUrls: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal. */
  token: Maybe<Scalars['String']>;
}

/**
 * Contains a set of URLs that allow the buyer to authorize payment and adjust
 * checkout details for Express Checkout and Payments Standard transactions.
 */
export interface PaypalExpressUrlList {
  __typename?: 'PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page. */
  start: Maybe<Scalars['String']>;
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Express Checkout and Payments Standard
 * payment methods.
 */
export interface PaypalExpressUrlsInput {
  /**
   * The relative URL of the page that PayPal redirects to when the buyer cancels
   * the transaction in order to choose a different payment method. If the full URL
   * to this page is https://www.example.com/paypal/action/cancel.html, the
   * relative URL is paypal/action/cancel.html.
   */
  cancel_url: Scalars['String'];
  /**
   * The relative URL of the page that PayPal redirects to when the payment has
   * been put on hold for additional review. This condition mostly applies to ACH
   * transactions, and is not applicable to most PayPal solutions. If the full URL
   * to this page is https://www.example.com/paypal/action/success_pending.html,
   * the relative URL is paypal/action/success_pending.html.
   */
  pending_url?: Maybe<Scalars['String']>;
  /**
   * The relative URL of the final confirmation page that PayPal redirects to upon
   * payment success. If the full URL to this page is
   * https://www.example.com/paypal/action/return.html, the relative URL is
   * paypal/action/return.html.
   */
  return_url: Scalars['String'];
  /**
   * The relative URL of the order confirmation page that PayPal redirects to when
   * the payment is successful and additional confirmation is not needed. Not
   * applicable to most PayPal solutions. If the full URL to this page is
   * https://www.example.com/paypal/action/success.html, the relative URL is
   * paypal/action/success.html.
   */
  success_url?: Maybe<Scalars['String']>;
}

/** Contains attributes specific to tangible products. */
export interface PhysicalProductInterface {
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}

/** Specifies the quote to be converted to an order. */
export interface PlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
}

/** Contains the results of the request to place an order. */
export interface PlaceOrderOutput {
  __typename?: 'PlaceOrderOutput';
  /** The ID of the order. */
  order: Order;
}

export interface PostCodeAddress {
  __typename?: 'PostCodeAddress';
  /** Type of this address. (https://api.postcode.nl/documentation/Documentation/postcodeNlTypes for possible values) */
  addressType: Maybe<Scalars['String']>;
  /**
   * Dutch term used in BAG: "adresseerbaar object id". Unique identification for
   * objects which have 'building', 'house boat site', or 'mobile home site' as addressType.
   */
  bagAddressableObjectId: Maybe<Scalars['String']>;
  /** Dutch term used in BAG: "nummeraanduiding id". */
  bagNumberDesignationId: Maybe<Scalars['String']>;
  /**
   * Official city name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks and
   * accents. This field is at most 80 characters in length.
   */
  city: Maybe<Scalars['String']>;
  /** House number of a perceel. In case of a Postbus match the house number will always be 0. Range: 0-99999 */
  houseNumber: Maybe<Scalars['Int']>;
  /**
   * Addition of the house number to uniquely define a location. These additions
   * are officially recognized by the municipality. This field is at most 6
   * characters in length and null if addition not found (see houseNumberAdditions result field).
   */
  houseNumberAddition: Maybe<Scalars['String']>;
  /** List of all house number additions having the postcode and houseNumber which was input. */
  houseNumberAdditions: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Latitude of address. Null for PO Boxes. */
  latitude: Maybe<Scalars['Float']>;
  /** Longitude of address. Null for PO Boxes. */
  longitude: Maybe<Scalars['Float']>;
  /**
   * Municipality name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks and
   * accents. This field is at most 80 characters in length.
   */
  municipality: Maybe<Scalars['String']>;
  /**
   * Four digit neighborhood code (first part of a postcode). Range: 1000-9999 plus
   * two character letter combination (second part of a postcode). Range: "AA"-"ZZ"
   */
  postcode: Maybe<Scalars['String']>;
  /** Official name of the province, correctly cased and with dashes where applicable. */
  province: Maybe<Scalars['String']>;
  /**
   * List of all purposes (Dutch: "gebruiksdoelen"). Null or an array of text
   * values. (https://api.postcode.nl/documentation/Documentation/postcodeNlTypes
   * for possible values)
   */
  purposes: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * X coordinate according to Dutch Rijksdriehoeksmeting "(EPSG) 28992 Amersfoort
   * / RD New". Values range from 0 to 300000 meters. Null for PO Boxes.
   */
  rdX: Maybe<Scalars['Int']>;
  /**
   * Y coordinate according to Dutch Rijksdriehoeksmeting "(EPSG) 28992 Amersfoort
   * / RD New". Values range from 300000 to 620000 meters. Null for PO Boxes.
   */
  rdY: Maybe<Scalars['Int']>;
  /**
   * Street name in accordance with "BAG (Basisregistraties adressen en gebouwen)".
   * In capital and lowercase letters, including punctuation marks and accents.
   * This field is at most 80 characters in length. Filled with "Postbus" in case
   * it is a range of PO boxes.
   */
  street: Maybe<Scalars['String']>;
  /** Surface in square meters. Null for PO Boxes. */
  surfaceArea: Maybe<Scalars['Int']>;
}

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export interface Price {
  __typename?: 'Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount: Maybe<Money>;
}

/**
 * Deprecated. Taxes will be included or excluded in the price. Defines the amount
 * of money to apply as an adjustment, the type of adjustment to apply, and whether
 * the item is included or excluded from the adjustment.
 */
export interface PriceAdjustment {
  __typename?: 'PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description: Maybe<PriceAdjustmentDescriptionEnum>;
}

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  TAX = 'TAX',
  WEEE = 'WEEE',
  WEEE_TAX = 'WEEE_TAX'
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED'
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export interface PriceRange {
  __typename?: 'PriceRange';
  /** The highest possible price for the product. */
  maximumPrice: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimumPrice: ProductPrice;
}

/** Defines the price type. */
export enum PriceTypeEnum {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  DYNAMIC = 'DYNAMIC'
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  PRICE_RANGE = 'PRICE_RANGE',
  AS_LOW_AS = 'AS_LOW_AS'
}

export interface PrismicDocument {
  __typename?: 'PrismicDocument';
  data: Maybe<Scalars['JSON']>;
}

export interface PrismicPredicateManyValues {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
}

export interface PrismicPredicateOneValue {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface PrismicTypeAdvisor extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeAdvisor';
  title: Maybe<Scalars['String']>;
  mainLink: Maybe<PrismicHyperlink>;
  mainLinkTitle: Maybe<Scalars['String']>;
  mainImage: Maybe<Scalars['Json']>;
  body: Maybe<Array<AdvisorBody>>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeAdvisorConnectionConnection {
  __typename?: 'PrismicTypeAdvisorConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeAdvisorConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeAdvisorConnectionEdge {
  __typename?: 'PrismicTypeAdvisorConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeAdvisor;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeAttribute extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeAttribute';
  tooltip: Maybe<Scalars['Json']>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeAttributeConnectionConnection {
  __typename?: 'PrismicTypeAttributeConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeAttributeConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeAttributeConnectionEdge {
  __typename?: 'PrismicTypeAttributeConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeAttribute;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeBeddengoed extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeBeddengoed';
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeBeddengoedConnectionConnection {
  __typename?: 'PrismicTypeBeddengoedConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeBeddengoedConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeBeddengoedConnectionEdge {
  __typename?: 'PrismicTypeBeddengoedConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeBeddengoed;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

/** A prismic document */
export interface PrismicTypeDocument {
  meta: PrismicTypeMeta;
}

/** A connection to a list of items. */
export interface PrismicTypeDocumentConnection {
  __typename?: 'PrismicTypeDocumentConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeDocumentEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeDocumentEdge {
  __typename?: 'PrismicTypeDocumentEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeDocument;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

/** An external link */
export interface PrismicTypeExternalLink extends PrismicTypeLinkable {
  __typename?: 'PrismicTypeExternalLink';
  url: Scalars['String'];
  target: Maybe<Scalars['String']>;
  linkType: Maybe<Scalars['String']>;
}

/** A linked file */
export interface PrismicTypeFileLink extends PrismicTypeLinkable {
  __typename?: 'PrismicTypeFileLink';
  name: Scalars['String'];
  url: Scalars['String'];
  size: Scalars['Long'];
  linkType: Maybe<Scalars['String']>;
}

export interface PrismicTypeHeader extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeHeader';
  usp: Maybe<Scalars['Json']>;
  body: Maybe<Array<HeaderBody>>;
  trustpilotScore: Maybe<Scalars['Json']>;
  trustpilotText: Maybe<Scalars['Json']>;
  contactInfoTitle: Maybe<Scalars['Json']>;
  contactInfo: Maybe<Scalars['Json']>;
  paymentMethods: Maybe<Array<PrismicTypeHeaderPaymentMethods>>;
  legal: Maybe<Array<PrismicTypeHeaderLegal>>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeHeaderConnectionConnection {
  __typename?: 'PrismicTypeHeaderConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeHeaderConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeHeaderConnectionEdge {
  __typename?: 'PrismicTypeHeaderConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeHeader;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeHeaderLegal {
  __typename?: 'PrismicTypeHeaderLegal';
  text: Maybe<Scalars['Json']>;
}

export interface PrismicTypeHeaderPaymentMethods {
  __typename?: 'PrismicTypeHeaderPaymentMethods';
  image: Maybe<PrismicImageType>;
}

/** A linked image */
export interface PrismicTypeImageLink extends PrismicTypeLinkable {
  __typename?: 'PrismicTypeImageLink';
  name: Scalars['String'];
  url: Scalars['String'];
  size: Scalars['Long'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  linkType: Maybe<Scalars['String']>;
}

/** A prismic link */
export interface PrismicTypeLinkable {
  linkType: Maybe<Scalars['String']>;
}

export interface PrismicTypeMeta {
  __typename?: 'PrismicTypeMeta';
  /** The id of the document. */
  id: Scalars['String'];
  /** The uid of the document. */
  uid: Maybe<Scalars['String']>;
  /** The type of the document. */
  type: Scalars['String'];
  /** The tags of the document. */
  tags: Array<Scalars['String']>;
  /** The language of the document. */
  lang: Scalars['String'];
  /** Alternate languages the document. */
  alternateLanguages: Array<PrismicTypeRelatedDocument>;
  /** The first publication date of the document. */
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  /** The last publication date of the document. */
  lastPublicationDate: Maybe<Scalars['DateTime']>;
}

/** Information about pagination in a connection. */
export interface PrismicTypePageInfo {
  __typename?: 'PrismicTypePageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
}

export interface PrismicTypeRelatedDocument {
  __typename?: 'PrismicTypeRelatedDocument';
  /** The id of the document. */
  id: Scalars['String'];
  /** The uid of the document. */
  uid: Maybe<Scalars['String']>;
  /** The type of the document. */
  type: Scalars['String'];
  /** The language of the document. */
  lang: Scalars['String'];
}

export interface PrismicTypeSearch extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeSearch';
  autocompleteContentBlocks: Maybe<Array<PrismicTypeSearchAutocompleteContentBlocks>>;
  autocompleteNoResultsText: Maybe<Scalars['Json']>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

export interface PrismicTypeSearchAutocompleteContentBlocks {
  __typename?: 'PrismicTypeSearchAutocompleteContentBlocks';
  image: Maybe<PrismicImageType>;
  title: Maybe<Scalars['Json']>;
  description: Maybe<Scalars['Json']>;
  link: Maybe<PrismicHyperlink>;
}

/** A connection to a list of items. */
export interface PrismicTypeSearchConnectionConnection {
  __typename?: 'PrismicTypeSearchConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeSearchConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeSearchConnectionEdge {
  __typename?: 'PrismicTypeSearchConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeSearch;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeSimilar {
  documentId: Scalars['String'];
  max: Scalars['Int'];
}

export enum PrismicTypeSortAdvisory {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  MAIN_LINK_TITLE_ASC = 'main_link_title_ASC',
  MAIN_LINK_TITLE_DESC = 'main_link_title_DESC'
}

export enum PrismicTypeSortAttributey {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  TOOLTIP_ASC = 'tooltip_ASC',
  TOOLTIP_DESC = 'tooltip_DESC'
}

export enum PrismicTypeSortBeddengoedy {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC'
}

export enum PrismicTypeSortDocumentsBy {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC'
}

export enum PrismicTypeSortHeadery {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  USP_ASC = 'usp_ASC',
  USP_DESC = 'usp_DESC',
  TRUSTPILOT_TEXT_ASC = 'trustpilot_text_ASC',
  TRUSTPILOT_TEXT_DESC = 'trustpilot_text_DESC',
  CONTACT_INFO_TITLE_ASC = 'contact_info_title_ASC',
  CONTACT_INFO_TITLE_DESC = 'contact_info_title_DESC',
  CONTACT_INFO_ASC = 'contact_info_ASC',
  CONTACT_INFO_DESC = 'contact_info_DESC'
}

export enum PrismicTypeSortSearchy {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  AUTOCOMPLETE_NO_RESULTS_TEXT_ASC = 'autocomplete_no_results_text_ASC',
  AUTOCOMPLETE_NO_RESULTS_TEXT_DESC = 'autocomplete_no_results_text_DESC'
}

export enum PrismicTypeSortStorelocatory {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  DESCRIPTION_ASC = 'description_ASC',
  DESCRIPTION_DESC = 'description_DESC',
  CONTENT_ASC = 'content_ASC',
  CONTENT_DESC = 'content_DESC'
}

export enum PrismicTypeSortStorey {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  URL_KEY_ASC = 'url_key_ASC',
  URL_KEY_DESC = 'url_key_DESC',
  SEO_TITLE_ASC = 'seo_title_ASC',
  SEO_TITLE_DESC = 'seo_title_DESC',
  SEO_DESCRIPTION_ASC = 'seo_description_ASC',
  SEO_DESCRIPTION_DESC = 'seo_description_DESC',
  CALENDLY_URL_ASC = 'calendly_url_ASC',
  CALENDLY_URL_DESC = 'calendly_url_DESC',
  MATTERPORT_URL_ASC = 'matterport_url_ASC',
  MATTERPORT_URL_DESC = 'matterport_url_DESC',
  EMAIL_ASC = 'email_ASC',
  EMAIL_DESC = 'email_DESC'
}

export enum PrismicTypeSortSubmenuy {
  META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
  META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
  META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
  META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
  KEY_ASC = 'key_ASC',
  KEY_DESC = 'key_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC'
}

export interface PrismicTypeStore extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeStore';
  urlKey: Maybe<Scalars['String']>;
  seoTitle: Maybe<Scalars['String']>;
  seoDescription: Maybe<Scalars['String']>;
  calendlyUrl: Maybe<Scalars['String']>;
  matterportUrl: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  body: Maybe<Array<StoreBody>>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeStoreConnectionConnection {
  __typename?: 'PrismicTypeStoreConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeStoreConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeStoreConnectionEdge {
  __typename?: 'PrismicTypeStoreConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeStore;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeStorelocator extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeStorelocator';
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['Json']>;
  body: Maybe<Array<StorelocatorBody>>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeStorelocatorConnectionConnection {
  __typename?: 'PrismicTypeStorelocatorConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeStorelocatorConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeStorelocatorConnectionEdge {
  __typename?: 'PrismicTypeStorelocatorConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeStorelocator;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeSubmenu extends PrismicTypeDocument, PrismicTypeLinkable {
  __typename?: 'PrismicTypeSubmenu';
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  image: Maybe<PrismicImageType>;
  body: Maybe<Array<SubmenuBody>>;
  meta: PrismicTypeMeta;
  linkType: Maybe<Scalars['String']>;
}

/** A connection to a list of items. */
export interface PrismicTypeSubmenuConnectionConnection {
  __typename?: 'PrismicTypeSubmenuConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PrismicTypePageInfo;
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrismicTypeSubmenuConnectionEdge>>>;
  totalCount: Scalars['Long'];
}

/** An edge in a connection. */
export interface PrismicTypeSubmenuConnectionEdge {
  __typename?: 'PrismicTypeSubmenuConnectionEdge';
  /** The item at the end of the edge. */
  node: PrismicTypeSubmenu;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
}

export interface PrismicTypeWhereAdvisor {
  title?: Maybe<Scalars['String']>;
  titleFulltext?: Maybe<Scalars['String']>;
  /** main_link */
  mainLink?: Maybe<Scalars['String']>;
  mainLinkTitle?: Maybe<Scalars['String']>;
  mainLinkTitleFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereAttribute {
  /** tooltip */
  tooltipFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereHeader {
  /** usp */
  uspFulltext?: Maybe<Scalars['String']>;
  /** trustpilot_text */
  trustpilotTextFulltext?: Maybe<Scalars['String']>;
  /** contact_info_title */
  contactInfoTitleFulltext?: Maybe<Scalars['String']>;
  /** contact_info */
  contactInfoFulltext?: Maybe<Scalars['String']>;
  legal?: Maybe<PrismicTypeWhereHeaderLegal>;
}

export interface PrismicTypeWhereHeaderLegal {
  /** text */
  textFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereSearch {
  autocompleteContentBlocks?: Maybe<PrismicTypeWhereSearchAutocompleteContentBlocks>;
  /** autocomplete_no_results_text */
  autocompleteNoResultsTextFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereSearchAutocompleteContentBlocks {
  /** title */
  titleFulltext?: Maybe<Scalars['String']>;
  /** description */
  descriptionFulltext?: Maybe<Scalars['String']>;
  /** link */
  link?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereStore {
  urlKey?: Maybe<Scalars['String']>;
  urlKeyFulltext?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleFulltext?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionFulltext?: Maybe<Scalars['String']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  calendlyUrlFulltext?: Maybe<Scalars['String']>;
  matterportUrl?: Maybe<Scalars['String']>;
  matterportUrlFulltext?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereStorelocator {
  title?: Maybe<Scalars['String']>;
  titleFulltext?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionFulltext?: Maybe<Scalars['String']>;
  /** content */
  contentFulltext?: Maybe<Scalars['String']>;
}

export interface PrismicTypeWhereSubmenu {
  key?: Maybe<Scalars['String']>;
  keyFulltext?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleFulltext?: Maybe<Scalars['String']>;
}

/** Contains a product attribute code and value. */
export interface ProductAttribute {
  __typename?: 'ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute. */
  value: Scalars['String'];
}

/**
 * ProductAttributeFilterInput defines the filters to be used in the search. A
 * filter contains at least one attribute, a comparison operator, and the value
 * that is being searched for.
 */
export interface ProductAttributeFilterInput {
  /** Attribute label: Type - accessoires */
  accessoires_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type - bedbodems */
  bedbodem_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Verstelbaar - bedbodem */
  bedbodem_verstelbaar?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Collectie - beddengoed */
  beddengoed_collectie?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Maat- Beddengoed */
  beddengoed_maat?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Thema */
  beddengoed_thema?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type - beddengoed */
  beddengoed_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Collectie - boxspring/matrassen */
  boxspring_collectie?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Design */
  boxspring_design?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Opbergruimte */
  boxspring_opbergruimte?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Verstelbaar - boxspring */
  boxspring_verstelbaar_fil?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Breedte */
  breedte?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: Maybe<FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  created_at?: Maybe<FilterTypeInput>;
  /** Attribute label: Dessin */
  dekbedovertrek_dessin?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Materiaal */
  dekbedovertrek_materiaal?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Direct leverbaar */
  direct_beschikbaar?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Geschikt voor - hoeslakens / moltons */
  hoeslaken_geschikt_voor?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Anti-allergisch - hoofdkussen */
  hoofdkussen_anti_allergisch?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Neksteun */
  hoofdkussen_neksteun?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Vulling - beddengoed */
  hoofdkussen_vulling?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Kleur - filterbaar */
  kleur?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Lengte */
  lengte?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Maatvoering */
  maatvoering?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Ventilatie */
  matras__ventilatie?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Drukverlagend */
  matras_drukverlagend?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Afdeklaag */
  matras_filter_afdek?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Ondersteuning */
  matras_ondersteuning?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type - matrassen */
  matras_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Merk */
  merk?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Productnaam */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter product by news_from_date. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** Filter product by news_to_date. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** Attribute label: Nieuwe Collectie */
  nieuwe_collectie?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Sale */
  sale_2?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Segment */
  segment?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: SKU */
  sku?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type - toppers */
  topper_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Materiaal - toppers */
  topper_uitvoering?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: TV Lift */
  tv_lift?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Uitvoering - beddengoed */
  uitvoering?: Maybe<FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterEqualTypeInput>;
}

/**
 * Specifies the attribute to use for sorting search results and indicates whether
 * the results are sorted in ascending or descending order. It's possible to sort
 * products using searchable attributes with enabled 'Use in Filter Options' option
 */
export interface ProductAttributeSortInput {
  /** Attribute label: Productnaam */
  name?: Maybe<SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: Maybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: Maybe<SortEnum>;
}

/** Contains an array of Product ids and skus. */
export interface ProductConfigurableIdList {
  __typename?: 'ProductConfigurableIdList';
  /** An array of Product ids and skus results. */
  items: Maybe<Array<Maybe<ProductConfigurableItem>>>;
}

/** Product item result */
export interface ProductConfigurableItem {
  __typename?: 'ProductConfigurableItem';
  entityId: Maybe<Scalars['Int']>;
  sku: Maybe<Scalars['String']>;
}

/** Product delivery time result */
export interface ProductDeliveryTime {
  __typename?: 'ProductDeliveryTime';
  deliveryDate: Maybe<Scalars['String']>;
  deliveryUnknown: Maybe<Scalars['Boolean']>;
  deliveryWeek: Maybe<Scalars['String']>;
  displayText: Maybe<Scalars['String']>;
  sku: Maybe<Scalars['String']>;
  weekDay: Maybe<Scalars['String']>;
}

/** Contains an array CMS block items. */
export interface ProductDeliveryTimes {
  __typename?: 'ProductDeliveryTimes';
  /** An array of Product delivery time results. */
  items: Maybe<Array<Maybe<ProductDeliveryTime>>>;
}

/** Contains the discount applied to a product price. */
export interface ProductDiscount {
  __typename?: 'ProductDiscount';
  /** The actual value of the discount. */
  amountOff: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percentOff: Maybe<Scalars['Float']>;
}

/**
 * ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead.
 * ProductFilterInput defines the filters to be used in the search. A filter
 * contains at least one attribute, a comparison operator, and the value that is
 * being searched for.
 */
export interface ProductFilterInput {
  /** The category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>;
  /** Indicates whether the product can be returned. */
  is_returnable?: Maybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>;
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterTypeInput>;
  url_path?: Maybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>;
}

/** Contains product image information, including the image URL and label. */
export interface ProductImage extends MediaGalleryInterface {
  __typename?: 'ProductImage';
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']>;
  /** The URL of the product image or video if it is set for the type. */
  urlIfSet: Maybe<Scalars['String']>;
}

/** Contains fields that are common to all types of products. */
export interface ProductInterface {
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
}


/** Contains fields that are common to all types of products. */
export interface ProductInterfaceReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** An implementation of `ProductLinksInterface`. */
export interface ProductLinks extends ProductLinksInterface {
  __typename?: 'ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']>;
}

/** Contains information about linked products, including the link type and product type of each item. */
export interface ProductLinksInterface {
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']>;
}

/** Contains an image in base64 format and basic information about the image. */
export interface ProductMediaGalleryEntriesContent {
  __typename?: 'ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64EncodedData: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type: Maybe<Scalars['String']>;
}

/** Contains a link to a video file and basic information about the video. */
export interface ProductMediaGalleryEntriesVideoContent {
  __typename?: 'ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  mediaType: Maybe<Scalars['String']>;
  /** A description of the video. */
  videoDescription: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  videoMetadata: Maybe<Scalars['String']>;
  /** Describes the video source. */
  videoProvider: Maybe<Scalars['String']>;
  /** The title of the video. */
  videoTitle: Maybe<Scalars['String']>;
  /** The URL to the video. */
  videoUrl: Maybe<Scalars['String']>;
}

/** Represents a product price. */
export interface ProductPrice {
  __typename?: 'ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount: Maybe<ProductDiscount>;
  /** The final price of the product after applying discounts. */
  finalPrice: Money;
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The regular price of the product. */
  regularPrice: Money;
}

/**
 * Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as
 * well as its minimum and maximum prices. Only composite products, which include
 * bundle, configurable, and grouped products, can contain a minimum and maximum price.
 */
export interface ProductPrices {
  __typename?: 'ProductPrices';
  /**
   * The highest possible final price for all the options defined within a
   * composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite
   * product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice: Maybe<Price>;
}

/** Contains details of a product review. */
export interface ProductReview {
  __typename?: 'ProductReview';
  /** The average of all ratings for this product. */
  averageRating: Scalars['Float'];
  /** Bad points for this product. */
  badPoints: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The date the review was created. */
  createdAt: Scalars['String'];
  /** Good points for this product. */
  goodPoints: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The reviewed product. */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratingsBreakdown: Array<Maybe<ProductReviewRating>>;
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
}

/** Contains data about a single aspect of a product review. */
export interface ProductReviewRating {
  __typename?: 'ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
}

/** Contains the reviewer's rating for a single aspect of a review. */
export interface ProductReviewRatingInput {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
}

/** Contains details about a single aspect of a product review. */
export interface ProductReviewRatingMetadata {
  __typename?: 'ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
}

/** Contains an array of metadata about each aspect of a product review. */
export interface ProductReviewRatingsMetadata {
  __typename?: 'ProductReviewRatingsMetadata';
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
}

/** Contains details about a single value in a product review. */
export interface ProductReviewRatingValueMetadata {
  __typename?: 'ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String'];
  /** An encoded rating value ID. */
  valueId: Scalars['String'];
}

/** Contains an array of product reviews. */
export interface ProductReviews {
  __typename?: 'ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  pageInfo: SearchResultPageInfo;
}

/** Contains the results of a `products` query. */
export interface Products {
  __typename?: 'Products';
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sortFields: Maybe<SortFields>;
  /** An array of search suggestions for case when search query have no results. */
  suggestions: Maybe<Array<Maybe<SearchSuggestion>>>;
  /**
   * The number of products that are marked as visible. By default, in complex
   * products, parent products are visible, but their child products are not.
   */
  totalCount: Maybe<Scalars['Int']>;
}


/** Contains the results of a `products` query. */
export interface ProductsAggregationsArgs {
  filter: Maybe<AggregationsFilterInput>;
}

/**
 * Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to
 * use for sorting search results and indicates whether the results are sorted in
 * ascending or descending order.
 */
export interface ProductSortInput {
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<SortEnum>;
  /** The label assigned to a product image. */
  image_label?: Maybe<SortEnum>;
  /** Indicates whether the product can be returned. */
  is_returnable?: Maybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<SortEnum>;
  /** The price of the item. */
  price?: Maybe<SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<SortEnum>;
  /** The discounted price of the product. */
  special_price?: Maybe<SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<SortEnum>;
  /** Indicates the criteria to sort swatches. */
  swatch_image?: Maybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<SortEnum>;
  url_path?: Maybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<SortEnum>;
}

/** States whether a product stock status is in stock or out of stock. */
export enum ProductStockStatus {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

/**
 * Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity
 * discount offered to a specific customer group.
 */
export interface ProductTierPrices {
  __typename?: 'ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customerGroupId: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentageValue: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  websiteId: Maybe<Scalars['Float']>;
}

/** Contains information about a product video. */
export interface ProductVideo extends MediaGalleryInterface {
  __typename?: 'ProductVideo';
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']>;
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>;
}

export interface Query {
  __typename?: 'Query';
  resolveDataHash: Maybe<Scalars['JSON']>;
  googleMapsGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>;
  googleMapsReverseGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>;
  googleTravelDistance: Maybe<Array<Maybe<GoogleMapsTravelDistance>>>;
  googleTravelDistances: Maybe<Array<Maybe<GoogleMapsDistanceDestination>>>;
  googleLocation: Maybe<GoogleLocationInfo>;
  googleLocations: Maybe<Array<GoogleLocationInfo>>;
  googleLocationMedia: Maybe<GoogleLocationMedia>;
  googleAccounts: Maybe<Array<GoogleAccount>>;
  /** Returns Json Encoded Autocomplete suggestions */
  addressDetails: Maybe<AddressDetails>;
  adyenPaymentMethods: Maybe<AdyenPaymentMethods>;
  adyenPaymentStatus: Maybe<AdyenPaymentStatus>;
  /** Returns Json Encoded Autocomplete suggestions */
  autocomplete: Maybe<AutocompleteMatches>;
  /** Request for available countries with required regions. */
  availableCountries: Array<Maybe<Country>>;
  /** Get a list of available store views and their config information. */
  availableStores: Maybe<Array<Maybe<StoreConfig>>>;
  /** Return information about the specified shopping cart. */
  cart: Maybe<Cart>;
  /** Return a list of categories that match the specified filter. */
  categories: Maybe<CategoryResult>;
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category: Maybe<CategoryTree>;
  /** Retrieve a category by UID */
  categoryByUid: Maybe<CategoryInterface>;
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList: Maybe<Array<Maybe<CategoryTree>>>;
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements: Maybe<Array<Maybe<CheckoutAgreement>>>;
  /** Return information about CMS blocks. */
  cmsBlocks: Maybe<CmsBlocks>;
  /** Return details about a CMS page. */
  cmsPage: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list. */
  compareList: Maybe<CompareList>;
  /** The countries query provides information for all countries. */
  countries: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country: Maybe<Country>;
  /** Return information about the store's currency. */
  currency: Maybe<Currency>;
  /** Return the attribute type, given an attribute code and entity type. */
  customAttributeMetadata: Maybe<CustomAttributeMetadata>;
  /** Return detailed information about a customer account. */
  customer: Maybe<Customer>;
  /** Return information about the customer's shopping cart. */
  customerCart: Cart;
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts: Maybe<CustomerDownloadableProducts>;
  /** @deprecated Use the `customer` query instead. */
  customerOrders: Maybe<CustomerOrders>;
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>;
  /** Get delivery time */
  deliveryTime: DeliveryTime;
  /** Get delayed options */
  deliveryTimeDelayedOptions: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Return a list of dynamic blocks filtered by type, location, or UIDs. */
  dynamicBlocks: DynamicBlocks;
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl: Maybe<HostedProUrl>;
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken: Maybe<PayflowLinkToken>;
  /** Return details about a specific gift card. */
  giftCardAccount: Maybe<GiftCardAccount>;
  /** Return the specified gift registry. Some details will not be available to guests. */
  giftRegistry: Maybe<GiftRegistry>;
  /** Search for gift registries by specifying a registrant email address. */
  giftRegistryEmailSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>;
  /** Search for gift registries by specifying a registry URL key. */
  giftRegistryIdSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>;
  /** Search for gift registries by specifying the registrant name and registry type ID. */
  giftRegistryTypeSearch: Maybe<Array<Maybe<GiftRegistrySearchResult>>>;
  /** Get a list of available gift registry types. */
  giftRegistryTypes: Maybe<Array<Maybe<GiftRegistryType>>>;
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable: Maybe<IsEmailAvailableOutput>;
  /** Searches for Best Sellers information matches the filter. */
  mpSmtpBestsellers: Maybe<SmtpBestsellersOutput>;
  order: Maybe<CustomerOrder>;
  /** Returns Json Encoded AddressInfo */
  postcode: Maybe<PostCodeAddress>;
  /** @deprecated id is deprecated, use productByUid */
  product: Maybe<ProductInterface>;
  /** Retrieve a product by UID */
  productByUid: Maybe<ProductInterface>;
  /** Get configurable product ids and skus of the parent */
  productConfigurableIdList: Maybe<ProductConfigurableIdList>;
  /** Get product delivery time list */
  productDeliveryTimeList: ProductDeliveryTimes;
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products: Maybe<Products>;
  /** Return the full details for a specified product, category, or CMS page. */
  route: Maybe<RoutableInterface>;
  /** Return details about the store's configuration. */
  storeConfig: Maybe<StoreConfig>;
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver: Maybe<EntityUrl>;
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist: Maybe<WishlistOutput>;
  m2RestAvailableDeliveryDates: Array<Scalars['String']>;
  m2RestStoreConfig: M2RestStoreConfig;
  m2RestCustomerById: Maybe<M2RestCustomer>;
  customerSubscriptions: Maybe<Array<Maybe<CustomerSubscription>>>;
  muleSoftCustomer: Maybe<MuleSoftCustomer>;
  salesForceCustomer: Maybe<SalesForceCustomer>;
  muleSoftOmnichannelCustomers: Array<MuleSoftOmnichannelCustomer>;
  /** Gets all sessions linked to the current adminId */
  currentCustomerSessions: Maybe<Array<Maybe<AdminCustomerSession>>>;
  /** Gets the sales office for a specific admin user */
  salesOfficeByAdminId: Scalars['String'];
  /** Validate an IBAN number */
  validateIban: Scalars['Boolean'];
  /** Get an order status */
  orderStatus: Maybe<OrderStatus>;
  prismicTypeAllDocuments: PrismicTypeDocumentConnection;
  prismicTypeAdvisor: Maybe<PrismicTypeAdvisor>;
  prismicTypeAllAdvisors: PrismicTypeAdvisorConnectionConnection;
  prismicTypeAllStores: PrismicTypeStoreConnectionConnection;
  prismicTypeAllHeaders: PrismicTypeHeaderConnectionConnection;
  prismicTypeAllStorelocators: PrismicTypeStorelocatorConnectionConnection;
  prismicTypeAllBeddengoeds: PrismicTypeBeddengoedConnectionConnection;
  prismicTypeAllSearchs: PrismicTypeSearchConnectionConnection;
  prismicTypeAllSubmenus: PrismicTypeSubmenuConnectionConnection;
  prismicTypeAttribute: Maybe<PrismicTypeAttribute>;
  prismicTypeAllAttributes: PrismicTypeAttributeConnectionConnection;
  prismicResolvedPreviewUrl: Maybe<Scalars['String']>;
  prismicDocument: Maybe<PrismicDocument>;
  prismicDocuments: Maybe<Array<PrismicDocument>>;
  getProgrammableArticle: TeleConnectPagination;
  getConfiguratorQuestions: Maybe<Array<TeleConnectQuestion>>;
  getConfiguratorQuestionAnswers: Maybe<Array<TeleConnectAnswer>>;
}


export interface QueryResolveDataHashArgs {
  hash: Scalars['String'];
}


export interface QueryGoogleMapsGeocodeArgs {
  search: Scalars['String'];
}


export interface QueryGoogleMapsReverseGeocodeArgs {
  coordinates: GoogleMapsGeocodeLocationInput;
}


export interface QueryGoogleTravelDistanceArgs {
  from: GoogleMapsGeocodeLocationInput;
  to: GoogleMapsGeocodeLocationInput;
}


export interface QueryGoogleTravelDistancesArgs {
  input: GoogleMapsDistanceDestinationsInput;
}


export interface QueryGoogleLocationArgs {
  name: Scalars['ID'];
}


export interface QueryGoogleLocationMediaArgs {
  name: Scalars['ID'];
}


export interface QueryAddressDetailsArgs {
  context: Scalars['String'];
  xAutocompleteSession: Maybe<Scalars['String']>;
}


export interface QueryAdyenPaymentMethodsArgs {
  cart_id: Scalars['String'];
  shopper_locale: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
}


export interface QueryAdyenPaymentStatusArgs {
  orderNumber: Maybe<Scalars['String']>;
  cartId: Maybe<Scalars['String']>;
}


export interface QueryAutocompleteArgs {
  countryId: Scalars['String'];
  searchTerm: Scalars['String'];
  xAutocompleteSession: Maybe<Scalars['String']>;
}


export interface QueryAvailableStoresArgs {
  useCurrentGroup: Maybe<Scalars['Boolean']>;
}


export interface QueryCartArgs {
  cart_id: Scalars['String'];
}


export interface QueryCategoriesArgs {
  filters: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}


export interface QueryCategoryArgs {
  id: Maybe<Scalars['Int']>;
}


export interface QueryCategoryByUidArgs {
  uid: Scalars['ID'];
}


export interface QueryCategoryListArgs {
  filters: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}


export interface QueryCmsBlocksArgs {
  identifiers: Maybe<Array<Maybe<Scalars['String']>>>;
}


export interface QueryCmsPageArgs {
  id: Maybe<Scalars['Int']>;
  identifier: Maybe<Scalars['String']>;
}


export interface QueryCompareListArgs {
  uid: Scalars['ID'];
}


export interface QueryCountryArgs {
  id: Maybe<Scalars['String']>;
}


export interface QueryCustomAttributeMetadataArgs {
  attributes: Array<AttributeInput>;
}


export interface QueryDeliveryTimeArgs {
  cart_id: Scalars['String'];
  check_paazl_relevancy: Maybe<Scalars['Boolean']>;
}


export interface QueryDeliveryTimeDelayedOptionsArgs {
  cart_id: Scalars['String'];
  force_week: Maybe<Scalars['Boolean']>;
}


export interface QueryDynamicBlocksArgs {
  input: Maybe<DynamicBlocksFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}


export interface QueryGetHostedProUrlArgs {
  input: HostedProUrlInput;
}


export interface QueryGetPayflowLinkTokenArgs {
  input: PayflowLinkTokenInput;
}


export interface QueryGiftCardAccountArgs {
  input: GiftCardAccountInput;
}


export interface QueryGiftRegistryArgs {
  giftRegistryUid: Scalars['ID'];
}


export interface QueryGiftRegistryEmailSearchArgs {
  email: Scalars['String'];
}


export interface QueryGiftRegistryIdSearchArgs {
  giftRegistryUid: Scalars['ID'];
}


export interface QueryGiftRegistryTypeSearchArgs {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  giftRegistryTypeUid: Maybe<Scalars['ID']>;
}


export interface QueryIsEmailAvailableArgs {
  email: Scalars['String'];
}


export interface QueryMpSmtpBestsellersArgs {
  app_id: Scalars['String'];
  secret_key: Scalars['String'];
  filters: Maybe<MpFilters>;
}


export interface QueryOrderArgs {
  orderMaskId: Scalars['String'];
}


export interface QueryPostcodeArgs {
  postcode: Scalars['String'];
  houseNumber: Scalars['String'];
  houseNumberAddition: Maybe<Scalars['String']>;
}


export interface QueryProductArgs {
  id: Scalars['Int'];
}


export interface QueryProductByUidArgs {
  uid: Scalars['ID'];
}


export interface QueryProductConfigurableIdListArgs {
  skuList: Maybe<Array<Maybe<Scalars['String']>>>;
}


export interface QueryProductDeliveryTimeListArgs {
  skuList: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Maybe<Scalars['String']>;
}


export interface QueryProductsArgs {
  search: Maybe<Scalars['String']>;
  filter: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  sort: Maybe<ProductAttributeSortInput>;
}


export interface QueryRouteArgs {
  url: Scalars['String'];
}


export interface QueryUrlResolverArgs {
  url: Scalars['String'];
}


export interface QueryM2RestAvailableDeliveryDatesArgs {
  guestCartId: Maybe<Scalars['String']>;
  forceWeek: Maybe<Scalars['Boolean']>;
}


export interface QueryM2RestCustomerByIdArgs {
  customerId: Scalars['ID'];
}


export interface QueryCustomerSubscriptionsArgs {
  customerId: Scalars['Int'];
  email: Scalars['String'];
}


export interface QueryMuleSoftCustomerArgs {
  email: Scalars['String'];
  customerId: Maybe<Scalars['Int']>;
}


export interface QuerySalesForceCustomerArgs {
  email: Scalars['String'];
}


export interface QueryMuleSoftOmnichannelCustomersArgs {
  search: Scalars['String'];
}


export interface QuerySalesOfficeByAdminIdArgs {
  adminUserId: Maybe<Scalars['String']>;
}


export interface QueryValidateIbanArgs {
  iban: Scalars['String'];
}


export interface QueryOrderStatusArgs {
  orderId: Scalars['String'];
}


export interface QueryPrismicTypeAllDocumentsArgs {
  sortBy: Maybe<PrismicTypeSortDocumentsBy>;
  type: Maybe<Scalars['String']>;
  type_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAdvisorArgs {
  uid: Scalars['String'];
  lang: Scalars['String'];
}


export interface QueryPrismicTypeAllAdvisorsArgs {
  sortBy: Maybe<PrismicTypeSortAdvisory>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereAdvisor>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllStoresArgs {
  sortBy: Maybe<PrismicTypeSortStorey>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereStore>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllHeadersArgs {
  sortBy: Maybe<PrismicTypeSortHeadery>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereHeader>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllStorelocatorsArgs {
  sortBy: Maybe<PrismicTypeSortStorelocatory>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereStorelocator>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllBeddengoedsArgs {
  sortBy: Maybe<PrismicTypeSortBeddengoedy>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllSearchsArgs {
  sortBy: Maybe<PrismicTypeSortSearchy>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereSearch>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAllSubmenusArgs {
  sortBy: Maybe<PrismicTypeSortSubmenuy>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereSubmenu>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicTypeAttributeArgs {
  uid: Scalars['String'];
  lang: Scalars['String'];
}


export interface QueryPrismicTypeAllAttributesArgs {
  sortBy: Maybe<PrismicTypeSortAttributey>;
  uid: Maybe<Scalars['String']>;
  uid_in: Maybe<Array<Scalars['String']>>;
  id: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Scalars['String']>>;
  lang: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  tags_in: Maybe<Array<Scalars['String']>>;
  firstPublicationDate: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before: Maybe<Scalars['DateTime']>;
  lastPublicationDate: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before: Maybe<Scalars['DateTime']>;
  fulltext: Maybe<Scalars['String']>;
  similar: Maybe<PrismicTypeSimilar>;
  where: Maybe<PrismicTypeWhereAttribute>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
}


export interface QueryPrismicResolvedPreviewUrlArgs {
  documentId: Scalars['String'];
}


export interface QueryPrismicDocumentArgs {
  storeView: Maybe<Scalars['String']>;
  predicate: PrismicPredicateOneValue;
  lang: Scalars['String'];
  ref: Maybe<Scalars['String']>;
}


export interface QueryPrismicDocumentsArgs {
  storeView: Maybe<Scalars['String']>;
  predicate: PrismicPredicateManyValues;
  lang: Scalars['String'];
  ref: Maybe<Scalars['String']>;
}


export interface QueryGetProgrammableArticleArgs {
  catalogAlias: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  language?: Maybe<ConfiguratorLanguages>;
}


export interface QueryGetConfiguratorQuestionsArgs {
  configuratorId: Scalars['ID'];
}


export interface QueryGetConfiguratorQuestionAnswersArgs {
  configuratorId: Scalars['ID'];
  questionOrder: Scalars['String'];
}

export interface QuotationFinishOutput {
  __typename?: 'QuotationFinishOutput';
  cart: Cart;
}

export interface QuotePermission {
  __typename?: 'QuotePermission';
  /** Boolean if action is allowed */
  allowed: Scalars['Boolean'];
  /** Messages SAP returned */
  messages: Array<Maybe<Scalars['String']>>;
}

export interface QuotePermissions {
  __typename?: 'QuotePermissions';
  /** Check if editing address is allowed on quote according to SAP */
  addressEdit: QuotePermission;
  /** Check if anything is allowed to be changed on quote according to SAP */
  anyPermission: Scalars['Boolean'];
  /** Check if applying a giftcard is allowed on quote according to SAP */
  applyGiftCard: QuotePermission;
  /** Check if editing delivery date is allowed on quote according to SAP */
  deliveryDateEdit: QuotePermission;
  /** Check if editing cart items is allowed on quote according to SAP */
  edit: QuotePermission;
}

export interface QuoteSapDelivery {
  __typename?: 'QuoteSapDelivery';
  date: Maybe<Scalars['String']>;
  /** Delayed delivery date chosen by customer. */
  delayedDate: Maybe<Scalars['String']>;
  delayedWeekMode: Maybe<Scalars['Boolean']>;
  label: Maybe<Scalars['String']>;
  /** Nr of days after which delivery can happen. */
  time: Maybe<Scalars['Int']>;
  /** Configurable text to display based on delivery time. */
  timeString: Maybe<Scalars['String']>;
}

export interface QuoteSapInformation {
  __typename?: 'QuoteSapInformation';
  /** Returns SAP delivery information. */
  delivery: QuoteSapDelivery;
  /** Returns if SAP is currently marked as down. */
  down: Scalars['Boolean'];
  /** Returns if quote is imported from SAP order, happens in edit mode. */
  imported: Scalars['Boolean'];
  /** Returns if SAP next day delivery is relevant. */
  nextDayDeliveryRelevant: Scalars['Boolean'];
}

/** Recipients Gift Card Account */
export interface RecipientsGiftCardAccount {
  __typename?: 'RecipientsGiftCardAccount';
  email: Scalars['String'];
  name: Scalars['String'];
  storeId: Scalars['ID'];
}

export interface RecipientsGiftCardAccountInput {
  email: Scalars['String'];
  name: Scalars['String'];
  store_id: Scalars['Int'];
}

export interface Region {
  __typename?: 'Region';
  /** The two-letter code for the region, such as TX for Texas. */
  code: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id: Maybe<Scalars['Int']>;
  /** The name of the region, such as Texas. */
  name: Maybe<Scalars['String']>;
}

/** Specifies the cart from which to remove a coupon. */
export interface RemoveCouponFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
}

/** Contains details about the cart after removing a coupon. */
export interface RemoveCouponFromCartOutput {
  __typename?: 'RemoveCouponFromCartOutput';
  /** The cart after removing a coupon. */
  cart: Maybe<Cart>;
}

/** Defines the input required to run the `removeGiftCardFromCart` mutation. */
export interface RemoveGiftCardFromCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The gift card code to be removed to the cart. */
  gift_card_code: Scalars['String'];
}

/** Defines the possible output for the `removeGiftCardFromCart` mutation. */
export interface RemoveGiftCardFromCartOutput {
  __typename?: 'RemoveGiftCardFromCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Cart;
}

/** Contains the results of a request to remove an item from a gift registry. */
export interface RemoveGiftRegistryItemsOutput {
  __typename?: 'RemoveGiftRegistryItemsOutput';
  /** The gift registry after removing items. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Contains the results of a request to delete a gift registry. */
export interface RemoveGiftRegistryOutput {
  __typename?: 'RemoveGiftRegistryOutput';
  /** Indicates whether the gift registry was successfully deleted. */
  success: Scalars['Boolean'];
}

/** Contains the results of a request to delete a registrant. */
export interface RemoveGiftRegistryRegistrantsOutput {
  __typename?: 'RemoveGiftRegistryRegistrantsOutput';
  /** The gift registry after deleting registrants. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Specifies which items to remove from the cart. */
export interface RemoveItemFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
}

/** Contains details about the cart after removing an item. */
export interface RemoveItemFromCartOutput {
  __typename?: 'RemoveItemFromCartOutput';
  /** The cart after removing an item. */
  cart: Cart;
}

/** Defines which products to remove from a compare list. */
export interface RemoveProductsFromCompareListInput {
  /** An array of product IDs to remove from the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
}

/** Contains the customer's wish list and any errors encountered. */
export interface RemoveProductsFromWishlistOutput {
  __typename?: 'RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist;
}

/** Defines the tracking information to delete. */
export interface RemoveReturnTrackingInput {
  /** The unique ID for a `ReturnShippingTracking` object. */
  return_shipping_tracking_uid: Scalars['ID'];
}

/** Contains the response after deleting tracking information. */
export interface RemoveReturnTrackingOutput {
  __typename?: 'RemoveReturnTrackingOutput';
  /** Contains details about the modified return. */
  return: Maybe<Return>;
}

/** Contains the customer cart. */
export interface RemoveRewardPointsFromCartOutput {
  __typename?: 'RemoveRewardPointsFromCartOutput';
  /** The customer cart after reward points are removed. */
  cart: Cart;
}

/** Defines the input required to run the `removeStoreCreditFromCart` mutation. */
export interface RemoveStoreCreditFromCartInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
}

/** Defines the possible output for the `removeStoreCreditFromCart` mutation. */
export interface RemoveStoreCreditFromCartOutput {
  __typename?: 'RemoveStoreCreditFromCartOutput';
  /** The contents of the specified shopping cart. */
  cart: Cart;
}

/** Contains the cart and any errors after adding products. */
export interface ReorderItemsOutput {
  __typename?: 'ReorderItemsOutput';
  /** Detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
}

/** Contains information needed to start a return request. */
export interface RequestReturnInput {
  /** Text the buyer entered that describes the reason for the refund request. */
  comment_text?: Maybe<Scalars['String']>;
  /** The email address the buyer enters to receive notifications about the status of the return. */
  contact_email?: Maybe<Scalars['String']>;
  /** An array of items to be returned. */
  items: Array<Maybe<RequestReturnItemInput>>;
  /** The unique ID for a `Order` object. */
  order_uid: Scalars['ID'];
}

/** Contains details about an item to be returned. */
export interface RequestReturnItemInput {
  /** Details about a custom attribute that was entered. */
  entered_custom_attributes?: Maybe<Array<Maybe<EnteredCustomAttributeInput>>>;
  /** The unique ID for a `OrderItemInterface` object. */
  order_item_uid: Scalars['ID'];
  /** The quantity of the item to be returned. */
  quantity_to_return: Scalars['Float'];
  /**
   * An array of selected custom option IDs associated with the item to be
   * returned. For example, the IDs for the selected color and size of a
   * configurable product.
   */
  selected_custom_attributes?: Maybe<Array<Maybe<SelectedCustomAttributeInput>>>;
}

/** Contains the response to a return request. */
export interface RequestReturnOutput {
  __typename?: 'RequestReturnOutput';
  /** Details about a single return request. */
  return: Maybe<Return>;
  /** An array of return requests. */
  returns: Maybe<Returns>;
}


/** Contains the response to a return request. */
export interface RequestReturnOutputReturnsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Contains details about a return. */
export interface Return {
  __typename?: 'Return';
  /** A list of shipping carriers available for returns. */
  availableShippingCarriers: Maybe<Array<Maybe<ReturnShippingCarrier>>>;
  /** A list of comments posted for the return request. */
  comments: Maybe<Array<Maybe<ReturnComment>>>;
  /** The date the return was requested. */
  createdAt: Scalars['String'];
  /** Data from the customer who created the return request. */
  customer: ReturnCustomer;
  /** A list of items being returned. */
  items: Maybe<Array<Maybe<ReturnItem>>>;
  /** A human-readable return number. */
  number: Scalars['String'];
  /** The order associated with the return. */
  order: Maybe<CustomerOrder>;
  /** Shipping information for the return. */
  shipping: Maybe<ReturnShipping>;
  /** The status of the return request. */
  status: Maybe<ReturnStatus>;
  /** The unique ID for a `Return` object. */
  uid: Scalars['ID'];
}

/** Contains details about a return comment. */
export interface ReturnComment {
  __typename?: 'ReturnComment';
  /** The name or author who posted the comment. */
  authorName: Scalars['String'];
  /** The date and time the comment was posted. */
  createdAt: Scalars['String'];
  /** The contents of the comment. */
  text: Scalars['String'];
  /** The unique ID for a `ReturnComment` object. */
  uid: Scalars['ID'];
}

/** Contains details about a `ReturnCustomerAttribute` object. */
export interface ReturnCustomAttribute {
  __typename?: 'ReturnCustomAttribute';
  /** A description of the attribute. */
  label: Scalars['String'];
  /** The unique ID for a `ReturnCustomAttribute` object. */
  uid: Scalars['ID'];
  /** A JSON-encoded value of the attribute. */
  value: Scalars['String'];
}

/** The customer information for the return. */
export interface ReturnCustomer {
  __typename?: 'ReturnCustomer';
  /** The email address of the customer. */
  email: Scalars['String'];
  /** The first name of the customer. */
  firstname: Maybe<Scalars['String']>;
  /** The last name of the customer. */
  lastname: Maybe<Scalars['String']>;
}

/** Contains details about a product being returned. */
export interface ReturnItem {
  __typename?: 'ReturnItem';
  /** Return item custom attributes that are visible on the storefront. */
  customAttributes: Maybe<Array<Maybe<ReturnCustomAttribute>>>;
  /** Provides access to the product being returned, including information about selected and entered options. */
  orderItem: OrderItemInterface;
  /** The quantity of the item the merchant authorized to be returned. */
  quantity: Scalars['Float'];
  /** The quantity of the item requested to be returned. */
  requestQuantity: Scalars['Float'];
  /** The return status of the item. */
  status: ReturnItemStatus;
  /** The unique ID for a `ReturnItem` object. */
  uid: Scalars['ID'];
}

export enum ReturnItemStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  RECEIVED = 'RECEIVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DENIED = 'DENIED'
}

/** Contains a list of customer return requests. */
export interface Returns {
  __typename?: 'Returns';
  /** A list of return requests. */
  items: Maybe<Array<Maybe<Return>>>;
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total number of return requests. */
  totalCount: Maybe<Scalars['Int']>;
}

/** Contains details about the return shipping address. */
export interface ReturnShipping {
  __typename?: 'ReturnShipping';
  /** The merchant-defined return shipping address. */
  address: Maybe<ReturnShippingAddress>;
  /**
   * The unique ID for a `ReturnShippingTracking` object. If a single UID is
   * specified, the array contains a single tracking record. Otherwise, array
   * contains all tracking information.
   */
  tracking: Maybe<Array<Maybe<ReturnShippingTracking>>>;
}


/** Contains details about the return shipping address. */
export interface ReturnShippingTrackingArgs {
  uid: Maybe<Scalars['ID']>;
}

/** Contains details about the shipping address used for receiving returned items. */
export interface ReturnShippingAddress {
  __typename?: 'ReturnShippingAddress';
  /** The city for product returns. */
  city: Scalars['String'];
  /** The merchant's contact person. */
  contactName: Maybe<Scalars['String']>;
  /** An object that defines the country for product returns. */
  country: Country;
  /** The postal code for product returns. */
  postcode: Scalars['String'];
  /** An object that defines the state or province for product returns. */
  region: Region;
  /** The street address for product returns. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for product returns. */
  telephone: Maybe<Scalars['String']>;
}

/** Contains details about the carrier on a return. */
export interface ReturnShippingCarrier {
  __typename?: 'ReturnShippingCarrier';
  /** A description of the shipping carrier. */
  label: Scalars['String'];
  /** The unique ID for a `ReturnShippingCarrier` object assigned to the shipping carrier. */
  uid: Scalars['ID'];
}

/** Contains shipping and tracking details. */
export interface ReturnShippingTracking {
  __typename?: 'ReturnShippingTracking';
  /** Contains details of a shipping carrier. */
  carrier: ReturnShippingCarrier;
  /** Details about the status of a shipment. */
  status: Maybe<ReturnShippingTrackingStatus>;
  /** A tracking number assigned by the carrier. */
  trackingNumber: Scalars['String'];
  /** The unique ID for a `ReturnShippingTracking` object assigned to the tracking item. */
  uid: Scalars['ID'];
}

/** Contains the status of a shipment. */
export interface ReturnShippingTrackingStatus {
  __typename?: 'ReturnShippingTrackingStatus';
  /** Text that describes the status. */
  text: Scalars['String'];
  /** Indicates whether the status type is informational or an error. */
  type: ReturnShippingTrackingStatusType;
}

export enum ReturnShippingTrackingStatusType {
  INFORMATION = 'INFORMATION',
  ERROR = 'ERROR'
}

export enum ReturnStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED',
  RECEIVED = 'RECEIVED',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  APPROVED = 'APPROVED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  REJECTED = 'REJECTED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  DENIED = 'DENIED',
  PROCESSED_AND_CLOSED = 'PROCESSED_AND_CLOSED',
  CLOSED = 'CLOSED'
}

/** Contains the result of a request to revoke a customer token. */
export interface RevokeCustomerTokenOutput {
  __typename?: 'RevokeCustomerTokenOutput';
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean'];
}

/** Contains details about a customer's reward points. */
export interface RewardPoints {
  __typename?: 'RewardPoints';
  /** The current balance of reward points. */
  balance: Maybe<RewardPointsAmount>;
  /**
   * The balance history of reward points. If the ability for customers to view the
   * balance history has been disabled in the Admin, this field will be set to null.
   */
  balanceHistory: Maybe<Array<Maybe<RewardPointsBalanceHistoryItem>>>;
  /** The current exchange rates for reward points. */
  exchangeRates: Maybe<RewardPointsExchangeRates>;
  /** The subscription status of emails related to reward points. */
  subscriptionStatus: Maybe<RewardPointsSubscriptionStatus>;
}

export interface RewardPointsAmount {
  __typename?: 'RewardPointsAmount';
  /** The reward points amount in store currency. */
  money: Money;
  /** The reward points amount in points. */
  points: Scalars['Float'];
}

/** Contain details about the reward points transaction. */
export interface RewardPointsBalanceHistoryItem {
  __typename?: 'RewardPointsBalanceHistoryItem';
  /** The award points balance after the completion of the transaction. */
  balance: Maybe<RewardPointsAmount>;
  /** The reason the balance changed. */
  changeReason: Scalars['String'];
  /** The date of the transaction. */
  date: Scalars['String'];
  /** The number of points added or deducted in the transaction. */
  pointsChange: Scalars['Float'];
}

/** Lists the reward points exchange rates. The values depend on the customer group. */
export interface RewardPointsExchangeRates {
  __typename?: 'RewardPointsExchangeRates';
  /** How many points are earned for a given amount spent. */
  earning: Maybe<RewardPointsRate>;
  /** How many points must be redeemed to get a given amount of currency discount at the checkout. */
  redemption: Maybe<RewardPointsRate>;
}

/** Contains details about customer's reward points rate. */
export interface RewardPointsRate {
  __typename?: 'RewardPointsRate';
  /**
   * The money value for the exchange rate. For earnings, this is the amount spent
   * to earn the specified points. For redemption, this is the amount of money the
   * number of points represents.
   */
  currencyAmount: Scalars['Float'];
  /**
   * The number of points for an exchange rate. For earnings, this is the number of
   * points earned. For redemption, this is the number of points needed for redemption.
   */
  points: Scalars['Float'];
}

/** Indicates whether the customer subscribes to reward points emails. */
export interface RewardPointsSubscriptionStatus {
  __typename?: 'RewardPointsSubscriptionStatus';
  /** Indicates whether the customer subscribes to 'Reward points balance updates' emails. */
  balanceUpdates: RewardPointsSubscriptionStatusesEnum;
  /** Indicates whether the customer subscribes to 'Reward points expiration notifications' emails. */
  pointsExpirationNotifications: RewardPointsSubscriptionStatusesEnum;
}

export enum RewardPointsSubscriptionStatusesEnum {
  SUBSCRIBED = 'SUBSCRIBED',
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED'
}

/** Routable entities serve as the model for a rendered page. */
export interface RoutableInterface {
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
}

/** Contains details about a comment. */
export interface SalesCommentItem {
  __typename?: 'SalesCommentItem';
  /** The text of the message. */
  message: Scalars['String'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String'];
}

export interface SalesForceCustomer {
  __typename?: 'SalesForceCustomer';
  globalCustomerId: Scalars['Int'];
  originSystem: Scalars['String'];
  originSystemCustomerId: Scalars['String'];
  lastModifiedDate: Scalars['DateTime'];
}

export interface SalesItemInterface {
  __typename?: 'SalesItemInterface';
  /** The entered gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
}

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
  GLOBAL = 'GLOBAL',
  WEBSITE = 'WEBSITE',
  STORE = 'STORE'
}

/** Provides navigation for the query response. */
export interface SearchResultPageInfo {
  __typename?: 'SearchResultPageInfo';
  /** The specific page to return. */
  currentPage: Maybe<Scalars['Int']>;
  /** The maximum number of items to return per page of results. */
  pageSize: Maybe<Scalars['Int']>;
  /** The total number of pages in the response. */
  totalPages: Maybe<Scalars['Int']>;
}

/** A string that contains search suggestion */
export interface SearchSuggestion {
  __typename?: 'SearchSuggestion';
  /** The search suggestion of existing product. */
  search: Scalars['String'];
}

/** Contains details about a selected bundle option. */
export interface SelectedBundleOption {
  __typename?: 'SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the selected bundle product option. */
  label: Scalars['String'];
  /** The type of selected bundle product option. */
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>;
}

/** Contains details about a value for a selected bundle option. */
export interface SelectedBundleOptionValue {
  __typename?: 'SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String'];
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float'];
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
}

/** Contains details about a selected configurable option. */
export interface SelectedConfigurableOption {
  __typename?: 'SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurableProductOptionUid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurableProductOptionValueUid: Scalars['ID'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int'];
  /** The display text for the option. */
  optionLabel: Scalars['String'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  valueId: Scalars['Int'];
  /** The display name of the selected configurable option. */
  valueLabel: Scalars['String'];
}

/** Contains details about an attribute the buyer selected. */
export interface SelectedCustomAttributeInput {
  /** A string that identifies the selected attribute. */
  attribute_code: Scalars['String'];
  /** The unique ID for a `CustomAttribute` object of a selected custom attribute. */
  value: Scalars['ID'];
}

/** Identifies a customized product that has been placed in a cart. */
export interface SelectedCustomizableOption {
  __typename?: 'SelectedCustomizableOption';
  /**
   * The unique ID for a specific `CustomizableOptionInterface` object, such as a
   * `CustomizableFieldOption`, `CustomizableFileOption`, or
   * `CustomizableAreaOption` object.
   */
  customizableOptionUid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int'];
  /** Indicates whether the customizable option is required. */
  isRequired: Scalars['Boolean'];
  /** The display name of the selected customizable option. */
  label: Scalars['String'];
  /** A value indicating the order to display this option. */
  sortOrder: Scalars['Int'];
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String'];
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
}

/** Identifies the value of the selected customized option. */
export interface SelectedCustomizableOptionValue {
  __typename?: 'SelectedCustomizableOptionValue';
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizableOptionValueUid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int'];
  /** The display name of the selected value. */
  label: Scalars['String'];
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice;
  /** The text identifying the selected value. */
  value: Scalars['String'];
}

/** Describes the payment method the shopper selected. */
export interface SelectedPaymentMethod {
  __typename?: 'SelectedPaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  /** The purchase order number. */
  purchaseOrderNumber: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
}

/** Contains details about the selected shipping method and carrier. */
export interface SelectedShippingMethod {
  __typename?: 'SelectedShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String'];
  /** A shipping method code associated with a carrier. */
  methodCode: Scalars['String'];
  /** The label for the method code. */
  methodTitle: Scalars['String'];
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money;
}

/** Defines the referenced product and the email sender and recipients. */
export interface SendEmailToFriendInput {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int'];
  /** An array containing information about each recipient. */
  recipients: Array<Maybe<SendEmailToFriendRecipientInput>>;
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput;
}

/** Contains information about the sender and recipients. */
export interface SendEmailToFriendOutput {
  __typename?: 'SendEmailToFriendOutput';
  /** An array containing information about each recipient. */
  recipients: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  /** Information about the customer and the content of the message. */
  sender: Maybe<SendEmailToFriendSender>;
}

/** An output object that contains information about the recipient. */
export interface SendEmailToFriendRecipient {
  __typename?: 'SendEmailToFriendRecipient';
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
}

/** Contains details about a recipient. */
export interface SendEmailToFriendRecipientInput {
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
}

/** An output object that contains information about the sender. */
export interface SendEmailToFriendSender {
  __typename?: 'SendEmailToFriendSender';
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
}

/** Contains details about the sender. */
export interface SendEmailToFriendSenderInput {
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
}

/** Contains details about the configuration of the Email to a Friend feature. */
export interface SendFriendConfiguration {
  __typename?: 'SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabledForCustomers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabledForGuests: Scalars['Boolean'];
}

/** Sets the billing address. */
export interface SetBillingAddressOnCartInput {
  /** The billing address. */
  billing_address: BillingAddressInput;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
}

/** Contains details about the cart after setting the billing address. */
export interface SetBillingAddressOnCartOutput {
  __typename?: 'SetBillingAddressOnCartOutput';
  /** The cart after setting the billing address. */
  cart: Cart;
}

/** Defines the gift options applied to the cart. */
export interface SetGiftOptionsOnCartInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** Gift message details for the cart. */
  gift_message?: Maybe<GiftMessageInput>;
  /** Whether customer requested gift receipt for the cart. */
  gift_receipt_included: Scalars['Boolean'];
  /** The unique ID for a `GiftWrapping` object to be used for the cart. */
  gift_wrapping_id?: Maybe<Scalars['ID']>;
  /** Whether customer requested printed card for the cart. */
  printed_card_included: Scalars['Boolean'];
}

/** Contains the cart after gift options have been applied. */
export interface SetGiftOptionsOnCartOutput {
  __typename?: 'SetGiftOptionsOnCartOutput';
  /** The modified cart object. */
  cart: Cart;
}

/** Defines the guest email and cart. */
export interface SetGuestEmailOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The email address of the guest. */
  email: Scalars['String'];
}

/** Contains details about the cart after setting the email of a guest. */
export interface SetGuestEmailOnCartOutput {
  __typename?: 'SetGuestEmailOnCartOutput';
  /** The cart after setting the guest email. */
  cart: Cart;
}

/** Applies a payment method to the quote. */
export interface SetPaymentMethodAndPlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
}

/** Applies a payment method to the cart. */
export interface SetPaymentMethodOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
}

/** Contains details about the cart after setting the payment method. */
export interface SetPaymentMethodOnCartOutput {
  __typename?: 'SetPaymentMethodOnCartOutput';
  /** The cart after setting the payment method. */
  cart: Cart;
}

/** Specifies an array of addresses to use for shipping. */
export interface SetShippingAddressesOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping addresses. */
  shipping_addresses: Array<Maybe<ShippingAddressInput>>;
}

/** Contains details about the cart after setting the shipping addresses. */
export interface SetShippingAddressesOnCartOutput {
  __typename?: 'SetShippingAddressesOnCartOutput';
  /** The cart after setting the shipping addresses. */
  cart: Cart;
}

/** Applies one or shipping methods to the cart. */
export interface SetShippingMethodsOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping methods. */
  shipping_methods: Array<Maybe<ShippingMethodInput>>;
}

/** Contains details about the cart after setting the shipping methods. */
export interface SetShippingMethodsOnCartOutput {
  __typename?: 'SetShippingMethodsOnCartOutput';
  /** The cart after setting the shipping methods. */
  cart: Cart;
}

/** Defines a gift registry invitee. */
export interface ShareGiftRegistryInviteeInput {
  /** The email address of the gift registry invitee. */
  email: Scalars['String'];
  /** The name of the gift registry invitee. */
  name: Scalars['String'];
}

/** Contains the results of a request to share a gift registry. */
export interface ShareGiftRegistryOutput {
  __typename?: 'ShareGiftRegistryOutput';
  /** Indicates whether the gift registry was successfully shared. */
  isShared: Scalars['Boolean'];
}

/** Defines the sender of an invitation to view a gift registry. */
export interface ShareGiftRegistrySenderInput {
  /** A brief message from the sender. */
  message: Scalars['String'];
  /** The sender of the gift registry invitation. */
  name: Scalars['String'];
}

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  TOGETHER = 'TOGETHER',
  SEPARATELY = 'SEPARATELY'
}

export interface ShipmentItem extends ShipmentItemInterface {
  __typename?: 'ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
}

/** Order shipment item details. */
export interface ShipmentItemInterface {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float'];
}

/** Contains order shipment tracking details. */
export interface ShipmentTracking {
  __typename?: 'ShipmentTracking';
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment. */
  number: Maybe<Scalars['String']>;
  /** The shipment tracking title. */
  title: Scalars['String'];
}

/** Defines a single shipping address. */
export interface ShippingAddressInput {
  /** Defines a shipping address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Text provided by the shopper. */
  customer_notes?: Maybe<Scalars['String']>;
}

/** Contains shipping addresses and methods. */
export interface ShippingCartAddress extends CartAddressInterface {
  __typename?: 'ShippingCartAddress';
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** @deprecated Use `cart_items_v2` instead. */
  cartItems: Maybe<Array<Maybe<CartItemQuantity>>>;
  /** An array that lists the items in the cart. */
  cartItemsV2: Maybe<Array<Maybe<CartItemInterface>>>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** Text provided by the shopper. */
  customerNotes: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  /** The gender_address attribute value. */
  genderAddress: Maybe<Scalars['Int']>;
  /** Returns whether the shipping address is the dummy address. */
  isDummyAddress: Maybe<Scalars['Boolean']>;
  /** @deprecated This information should not be exposed on the frontend. */
  itemsWeight: Maybe<Scalars['Float']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']>;
  /** Extra questions asked for Swiss Sense. */
  questions: AddressQuestions;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** Returns whether shipping is the same as billing. */
  sameAsBilling: Maybe<Scalars['Boolean']>;
  /** An object that describes the selected shipping method. */
  selectedShippingMethod: Maybe<SelectedShippingMethod>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']>;
}

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export interface ShippingDiscount {
  __typename?: 'ShippingDiscount';
  /** The amount of the discount. */
  amount: Money;
}

/** Contains details about shipping and handling costs. */
export interface ShippingHandling {
  __typename?: 'ShippingHandling';
  /** The shipping amount, excluding tax. */
  amountExcludingTax: Maybe<Money>;
  /** The shipping amount, including tax. */
  amountIncludingTax: Maybe<Money>;
  /** The applied discounts to the shipping. */
  discounts: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Details about taxes applied for shipping. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping. */
  totalAmount: Money;
}

/** Defines the shipping carrier and method. */
export interface ShippingMethodInput {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String'];
  /**
   * A string that indicates which service a commercial carrier will use to ship
   * items. For offline delivery methods, this value is similar to the label
   * displayed on the checkout page.
   */
  method_code: Scalars['String'];
}

/** An implementation for simple product cart items. */
export interface SimpleCartItem extends CartItemInterface {
  __typename?: 'SimpleCartItem';
  /** The list of available gift wrapping options for the cart item. */
  availableGiftWrapping: Array<Maybe<GiftWrapping>>;
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** The selected gift wrapping for the cart item. */
  giftWrapping: Maybe<GiftWrapping>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** Extends cart item response data */
  teleconnect: Maybe<TeleConnectCartItem>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProduct extends ProductInterface, RoutableInterface, PhysicalProductInterface, CustomizableProductInterface {
  __typename?: 'SimpleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']>;
}


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Defines a single product to add to the cart. */
export interface SimpleProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
}

/** Contains a simple product wish list item. */
export interface SimpleWishlistItem extends WishlistItemInterface {
  __typename?: 'SimpleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

export interface SmtpBestsellersOutput {
  __typename?: 'SmtpBestsellersOutput';
  /** Bestsellers Product Information. */
  mpBestsellers: Maybe<Array<Maybe<BestsellersProductOutput>>>;
}

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

/** Defines a possible sort field. */
export interface SortField {
  __typename?: 'SortField';
  /** The label of the sort field. */
  label: Maybe<Scalars['String']>;
  /** The attribute code of the sort field. */
  value: Maybe<Scalars['String']>;
}

/** Contains a default value for sort fields and all available sort fields. */
export interface SortFields {
  __typename?: 'SortFields';
  /** The default sort field value. */
  default: Maybe<Scalars['String']>;
  /** An array of possible sort fields. */
  options: Maybe<Array<Maybe<SortField>>>;
}

export type StoreBody = StoreBodyHeader | StoreBodySpecialOpenInfo | StoreBodySpecialClosedInfo | StoreBodyUsp | StoreBodyFacility | StoreBodyTextCenter | StoreBodyBannerWithText | StoreBodyBannerBottom | StoreBodyStreetview | StoreBodyTopContentBanner | StoreBodyCountdownBanner;

export interface StoreBodyBannerBottom {
  __typename?: 'StoreBodyBannerBottom';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyBannerBottomPrimary>;
}

export interface StoreBodyBannerBottomPrimary {
  __typename?: 'StoreBodyBannerBottomPrimary';
  link: Maybe<PrismicHyperlink>;
  image: Maybe<PrismicImageType>;
}

export interface StoreBodyBannerWithText {
  __typename?: 'StoreBodyBannerWithText';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyBannerWithTextPrimary>;
}

export interface StoreBodyBannerWithTextPrimary {
  __typename?: 'StoreBodyBannerWithTextPrimary';
  text1: Maybe<Scalars['Json']>;
  image1: Maybe<Scalars['Json']>;
  text2: Maybe<Scalars['Json']>;
  image2: Maybe<Scalars['Json']>;
}

export interface StoreBodyCountdownBanner {
  __typename?: 'StoreBodyCountdownBanner';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyCountdownBannerPrimary>;
}

export interface StoreBodyCountdownBannerPrimary {
  __typename?: 'StoreBodyCountdownBannerPrimary';
  backgroundColor: Maybe<Scalars['String']>;
  textTitle: Maybe<Scalars['String']>;
  titleColor: Maybe<Scalars['String']>;
  textSubtitle: Maybe<Scalars['String']>;
  subtitleColor: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['DateTime']>;
  countdownTileColor: Maybe<Scalars['String']>;
  countdownTextColor: Maybe<Scalars['String']>;
  countdownSubtextColor: Maybe<Scalars['String']>;
}

export interface StoreBodyFacility {
  __typename?: 'StoreBodyFacility';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyFacilityPrimary>;
  fields: Maybe<Array<StoreBodyFacilityFields>>;
}

export interface StoreBodyFacilityFields {
  __typename?: 'StoreBodyFacilityFields';
  image: Maybe<PrismicImageType>;
  text: Maybe<Scalars['Json']>;
}

export interface StoreBodyFacilityPrimary {
  __typename?: 'StoreBodyFacilityPrimary';
  title: Maybe<Scalars['String']>;
}

export interface StoreBodyHeader {
  __typename?: 'StoreBodyHeader';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyHeaderPrimary>;
}

export interface StoreBodyHeaderPrimary {
  __typename?: 'StoreBodyHeaderPrimary';
  image: Maybe<PrismicImageType>;
}

export interface StoreBodySpecialClosedInfo {
  __typename?: 'StoreBodySpecialClosedInfo';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodySpecialClosedInfoPrimary>;
  fields: Maybe<Array<StoreBodySpecialClosedInfoFields>>;
}

export interface StoreBodySpecialClosedInfoFields {
  __typename?: 'StoreBodySpecialClosedInfoFields';
  content: Maybe<Scalars['String']>;
  startDateTime: Maybe<Scalars['DateTime']>;
  endDateTime: Maybe<Scalars['DateTime']>;
}

export interface StoreBodySpecialClosedInfoPrimary {
  __typename?: 'StoreBodySpecialClosedInfoPrimary';
  image: Maybe<PrismicImageType>;
}

export interface StoreBodySpecialOpenInfo {
  __typename?: 'StoreBodySpecialOpenInfo';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodySpecialOpenInfoPrimary>;
  fields: Maybe<Array<StoreBodySpecialOpenInfoFields>>;
}

export interface StoreBodySpecialOpenInfoFields {
  __typename?: 'StoreBodySpecialOpenInfoFields';
  content: Maybe<Scalars['String']>;
  startDateTime: Maybe<Scalars['DateTime']>;
  endDateTime: Maybe<Scalars['DateTime']>;
}

export interface StoreBodySpecialOpenInfoPrimary {
  __typename?: 'StoreBodySpecialOpenInfoPrimary';
  image: Maybe<PrismicImageType>;
}

export interface StoreBodyStreetview {
  __typename?: 'StoreBodyStreetview';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyStreetviewPrimary>;
}

export interface StoreBodyStreetviewPrimary {
  __typename?: 'StoreBodyStreetviewPrimary';
  povHeading: Maybe<Scalars['String']>;
  povPitch: Maybe<Scalars['String']>;
  geopoint: Maybe<Scalars['Json']>;
}

export interface StoreBodyTextCenter {
  __typename?: 'StoreBodyTextCenter';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyTextCenterPrimary>;
}

export interface StoreBodyTextCenterPrimary {
  __typename?: 'StoreBodyTextCenterPrimary';
  text: Maybe<Scalars['Json']>;
}

export interface StoreBodyTopContentBanner {
  __typename?: 'StoreBodyTopContentBanner';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyTopContentBannerPrimary>;
}

export interface StoreBodyTopContentBannerPrimary {
  __typename?: 'StoreBodyTopContentBannerPrimary';
  link: Maybe<PrismicHyperlink>;
  image: Maybe<PrismicImageType>;
}

export interface StoreBodyUsp {
  __typename?: 'StoreBodyUsp';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StoreBodyUspPrimary>;
  fields: Maybe<Array<StoreBodyUspFields>>;
}

export interface StoreBodyUspFields {
  __typename?: 'StoreBodyUspFields';
  text: Maybe<Scalars['Json']>;
}

export interface StoreBodyUspPrimary {
  __typename?: 'StoreBodyUspPrimary';
  title: Maybe<Scalars['String']>;
}

/** The type contains information about a store config */
export interface StoreConfig {
  __typename?: 'StoreConfig';
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absoluteFooter: Maybe<Scalars['String']>;
  /** Adyen front end resources region. */
  adyenCheckoutFrontendRegion: Maybe<Scalars['String']>;
  /** Adyen client key for LIVE environment. */
  adyenClientKeyLive: Maybe<Scalars['String']>;
  /** Adyen client key for TEST environment. */
  adyenClientKeyTest: Maybe<Scalars['String']>;
  /** Adyen demo mode enabled (TEST). */
  adyenDemoMode: Maybe<Scalars['Boolean']>;
  /** Adyen card holder's name is visible. */
  adyenHasHolderName: Maybe<Scalars['Boolean']>;
  /** Adyen card holder's name is required. */
  adyenHolderNameRequired: Maybe<Scalars['Boolean']>;
  /** Adyen Return path in case of error */
  adyenReturnPathError: Maybe<Scalars['String']>;
  /** Adyen Render Mode for Payment Method. */
  adyenTitleRenderer: Maybe<Scalars['String']>;
  /** Indicates if the gift sender has the option to send a gift receipt. Possible values: 1 (Yes) and 0 (No). */
  allowGiftReceipt: Maybe<Scalars['String']>;
  /** Indicates whether gift wrapping can be added for the entire order. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrder: Maybe<Scalars['String']>;
  /** Indicates whether gift wrapping can be added for individual order items. Possible values: 1 (Yes) and 0 (No). */
  allowGiftWrappingOnOrderItems: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allowGuestsToWriteProductReviews: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allowItems: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allowOrder: Maybe<Scalars['String']>;
  /** Indicates if a printed card can accompany an order. Possible values: 1 (Yes) and 0 (No). */
  allowPrintedCard: Maybe<Scalars['String']>;
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocompleteOnStorefront: Maybe<Scalars['Boolean']>;
  /** The base currency code. */
  baseCurrencyCode: Maybe<Scalars['String']>;
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  baseLinkUrl: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of media files. */
  baseMediaUrl: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of static view files. */
  baseStaticUrl: Maybe<Scalars['String']>;
  /** The store’s fully-qualified base URL. */
  baseUrl: Maybe<Scalars['String']>;
  /** Indicates if gift wrapping prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartGiftWrapping: Maybe<Scalars['String']>;
  /** Indicates if printed card prices are displayed on the Shopping Cart page. Possible values: 1 (Yes) and 0 (No). */
  cartPrintedCard: Maybe<Scalars['String']>;
  /** The default sort order of the search results list. */
  catalogDefaultSortBy: Maybe<Scalars['String']>;
  /**
   * Corresponds to the 'Display Prices In Product Lists' field in the Admin. It
   * indicates how FPT information is displayed on category pages.
   */
  categoryFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>;
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  categoryUrlSuffix: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  checkMoneyOrderEnableForSpecificCountries: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Check/Money Order payment method is enabled. */
  checkMoneyOrderEnabled: Maybe<Scalars['Boolean']>;
  /** The name of the party to whom the check must be payable. */
  checkMoneyOrderMakeCheckPayableTo: Maybe<Scalars['String']>;
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMaxOrderTotal: Maybe<Scalars['String']>;
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMinOrderTotal: Maybe<Scalars['String']>;
  /** The status of new orders placed using the Check/Money Order payment method. */
  checkMoneyOrderNewOrderStatus: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  checkMoneyOrderPaymentFromSpecificCountries: Maybe<Scalars['String']>;
  /** The full street address or PO Box where the checks are mailed. */
  checkMoneyOrderSendCheckTo: Maybe<Scalars['String']>;
  /**
   * A number indicating the position of the Check/Money Order payment method in
   * the list of available payment methods during checkout.
   */
  checkMoneyOrderSortOrder: Maybe<Scalars['Int']>;
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  checkMoneyOrderTitle: Maybe<Scalars['String']>;
  /** Emico checkout opt out enabled config */
  checkoutOptOutEnabled: Maybe<Scalars['Boolean']>;
  /** The name of the CMS page that identifies the home page for the store. */
  cmsHomePage: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cmsNoCookies: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cmsNoRoute: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code: Maybe<Scalars['String']>;
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurableThumbnailSource: Maybe<Scalars['String']>;
  /** The copyright statement that appears at the bottom of each page. */
  copyright: Maybe<Scalars['String']>;
  /**
   * The description that provides a summary of your site for search engine
   * listings. It should not be more than 160 characters in length.
   */
  defaultDescription: Maybe<Scalars['String']>;
  /** The default display currency code. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']>;
  /** A series of keywords that describe your store, each separated by a comma. */
  defaultKeywords: Maybe<Scalars['String']>;
  /** The title that appears at the title bar of each page when viewed in a browser. */
  defaultTitle: Maybe<Scalars['String']>;
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice: Maybe<Scalars['Int']>;
  /** Indicates whether customers can have multiple wish lists. Possible values: 1 (Yes) and 0 (No). */
  enableMultipleWishlists: Maybe<Scalars['String']>;
  /** The landing page that is associated with the base URL. */
  front: Maybe<Scalars['String']>;
  /** JSON encoded ga4data object */
  ga4PageData: Maybe<Scalars['String']>;
  /** Default shipping country */
  generalCountryDefault: Maybe<Scalars['String']>;
  /** The default number of products per page in Grid View. */
  gridPerPage: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in Grid View. */
  gridPerPageValues: Maybe<Scalars['String']>;
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  headIncludes: Maybe<Scalars['String']>;
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  headShortcutIcon: Maybe<Scalars['String']>;
  /** The path to the logo that appears in the header. */
  headerLogoSrc: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id: Maybe<Scalars['Int']>;
  /** Indicates whether the store view has been designated as the default within the store group. */
  isDefaultStore: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website. */
  isDefaultStoreGroup: Maybe<Scalars['Boolean']>;
  /** The format of the search results list. */
  listMode: Maybe<Scalars['String']>;
  /** The default number of products per page in List View. */
  listPerPage: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in List View. */
  listPerPageValues: Maybe<Scalars['String']>;
  /** The store locale. */
  locale: Maybe<Scalars['String']>;
  /** The Alt text that is associated with the logo. */
  logoAlt: Maybe<Scalars['String']>;
  /** The height of the logo image, in pixels. */
  logoHeight: Maybe<Scalars['Int']>;
  /** The width of the logo image, in pixels. */
  logoWidth: Maybe<Scalars['Int']>;
  /** Indicates whether reward points functionality is enabled. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralIsEnabled: Maybe<Scalars['String']>;
  /** Indicates whether reward points functionality is enabled on the storefront. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralIsEnabledOnFront: Maybe<Scalars['String']>;
  /** The minimum point balance customers must have before they can redeem them. A null value indicates no minimum. */
  magentoRewardGeneralMinPointsBalance: Maybe<Scalars['String']>;
  /** When enabled, customers can see a detailed history of their reward points. Possible values: 1 (Enabled) and 0 (Disabled). */
  magentoRewardGeneralPublishHistory: Maybe<Scalars['String']>;
  /** The number of points for a referral when an invitee registers on the site. */
  magentoRewardPointsInvitationCustomer: Maybe<Scalars['String']>;
  /** The maximum number of registration referrals that will qualify for rewards. A null value indicates no limit. */
  magentoRewardPointsInvitationCustomerLimit: Maybe<Scalars['String']>;
  /** The number of points for a referral, when an invitee places their first order on the site. */
  magentoRewardPointsInvitationOrder: Maybe<Scalars['String']>;
  /**
   * The number of order conversions that can earn points for the customer who
   * sends the invitation. A null value indicates no limit.
   */
  magentoRewardPointsInvitationOrderLimit: Maybe<Scalars['String']>;
  /** The number of points earned by registered customers who subscribe to a newsletter. */
  magentoRewardPointsNewsletter: Maybe<Scalars['String']>;
  /**
   * Indicates customers earn points for shopping according to the reward point
   * exchange rate. In Luma, this also controls whether to show a message in the
   * shopping cart about the rewards points earned for the purchase, as well as the
   * customer’s current reward point balance.
   */
  magentoRewardPointsOrder: Maybe<Scalars['String']>;
  /** The number of points customer gets for registering. */
  magentoRewardPointsRegister: Maybe<Scalars['String']>;
  /** The number of points for writing a review. */
  magentoRewardPointsReview: Maybe<Scalars['String']>;
  /** The maximum number of reviews that will qualify for the rewards. A null value indicates no limit. */
  magentoRewardPointsReviewLimit: Maybe<Scalars['String']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magentoWishlistGeneralIsEnabled: Maybe<Scalars['String']>;
  /** If multiple wish lists are enabled, the maximum number of wish lists the customer can have. */
  maximumNumberOfWishlists: Maybe<Scalars['String']>;
  /** The minimum number of characters required for a valid password. */
  minimumPasswordLength: Maybe<Scalars['String']>;
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  noRoute: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  paymentPayflowproCcVaultActive: Maybe<Scalars['String']>;
  /** The default price of a printed card that accompanies an order. */
  printedCardPrice: Maybe<Scalars['String']>;
  /**
   * Corresponds to the 'Display Prices On Product View Page' field in the Admin.
   * It indicates how FPT information is displayed on product pages.
   */
  productFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  productReviewsEnabled: Maybe<Scalars['String']>;
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  productUrlSuffix: Maybe<Scalars['String']>;
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  requiredCharacterClassesNumber: Maybe<Scalars['String']>;
  /** Indicates whether RMA is enabled on the storefront. Possible values: enabled/disabled. */
  returnsEnabled: Scalars['String'];
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  rootCategoryId: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  rootCategoryUid: Maybe<Scalars['ID']>;
  /**
   * Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It
   * indicates how FPT information is displayed on cart, checkout, and order pages.
   */
  salesFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates if gift wrapping prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesGiftWrapping: Maybe<Scalars['String']>;
  /** Indicates if printed card prices are displayed on the Orders page. Possible values: 1 (Yes) and 0 (No). */
  salesPrintedCard: Maybe<Scalars['String']>;
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secureBaseLinkUrl: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of media files. */
  secureBaseMediaUrl: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secureBaseStaticUrl: Maybe<Scalars['String']>;
  /** The store’s fully-qualified secure base URL. */
  secureBaseUrl: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  sendFriend: Maybe<SendFriendConfiguration>;
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  showCmsBreadcrumbs: Maybe<Scalars['Int']>;
  /**
   * The unique ID of the store view. In the Admin, this is called the Store View
   * Code. When making a GraphQL call, assign this value to the `Store` header to
   * provide the scope.
   */
  storeCode: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  storeGroupCode: Maybe<Scalars['ID']>;
  /** The label assigned to the store group. */
  storeGroupName: Maybe<Scalars['String']>;
  /** The label assigned to the store view. */
  storeName: Maybe<Scalars['String']>;
  /** The store view sort order. */
  storeSortOrder: Maybe<Scalars['Int']>;
  /** Teleconnect show from price */
  teleconnectShowFromPrice: Maybe<Scalars['Boolean']>;
  /** The time zone of the store. */
  timezone: Maybe<Scalars['String']>;
  /** A prefix that appears before the title to create a two- or three-part title. */
  titlePrefix: Maybe<Scalars['String']>;
  /** The character that separates the category name and subcategory in the browser title bar. */
  titleSeparator: Maybe<Scalars['String']>;
  /** A suffix that appears after the title to create a two- or three-part title. */
  titleSuffix: Maybe<Scalars['String']>;
  /** Indicates whether the store code should be used in the URL. */
  useStoreInUrl: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website. */
  websiteCode: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  websiteId: Maybe<Scalars['Int']>;
  /** The label assigned to the website. */
  websiteName: Maybe<Scalars['String']>;
  /** The unit of weight. */
  weightUnit: Maybe<Scalars['String']>;
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome: Maybe<Scalars['String']>;
  /** Indicates whether only specific countries can use this payment method. */
  zeroSubtotalEnableForSpecificCountries: Maybe<Scalars['Boolean']>;
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zeroSubtotalEnabled: Maybe<Scalars['Boolean']>;
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zeroSubtotalNewOrderStatus: Maybe<Scalars['String']>;
  /**
   * When the new order status is 'Processing', this can be set to
   * `authorize_capture` to automatically invoice all items that have a zero balance.
   */
  zeroSubtotalPaymentAction: Maybe<Scalars['String']>;
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zeroSubtotalPaymentFromSpecificCountries: Maybe<Scalars['String']>;
  /**
   * A number indicating the position of the Zero Subtotal payment method in the
   * list of available payment methods during checkout.
   */
  zeroSubtotalSortOrder: Maybe<Scalars['Int']>;
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zeroSubtotalTitle: Maybe<Scalars['String']>;
}

/** Indicates where an attribute can be displayed. */
export interface StorefrontProperties {
  __typename?: 'StorefrontProperties';
  /** The relative position of the attribute in the layered navigation block. */
  position: Maybe<Scalars['Int']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  useInLayeredNavigation: Maybe<UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings. */
  useInProductListing: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  useInSearchResultsLayeredNavigation: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute is displayed on product pages. */
  visibleOnCatalogPages: Maybe<Scalars['Boolean']>;
}

export type StorelocatorBody = StorelocatorBodyBanner | StorelocatorBodyStoresToExclude | StorelocatorBodyTopContentBanner | StorelocatorBodyStoresToLocate | StorelocatorBodySpecialOpenInfo | StorelocatorBodySpecialClosedInfo | StorelocatorBodyCountdownBanner;

export interface StorelocatorBodyBanner {
  __typename?: 'StorelocatorBodyBanner';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StorelocatorBodyBannerPrimary>;
}

export interface StorelocatorBodyBannerPrimary {
  __typename?: 'StorelocatorBodyBannerPrimary';
  country: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Float']>;
  gtmCreative: Maybe<Scalars['String']>;
  link: Maybe<PrismicHyperlink>;
  imageLarge: Maybe<Scalars['Json']>;
  imageMedium: Maybe<Scalars['Json']>;
  imageSmall: Maybe<Scalars['Json']>;
}

export interface StorelocatorBodyCountdownBanner {
  __typename?: 'StorelocatorBodyCountdownBanner';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StorelocatorBodyCountdownBannerPrimary>;
}

export interface StorelocatorBodyCountdownBannerPrimary {
  __typename?: 'StorelocatorBodyCountdownBannerPrimary';
  country: Maybe<Scalars['String']>;
  storesToExclude: Maybe<Scalars['String']>;
  visibleOnStorelocator: Maybe<Scalars['Boolean']>;
  backgroundColor: Maybe<Scalars['String']>;
  textTitle: Maybe<Scalars['String']>;
  titleColor: Maybe<Scalars['String']>;
  textSubtitle: Maybe<Scalars['String']>;
  subtitleColor: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['DateTime']>;
  countdownTileColor: Maybe<Scalars['String']>;
  countdownTextColor: Maybe<Scalars['String']>;
  countdownSubtextColor: Maybe<Scalars['String']>;
}

export interface StorelocatorBodySpecialClosedInfo {
  __typename?: 'StorelocatorBodySpecialClosedInfo';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StorelocatorBodySpecialClosedInfoPrimary>;
  fields: Maybe<Array<StorelocatorBodySpecialClosedInfoFields>>;
}

export interface StorelocatorBodySpecialClosedInfoFields {
  __typename?: 'StorelocatorBodySpecialClosedInfoFields';
  text: Maybe<Scalars['String']>;
  startDateTime: Maybe<Scalars['DateTime']>;
  endDateTime: Maybe<Scalars['DateTime']>;
}

export interface StorelocatorBodySpecialClosedInfoPrimary {
  __typename?: 'StorelocatorBodySpecialClosedInfoPrimary';
  visibleOnNlStores: Maybe<Scalars['Boolean']>;
  visibleOnBeStores: Maybe<Scalars['Boolean']>;
  visibleOnDeStores: Maybe<Scalars['Boolean']>;
  visibleOnAtStores: Maybe<Scalars['Boolean']>;
  storesToInclude: Maybe<Scalars['String']>;
  image: Maybe<PrismicImageType>;
}

export interface StorelocatorBodySpecialOpenInfo {
  __typename?: 'StorelocatorBodySpecialOpenInfo';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StorelocatorBodySpecialOpenInfoPrimary>;
  fields: Maybe<Array<StorelocatorBodySpecialOpenInfoFields>>;
}

export interface StorelocatorBodySpecialOpenInfoFields {
  __typename?: 'StorelocatorBodySpecialOpenInfoFields';
  text: Maybe<Scalars['String']>;
  startDateTime: Maybe<Scalars['DateTime']>;
  endDateTime: Maybe<Scalars['DateTime']>;
}

export interface StorelocatorBodySpecialOpenInfoPrimary {
  __typename?: 'StorelocatorBodySpecialOpenInfoPrimary';
  visibleOnNlStores: Maybe<Scalars['Boolean']>;
  visibleOnBeStores: Maybe<Scalars['Boolean']>;
  visibleOnDeStores: Maybe<Scalars['Boolean']>;
  visibleOnAtStores: Maybe<Scalars['Boolean']>;
  storesToInclude: Maybe<Scalars['String']>;
  image: Maybe<PrismicImageType>;
}

export interface StorelocatorBodyStoresToExclude {
  __typename?: 'StorelocatorBodyStoresToExclude';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  fields: Maybe<Array<StorelocatorBodyStoresToExcludeFields>>;
}

export interface StorelocatorBodyStoresToExcludeFields {
  __typename?: 'StorelocatorBodyStoresToExcludeFields';
  storecode: Maybe<Scalars['String']>;
}

export interface StorelocatorBodyStoresToLocate {
  __typename?: 'StorelocatorBodyStoresToLocate';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  fields: Maybe<Array<StorelocatorBodyStoresToLocateFields>>;
}

export interface StorelocatorBodyStoresToLocateFields {
  __typename?: 'StorelocatorBodyStoresToLocateFields';
  locationName: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
}

export interface StorelocatorBodyTopContentBanner {
  __typename?: 'StorelocatorBodyTopContentBanner';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<StorelocatorBodyTopContentBannerPrimary>;
}

export interface StorelocatorBodyTopContentBannerPrimary {
  __typename?: 'StorelocatorBodyTopContentBannerPrimary';
  country: Maybe<Scalars['String']>;
  storesToExclude: Maybe<Scalars['String']>;
  link: Maybe<PrismicHyperlink>;
  image: Maybe<PrismicImageType>;
}

export type SubmenuBody = SubmenuBodySubmenuSection | SubmenuBodyMenuVisual | SubmenuBodyAccessoryTile;

export interface SubmenuBodyAccessoryTile {
  __typename?: 'SubmenuBodyAccessoryTile';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<SubmenuBodyAccessoryTilePrimary>;
}

export interface SubmenuBodyAccessoryTilePrimary {
  __typename?: 'SubmenuBodyAccessoryTilePrimary';
  tileTitle: Maybe<Scalars['String']>;
  tileLink: Maybe<PrismicHyperlink>;
  tileLinkTitle: Maybe<Scalars['String']>;
  tileImage: Maybe<Scalars['Json']>;
}

export interface SubmenuBodyMenuVisual {
  __typename?: 'SubmenuBodyMenuVisual';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<SubmenuBodyMenuVisualPrimary>;
}

export interface SubmenuBodyMenuVisualPrimary {
  __typename?: 'SubmenuBodyMenuVisualPrimary';
  image: Maybe<PrismicImageType>;
  link: Maybe<PrismicHyperlink>;
  button: Maybe<Scalars['Boolean']>;
}

export interface SubmenuBodySubmenuSection {
  __typename?: 'SubmenuBodySubmenuSection';
  type: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  primary: Maybe<SubmenuBodySubmenuSectionPrimary>;
  fields: Maybe<Array<SubmenuBodySubmenuSectionFields>>;
}

export interface SubmenuBodySubmenuSectionFields {
  __typename?: 'SubmenuBodySubmenuSectionFields';
  link: Maybe<PrismicHyperlink>;
  linkTitle: Maybe<Scalars['String']>;
  submenuKey: Maybe<Scalars['String']>;
  linkHighlighted: Maybe<Scalars['Boolean']>;
}

export interface SubmenuBodySubmenuSectionPrimary {
  __typename?: 'SubmenuBodySubmenuSectionPrimary';
  title: Maybe<Scalars['String']>;
  sectionKey: Maybe<Scalars['String']>;
}

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export interface SubscribeEmailToNewsletterOutput {
  __typename?: 'SubscribeEmailToNewsletterOutput';
  /** The status of the subscription request. */
  status: Maybe<SubscriptionStatusesEnum>;
}

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  NOT_ACTIVE = 'NOT_ACTIVE',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  UNCONFIRMED = 'UNCONFIRMED'
}

/** Describes the swatch type and a value. */
export interface SwatchData {
  __typename?: 'SwatchData';
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type: Maybe<Scalars['String']>;
  /** The value for the swatch item. It could be text or an image link. */
  value: Maybe<Scalars['String']>;
}

export interface SwatchDataInterface {
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']>;
}

export interface SwatchLayerFilterItem extends LayerFilterItemInterface, SwatchLayerFilterItemInterface {
  __typename?: 'SwatchLayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']>;
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']>;
}

export interface SwatchLayerFilterItemInterface {
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>;
}

/** Contains tax item details. */
export interface TaxItem {
  __typename?: 'TaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The rate used to calculate the tax. */
  rate: Scalars['Float'];
  /** A title that describes the tax. */
  title: Scalars['String'];
}

export interface TeleConnectAnswer {
  __typename?: 'TeleConnectAnswer';
  id: Scalars['ID'];
  ean: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  consumerPrice: Scalars['Float'];
  mediaRecord: Maybe<TeleConnectMediaRecord>;
  imageUrl: Maybe<Scalars['String']>;
}

export interface TeleConnectArticle {
  __typename?: 'TeleConnectArticle';
  id: Scalars['ID'];
  catalog: Scalars['Int'];
  catalogAlias: Scalars['String'];
  catalogVersion: Scalars['Int'];
  program: Scalars['Int'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  ean: Scalars['String'];
  consumerPrice: Scalars['Float'];
  dealerPrice: Scalars['Float'];
  description: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Int']>;
  depth: Maybe<Scalars['Int']>;
  width: Maybe<Scalars['Int']>;
  imageUrl: Maybe<Scalars['String']>;
  imageThumbnailUrl: Maybe<Scalars['String']>;
  consumerFromPrice: Scalars['Float'];
  availableLanguages: Array<Maybe<Scalars['String']>>;
  catalogOwnerId: Scalars['Int'];
  mediaRecord: Maybe<TeleConnectMediaRecord>;
}

export interface TeleConnectCartItem {
  __typename?: 'TeleConnectCartItem';
  additionalOptions: Maybe<Array<Maybe<AdditionalOption>>>;
  teleconnectConfigEan: Maybe<Scalars['String']>;
}

export interface TeleConnectConfiguration {
  __typename?: 'TeleConnectConfiguration';
  id: Scalars['ID'];
  articleName: Scalars['String'];
  articleCatalogName: Scalars['String'];
  ean: Scalars['String'];
  oldEan: Maybe<Scalars['String']>;
  articleEan: Scalars['String'];
  thumbnail: Scalars['String'];
  category: Scalars['String'];
  catalogAlias: Scalars['String'];
  catalogVersion: Scalars['Int'];
  version: Maybe<Scalars['Int']>;
  configurationDate: Scalars['String'];
  quantity: Scalars['Int'];
  consumerPrice: Scalars['String'];
  supplierPrice: Scalars['String'];
  dealerPrice: Scalars['String'];
  priceIncludingDiscount: Maybe<Scalars['String']>;
  vatAmount: Maybe<Scalars['String']>;
  vatPercentage: Maybe<Scalars['String']>;
  costPercentage: Maybe<Scalars['String']>;
  discountPercentage: Maybe<Scalars['String']>;
  remark: Maybe<Scalars['String']>;
  creator: Scalars['Int'];
  configurationdetailSet: Maybe<Array<Maybe<TeleConnectionConfigurationDetailSet>>>;
  hash: Scalars['String'];
}

export interface TeleConnectConfigurator {
  __typename?: 'TeleConnectConfigurator';
  id: Scalars['Int'];
  article: TeleConnectArticle;
}

export interface TeleConnectionConfigurationDetailSet {
  __typename?: 'TeleConnectionConfigurationDetailSet';
  id: Scalars['ID'];
  vraagnummer: Scalars['String'];
  visible: Maybe<Scalars['Boolean']>;
  visibleInSummary: Scalars['Boolean'];
  articleName: Scalars['String'];
  articleEan: Scalars['String'];
  answerEan: Scalars['String'];
  answerString: Scalars['String'];
  questionType: Scalars['Int'];
  catalogAnswerString: Scalars['String'];
  isArticle: Scalars['Boolean'];
  questionEan: Scalars['String'];
  questionString: Scalars['String'];
  catalogQuestionString: Scalars['String'];
  parameter: Maybe<Scalars['String']>;
  alfaParameter: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  consumerPrice: Scalars['String'];
  supplierPrice: Scalars['String'];
  dealerPrice: Scalars['String'];
  internalInfo: Scalars['String'];
  oldReference: Maybe<Scalars['String']>;
  configuration: Scalars['Int'];
}

export interface TeleConnectMediaRecord {
  __typename?: 'TeleConnectMediaRecord';
  longDescription: Scalars['String'];
  mainImageUrl: Maybe<Scalars['String']>;
  thumbnailImageUrl: Maybe<Scalars['String']>;
  availableOn: Maybe<Scalars['String']>;
  pagina: Scalars['Int'];
  questionHandling: Scalars['String'];
  internalInfo: Scalars['String'];
  image2: Maybe<Scalars['String']>;
  image3: Maybe<Scalars['String']>;
  image4: Maybe<Scalars['String']>;
  expiviid: Maybe<Scalars['Int']>;
  question: Maybe<Scalars['String']>;
  questionOcp: Maybe<Scalars['String']>;
  questionCode: Maybe<Scalars['String']>;
  crosssellCatalogs: Maybe<Scalars['String']>;
  crosssellEans: Maybe<Scalars['String']>;
  layout: Maybe<Scalars['String']>;
  suggestion: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  filter: Maybe<Array<Scalars['String']>>;
  category: Maybe<Array<Scalars['String']>>;
  hasVisualRender: Scalars['Boolean'];
  microcopy: Maybe<Array<Scalars['String']>>;
  usps: Maybe<Array<Scalars['String']>>;
  isHighlighted: Scalars['Boolean'];
  /** If it is visible in the summary for OCP */
  isVisible: Scalars['Boolean'];
  /** If it is visible in the summary for Web */
  isVisibleOnline: Scalars['Boolean'];
  /** If it is visible in the summary for OCP */
  isVisibleInSummary: Scalars['Boolean'];
  /** If it is visible in the summary for Web. */
  isVisibleInSummaryOnline: Scalars['Boolean'];
  /** If it is always visible in the summary for OCP. */
  isAlwaysVisible: Scalars['Boolean'];
  /** If it is always visible in the summary for Web */
  isAlwaysVisibleOnline: Scalars['Boolean'];
  /** @deprecated text1 is deprecated. Use specific identifier instead. */
  text1: Maybe<Scalars['String']>;
  /** @deprecated text2 is deprecated. Use specific identifier instead. */
  text2: Maybe<Scalars['String']>;
  /** @deprecated text3 is deprecated. Use specific identifier instead. */
  text3: Maybe<Scalars['String']>;
  /** @deprecated text4 is deprecated. Use specific identifier instead. */
  text4: Maybe<Scalars['String']>;
  /** @deprecated text5 is deprecated. Use specific identifier instead. */
  text5: Maybe<Scalars['String']>;
  /** @deprecated text6 is deprecated. Use specific identifier instead. */
  text6: Maybe<Scalars['String']>;
  /** @deprecated text7 is deprecated. Use specific identifier instead. */
  text7: Maybe<Scalars['String']>;
  /** @deprecated text8 is deprecated. Use specific identifier instead. */
  text8: Maybe<Scalars['String']>;
  /** @deprecated text9 is deprecated. Use specific identifier instead. */
  text9: Maybe<Scalars['String']>;
  /** @deprecated text10 is deprecated. Use specific identifier instead. */
  text10: Maybe<Scalars['String']>;
  /** @deprecated text11 is deprecated. Use isAlwaysVisibleOnline instead. */
  text11: Maybe<Scalars['String']>;
  /** @deprecated text12 is deprecated. Use isVisibleInSummaryOnline instead. */
  text12: Maybe<Scalars['String']>;
  /** @deprecated text13 is deprecated. Use specific identifier instead. */
  text13: Maybe<Scalars['String']>;
  /** Unknown data variable */
  date1: Maybe<Scalars['String']>;
  /** Unknown data variable */
  date2: Maybe<Scalars['String']>;
  /** Unknown int variable */
  amount1: Maybe<Scalars['Int']>;
  /** Unknown int variable */
  amount2: Maybe<Scalars['Int']>;
  /** @deprecated visible is deprecated. Use isVisible instead. */
  visible: Maybe<Scalars['Boolean']>;
  /** @deprecated visibleOnline is deprecated. Use isVisibleOnline instead. */
  visibleOnline: Maybe<Scalars['Boolean']>;
  /** @deprecated visibleInSummary is deprecated. Use isVisibleInSummary instead. */
  visibleInSummary: Maybe<Scalars['Boolean']>;
  /** @deprecated visibleInSummaryOnline is deprecated. Use isVisibleInSummaryOnline instead. */
  visibleInSummaryOnline: Maybe<Scalars['Boolean']>;
  /** @deprecated alwaysVisibleOnline is deprecated. Use isAlwaysVisibleOnline instead. */
  alwaysVisibleOnline: Maybe<Scalars['Boolean']>;
  /** @deprecated alwaysVisible is deprecated. Use isAlwaysVisible instead. */
  alwaysVisible: Maybe<Scalars['Boolean']>;
  /** @deprecated highlighted is deprecated. Use isHighlighted instead. */
  highlighted: Maybe<Scalars['Boolean']>;
}

export interface TeleConnectPagination {
  __typename?: 'TeleConnectPagination';
  count: Scalars['Int'];
  next: Maybe<Scalars['String']>;
  previous: Maybe<Scalars['String']>;
  results: Maybe<Array<TeleConnectArticle>>;
}

/** Defines a single TeleConnect product to add to the cart. */
export interface TeleConnectProductCartItemInput {
  /** The TeleConnect configurator config id. */
  configuratorConfigId: Scalars['String'];
  /** Image url to be used in cart and checkout. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float'];
}

export interface TeleConnectQuestion {
  __typename?: 'TeleConnectQuestion';
  id: Scalars['ID'];
  ean: Scalars['String'];
  order: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['Int'];
  isRequired: Scalars['Boolean'];
  isAnswered: Scalars['Boolean'];
  answerName: Maybe<Scalars['String']>;
  answerEan: Maybe<Scalars['String']>;
  defaultChoice: Maybe<Scalars['String']>;
  supplierPrice: Scalars['Float'];
  consumerPrice: Scalars['Float'];
  dealerPrice: Scalars['Float'];
  articleParameter: Maybe<Scalars['String']>;
  oldReference: Maybe<Scalars['String']>;
  mediaRecord: TeleConnectMediaRecord;
  /** @deprecated required is deprecated. Use isRequired instead. */
  required: Scalars['Boolean'];
  /** @deprecated questionCode is deprecated. Use questionCode on MediaRecord instead. */
  questionCode: Maybe<Scalars['String']>;
}

export interface TextSwatchData extends SwatchDataInterface {
  __typename?: 'TextSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']>;
}

/** Defines a price based on the quantity purchased. */
export interface TierPrice {
  __typename?: 'TierPrice';
  /** The price discount that this tier represents. */
  discount: Maybe<ProductDiscount>;
  /** The price of the product at this tier. */
  finalPrice: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity: Maybe<Scalars['Float']>;
}

export interface TotalSegment {
  __typename?: 'TotalSegment';
  amount: Money;
  code: Scalars['String'];
  title: Scalars['String'];
}

/** Modifies the specified items in the cart. */
export interface UpdateCartItemsInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of items to be updated. */
  cart_items: Array<Maybe<CartItemUpdateInput>>;
}

/** Contains details about the cart after updating items. */
export interface UpdateCartItemsOutput {
  __typename?: 'UpdateCartItemsOutput';
  /** The cart after updating products. */
  cart: Cart;
}

/** Defines updates to a `GiftRegistry` object. */
export interface UpdateGiftRegistryInput {
  /** Additional attributes specified as a code-value pair. Unspecified dynamic attributes are not changed. */
  dynamic_attributes?: Maybe<Array<Maybe<GiftRegistryDynamicAttributeInput>>>;
  /** The updated name of the event. */
  event_name?: Maybe<Scalars['String']>;
  /** The updated message describing the event. */
  message?: Maybe<Scalars['String']>;
  /** Indicates whether the gift registry is PRIVATE or PUBLIC. */
  privacy_settings?: Maybe<GiftRegistryPrivacySettings>;
  /** The updated shipping address for all gift registry items. */
  shipping_address?: Maybe<GiftRegistryShippingAddressInput>;
  /** Indicates whether the gift registry is ACTIVE or INACTIVE. */
  status?: Maybe<GiftRegistryStatus>;
}

/** Defines updates to an item in a gift registry. */
export interface UpdateGiftRegistryItemInput {
  /** The unique ID of a `giftRegistryItem` object. */
  gift_registry_item_uid: Scalars['ID'];
  /** The updated description of the item. */
  note?: Maybe<Scalars['String']>;
  /** The updated quantity of the gift registry item. */
  quantity: Scalars['Float'];
}

/** Contains the results of a request to update gift registry items. */
export interface UpdateGiftRegistryItemsOutput {
  __typename?: 'UpdateGiftRegistryItemsOutput';
  /** The gift registry after updating updating items. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Contains the results of a request to update a gift registry. */
export interface UpdateGiftRegistryOutput {
  __typename?: 'UpdateGiftRegistryOutput';
  /** The updated gift registry. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Defines updates to an existing registrant. */
export interface UpdateGiftRegistryRegistrantInput {
  /**
   * As a result of the update, only the values of provided attributes will be
   * affected. If the attribute is missing in the request, its value will not be changed.
   */
  dynamic_attributes?: Maybe<Array<Maybe<GiftRegistryDynamicAttributeInput>>>;
  /** The updated email address of the registrant. */
  email?: Maybe<Scalars['String']>;
  /** The updated first name of the registrant. */
  firstname?: Maybe<Scalars['String']>;
  /** The unique ID of a `giftRegistryRegistrant` object. */
  gift_registry_registrant_uid: Scalars['ID'];
  /** The updated last name of the registrant. */
  lastname?: Maybe<Scalars['String']>;
}

/** Contains the results a request to update registrants. */
export interface UpdateGiftRegistryRegistrantsOutput {
  __typename?: 'UpdateGiftRegistryRegistrantsOutput';
  /** The gift registry after updating registrants. */
  giftRegistry: Maybe<GiftRegistry>;
}

/** Contains the customer's wish list and any errors encountered. */
export interface UpdateProductsInWishlistOutput {
  __typename?: 'UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist;
}

/** Contains the name and visibility of an updated wish list. */
export interface UpdateWishlistOutput {
  __typename?: 'UpdateWishlistOutput';
  /** The wish list name. */
  name: Scalars['String'];
  /** The unique ID of a `Wishlist` object. */
  uid: Scalars['ID'];
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum;
}

/** Contains URL rewrite details. */
export interface UrlRewrite {
  __typename?: 'UrlRewrite';
  /** An array of request parameters. */
  parameters: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** The request URL. */
  url: Maybe<Scalars['String']>;
}

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  CMS_PAGE = 'CMS_PAGE',
  PRODUCT = 'PRODUCT',
  CATEGORY = 'CATEGORY'
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  NO = 'NO',
  FILTERABLE_WITH_RESULTS = 'FILTERABLE_WITH_RESULTS',
  FILTERABLE_NO_RESULT = 'FILTERABLE_NO_RESULT'
}

/** Contains required input for payment methods with Vault support. */
export interface VaultTokenInput {
  /** The public hash of the payment token. */
  public_hash: Scalars['String'];
}

/** An implementation for virtual product cart items. */
export interface VirtualCartItem extends CartItemInterface {
  __typename?: 'VirtualCartItem';
  /** plain cartItem custom image string */
  customImage: Maybe<CustomImage>;
  /** An array containing customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  rejectionReason: Maybe<Scalars['String']>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProduct extends ProductInterface, RoutableInterface, CustomizableProductInterface {
  __typename?: 'VirtualProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  aanbevolen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoireGewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoiresType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  afmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  alternativeImage: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  availableForSale: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalLatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemAantalSchotels: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHardheidszones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemHouders: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMateriaalFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemSoortVerbinding: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedbodemVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedDraaddichtheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedKleur: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedMaat: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedThema: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  beddengoedType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedframeHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeAantalLeds: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeBevestiging: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeDimbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeSchakelaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bedlampjeVerlichting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bestBeoordeeld: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bijzonderheden: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringBediening: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringCollectie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringDesign: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringFrame: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringGetoondeStof: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbord: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoofdbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringLighoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringMatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergAfmeting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringStitching: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoesTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringTopmatrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVerstelbaarFil: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  boxspringVoetbordHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  brandLogo: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  breedte: Maybe<Scalars['String']>;
  /**
   * The relative canonical URL. This value is returned only if the system setting
   * 'Use Canonical Link Meta Tag For Products' is enabled.
   */
  canonicalUrl: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The relative path to the configurator image, which is used on configurator pages. */
  configuratorImage: Maybe<ProductImage>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']>;
  /** An array of cross-sell products. */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedMateriaalTijk: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSeizoen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedSoortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulgewicht: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedVulling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedWarmteklasse: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekDessin: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekInstopstrook: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekKussensloop: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekStrijkvrij: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dekbedovertrekWasvoorschrift: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deliveryTimeUsp: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  directBeschikbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']>;
  /** Returns a discount breakdown for if this product is added to the cart */
  expectedCartDiscountBreakdown: Array<Maybe<CartRuleDiscountBreakdown>>;
  /** JSON encoded ga4data object */
  ga4Data: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  getoondeHoutkleur: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerAfwerking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerOpbergruimte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hockerWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoekhoogte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenGeschiktVoor: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoeslakenMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiAllergisch: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenAntiHuisstofmijt: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenNeksteun: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenSoortKussen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoofdkussenVulling: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  inhoudVerpakking: Maybe<Scalars['Int']>;
  /** Indicates whether the product can be returned. */
  isReturnable: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalDeuren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAantalLades: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingBreedte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastAfmetingHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastExtraInformatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastHandgrepen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingAantalVakken: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastIndelingHanggedeelte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastPoten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kastWieltjes: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kleur: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelBottomRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopLeftText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightBackground: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightColor: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightOffset: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  labelTopRightText: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantBedrandDiepte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ledikantFrameLengte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengte: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lengteBedlamp: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maatvoering: Maybe<Scalars['String']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVentilatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalComfortzones: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAantalVeren: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasAfdeklaagDikte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasDrukverlagend: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFilterAfdek: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasFrameSpecificatie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasGarantie: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHandvaten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoesTijkWasbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasKernHoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasMaximaleBelasting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasOndersteuning: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingFirm: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingHardheid: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasSamenstellingMedium: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasTijkHandvatten: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasType: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  matrasVoorVerstelbareBodems: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximaleBelasting: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meestGekozen: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  merk: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonDiepteSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonHoekhoogte: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  moltonMetSplit: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  montage: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  nieuweCollectie: Maybe<Scalars['Int']>;
  /** The value assigned to the Only X Left Threshold option in the Admin. */
  onlyXLeftInStock: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  plaidInclusiefKussensloop: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  productMateriaal: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  producttype: Maybe<Scalars['String']>;
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float'];
  /**
   * Contains 0 when there is no redirect error. A value of 301 indicates the URL
   * of the requested resource has been changed permanently, while a value of 302
   * indicates a temporary redirect.
   */
  redirectCode: Scalars['Int'];
  /** An array of related products. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  sale2: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  segment: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenMaat: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenNavulbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sierkussenWasbaar: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sluitmechanisme: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortSluiting: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  soortVerbinding: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']>;
  /** Indicates whether the product is staged for a future campaign. */
  staged: Scalars['Boolean'];
  /** The stock status of the product. */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectCatalogId: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  teleconnectEan: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelBottom: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelLeft: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  textLabelTop: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperAfdeklaag: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkAfritsbaar: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkDoorstikking: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperHoesTijkSamenstelling: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperKern: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperType: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  topperUitvoering: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tvLift: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /** @deprecated Use the `custom_attributes` field instead. */
  uitvoering: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']>;
  /** An array of up-sell products. */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
}


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductReviewsArgs {
  pageSize?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
}

/** Defines a single product to add to the cart. */
export interface VirtualProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
}

/** Contains a virtual product wish list item. */
export interface VirtualWishlistItem extends WishlistItemInterface {
  __typename?: 'VirtualWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export interface Website {
  __typename?: 'Website';
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code: Maybe<Scalars['String']>;
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  defaultGroupId: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id: Maybe<Scalars['Int']>;
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  isDefault: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sortOrder: Maybe<Scalars['Int']>;
}

/** Contains a customer wish list. */
export interface Wishlist {
  __typename?: 'Wishlist';
  /** The unique ID for a `Wishlist` object. */
  id: Maybe<Scalars['ID']>;
  /** @deprecated Use the `items_v2` field instead. */
  items: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list. */
  itemsCount: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list. */
  itemsV2: Maybe<WishlistItems>;
  /** The name of the wish list. */
  name: Maybe<Scalars['String']>;
  /** An encrypted code that Magento uses to link to the wish list. */
  sharingCode: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list. */
  updatedAt: Maybe<Scalars['String']>;
  /** Indicates whether the wish list is public or private. */
  visibility: WishlistVisibilityEnum;
}


/** Contains a customer wish list. */
export interface WishlistItemsV2Args {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export interface WishlistCartUserInputError {
  __typename?: 'WishlistCartUserInputError';
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID'];
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID'];
}

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  NOT_SALABLE = 'NOT_SALABLE',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  UNDEFINED = 'UNDEFINED'
}

/** Contains details about a wish list item. */
export interface WishlistItem {
  __typename?: 'WishlistItem';
  /** The time when the customer added the item to the wish list. */
  addedAt: Maybe<Scalars['String']>;
  /** The customer's comment about this item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object. */
  id: Maybe<Scalars['Int']>;
  /** Details about the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty: Maybe<Scalars['Float']>;
}

/** Specifies the IDs of items to copy and their quantities. */
export interface WishlistItemCopyInput {
  /**
   * The quantity of this item to copy to the destination wish list. This value
   * can't be greater than the quantity in the source wish list.
   */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID of the `WishlistItemInterface` object to be copied. */
  wishlist_item_id: Scalars['ID'];
}

/** Defines the items to add to a wish list. */
export interface WishlistItemInput {
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of items to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String'];
}

/** The interface for wish list items. */
export interface WishlistItemInterface {
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
}

/** Specifies the IDs of the items to move and their quantities. */
export interface WishlistItemMoveInput {
  /**
   * The quantity of this item to move to the destination wish list. This value
   * can't be greater than the quantity in the source wish list.
   */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID of the `WishlistItemInterface` object to be moved. */
  wishlist_item_id: Scalars['ID'];
}

/** Contains an array of items in a wish list. */
export interface WishlistItems {
  __typename?: 'WishlistItems';
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
}

/** Defines updates to items in a wish list. */
export interface WishlistItemUpdateInput {
  /** Customer-entered comments about the item. */
  description?: Maybe<Scalars['String']>;
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** The new amount or number of this item. */
  quantity?: Maybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID'];
}

/** Deprecated: Use the `Wishlist` type instead. */
export interface WishlistOutput {
  __typename?: 'WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  itemsCount: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name: Maybe<Scalars['String']>;
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharingCode: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updatedAt: Maybe<Scalars['String']>;
}

/** An error encountered while performing operations with WishList. */
export interface WishListUserInputError {
  __typename?: 'WishListUserInputError';
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
}

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED'
}

/** Defines the wish list visibility types. */
export enum WishlistVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}
