import React, { SVGProps } from 'react'

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden="true"
    viewBox="0 0 404.2 313.2"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M38.3,65.5c-1.3-1-2.1-2.5-2.1-4.1v-7.8c0-10.1,8.2-18.3,18.3-18.3h295.2c10.1,0,18.3,8.2,18.3,18.3v7.8
	c0,1.6-0.8,3.2-2.1,4.1L216.7,175.6c-8.4,6.2-20.7,6.2-29.2,0L38.3,65.5z M223.5,191.8c-6.3,4.6-13.8,7-21.4,7s-15.1-2.3-21.4-7
	L39.6,87.7c-1.4-1-3.4,0-3.4,1.7v170.2c0,10.1,8.2,18.3,18.3,18.3h295.2c10.1,0,18.3-8.2,18.3-18.3V89.4c0-1.8-2-2.8-3.4-1.7
	L223.5,191.8z"
    />
  </svg>
)
