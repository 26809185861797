import { HeaderMenu } from '@shared/layout/HeaderMenu'
import React, { useCallback, useEffect, useState } from 'react'

import { globalWindow } from '@emico/ssr-utils'

const HeaderMenuWrapper = () => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const navToggle = globalWindow?.document.querySelector('.nav-toggle')

  const handleClick = useCallback((e: Event) => {
    e.stopPropagation()
    e.preventDefault()
    setMenuIsOpen((menuIsOpen) => !menuIsOpen)
  }, [])

  useEffect(() => {
    if (navToggle) {
      const newNav = navToggle.cloneNode(true)
      navToggle.parentNode?.replaceChild(newNav, navToggle)
    }

    const newNavToggle = globalWindow?.document.querySelector('.nav-toggle')

    newNavToggle?.addEventListener('click', handleClick)

    return () => navToggle?.removeEventListener('click', handleClick)
  }, [handleClick, navToggle])

  return <HeaderMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
}

export default HeaderMenuWrapper
