import { CartSummaryCoupons } from '@checkout/cart-summary/CartSummaryCoupons'
import { CartSummaryGiftCard } from '@checkout/cart-summary/CartSummaryGiftCard'
import { useFlow } from '@checkout/flow'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { CartTotalLine } from '@shared/cart/CartTotalLine'
import { TotalSegment } from '@shared/cart/useCart'
import { useValidSegments } from '@shared/segments/useValidSegments'
import theme from '@src/theme'
import React from 'react'

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const ExtraSubLabel = styled.span`
  color: ${theme.gray500};
  font-size: 14px;
  font-weight: 400;
`

const Separator = styled.div`
  height: 1px;
  margin: 10px 0 10px 78px;
  background: currentColor;
`

interface CartTotalsProps {
  mode?: 'small' | 'big'
}

export const CartTotals = ({ mode = 'big' }: CartTotalsProps) => {
  const {
    subtotalSegment,
    showroomDiscountSegment,
    employeeDiscountSegment,
    amountPaidSegment,
    amountUnpaidSegment,
    giftCardAccountSegment,
    returnTotalSegment,
    grandTotalSegment,
    shippingSegment,
    discountSegment,
    additionalSegments,
  } = useValidSegments()
  const flow = useFlow()

  const renderTotalSegment = (totalSegment?: TotalSegment) => {
    if (!totalSegment) {
      return null
    }

    if (totalSegment.amount.value === 0) {
      return null
    }

    return (
      <CartTotalLine price={totalSegment.amount.value ?? 0} mode={mode}>
        {totalSegment.title}
      </CartTotalLine>
    )
  }

  return (
    <Content>
      <CartTotalLine
        price={subtotalSegment?.amount.value ?? 0}
        mode={mode}
        type="total"
      >
        <Trans>Subtotal</Trans>
      </CartTotalLine>
      {(showroomDiscountSegment?.amount.value ?? 0) > 0 ? (
        <CartTotalLine
          price={
            showroomDiscountSegment?.amount.value
              ? showroomDiscountSegment?.amount.value * -1
              : 0
          }
          mode={mode}
        >
          <Trans>Showroom Discount</Trans>
        </CartTotalLine>
      ) : null}
      {(employeeDiscountSegment?.amount.value ?? 0) > 0 ? (
        <CartTotalLine
          price={
            employeeDiscountSegment?.amount.value
              ? employeeDiscountSegment?.amount.value * -1
              : 0
          }
          mode={mode}
        >
          <Trans>Employee Discount</Trans>
        </CartTotalLine>
      ) : null}
      {(discountSegment?.amount.value ?? 0) !== 0 ? (
        <CartTotalLine price={discountSegment?.amount.value ?? 0} mode={mode}>
          <Trans>Discount</Trans>
          {mode === 'big' ? (
            <ExtraSubLabel>{discountSegment?.title}</ExtraSubLabel>
          ) : null}
        </CartTotalLine>
      ) : null}
      {flow === 'delivery' || flow === 'web' ? (
        <CartTotalLine
          price={shippingSegment?.amount.value ?? 0}
          stylizeFree
          isBold={false}
          mode={mode}
        >
          <Trans>Delivery</Trans>
        </CartTotalLine>
      ) : null}
      {additionalSegments.map(renderTotalSegment ?? [])}
      {renderTotalSegment(amountPaidSegment ?? undefined)}
      {renderTotalSegment(amountUnpaidSegment ?? undefined)}

      {mode === 'small' ? (
        <>
          <CartSummaryGiftCard totals={giftCardAccountSegment ?? undefined} />
          <CartSummaryCoupons />
        </>
      ) : null}

      {mode === 'small' ? <Separator /> : null}

      {returnTotalSegment?.amount.value !== 0 || flow === 'rma' ? (
        <CartTotalLine
          price={returnTotalSegment?.amount.value ?? 0}
          mode={mode}
          type="total"
        >
          <Trans>Return Amount</Trans>
        </CartTotalLine>
      ) : (
        <CartTotalLine
          price={grandTotalSegment?.amount.value ?? 0}
          mode={mode}
          type="total"
        >
          <Trans>Total Amount</Trans>
        </CartTotalLine>
      )}
    </Content>
  )
}
