import AdyenCheckout from '@adyen/adyen-web'
import React, { createContext, ReactNode, useRef, useContext } from 'react'

import { useActiveStoreView } from '@emico/storeviews'

export const ADYEN_HPP_CODE = 'adyen_hpp'
export const ADYEN_CC_CODE = 'adyen_cc'
export const ADYEN_PREFIX = 'adyen_'
export const ADYEN_HPP_UNSUPPORTED = ['boleto', 'wechatpay', 'ratepay']
export const ADYEN_PAYMENT_METHOD_MAP = [
  { adyen: 'scheme', magento: 'cc' },
  { adyen: 'boletobancario', magento: 'boleto' },
]
export const ADYEN_HPP_BRAND_CODE_SCHEME = 'scheme'
export const ADYEN_HPP_BRAND_CODE_BANCONTACT = 'bcmc'

const Context = createContext<AdyenCheckout>(new AdyenCheckout({}))

interface AdyenCheckoutProviderProps {
  children: ReactNode
}

export const AdyenCheckoutProvider = ({
  children,
}: AdyenCheckoutProviderProps) => {
  const activeStoreView = useActiveStoreView()

  let locale = 'nl-NL'

  if (activeStoreView.language === 'de' || activeStoreView.language === 'at') {
    locale = 'de-DE'
  }

  const checkout = useRef(
    new AdyenCheckout({
      locale,
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      showPayButton: false,
    }),
  )

  return (
    <Context.Provider value={checkout.current}>{children}</Context.Provider>
  )
}

export const useAdyenCheckout = () => useContext(Context)
