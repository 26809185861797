import { useGuestCartId } from '@checkout/cart/useGuestCartId'
import { TotalSegment, useCart } from '@shared/cart/useCart'

export const useValidSegments = () => {
  const guestCartId = useGuestCartId()
  const { cart } = useCart(guestCartId)

  const segments = cart?.totalSegments as [TotalSegment]

  const validSegments = segments
    ? segments.filter((segment) => {
        if (
          segment.code === 'grand_total' ||
          segment.code === 'return_total' ||
          segment.code === 'shipping'
        ) {
          return true
        }

        if (segment.code === 'tax') {
          return false
        }

        return segment.amount.value !== null && segment.amount.value !== 0
      })
    : []

  const additionalSegments = validSegments.filter((segment) => {
    if (!segment) {
      return null
    }
    if (!segment.code?.startsWith('additional_costs_')) {
      return null
    }
    return true
  })

  const getSegment = (code: string) =>
    validSegments.find((segment) => segment.code === code)

  return {
    validSegments,
    additionalSegments,
    returnTotalSegment: getSegment('return_total'),
    subtotalSegment: getSegment('subtotal'),
    shippingSegment: getSegment('shipping'),
    grandTotalSegment: getSegment('grand_total'),
    showroomDiscountSegment: getSegment('showroom_discount'),
    employeeDiscountSegment: getSegment('employee_discount'),
    amountPaidSegment: getSegment('amount_paid'),
    amountUnpaidSegment: getSegment('amount_unpaid'),
    giftCardAccountSegment: getSegment('giftcardaccount'),
    discountSegment: getSegment('discount'),
  }
}
