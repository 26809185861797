import { makeVar, useReactiveVar } from '@apollo/client'
import { Gender } from '@checkout/gender/helpers'

interface TrustedShopsInsuranceState {
  order?: {
    number?: string
    amount?: string
    currency?: string
    paymentType?: string
    estimatedDeliveryDate?: string
  }
  buyer?: {
    email?: string
  }
}

const trustedShopsInsuranceState = makeVar<TrustedShopsInsuranceState>({
  order: {
    number: '',
    amount: '',
    currency: '',
    paymentType: '',
    estimatedDeliveryDate: '',
  },
  buyer: {
    email: '',
  },
})

export const useTrustedShopsInsuranceVar = () => {
  const trustedShopsInsuranceVar = useReactiveVar(trustedShopsInsuranceState)

  const getTrustedShopsInsuranceVar = () => trustedShopsInsuranceState()

  const setTrustedShopsInsuranceState = (
    stateValue: Partial<TrustedShopsInsuranceState>,
  ) => {
    trustedShopsInsuranceState({
      ...trustedShopsInsuranceState(),
      ...stateValue,
    })
  }

  const setTrustedShopsInsuranceVarValue = (
    key: keyof TrustedShopsInsuranceState,
    value: string | Gender | boolean | number | null,
  ) => {
    if (trustedShopsInsuranceVar[key] === value) {
      return
    }
    trustedShopsInsuranceState({
      ...trustedShopsInsuranceState(),
      [key as string]: value,
    })
  }

  return {
    trustedShopsInsuranceVar,
    getTrustedShopsInsuranceVar,
    setTrustedShopsInsuranceState,
    setTrustedShopsInsuranceVarValue,
  }
}
