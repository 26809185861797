import styled from '@emotion/styled'
import { Price } from '@src/components/Price'
import theme from '@src/theme'
import React from 'react'

import { Image } from '@emico/ui'

const TicketLine = styled('div', {
  shouldForwardProp: (prop: string) => !['isBold'].includes(prop),
})<{
  isBold: boolean
}>`
  font-family: ${theme.fontFamilySecondary};
  display: flex;
  padding-bottom: 4px;
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  font-size: 12px;
  letter-spacing: 0.01em;
`

const TicketLineImage = styled.div`
  width: 55px;
  margin-right: 23px;
`

const TicketLineContent = styled.div`
  flex: 1;
  line-height: normal;
`

const TicketLinePrice = styled('div')`
  font-weight: 500;
  text-align: right;
`

const CylindoImage = styled('img')`
  width: 55px;
  height: 55px;
`

interface CartSummaryLineProps {
  src?: string
  customSrc?: string
  alt?: string
  price: number
  children: React.ReactNode
  stylizeFree?: boolean
  noPrice?: boolean
  isBold?: boolean
}

export const CartSummaryLine = ({
  src,
  customSrc,
  price,
  children,
  stylizeFree,
  noPrice,
  isBold = true,
  alt = '',
}: CartSummaryLineProps) => (
  <TicketLine isBold={isBold}>
    <TicketLineImage>
      {customSrc ? <CylindoImage src={customSrc} alt={alt} /> : null}
      {src && !customSrc ? (
        <Image
          url={src}
          width={55}
          height={55}
          originalWidth={512}
          originalHeight={512}
          alt={alt}
        />
      ) : null}
    </TicketLineImage>
    <TicketLineContent>{children}</TicketLineContent>
    <TicketLinePrice>
      {noPrice !== true && <Price stylizeFree={stylizeFree} value={price} />}
    </TicketLinePrice>
  </TicketLine>
)
