import { RightPaneButton } from '@configurator/ui'
import { Trans } from '@lingui/macro'
import React, { ComponentProps } from 'react'

interface NextButtonProps
  extends Partial<ComponentProps<typeof RightPaneButton>> {
  onClick: () => void
}

export const NextButton = ({ onClick, ...others }: NextButtonProps) => (
  <RightPaneButton analyticsName="Next" onClick={onClick} {...others}>
    <span>
      <Trans>Next</Trans>
    </span>
  </RightPaneButton>
)
