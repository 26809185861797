import { globalWindow } from '@emico/ssr-utils'

const usePopupsVisibility = () => {
  const wisePops = globalWindow?.document.getElementById('wisepops-root')

  const showPopups = () => {
    if (globalWindow?.Intercom) {
      globalWindow.Intercom('update')
    }

    if (globalWindow?.trustbadge) {
      globalWindow.trustbadge.reInitialize()
    }

    if (wisePops) {
      wisePops.style.display = 'block'
    }
  }

  const hidePopups = () => {
    if (globalWindow?.Intercom) {
      globalWindow.Intercom('shutdown')
    }

    if (globalWindow?.trustbadge?.remove) {
      globalWindow.trustbadge.remove()
    }

    if (wisePops) {
      wisePops.style.display = 'none'
    }
  }

  return {
    showPopups,
    hidePopups,
  }
}

export default usePopupsVisibility
