import styled from '@emotion/styled'
import theme from '@src/theme'

import { ButtonLink as ComponentButtonLink } from '@emico/ui'

import { resetStyles } from './Button'

export const ButtonLink = styled(ComponentButtonLink)`
  ${resetStyles}
  color: #000;
  padding: 5px;
  margin: -5px;

  &:hover {
    color: ${theme.primaryColor};
    text-decoration: underline;
    background: none;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }
`
