import { makeVar, useReactiveVar } from '@apollo/client'
import { Gender } from '@checkout/gender/helpers'
import { LoginFormValues } from '@checkout/login-form/LoginForm'

export interface LoginVarState extends Partial<LoginFormValues> {
  skip?: boolean
  mulesoftCustomerId?: string
  forceClear?: boolean
  shouldUpdate?: boolean
}

const loginState = makeVar<LoginVarState>({})

export const useLoginVar = () => {
  const loginVar = useReactiveVar(loginState)

  const getLoginVar = () => loginState()

  const setLoginVarState = (stateValue: Partial<LoginVarState>) => {
    loginState(stateValue)
  }

  const setLoginVarValue = (
    key: keyof LoginVarState,
    value: string | Gender | boolean | number | null,
  ) => {
    if (loginVar[key] === value) {
      return
    }

    loginState({
      ...loginState(),
      [key as string]: value,
    })
  }

  return {
    loginVar,
    getLoginVar,
    setLoginVarState,
    setLoginVarValue,
  }
}
