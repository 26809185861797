import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { ErrorMessage } from '@src/components/ErrorMessage'
import { SuccessMessage } from '@src/components/SuccessMessage'
import React from 'react'

import { globalWindow } from '@emico/ssr-utils'
import { minWidth, maxWidth } from '@emico/styles'

import CustomerSubscriptionsForm from './CustomerSubscriptionsForm'
import { EmailForm } from './EmailForm'

export const Wrapper = styled.div`
  max-width: 745px;
  padding-top: 25px;
  margin: 0 auto;
`

const IntroMessage = styled.div`
  margin-bottom: 25px;

  @media ${minWidth('md')} {
    margin-bottom: 15px;
  }

  @media ${maxWidth('sm')} {
    padding: 0 20px;
  }
`

const Heading = styled.h1`
  font-size: 20px;
  margin: 0;

  @media ${minWidth('md')} {
    font-size: 32px;
  }
`

const Description = styled.p`
  margin: 0;

  @media ${minWidth('md')} {
    font-size: 16px;
  }
`

const ContactPreferenceCenter = () => {
  const params = new URLSearchParams(globalWindow?.location.search)

  // Contact preferences are managed through a link that can be requested by the user through email.
  // When id and email are present in the url,
  // we assume it's a valid link and try and request the settings.
  // If they are missing, the link is invalid and the user should request a new link.
  if (
    !params.has('id') ||
    !params.has('email') ||
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Number.isNaN(parseInt(params.get('id')!, 10)) // Check if id is a valid number
  ) {
    return (
      <Wrapper>
        <SuccessMessage />
        <ErrorMessage />
        <EmailForm />
      </Wrapper>
    )
  }

  const id = Number(params.get('id'))

  // We just checked with params.has, so this should exist
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const emailParam = params.get('email')!
  // email addresses are allowed to have a + sign, the URLSearchParams converts it to spaces.
  const email = emailParam.replace(' ', '+')

  return (
    <Wrapper>
      <IntroMessage>
        <Heading>
          <Trans id="contactPreferences.introMessage.heading">
            Email instellingen
          </Trans>
        </Heading>
        <Description>
          <Trans id="contactPreferences.introMessage.text1">
            hieronder kun je je email instellingen wijzigen.
          </Trans>
        </Description>
      </IntroMessage>
      <SuccessMessage />
      <ErrorMessage />
      <CustomerSubscriptionsForm customerId={id} email={email} />
    </Wrapper>
  )
}

export default ContactPreferenceCenter
