import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { CartItem } from '@shared/cart/useCart'
import {
  ConfigurableCartItem,
  Maybe,
  SelectedCustomizableOption,
  SimpleCartItem,
} from '@src/schema.generated'
import React, { useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@emico/icons'

const ProductOption = styled.span`
  display: flex;
  color: #4d4d4d;
  font-size: 12px;
  opacity: 0.8;

  &:last-child {
    margin-bottom: 20px;
  }
`
const ProductOptionSpan = styled.span`
  color: #4d4d4d;
  font-size: 12px;
  opacity: 0.8;
  flex: 1;
  width: 50%;
  margin: 0;
`
const ViewAllProductOption = styled.span`
  color: #4d4d4d;
  font-size: 12px;
  opacity: 0.8;
  text-decoration: underline;
  cursor: pointer;
`
const StyledChevronDownIcon = styled(ChevronDownIcon)`
  margin-right: 5px;
`
const StyledChevronUpnIcon = styled(ChevronUpIcon)`
  margin-right: 5px;
`

const CartItemOptions = ({ cartItem }: { cartItem: CartItem }) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const optionsArray: Array<{ label: string; value: string }> = []

  if (cartItem.__typename === 'ConfigurableCartItem') {
    cartItem as ConfigurableCartItem
    cartItem.configurableOptions.forEach((configurableOption) => {
      if (!configurableOption) {
        return
      }
      optionsArray.push({
        value: configurableOption.valueLabel,
        label: configurableOption.optionLabel,
      })
    })
  }
  if ('customizableOptions' in cartItem) {
    const customizableOptions = cartItem.customizableOptions as Array<
      Maybe<SelectedCustomizableOption>
    >
    customizableOptions.forEach((configurableOption) => {
      if (!configurableOption) {
        return
      }
      optionsArray.push({
        value: configurableOption.values
          .map((optionValue) => optionValue?.label)
          .join(', '),
        label: configurableOption.label,
      })
    })
  }
  if (cartItem.__typename === 'SimpleCartItem') {
    cartItem as SimpleCartItem
    cartItem.teleconnect?.additionalOptions?.forEach((teleconnectOption) => {
      if (!teleconnectOption?.value || !teleconnectOption?.label) {
        return
      }
      optionsArray.push({
        value: teleconnectOption.value,
        label: teleconnectOption.label,
      })
    })
  }

  if (optionsArray.length > 2) {
    return isCollapsed ? (
      <ViewAllProductOption onClick={() => setCollapsed(false)}>
        <StyledChevronDownIcon />
        <Trans>Specifications</Trans>
      </ViewAllProductOption>
    ) : (
      <>
        <ViewAllProductOption onClick={() => setCollapsed(true)}>
          <StyledChevronUpnIcon />
          <Trans>Specifications</Trans>
        </ViewAllProductOption>
        {optionsArray.map((option) => (
          <ProductOption key={option.label}>
            <ProductOptionSpan>{option.label}:</ProductOptionSpan>
            <ProductOptionSpan>{option.value}</ProductOptionSpan>
          </ProductOption>
        ))}
      </>
    )
  }

  return (
    <>
      {optionsArray.map((option) => (
        <ProductOption key={option.label}>
          <ProductOptionSpan>{option.label}:</ProductOptionSpan>
          <ProductOptionSpan>{option.value}</ProductOptionSpan>
        </ProductOption>
      ))}
    </>
  )
}

export default CartItemOptions
