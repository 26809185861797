import {
  CartSummaryHeader,
  getTotalAmountOfProducts,
} from '@checkout/cart-summary/CartSummaryHeader'
import { CartSummaryLine } from '@checkout/cart-summary/CartSummaryLine'
import { CompactDetails } from '@checkout/ui/CompactDetails'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { ButtonLink } from '@shared/button'
import { CartTotals } from '@shared/cart/CartTotals'
import { CartItem } from '@shared/cart/useCart'
import CartItemOptions from '@src/shared/cart/CartItemOptions'
import theme from '@src/theme'
import React from 'react'

import { maxWidth } from '@emico/styles'

const ButtonLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ProductDetails = styled.p`
  font-size: 10px;
  opacity: 0.8;
`

const ProductQty = styled.p`
  color: #4d4d4d;
  font-size: 12px;
  opacity: 0.8;
`
const InfoShort = styled.div`
  flex: 1;
`

const Products = styled.div`
  padding-top: 35px;
`

const Separator = styled.div`
  flex: 1;
  height: 1px;
  margin: 10px 0 10px 78px;
  background: currentColor;
`

export const SubtotalLine = styled.span`
  font-family: ${theme.fontFamilySecondary};
  color: #615e5e;
`

const StyledButtonLink = styled(ButtonLink)`
  padding: 0;
  margin: 0;
  height: 18px;
  font-weight: 700;

  @media ${maxWidth('sm')} {
    font-size: 10px;
  }
`

interface CartSummaryDetailsProps {
  items: CartItem[]
  showDetails: boolean

  onClick?(): void
}

export const CartSummaryDetails = ({
  items = [],
  showDetails,
  onClick,
}: CartSummaryDetailsProps) => (
  <CompactDetails>
    <InfoShort>
      <ButtonLinkWrapper>
        <CartSummaryHeader amountOfProducts={getTotalAmountOfProducts(items)} />
        {!showDetails ? (
          <StyledButtonLink
            analyticsName="Summary less details"
            analyticsContext="Checkout"
            onClick={onClick}
          >
            <Trans>Less details</Trans>
          </StyledButtonLink>
        ) : null}
      </ButtonLinkWrapper>

      {items && items.length > 0 ? (
        <Products>
          {items.map((item) => {
            if (item) {
              return (
                <CartSummaryLine
                  price={item.prices?.rowTotalIncludingTax.value ?? 0}
                  src={item.product.image?.url ?? undefined}
                  customSrc={
                    item.customImage &&
                    !Array.isArray(item.customImage) &&
                    item.customImage?.url
                      ? item.customImage.url
                      : undefined
                  }
                  alt={item.product.image?.label ?? 'Product image'}
                  key={item.id}
                >
                  <ProductDetails>{item.product.name}</ProductDetails>
                  <ProductQty>{item.quantity}x</ProductQty>
                  <CartItemOptions cartItem={item} />
                </CartSummaryLine>
              )
            } else {
              return null
            }
          })}
        </Products>
      ) : null}

      <Separator />

      <CartTotals mode="small" />
    </InfoShort>
  </CompactDetails>
)
