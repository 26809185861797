import styled from '@emotion/styled'

import { Button } from '@emico/ui'

export const ButtonTransparent = styled(Button)`
  color: inherit;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }

  /** Some very specific button reset styles */

  :not(.primary):active {
    box-shadow: none;
  }
`
