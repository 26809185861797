import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

const StyledEmptyCart = styled.div`
  padding: 1em 0;
`

/**
 * Component to display an empty cart and link back to the shop
 */
export const EmptyCart = () => (
  <StyledEmptyCart data-testid="checkout.emptyCart">
    <Trans>Your shopping cart is empty.</Trans>{' '}
    <Trans>
      Click <a href="/">here</a> to continue shopping
    </Trans>
  </StyledEmptyCart>
)
