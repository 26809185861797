import styled from '@emotion/styled'
import { GlobeIcon } from '@shared/icons'
import React from 'react'

import { StoreViewSelectorSelect } from '@emico/storeview-selector'
import { SelectUnstyled } from '@emico/ui'

const Select = styled(SelectUnstyled)`
  font-weight: bold;
  padding-right: calc(5px + 1em);
`

const Wrapper = styled.div`
  position: relative;
`

const StyledGlobeIcon = styled(GlobeIcon)`
  position: absolute;
  top: 3px;
  right: 1px;
  pointer-events: none;
`

StoreViewSelectorSelect.override((props) => (
  <Wrapper>
    <Select {...props} />
    <StyledGlobeIcon />
  </Wrapper>
))
