import { Loader } from '@configurator/ui'
import styled from '@emotion/styled'
import { useCart } from '@shared/cart/useCart'
import React, { Dispatch } from 'react'

import { stripMaybes } from '@emico/utils'

import { EmptyCart } from './EmptyCart'
import { ShoppingCartItem } from './ShoppingCartItem'

const Wrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

interface Props {
  setOpen: Dispatch<boolean>
}

export const ShoppingCartItems = ({ setOpen }: Props) => {
  const { cart, loading } = useCart()
  return (
    <Wrapper>
      {!loading ? (
        cart?.items && cart.items.length > 0 ? (
          cart.items
            .filter(stripMaybes)
            .map((item) => (
              <ShoppingCartItem key={item?.id} item={item} setOpen={setOpen} />
            ))
        ) : (
          <EmptyCart setOpen={setOpen} />
        )
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </Wrapper>
  )
}
