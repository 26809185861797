import styled from '@emotion/styled'

import {
  SearchOrCancelButton as BaseSearchOrCancelButton,
  StyledInput as BaseStyledInput,
} from '@emico/search-bar'
import { theme, maxWidth, minWidth } from '@emico/styles'

import { SearchOrCancelButton } from '../components/SearchOrCancelButton'

export const StyledInput = styled(BaseStyledInput.original)`
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    padding: 0 20px;

    &:focus {
      outline: none;
    }

    @media ${maxWidth('lg')} {
      font-size: 15px;
      border: 1px solid ${theme.primaryColor};
      border-radius: 4px;
      padding-right: 15px;
      padding-left: 15px;

      &:focus {
        border: 1px solid ${theme.primaryColor};
      }
    }

    @media ${minWidth('lg')} {
      height: 45px;
      border-radius: 3px;
    }

    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px;
    }
   }
  `

BaseStyledInput.override(StyledInput as typeof BaseStyledInput.original)
BaseSearchOrCancelButton.override(SearchOrCancelButton)
