import styled from '@emotion/styled'
import React from 'react'
import { TransitionStatus } from 'react-transition-group/Transition'

import { theme } from '@emico/styles'

import {
  ModalSegueBase,
  ModalSegueBaseProps,
  TranslateStates,
  AnimatedElement,
} from './ModalSegueBase'

const AnimateLeft = styled(AnimatedElement)<{ state: TransitionStatus }>`
  transition: transform ${theme.primaryTransitionSpeed}ms
    ${theme.primaryTransitionEase};
  transform: translateX(${({ state }) => TranslateStates[state] * -1}%);
`

export const ModalSegueLeft = (props: ModalSegueBaseProps) => (
  <ModalSegueBase Component={AnimateLeft} {...props} />
)
