import styled from '@emotion/styled'
import React from 'react'

import { PrismicImage } from '@emico/prismic'
import { theme } from '@emico/styles'
import { minWidth } from '@emico/styles'

import { PrismicTypeHeader } from '../schema.generated'

interface FooterPaymentMethodsProps {
  paymentMethods?: PrismicTypeHeader['paymentMethods']
}

const PaymentMethods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x4}px 0;
  grid-column-start: footer-logos;
  flex-wrap: wrap;

  @media ${minWidth('md')} {
    justify-content: flex-end;
    padding: 0;
  }
`

const PaymentMethodIcon = styled.div`
  display: inline-block;

  + div {
    margin-left: ${theme.spacing.x2}px;
  }
`

export const FooterPaymentMethods = ({
  paymentMethods,
}: FooterPaymentMethodsProps) => (
  <PaymentMethods>
    {paymentMethods?.map((item, i) => (
      <PaymentMethodIcon key={i}>
        <PrismicImage image={item.image} height={36} />
      </PaymentMethodIcon>
    ))}
  </PaymentMethods>
)
