import styled from '@emotion/styled'
import { Button } from '@shared/button'

export const ButtonOrange = styled(Button)`
  background: #e8431b;
  color: #fff;

  &:active {
    color: #fff;
    background: #e8431b;
  }

  &:disabled {
    background: #ef8b73;
  }

  &:hover,
  &:focus {
    color: #fff;
    background: #c74323;
  }
`
