import { useEffect } from 'react'

import { globalWindow } from '@emico/ssr-utils'
import { StoreView } from '@emico/storeviews'

const REACT_APP_TRUSTED_SHOPS_ID_DE = process.env.REACT_APP_TRUSTED_SHOPS_ID_DE
const REACT_APP_TRUSTED_SHOPS_ID_AT = process.env.REACT_APP_TRUSTED_SHOPS_ID_AT

export const TrustedShopsScript = ({
  activeStoreView,
}: {
  activeStoreView: StoreView
}) => {
  useEffect(() => {
    if (
      globalWindow &&
      !globalWindow?._tsid &&
      (activeStoreView.code === 'de_de' || activeStoreView.code === 'at_de')
    ) {
      const _tsid =
        activeStoreView.code === 'de_de'
          ? REACT_APP_TRUSTED_SHOPS_ID_DE
          : REACT_APP_TRUSTED_SHOPS_ID_AT
      globalWindow._tsConfig = {
        yOffset: '150' /* offset from page bottom */,
        variant:
          'reviews' /* text, default, small, reviews, custom, custom_reviews */,
        customElementId:
          '' /* required for variants custom and custom_reviews */,
        trustcardDirection:
          '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
        customBadgeWidth: '' /* for custom variants: 40 - 90 (in pixels) */,
        customBadgeHeight: '' /* for custom variants: 40 - 90 (in pixels) */,
        disableResponsive: 'false' /* deactivate responsive behaviour */,
        disableTrustbadge: 'false' /* deactivate trustbadge */,
        trustCardTrigger:
          'mouseenter' /* set to 'click' if you want the trustcard to be opened on click instead */,
        customCheckoutElementId: '' /* required for custom trustcard */,
        responsive: { variant: 'topbar' },
      }
      const _ts = globalWindow?.document.createElement('script')
      _ts.type = 'text/javascript'
      _ts.charset = 'utf-8'
      _ts.async = true
      _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'
      const __ts = globalWindow?.document.getElementsByTagName('script')[0]
      __ts.parentNode?.insertBefore(_ts, __ts)
    }
  }, [activeStoreView])

  return null
}
