import styled from '@emotion/styled'
import { Button } from '@shared/button'
import { darken } from 'polished'

export const ButtonRed = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active {
    background: #e8431b;
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: ${darken(0.2, '#e8431b')};
  }
`
