import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

/**
 * Hook that returns information from the cart in regards to winkelportaal
 */
export const useCartWinkelportaal = () => {
  const cartId = useCartId()
  const { cart } = useCart(cartId)

  const winkelportaal = cart?.ocpOrderInformation

  return {
    giftCardEnabled: winkelportaal?.giftcardEnabled ?? undefined,
  }
}
