import styled from '@emotion/styled'
import { Maybe, Scalars } from '@src/schema.generated'
import { RichText, HTMLSerializer } from 'prismic-reactjs'
import React, { ReactNode } from 'react'

import { globalWindow } from '@emico/ssr-utils'
import { theme } from '@emico/styles'

import { StyledCheckIcon } from './Header'

export const USP = styled.div`
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  p {
    display: inline;
    margin-bottom: 0;
    white-space: nowrap;
  }

  strong {
    color: ${theme.secondaryColor};
    font-weight: 500;
  }
`

const ChatLink = styled.strong`
  font-weight: 500;
  color: ${theme.secondaryColor};
  cursor: pointer;
`

// eslint-disable-next-line react/display-name
const handleContactInfoLinks = (): HTMLSerializer<ReactNode> =>
  function ContactLink(type, element, content, children, index) {
    if (!element.data.url.includes('#chat')) {
      return
    }

    return (
      <ChatLink
        key={index}
        onClick={() => {
          if (globalWindow?.Intercom) {
            globalWindow.Intercom('boot')
            globalWindow.Intercom('show')
          }
        }}
      >
        {content}
      </ChatLink>
    )
  }

interface HeaderUspProps {
  usp: Maybe<Scalars['Json']>
}
export const HeaderUsp = ({ usp }: HeaderUspProps) => (
  <USP>
    <StyledCheckIcon />{' '}
    <RichText render={[usp]} serializeHyperlink={handleContactInfoLinks()} />
  </USP>
)
