import { css, Global } from '@emotion/react'
import React from 'react'

import { theme } from '@emico/styles'

const defaultStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap');

  @font-face {
    font-family: 'Nexa';
    src: url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.ttf) format('truetype'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.woff) format('woff'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Nexa';
    src: url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.ttf) format('truetype'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.woff) format('woff'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Thin.woff2) format('woff2');
    font-weight: 100;
  }

  @font-face {
    font-family: 'Nexa';
    src: url(${process.env.PUBLIC_URL}/fonts/Nexa-Regular.ttf)
        format('truetype'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Regular.woff) format('woff'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Regular.woff2) format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Nexa';
    src: url(${process.env.PUBLIC_URL}/fonts/Nexa-bold.ttf) format('truetype'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-bold.woff) format('woff'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-bold.woff2) format('woff2');
    font-weight: 500 600;
  }

  @font-face {
    font-family: 'Nexa';
    src: url(${process.env.PUBLIC_URL}/fonts/Nexa-Black.ttf) format('truetype'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Black.woff) format('woff'),
      url(${process.env.PUBLIC_URL}/fonts/Nexa-Black.woff2) format('woff2');
    font-weight: 800;
  }
`

const globalStyles = () => css`
  ${defaultStyles}

  body {
    margin: 0;
    font-size: ${theme.fontSize};
    color: ${theme.onBackgroundColor};
    font-family: ${theme.fontFamilyPrimary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0;
  }

  h1 {
    font-size: ${theme.fontSizeH1};
  }

  h2 {
    font-size: ${theme.fontSizeH2};
  }

  h3 {
    font-size: ${theme.fontSizeH3};
  }

  h4 {
    font-size: ${theme.fontSizeH4};
  }

  h5 {
    font-size: ${theme.fontSizeH5};
  }

  h6 {
    font-size: ${theme.fontSizeH6};
  }

  label {
    margin-bottom: 0;
  }

  a {
    color: ${theme.secondaryColor};
    text-decoration: none;
  }

  select > option {
    font-size: 16px;
    font-weight: 700;
    font-family: ${theme.fontFamilyPrimary};
    color: ${theme.onBackgroundColor};
  }
`

const componentStyles = (id: string) => css`
  ${defaultStyles}

  #${id} {
    margin: 0;
    font-size: ${theme.fontSize};
    color: ${theme.onBackgroundColor};
    font-family: ${theme.fontFamilyPrimary};
  }

  :where(#${id}) h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0;
  }

  :where(#${id}) h1 {
    font-size: ${theme.fontSizeH1};
  }

  :where(#${id}) h2 {
    font-size: ${theme.fontSizeH2};
  }

  :where(#${id}) h3 {
    font-size: ${theme.fontSizeH3};
  }

  :where(#${id}) h4 {
    font-size: ${theme.fontSizeH4};
  }

  :where(#${id}) h5 {
    font-size: ${theme.fontSizeH5};
  }

  :where(#${id}) h6 {
    font-size: ${theme.fontSizeH6};
  }

  :where(#${id}) label {
    margin-bottom: 0;
  }

  :where(#${id}) a {
    color: ${theme.secondaryColor};
    text-decoration: none;
  }

  :where(#${id}) select > option {
    font-size: 16px;
    font-weight: 700;
    font-family: ${theme.fontFamilyPrimary};
    color: ${theme.onBackgroundColor};
  }
`

const AppStyles = ({ id }: { id?: string }) => (
  <Global styles={id ? componentStyles(id) : globalStyles()} />
)

export default AppStyles
