import React, { SVGProps } from 'react'

export const GeoPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden="true"
    viewBox="-16 -42 32 42"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="4"
      d="M 0 0 L -12 -17 
      A 15 15 0 1 1 12 -17z 
      M 0 -25 m -1, 0 
      a 1.1,1.1 1 1,0 2,0 a 1.1,1.1 1 1,0 -2,0z
      M 0 -25 m -2.1, 0 
      a 2.1,2.1 1 1,0 4,0 a 2.1,2.1 1 1,0 -4,0z
      M 0 -25 m -3.1, 0 
      a 3.1,3.1 1 1,0 6.2,0 a 3.1,3.1 1 1,0 -6.2,0z
      M 0 -25 m -4.1, 0 
      a 4.1,4.1 1 1,0 8.2,0 a 4.1,4.1 1 1,0 -8.2,0z
      M 0 -25 m -5.1, 0 
      a 5.1,5.1 1 1,0 10.2,0 a 5.1,5.1 1 1,0 -10.2,0z"
    />
  </svg>
)
