import { globalWindow } from '@emico/ssr-utils'
interface Admin {
  id: string
  token: string
}

let admin: Admin | null = null
/* eslint-disable @typescript-eslint/no-explicit-any */
let token: Promise<any> | null = null

export const getAdminAuthentication = async () => {
  if (admin !== null) {
    return admin
  }

  const params = new URLSearchParams(globalWindow?.location.search)
  const adminToken = params.get('adminToken')
  const adminId = params.get('adminId')

  if (adminToken && adminId) {
    admin = {
      id: adminId,
      token: adminToken,
    }

    return admin
  }

  if (token === null) {
    token = (await fetch('/swiss_klaur/integration/admintoken/get')).json()
  }

  admin = {
    id: (await token)['adminId'],
    token: (await token)['token'],
  }

  return admin
}
