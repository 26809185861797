import { useConfiguration } from '@shared/configuration/useConfiguration'
import { useEffect } from 'react'

import { currencyCode } from '@emico/currency-code'

export const DefaultCurrencyCode = () => {
  const { loading, configuration } = useConfiguration()
  useEffect(() => {
    if (loading || !configuration?.defaultDisplayCurrencyCode) {
      return
    }
    currencyCode(configuration.defaultDisplayCurrencyCode)
  }, [loading, configuration])

  return null
}
