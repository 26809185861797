import { useCountries } from '@checkout/country'
import { Gender, genderMap } from '@checkout/gender/helpers'
import { useCart } from '@shared/cart/useCart'

export type BillingAddress = {
  gender?: Gender
  firstName?: string
  lastName?: string
  fullName?: string
  company?: string
  street?: string
  houseNumber?: string
  houseNumberAddition?: string
  postalCode?: string
  city?: string
  countryId?: string
  countryName?: string
  regionId?: number
  region?: string
  fullRegion?: string
}

/**
 * Hook that returns the billing address on the cart
 */
export const useCartBillingAddress = () => {
  const { cart } = useCart()
  const { countries } = useCountries()

  const {
    firstname,
    lastname,
    company,
    street: streetArray,
    postcode,
    city,
    country,
    region,
  } = cart?.billingAddress || {}

  const foundCountry = countries.find(
    (countryData) => countryData.id === country?.code,
  )

  const foundRegion = foundCountry?.availableRegions?.find(
    (regionData) => regionData?.code === region?.code,
  )

  return {
    gender: genderMap[cart?.billingAddress?.genderAddress ?? ''],
    firstName: firstname ?? undefined,
    lastName: lastname ?? undefined,
    email: cart?.email ?? undefined,
    fullName:
      firstname || lastname
        ? [firstname, lastname].filter((value) => Boolean(value)).join(' ')
        : undefined,
    company: company ?? undefined,
    street: streetArray?.[0] ?? undefined,
    houseNumber: streetArray?.[1] ?? undefined,
    houseNumberAddition: streetArray?.[2] ?? undefined,
    postalCode: postcode ?? undefined,
    city: city ?? undefined,
    countryId: country?.code ?? undefined,
    countryName: countries.find(
      (countryData) => countryData.id === country?.code,
    )?.fullNameLocale,
    regionId: foundRegion?.id ?? undefined,
    region: foundRegion?.name ?? undefined,
    fullRegion: foundRegion
      ? `${foundRegion.id}|${foundRegion.name}`
      : undefined,
  }
}
