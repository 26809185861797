import capitalize from '@src/utils/capitalize'

import { globalWindow } from '@emico/ssr-utils'

class UAError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'UAError'
  }
}

interface ProductEcommerceEvent {
  name: string
  id: string
  price: number
  brand: string
  category: string
  variant: string
  quantity: number
  dimension18: string
  dimension22: string
  dimension29: string
  dimension30: string
}

interface DataLayerEvent {
  event: string
  eventCategory: string
  eventAction?: string
  eventLabel?: string
  ecommerce?: {
    add: {
      products: ProductEcommerceEvent[]
    }
  }
}

export const pushEvent = (dataLayerEvent: DataLayerEvent) => {
  const { event, eventCategory } = dataLayerEvent

  if (!globalWindow?.dataLayer || !event || !eventCategory) {
    throw new UAError(
      'globalWindow.dataLayer is not available, no event is available or no eventCategory is available',
    )
  }

  const capitalizedEventCategory = eventCategory
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')

  globalWindow?.dataLayer.push({
    ...dataLayerEvent,
    eventCategory: capitalizedEventCategory,
  })
}
