import styled from '@emotion/styled'
import theme from '@src/theme'
import { rgba } from 'polished'

import { Button } from './Button'

export const ButtonPrimary = styled(Button)`
  font-family: ${theme.fontFamilyPrimary};
  font-weight: ${theme.fontWeights.black};

  &,
  &:hover,
  &:focus,
  &:active {
    background: ${theme.colorGreenPrimary};
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${theme.colorGreenDark};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${rgba(theme.colorGreenDark, 0.2)};
  }
`
