import { globalWindow } from '@emico/ssr-utils'
import { getActiveStoreView, setStoreViews } from '@emico/storeviews'

import storeViews from './storeViews'

export let activeStoreView =
  globalWindow && getActiveStoreView(storeViews, globalWindow.location)

const overriddenStoreCode = new URLSearchParams(
  globalWindow?.location.search,
).get('storecode')
if (overriddenStoreCode) {
  const storeView = storeViews.find(
    (store) => store.code === overriddenStoreCode,
  )
  if (storeView) {
    activeStoreView = storeView
  }
}

if (activeStoreView) {
  setStoreViews(storeViews, activeStoreView)
}
