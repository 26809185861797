import styled from '@emotion/styled'
import { rgba } from 'polished'
import React, { ReactNode } from 'react'

import { CheckIcon } from '@emico/icons'
import { theme } from '@emico/styles'
import { overrideOptional } from '@emico/utils'

export interface StyledCheckboxProps {
  label?: ReactNode
}

export const StyledCheckIcon = overrideOptional(
  styled(CheckIcon)`
    color: white;
    /* IE needs sizes for svgs */
    height: 12px;
    width: 12px;
    vertical-align: top;
  `,
)

export const StyledCheck = overrideOptional(
  styled.div`
    transform: scale(0);
    transform-origin: center center;
    transition: transform 0.2s ease;
    line-height: 1;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    /* Selects:
            & this check
            * any parent (div by default, but is overridable)
            + next to a checked input */
    input[type='checkbox']:checked + * & {
      transform: scale(1);
    }

    input[type='checkbox']:not([disabled]):checked:active + * & {
      transform: scale(0.8);
    }
  `,
)

export const createVisualCheckbox = ({
  size = 16,
  borderSize = 2,
  color = theme.primaryColor,
  inactiveColor = theme.gray600,
} = {}) =>
  styled.div`
    width: ${size}px;
    height: ${size}px;
    border: solid ${borderSize}px ${inactiveColor};
    border-radius: 2px;

    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: baseline;

    transition: border-color 0.2s ease, background-color 0.2s ease;

    input[type='checkbox'][disabled] + & {
      opacity: 0.5;
      cursor: not-allowed;
    }

    input[type='checkbox']:checked + & {
      border-color: ${color};
      background: ${color};
    }

    input[type='checkbox']:focus + & {
      box-shadow: 0 0 0 3px ${rgba(color, 0.2)};
    }

    input[type='checkbox']:not([disabled]):active + & {
      background: ${rgba(inactiveColor, 0.5)};
    }

    input[type='checkbox']:not([disabled]):checked:active + & {
      background: ${color};
    }
  `

export const StyledVisualCheckbox = overrideOptional(createVisualCheckbox())

export const StyledLabel = overrideOptional(styled.span``)

export const StyledCheckboxLabel = overrideOptional(styled.label`
  position: relative;
`)

const StyledCheckbox = ({ label }: StyledCheckboxProps) => (
  <StyledCheckboxLabel>
    {/* <StyledHiddenCheckbox {...props} /> */}
    <StyledVisualCheckbox>
      <StyledCheck>
        <StyledCheckIcon />
      </StyledCheck>
    </StyledVisualCheckbox>
    <StyledLabel>{label}</StyledLabel>
  </StyledCheckboxLabel>
)

export default overrideOptional(StyledCheckbox)
