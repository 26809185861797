import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'

import { CheckIcon } from '@emico/icons'
import { theme } from '@emico/styles'
import { Radio } from '@emico/ui'
import { overrideOptional } from '@emico/utils'

// This radio is hidden but is used to handle the state.
const HiddenRadio = styled(Radio)`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`

const StyledVisualCheckbox = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: solid 1px ${theme.gray600};
  border-radius: 2px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: baseline;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  input[disabled] + & {
    opacity: 0.5;
    cursor: not-allowed;
  }

  input:checked + & {
    border-color: ${theme.primaryColor};
    background: ${theme.greenColor};
    border: none;
  }

  input:not([disabled]):checked:active + & {
    background: white;
  }
`

const StyledCheckIcon = overrideOptional(
  styled(CheckIcon)`
    color: white;
    /* IE needs sizes for svgs */
    height: 12px;
    width: 12px;
    position: relative;
    top: 2px;
  `,
)

const StyledCheck = overrideOptional(
  styled.div`
    transform: scale(0);
    transform-origin: center center;
    transition: transform 0.2s ease;
    line-height: 1;
    text-align: center;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    /* Selects:
            & this check
            * any parent (div by default, but is overridable)
            + next to a checked input */
    input:checked + * & {
      transform: scale(1);
    }

    input:not([disabled]):checked:active + * & {
      transform: scale(0.8);
    }
  `,
)

const StyledRadioLabel = overrideOptional(styled.label`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-right: 20px;

  & + & {
    margin-right: 0;
  }
`)

export const StyledLabel = overrideOptional(styled.span`
  margin-bottom: 10px;
  font-weight: 600;
`)

interface Props extends ComponentProps<typeof Radio> {
  label?: ReactNode
  className?: string
  labelClassName?: string
}

const StyledRadio = ({
  disabled,
  label,
  className,
  'data-testid': testId,
  ...props
}: Props & {
  'data-testid'?: string
}) => (
  <StyledRadioLabel className={className} data-testid={testId}>
    <HiddenRadio {...props} />
    <StyledVisualCheckbox>
      <StyledCheck>
        <StyledCheckIcon />
      </StyledCheck>
    </StyledVisualCheckbox>
    <StyledLabel>{label}</StyledLabel>
  </StyledRadioLabel>
)

export default overrideOptional(StyledRadio)
