import { AddressFormValues } from '@checkout/address-form'
import { Cart, CartAddress, CartShippingAddress } from '@shared/cart/useCart'
import { M2RestAddressInput } from '@src/schema.generated'

// TODO: rework utils
function cleanBillingAddress(address: Partial<CartAddress>) {
  if (!address) {
    return {}
  }
  const { __typename, country, genderAddress, ...cleanAddress } = address

  if ('selectedShippingMethod' in cleanAddress) {
    delete cleanAddress.selectedShippingMethod
  }

  return cleanAddress
}

function cleanShippingAddress(address: Partial<CartShippingAddress>) {
  address = cleanBillingAddress(address)
  const { __typename, isDummyAddress, questions, ...cleanAddress } = address
  return cleanAddress
}

export function getAddress(
  values: Partial<M2RestAddressInput>,
  formState: AddressFormValues,
  cart: Cart,
  type: 'shipping' | 'billing',
) {
  // eslint-disable-next-line prefer-const
  let { street, ...streetlessAddress } = values
  const newAddressValues: Partial<M2RestAddressInput> = {
    ...streetlessAddress,
    // sameAsBilling: Number(formState.sameAddress),
    street: undefined,
  }

  const cartAddress =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    type === 'shipping' ? cart?.shippingAddresses[0] : cart?.billingAddress

  if (street) {
    newAddressValues.street = street
  }

  // If no street is defined, get it from the cart shippingAddress
  if (!street && cartAddress?.street) {
    street = cartAddress?.street ?? undefined
  }

  // If still no street is defined, make an empty one
  if (!street) {
    street = ['', '']
  }

  const cleanAddress =
    type === 'shipping'
      ? cleanShippingAddress(
          cartAddress ? (cartAddress as CartShippingAddress) : {},
        )
      : cleanBillingAddress(cartAddress ?? {})

  if (type === 'shipping') {
    cartAddress as CartShippingAddress
  }
  // Filter object from all undefined values, only actual values
  // should overwrite the current shipping address.
  // except for the region and regionID allow null value
  const newAddress: CartAddress & { region?: string; regionId?: number } =
    Object.fromEntries(
      Object.entries(newAddressValues).filter(([key, value]) => {
        if (['region', 'regionId'].includes(key)) {
          return true
        }

        return Boolean(value)
      }),
    ) as CartAddress & { region?: string; regionId?: number }

  const address: M2RestAddressInput = {
    // Re-use existing shipping address to attempt to maintain as much
    // data as possible
    ...cleanAddress,
    ...cleanBillingAddress(newAddress),
    // Never save in the address book
    saveInAddressBook: 0,
    customerAddressId: undefined,
    region: newAddress.region ? newAddress.region : cartAddress?.region?.label,
    regionId: newAddress.regionId
      ? newAddress.regionId
      : cartAddress?.region?.code
      ? parseInt(cartAddress?.region?.code)
      : null,
  }

  if (type === 'shipping') {
    // Only the shipping address has a sameAsBilling property
    address.sameAsBilling = Number(formState.sameAddress)

    address.extensionAttributes = {
      ...address.extensionAttributes,

      // Extra info fields
      elevatorPresent: formState.lift ? formState.lift : null,
      floor: formState.floors?.toString(),
      storeyHouse: true,
      optOut: Boolean(values.extensionAttributes?.optOut),

      // Omnichannel fields
      reference: formState.reference,

      wouldRefer: formState.wouldRefer,

      particularities: formState.particularities,

      foundUs: formState.foundUs,

      age: formState.age,
      headboardMovable: formState.headboardMovable,
    }
  }

  // Remove __typename
  address.customAttributes = address.customAttributes?.map((o) => {
    if (!o) {
      return null
    }

    return {
      attributeCode: o.attributeCode,
      value: o.value,
    }
  })

  return address
}
