import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { CartTotals } from '@shared/cart/CartTotals'
import { useShoppingCartContext } from '@shared/cart/ShoppingCartContext'
import React from 'react'

import { ExternalLink } from '@emico/link'
import { maxWidth } from '@emico/styles'

import useRoutes from '../../routes'
import theme from '../../theme'

const Footer = styled.div`
  background: ${theme.gray050};
  padding: ${theme.spacing.x4}px ${theme.spacing.x5}px;
  box-sizing: border-box;
  width: 100%;
`

const StyledExternalLink = styled(ExternalLink)`
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #fff !important;
  background: ${theme.greenColor};
  border: 0;
  border-radius: 30px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 8px;

  &:hover {
    background: ${theme.colorGreenDark};
    color: #fff;
    text-decoration: none;
  }

  @media ${maxWidth('lg')} {
    font-size: 14px;
  }
`

export const ShoppingCartFooter = () => {
  const routes = useRoutes()
  const { cart } = useShoppingCartContext()

  if (!cart || !cart?.items || cart.items.length <= 0) {
    return null
  }

  return (
    <Footer>
      <CartTotals />
      <StyledExternalLink
        analyticsContext="header"
        analyticsName="Shopping cart"
        to={routes.cart}
      >
        <Trans id="header.cart.slideout.button.order">
          Verder naar bestellen
        </Trans>
      </StyledExternalLink>
    </Footer>
  )
}
