import React, { SVGProps } from 'react'

export const GlobeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M180.6 127.4c-4-2-3.6-22.5 2.6-24.1-.5-8.1 7.3-13.9 12.2-11.3-.1-1.3-.3-2.6-.5-3.9-3.9-4.2-1.4-12.4-4.3 1.6-7.6 3.4-7.2-9.8-2.9-11.6-1.1-2.3-1.4-4.3.8-4.8 1.7-.8 1.8-2.9 1.3-5.5-4.9-13.2-12.6-24.9-22.5-34.6-1.7 4.9-9.8 4.8-12.9 12.3-11.5 3.4-9.2-23-14.5-28.7-6-.2-7.8-2.5-7.8-5.5-1-.4-2-.7-3.1-1-.9 1.5-4 2.1-10.1 0-1.2-4.5-14.4 7.5-11.5-.6 1.6-1.3 3.1-2.3 4.5-3.1-2.2-.3-4.5-.5-6.8-.6 3.9 1.8-13.3 10.2-10.1.9 1.7-1.9 2.8.7 5.8-1h-.3c-15.4 0-29.9 3.7-42.8 10.2 17.3 1 38.3 9.5 46.4-.7 3.9-2.3 3.3-3 8.1-1 6.7-2.7 21.4 8 22.9 16-2.7 5.1-8.6-4.8-5.4 7.1-.2 5.2-7.7-3.2-11.3-5.8-4.7 1.2-11.3 1.6-12.5-.4-1.4-3-17.5 8.7-9.9 11.9 12.2 6.8 11.3 26.1 17.6-7.7 2.3-9.6 11.8 15.2 15.1 7.1.8.5 8.8 12.9 9.9 16.5 3.7 10.1-3.5 16.6-5.1 1.7 0-5.4-6 .3-7.1 2.6-.1 4.5 12.8 9.6-2 8.2-6.7.6-6.1 8.4-14.8 8-2.2 5.2-4.9 13.6-11.4 17.3-1.8 15.2-3.9 4.5-8.2.3-7.1-.2-23.3 2.1-18.2 15.3 1.9 10.1 12.6-4.3 14.2.9-.9 2.3-1.4 8.2 2.6 6.8 15.6 23.1 14-5.2 46.9 15.9 9.7 10.1 9.8 5.7 22.4 13.4 0 19.3-29.7 28.4-39.4 45.1 34.5-6.7 62.4-31.9 72.9-64.9-3.4-.5-7.3 3.2-10.8-2.3zM84 9.8c5.9-2 7 3.4 13.3 2.6 8 9.7-24.2 7-13.3-2.6z"
    />
    <path
      fill="currentColor"
      d="M107 166.5c-21.5-9.4 2.8-34.9-10.8-32.9-7.7-2.3-10.5-6.5-19-11.7-5.4-2.6-22.9-2.8-18.2-15.3-10.7-22.4-4.3-2.7-5.7 1.4-2.8-2.6-8-15.1-10.2-20.7 0-15.3-.1-27.4 5.4-40.9 2-11.3-13.8-11.4-19.9-3.7-1.6-.1-1.7-1.2-1-3C13.8 56.2 5.4 77.6 5.4 100.9c0 52.6 42.6 95.2 95.2 95.2 3 0 6-.2 9-.4-.5-10.5 8.7-24.9-2.6-29.2z"
    />
  </svg>
)
