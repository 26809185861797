import { globalWindow } from '@emico/ssr-utils'

const useMagentoCartEvent = () => (type: string) => {
  let event

  if (typeof globalWindow?.CustomEvent === 'function') {
    event = new Event(type, {
      bubbles: true,
    })
  } else {
    /* IE fallback */
    event = globalWindow?.document.createEvent('CustomEvent')
    event?.initCustomEvent(type, true, true, undefined)
  }

  if (event) {
    if (globalWindow?.document.getElementById('product_addtocart_form')) {
      globalWindow?.document
        .getElementById('product_addtocart_form')
        ?.dispatchEvent(event)
    } else {
      globalWindow?.document?.dispatchEvent(event)
    }
  }
}

export default useMagentoCartEvent
