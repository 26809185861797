import { Trans } from '@lingui/macro'
import useCartId from '@shared/cart/useCartId'
import { Col } from '@src/components/Col'
import useRoutes from '@src/routes'
import React from 'react'
import { useRouteMatch } from 'react-router'

import { globalWindow } from '@emico/ssr-utils'

import { AdyenPaymentValidation } from './AdyenPaymentValidation'

export const AdyenReturn = () => {
  const { params } = useRouteMatch<{
    orderId?: string
    maskedOrderId?: string
  }>()
  const routes = useRoutes()
  const cartId = useCartId()
  if (!globalWindow) {
    return null
  }
  if (!params.orderId || !cartId || !params.maskedOrderId) {
    globalWindow.location.href = routes.cart
    return null
  }

  return (
    <Col md={12}>
      <Trans>Please wait while we are validating your payment.</Trans>
      <AdyenPaymentValidation
        orderId={params.orderId}
        maskedOrderId={params.maskedOrderId}
      />
    </Col>
  )
}
