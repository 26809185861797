import { useAdyenPaymentMethods } from '@checkout/adyen'
import { SubtotalLine } from '@checkout/cart-summary/CartSummaryDetails'
import { CartSummaryLine } from '@checkout/cart-summary/CartSummaryLine'
import { useFlowEnhancers } from '@checkout/flow'
import { useRemoveGiftCard } from '@checkout/gift-card/useRemoveGiftCard'
import { usePaymentMethods } from '@checkout/payment-methods/usePaymentMethods'
import { useGiftCardVar } from '@checkout/vars'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { TotalSegment, useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'
import { BinIcon } from '@shared/icons'
import theme from '@src/theme'
import React, { useState, useEffect } from 'react'

const StyledRemoveGiftCard = styled.a`
  cursor: pointer;

  &:hover {
    color: ${theme.primaryColor} !important;
    text-decoration: underline;
  }
`

const StyledParagraph = styled.p`
  font-weight: bold;
  font-size: 12px;
  padding-left: 78px;
  color: #615e5e;
`

const StyledParagraphButton = styled(StyledParagraph)`
  cursor: pointer;
`

interface CartSummaryGiftCardProps {
  totals?: TotalSegment
}

export const CartSummaryGiftCard = ({ totals }: CartSummaryGiftCardProps) => {
  const [loading, setLoading] = useState(false)
  const [removed, setRemoved] = useState<string>()
  const { viewOrder } = useFlowEnhancers()
  const cartId = useCartId()
  const { cart, refetch: refetchCart } = useCart()
  const { removeGiftCard } = useRemoveGiftCard()
  const { setGiftCardVarState } = useGiftCardVar()

  const { getPaymentMethods } = usePaymentMethods()

  const { getAdyenPaymentMethods } = useAdyenPaymentMethods()

  const summaryGiftCardRemovingLabel = t({
    message: 'Removing Gift Card...',
  })

  useEffect(() => {
    if (totals && removed && !loading) {
      setRemoved(undefined)
    }
  }, [totals, removed, loading])

  if (removed) {
    return (
      <StyledParagraphButton onClick={() => setRemoved(undefined)}>
        <Trans>Giftcard {removed} removed!</Trans>
      </StyledParagraphButton>
    )
  }

  if (loading) {
    return <StyledParagraph>{summaryGiftCardRemovingLabel}</StyledParagraph>
  }

  if (!totals || !cartId) {
    return null
  }

  if (totals.amount.value === 0) {
    return null
  }

  async function handleClick() {
    const giftCardCode = cart?.appliedGiftCards?.[0]?.code

    if (!giftCardCode) {
      return
    }

    setLoading(true)

    await removeGiftCard({
      gift_card_code: giftCardCode,
      cart_id: cartId ?? '',
    })

    setGiftCardVarState({
      id: undefined,
      isApplied: undefined,
    })

    await refetchCart()

    await getPaymentMethods()
    await getAdyenPaymentMethods()

    setRemoved(giftCardCode)
    setLoading(false)
  }

  return (
    <CartSummaryLine price={totals.amount.value ?? 0}>
      {viewOrder ? (
        <SubtotalLine>
          <Trans>Sleep Credit</Trans>
        </SubtotalLine>
      ) : (
        <SubtotalLine>
          {!loading && (
            <StyledRemoveGiftCard onClick={handleClick}>
              <BinIcon width={15} height={15} /> <Trans>Remove</Trans>
              <br />
              <Trans>Sleep Credit</Trans>
            </StyledRemoveGiftCard>
          )}
          {loading && summaryGiftCardRemovingLabel}
        </SubtotalLine>
      )}
    </CartSummaryLine>
  )
}
