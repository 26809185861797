import { APP_ID as CONFIGURATOR_APP_ID } from '@configurator/initConfigurator'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { CartItem } from '@shared/cart/useCart'
import { PillowLoader } from '@src/components/PillowLoader'
import { Price } from '@src/components/Price'
import useMagentoCartEvent from '@src/hooks/useMagentoCartEvent'
import { SimpleCartItem, SimpleProduct } from '@src/schema.generated'
import CartItemOptions from '@src/shared/cart/CartItemOptions'
import { useRemoveCartItem } from '@src/shared/cart/useRemoveCartItem'
import React, { useState } from 'react'

import { Link } from '@emico/link'
import { globalWindow } from '@emico/ssr-utils'
import { theme } from '@emico/styles'
import { H2, Image } from '@emico/ui'

const ProductWrapper = styled.div`
  position: relative;
  background: white;
  padding: ${theme.spacing.x5}px 0 ${theme.spacing.x4}px;
  border-bottom: 1px solid #d8d8d8;
`

const PreloaderContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const EmptyProduct = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
`

const StyledProduct = styled.div`
  background: white;
  padding: 0 ${theme.spacing.x5}px ${theme.spacing.x2}px;
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;

  img {
    height: 130px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing.x5}px;
  justify-content: space-between;
`

const FooterWrapper = styled.div`
  display: flex;
  padding: 0 ${theme.spacing.x5}px;
  justify-content: right;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled(H2)`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;
`

const StyledTitleLink = styled(Link)`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;

  &:active,
  &:visited {
    color: ${theme.primaryColor};
  }

  &:hover {
    color: ${theme.secondaryColor} !important;
  }
`

const Option = styled.div`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: ${theme.fontFamilyPrimary};
  color: ${theme.gray500};
  letter-spacing: 0.01em;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

// const StyledPricePreDiscount = styled(Price)`
//   margin: 0;
//   font-size: 16px;
//   font-weight: 500;
//   font-family: ${theme.fontFamilySecondary};
//   color: #b8b8b8;
//   letter-spacing: 0.01em;
//   margin-right: ${theme.spacing.x2}px;
//   text-decoration: line-through;
// `

const StyledPriceNew = styled(Price)`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;
`

const StyledEditLink = styled.span`
  margin: 0;
  font-size: 14px;
  color: ${theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Nexa', serif;
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
    color: ${theme.secondaryColor};
  }
`

const RemoveItem = styled.span`
  margin-left: ${theme.spacing.x4}px;
  font-size: 14px;
  color: ${theme.primaryColor};
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Nexa', serif;

  &:hover {
    text-decoration: underline;
    color: ${theme.secondaryColor};
  }
`

interface Props {
  item: CartItem
  setOpen: React.Dispatch<boolean>
}

declare global {
  interface Window {
    requirejs: unknown
  }
}

export const ShoppingCartItem = ({ item, setOpen }: Props) => {
  const [cartItemRemoved, setCartItemRemoved] = useState(false)
  const remove = useRemoveCartItem()
  const triggerEvent = useMagentoCartEvent()
  const removeShoppingCartItem = async (cartItemId: string) => {
    setCartItemRemoved(true)
    await remove(cartItemId)
    // If requirejs exists it is loaded by Magento so, it's a Magento page
    if (!globalWindow?.requirejs) {
      localStorage.removeItem('mage-cache-storage')
    }
    triggerEvent('minicart:reload')
  }

  const handleEdit = () => {
    setOpen(false)

    if (!globalWindow) {
      return
    }

    if ((item as SimpleCartItem)?.teleconnect?.teleconnectConfigEan) {
      const simpleProduct = item.product as SimpleProduct
      globalWindow.location.href = `/configurator?catalogAlias=${
        simpleProduct.teleconnectCatalogId
      }&search=${simpleProduct.teleconnectEan}&sku=${
        item.product.sku
      }#${CONFIGURATOR_APP_ID}/cartitem-${item.uid}/configuration-ean-${
        (item as SimpleCartItem)?.teleconnect?.teleconnectConfigEan
      }/review`
    }

    if (item.__typename === 'ConfigurableCartItem') {
      globalWindow.location.href = `/checkout/cart/configureSku/id/${item.id}/product_sku/${item.product.sku}`
    }
  }

  return (
    <ProductWrapper>
      {cartItemRemoved && (
        <EmptyProduct>
          <PreloaderContainer>
            <PillowLoader />
            <Trans>Product removed</Trans>
          </PreloaderContainer>
        </EmptyProduct>
      )}
      <StyledProduct>
        <Image
          url={item.product.image?.url ?? ''}
          alt={item.product.image?.label ?? ''}
          width={170}
          height={130}
        />
        <ContentWrapper>
          <Content>
            {item.product.canonicalUrl ? (
              <StyledTitleLink
                external
                analyticsName="product"
                analyticsContext="shopping-cart-slideout"
                to={item.product.canonicalUrl}
              >
                {item.product.name}
              </StyledTitleLink>
            ) : (
              <Title>{item.product.name}</Title>
            )}

            {item.quantity && (
              <Option>
                <Trans>Quantity</Trans>: {item.quantity}
              </Option>
            )}

            <CartItemOptions cartItem={item} />
          </Content>
          <PriceWrapper>
            {item.prices?.rowTotalIncludingTax.value && (
              <StyledPriceNew value={item.prices?.rowTotalIncludingTax.value} />
            )}
          </PriceWrapper>
        </ContentWrapper>
      </StyledProduct>
      <FooterWrapper>
        {(item as SimpleCartItem)?.teleconnect?.teleconnectConfigEan ||
          (item.__typename === 'ConfigurableCartItem' && (
            <StyledEditLink onClick={handleEdit}>
              <Trans>Edit</Trans>
            </StyledEditLink>
          ))}

        <RemoveItem onClick={() => removeShoppingCartItem(item.uid)}>
          <Trans>Remove</Trans>
        </RemoveItem>
      </FooterWrapper>
    </ProductWrapper>
  )
}
