import { useFlow, useFlowEnhancers } from '@checkout/flow'
import styled from '@emotion/styled'
import { plural, t, Trans } from '@lingui/macro'
import { CartItem } from '@shared/cart/useCart'
import React from 'react'

import { maxWidth } from '@emico/styles'

const Header = styled.header`
  font-weight: 900;

  @media ${maxWidth('sm')} {
    font-size: 12px;
    font-weight: 700;
  }
`

interface CartSummaryHeaderProps {
  amountOfProducts: number
}

export const getTotalAmountOfProducts = (items: Array<Partial<CartItem>>) =>
  items.reduce((cur, prev) => cur + (prev?.quantity ?? 0), 0)

export const CartSummaryHeader = ({
  amountOfProducts,
}: CartSummaryHeaderProps) => {
  const flow = useFlow()
  const { omnichannel, quotation } = useFlowEnhancers()

  const getTitle = () => {
    if (flow === 'rma') {
      return t({
        message: 'Return Overview',
      })
    }
    if (quotation) {
      return t({
        message: 'My Quotation',
      })
    }
    return t({
      message: 'My Order',
    })
  }

  const renderOrderCondition = () => {
    switch (flow) {
      case 'rma':
        return t({
          message: 'RMA Order',
        })
      case 'delivery':
        return t({
          message: 'Delivery Order',
        })
      case 'web':
        return t({
          message: 'Webshop Order',
        })
      case 'pickup':
        return t({
          message: 'Pickup Order',
        })
      case 'cash_sale':
        return t({
          message: 'Cash Sales Order',
        })
      default:
        return ''
    }
  }

  const ticketProductsLabel = (productsLength: number) =>
    t({
      message: plural(productsLength, {
        1: '# product',
        other: '# products',
      }),
    })

  return (
    <div>
      <Header>
        {getTitle()}
        {': '}
        {ticketProductsLabel(amountOfProducts)}
      </Header>
      {omnichannel && <Trans>Order Condition: {renderOrderCondition()}</Trans>}
    </div>
  )
}
