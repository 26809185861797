import styled from '@emotion/styled'
import React from 'react'

import { ChevronRightSolidIcon } from '@emico/icons'
import { PrismicImage, PrismicLink } from '@emico/prismic'
import { theme } from '@emico/styles'

import { SubmenuBodyMenuVisual } from '../schema.generated'
import { pushEvent } from '../utils/analytics'

const SubmenuVisualWrapper = styled.div`
  position: relative;
`
const SubmenuVisual = styled(PrismicImage)`
  display: block;
  width: 100%;
  height: auto;
`
const DummyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  color: white;
  background-color: ${theme.secondaryColor};
`
const StyledChevronRightSolidIcon = styled(ChevronRightSolidIcon)`
  width: 18px;
  height: 18px;
  flex: 1;
`

interface PrismicSliceMenuVisualProps {
  slice: SubmenuBodyMenuVisual
  submenuTitle: string
  columnId: number
}

export const PrismicSliceMenuVisual = ({
  slice,
  submenuTitle,
  columnId,
}: PrismicSliceMenuVisualProps) =>
  slice.primary?.link && slice.primary?.image ? (
    <SubmenuVisualWrapper>
      <PrismicLink
        external
        to={slice.primary.link}
        analyticsName=""
        analyticsContext=""
        onClick={() => {
          pushEvent({
            event: `menu click`,
            eventCategory: `menu`,
            eventAction: 'click',
            eventLabel: `${submenuTitle} - banner - column: ${columnId}`,
          })
        }}
      >
        {slice.primary.image && (
          <SubmenuVisual
            loading="lazy"
            image={slice.primary.image}
          ></SubmenuVisual>
        )}

        {slice.primary.button && (
          <DummyButton>
            <StyledChevronRightSolidIcon />
          </DummyButton>
        )}
      </PrismicLink>
    </SubmenuVisualWrapper>
  ) : (
    <SubmenuVisualWrapper>
      {slice.primary?.image && (
        <SubmenuVisual
          loading="lazy"
          image={slice.primary.image}
        ></SubmenuVisual>
      )}
    </SubmenuVisualWrapper>
  )
