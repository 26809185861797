import {
  M2RestCustomAttribute,
  M2RestCustomAttributeDataInput,
  Maybe,
} from '@src/schema.generated'

export type Gender = 'male' | 'female' | undefined

export const genderMap: { [key: string]: Gender } = {
  '2688': 'male',
  '2689': 'female',
}

export const reversedGenderMap = Object.fromEntries(
  Object.entries(genderMap).map(([code, gender]) => [gender, code]),
)

/**
 * Returns the gender in a more readable format
 * @param customAttributes Magento Custom attributes
 */
export function getGenderFromCustomAttributes(
  customAttributes?: Array<Maybe<M2RestCustomAttribute>>,
): Gender {
  const id = getGenderFromCustomAttributesUnmapped(customAttributes)

  return genderMap[id ?? '']
}

export function getGenderFromCustomAttributesUnmapped(
  customAttributes?: Array<Maybe<M2RestCustomAttribute>>,
): string | null {
  const genderObject = customAttributes?.find(
    (o) => o?.attributeCode === 'gender_address',
  )
  const id = genderObject?.value

  return id ?? null
}

export function getCustomAttributesFromGender(
  gender: Gender,
): M2RestCustomAttributeDataInput | undefined {
  if (!gender) {
    return
  }

  return {
    attributeCode: 'gender_address',
    value: reversedGenderMap[gender],
  }
}
