import styled from '@emotion/styled'
import React, {
  ReactElement,
  PropsWithChildren,
  useReducer,
  Dispatch,
  useContext,
} from 'react'

import { AnswerProps } from './components/Answer'
import { MessageProps } from './components/Message'
import { AdviceRequestData } from './components/types/AdviceRequest'

const ChatPadding = styled.div`
  padding: 40px 0;
`

export enum ChatType {
  MATTRESS = 'mattress',
  PILLOW = 'pillow',
  TOPPER = 'topper',
  DUVET = 'duvet',
}

export interface GuideResult {
  id?: number
  url?: string
  within_budget: boolean
  products?: Product[]
}

export interface AdviceResponse {
  response: GuideResult
  request: AdviceRequestData
}

export interface Product {
  id: string
  sku: string
  score: number
  name: string
  url: string
  image: string
  normal_price: number
  special_price?: number
  review_score: number
  review_amount: number
  width: number
  length: number
  u_sp1?: string
  u_sp2?: string
  u_sp3?: string
  label?: string
}

export interface AdvisorUsp {
  identifier: string
  text: string
}

export interface ChatState {
  chatType: ChatType
  messages: Array<ReactElement<MessageProps>>
  catalog: Array<ReactElement<MessageProps>>
  answers: AnswerProps[]
  result?: GuideResult
  usps: AdvisorUsp[]
}

export type MessageActionType =
  | { type: 'SET_CHAT_TYPE'; chatType: ChatType }
  | { type: 'ADD_MESSAGE'; message: React.ReactElement<MessageProps> }
  | {
      type: 'SET_MESSAGES'
      messages: Array<React.ReactElement<MessageProps>>
    }
  | { type: 'ADD_ANSWER'; answer: AnswerProps }
  | { type: 'SET_RESULT'; result: GuideResult }
  | { type: 'RESET_FORM'; key: string; identifier: string }
  | { type: 'ADD_USP'; usp: AdvisorUsp }

const initialState: ChatState = {
  chatType: ChatType.MATTRESS,
  catalog: [],
  messages: [],
  answers: [],
  usps: [],
}

export const ChatContext = React.createContext<{
  state: ChatState
  dispatch: Dispatch<MessageActionType>
}>({
  state: initialState,
  dispatch: () => null,
})

export const chatReducer = (state: ChatState, action: MessageActionType) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.message],
      }

    case 'SET_MESSAGES':
      return {
        ...state,
        catalog: action.messages,
      }

    case 'ADD_ANSWER':
      return {
        ...state,
        answers: [...state.answers, action.answer],
      }

    case 'SET_RESULT':
      return {
        ...state,
        result: action.result,
      }

    case 'RESET_FORM': {
      const messageIndex = state.messages.findIndex((o) => o.key === action.key)
      const messages = state.messages.slice(0, messageIndex + 1)

      const answerIndex = state.answers.findIndex(
        (o) => o.identifier === action.identifier,
      )
      const answers = state.answers.slice(0, answerIndex)

      return {
        ...state,
        messages,
        answers,
      }
    }

    case 'ADD_USP': {
      const usps = [...state.usps]
      const index = usps.findIndex(
        ({ identifier }) => identifier === action.usp.identifier,
      )

      if (index !== -1) {
        usps[index] = action.usp
        return { ...state, usps }
      }

      return { ...state, usps: [...state.usps, action.usp] }
    }

    case 'SET_CHAT_TYPE':
      return {
        ...state,
        chatType: action.chatType,
      }
  }
}

export const ChatProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(chatReducer, initialState)

  return (
    <ChatPadding>
      <ChatContext.Provider value={{ state, dispatch }}>
        {children}
      </ChatContext.Provider>
    </ChatPadding>
  )
}

export const useChat = () => useContext(ChatContext)
export const useChatType = () => {
  const { state } = useChat()
  return state.chatType
}
