import useCartId from '@shared/cart/useCartId'
import { useMagentoSession } from '@src/components/MagentoSessionContext'
import isOmnichannelStoreView from '@src/utils/isOmnichannelStoreView'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useActiveStoreView } from '@emico/storeviews'

import { Cart, useCartActual } from './useCart'

interface ShoppingCartContextInterface {
  cart: Cart | null
  cartLoading: boolean
  setCart: Dispatch<SetStateAction<Cart | null | undefined>>
  refreshCart: () => Promise<void>
}

const ShoppingCartContext = createContext<ShoppingCartContextInterface>({
  cart: null,
  cartLoading: false,
  setCart: () => {
    //
  },
  refreshCart: async () => {
    //
  },
})

interface Props {
  children: JSX.Element
  forceGet?: boolean
}

const FILTERED_REJECTION_ITEMS = 'Z1'

export const ShoppingCartProvider = ({ children, forceGet = false }: Props) => {
  const [cart, setCart] = useState<Cart | null | undefined>(undefined)
  const cartId = useCartId()
  const { session, refreshSession } = useMagentoSession()
  const { refetch: getCart, cart: cartData, loading, called } = useCartActual()
  const storeView = useActiveStoreView()

  useEffect(() => {
    if (loading || !called) {
      setCart(undefined)
      return
    }
    if (!cartData) {
      setCart(null)
      return
    }
    if (isOmnichannelStoreView(storeView)) {
      setCart({
        ...cartData,
        items:
          cartData.items?.filter(
            (item) => item?.rejectionReason !== FILTERED_REJECTION_ITEMS,
          ) ?? [],
      })
    } else {
      setCart(cartData)
    }
  }, [cartData, storeView, loading, called])

  useEffect(() => {
    if (!called && !loading && forceGet && cartId) {
      getCart()
    }
  }, [called, loading, getCart, forceGet, cartId])

  useCallback(async () => {
    if (session?.cartId === '') {
      return
    }
    getCart()
  }, [getCart, session?.cartId])

  const refreshCart = useCallback(async () => {
    if (refreshSession) {
      if (session?.cartId === '') {
        await refreshSession()
      }
    }
    if (cartId) {
      getCart()
    }
  }, [refreshSession, getCart, session?.cartId, cartId])

  const value = {
    cart: useMemo(() => (cart === undefined ? null : cart), [cart]),
    cartLoading: loading,
    setCart,
    refreshCart,
  }

  return (
    <ShoppingCartContext.Provider value={value}>
      {children}
    </ShoppingCartContext.Provider>
  )
}

export function useShoppingCartContext() {
  return useContext(ShoppingCartContext)
}
