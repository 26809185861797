import { makeVar, useReactiveVar } from '@apollo/client'
import { GiftCardFormValues } from '@checkout/gift-card-form/GiftCardForm'

export interface GiftCardVarState extends Partial<GiftCardFormValues> {
  id?: string
  isApplied?: boolean
  balance?: number
}

const giftCardState = makeVar<GiftCardVarState>({})

export const useGiftCardVar = () => {
  const giftCardVar = useReactiveVar(giftCardState)

  const getGiftCardVar = () => giftCardState()

  const setGiftCardVarState = (stateValue: Partial<GiftCardVarState>) => {
    giftCardState({
      ...giftCardState(),
      ...stateValue,
    })
  }

  const setGiftCardVarValue = (
    key: keyof GiftCardVarState,
    value: string | boolean | number | null,
  ) => {
    if (giftCardVar[key] === value) {
      return
    }
    giftCardState({
      ...giftCardState(),
      [key as string]: value,
    })
  }

  return {
    giftCardVar,
    getGiftCardVar,
    setGiftCardVarState,
    setGiftCardVarValue,
  }
}
