import { useCart } from '@shared/cart/useCart'

/**
 * Hook that returns information from the cart in regards to sale condition
 */
export const useCartSaleCondition = () => {
  const { cart } = useCart()

  return {
    orderCondition: cart?.orderCondition ?? undefined,
    shipmentCondition: cart?.shipmentCondition ?? undefined,
  }
}
