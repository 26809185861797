import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { Dispatch } from 'react'

import { ShoppingCartIcon } from '@emico/icons'
import { theme } from '@emico/styles'
import { H2, ButtonUnstyled } from '@emico/ui'

const CartEmpty = styled.div`
  display: flex;
  align-items: center;
  height: calc(100% - 120px); // - ShoppingCartHeader
  flex-direction: column;
  justify-content: center;
  padding: 0 ${theme.spacing.x5}px;
`

const CartEmptyTitle = styled(H2)`
  margin: 0;
  padding: ${theme.spacing.x4}px ${theme.spacing.x5}px ${theme.spacing.x4}px;
  font-size: 20px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;
  text-align: center;
`

const StyledExternalLink = styled(ButtonUnstyled)`
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #fff !important;
  background: ${theme.onBackgroundColor};
  border: 0;
  border-radius: 5px;
  padding: 20px 30px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  &:hover,
  &:active,
  &:focus {
    color: white;
    text-decoration: none;
    background: ${theme.onBackgroundColor};
  }
`

const ShoppingCartIconWrapper = styled.div`
  background: #f9f9f9;
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
`

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  width: 50px;
  height: auto;
  color: #c5c5c5;
`

const ShoppingCartQuantity = styled.div`
  background: ${theme.secondaryColor};
  width: 15px;
  height: 15px;
  color: white;
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  setOpen: Dispatch<boolean>
}

export const EmptyCart = ({ setOpen }: Props) => (
  <CartEmpty>
    <ShoppingCartIconWrapper>
      <StyledShoppingCartIcon />
      <ShoppingCartQuantity>0</ShoppingCartQuantity>
    </ShoppingCartIconWrapper>
    <CartEmptyTitle>
      <Trans id="header.cart.slideout.emptycart">
        Je winkelmandje is leeg. Voeg wat producten toe!
      </Trans>
    </CartEmptyTitle>

    <StyledExternalLink
      onClick={() => setOpen(false)}
      analyticsName="Continue shopping"
      analyticsContext="Shopping Cart SlideOut"
    >
      <Trans id="header.cart.slideout.button.emptycart">Verder winkelen</Trans>
    </StyledExternalLink>
  </CartEmpty>
)
