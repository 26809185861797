import { TypedDocumentNode } from '@apollo/client'
import gql from 'graphql-tag'

import {
  SwissCartAddressFragment,
  SwissCartFragment,
} from './fragments.generated'

const cartAddressFragment = gql`
  fragment SwissCartAddressFragment on CartAddressInterface {
    firstname
    lastname
    telephone
    postcode
    street
    city
    company
    country {
      code
      label
    }
    region {
      code
      label
    }
  }
` as TypedDocumentNode<SwissCartAddressFragment, never>

export const cartFragment = gql`
  ${cartAddressFragment}
  fragment SwissCartFragment on Cart {
    id
    email
    totalQuantity
    orderInformation {
      optOut
      reference
      wouldRefer
      particularities
      foundUs
      age
      headboardMovable
    }
    ocpOrderInformation {
      giftcardEnabled
    }
    appliedGiftCards {
      code
    }
    shipmentCondition
    orderCondition
    quotePermissions {
      anyPermission
      edit {
        allowed
        messages
      }
      addressEdit {
        allowed
        messages
      }
      applyGiftCard {
        allowed
        messages
      }
      deliveryDateEdit {
        allowed
        messages
      }
    }
    mulesoftCustomerId
    sap {
      delivery {
        date
        delayedDate
        timeString
      }
      down
      nextDayDeliveryRelevant
      imported
    }
    shippingAddresses {
      ...SwissCartAddressFragment
      genderAddress
      sameAsBilling
      isDummyAddress
      questions {
        floor
        elevatorPresent
      }
      selectedShippingMethod {
        amount {
          value
        }
      }
    }
    billingAddress {
      ...SwissCartAddressFragment
      genderAddress
    }
    totalSegments {
      amount {
        value
      }
      code
      title
    }
    items {
      id
      uid
      quantity
      ga4Data
      rejectionReason
      customImage {
        url
      }
      product {
        id
        name
        sku
        merk
        teleconnectCatalogId
        teleconnectEan
        canonicalUrl
        categories {
          id
          name
        }
        image {
          label
          url
        }
      }
      ... on ConfigurableCartItem {
        configuredVariant {
          sku
        }
        configurableOptions {
          optionLabel
          valueLabel
        }
        customizableOptions {
          label
          values {
            label
          }
        }
      }
      ... on SimpleCartItem {
        teleconnect {
          teleconnectConfigEan
          additionalOptions {
            label
            value
          }
        }
        customizableOptions {
          label
          values {
            label
          }
        }
      }
      prices {
        priceInclTax {
          value
        }
        rowTotalIncludingTax {
          value
        }
        rowTotalWithDiscountInclTax {
          value
        }
      }
    }
    appliedCoupons {
      code
    }
    prices {
      grandTotal {
        value
      }
      subtotalExcludingTax {
        value
        currency
      }
      subtotalIncludingTax {
        value
      }
    }
  }
` as TypedDocumentNode<SwissCartFragment, never>
