import { globalWindow } from '@emico/ssr-utils'

import { CartEventItemPayload } from './main'

/**
 * Custom Error Class for GA4 (Google Analytics 4) Related Errors
 *
 * This error class is used to represent and handle errors related to Google Analytics 4 (GA4) tracking events and interactions.
 * It extends the built-in Error class and provides a more specific name for these errors.
 *
 * @param {string} message - A descriptive error message that explains the cause of the error.
 *
 * @example
 * throw new GA4Error('Global window dataLayer is not available');
 */
export class GA4Error extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'GA4Error'
  }
}

/**
 * Enumerates various types of events for tracking and analytics purposes.
 *
 * @enum {string} Event
 *
 * - `ADD_TO_CART`: Represents the action of adding an item to the shopping cart.
 * - `REMOVE_FROM_CART`: Represents the action of removing an item from the shopping cart.
 * - `BEGIN_CHECKOUT`: Indicates the start of the checkout process.
 * - `ADD_SHIPPING_INFO`: Represents the addition of shipping information during checkout.
 * - `ADD_PAYMENT_INFO`: Represents the addition of payment information during checkout.
 * - `PURCHASE`: Indicates the completion of a purchase transaction.
 * - `INTERACTION`: Represents various user interactions and events, typically used for tracking user engagement and interactions with the application.
 */
export enum Event {
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  VIEW_ORDER_OVERVIEW = 'view_order_overview',
  PURCHASE = 'purchase',
  INTERACTION = 'interaction',
  EMPTY = '',
}

/**
 * Represents an item within an e-commerce event payload.
 * TODO: Should be connected to the generated Product, CartItem or Article type or interface.
 */
export type EcommerceItem = Record<string, unknown>

/**
 * Represents a payload for tracking and logging events with associated data.
 *
 * @property {('EUR' | '') | undefined} currency - The currency associated with the event, typically in 'EUR' or an empty string.
 * @property {string | undefined} transaction_id - The unique identifier for a transaction or event, if applicable.
 * @property {number | undefined} value - The numerical value associated with the event, such as a monetary amount.
 * @property {number | undefined} tax - The tax amount associated with the event, if applicable.
 * @property {number | undefined} shipping - The shipping cost associated with the event, if applicable.
 * @property {string | undefined} coupon - The coupon code or identifier associated with the event, if applicable.
 * @property {EcommerceData | undefined} ecommerce - An object containing additional data related to e-commerce events, such as the value, currency, payment type, and items.
 * @property {EventParams | undefined} event_params - An object containing custom event parameters, including the event name, step name, selected value, configurator item, share platform, share URL, question, and answer.
 *
 */
type Payload = {
  value?: number
  ecommerce?: {
    value?: number
    currency?: 'EUR' | ''
    payment_type?: string
    items: EcommerceItem[]
    tax?: number | string
    shipping?: number | string
    coupon?: string
    transaction_id?: string
  }
  event_params?: {
    event_name: string
    step_name?: string
    selected_value?: string
    configurator_item?: string
    share_platform?: string
    share_url?: string
    question?: string
    answer?: string
    items?: EcommerceItem[]
  }
  page?: {
    environment?: string
    country?: string
    language?: string
    type?: string
    brand?: string
  }
}

/**
 * Pushes an event to Google Analytics 4 (GA4) dataLayer for tracking user interactions.
 *
 * @param {Event} event - The type of event to be tracked. Use the Event enum for predefined event names.
 * @param {Payload} payload - The data payload associated with the event. This payload contains information such as currency, transaction details, and custom event parameters.
 *
 * @throws {Error} Throws an Error if the global `dataLayer` object or `globalWindow` is not available, indicating a failure to track the event.
 *
 * @example
 * // Push an "add_to_cart" event with a payload
 * pushEvent(Event.ADD_TO_CART, {
 *   currency: 'EUR',
 *   transaction_id: '12345',
 *   value: 50.0,
 *   event_params: {
 *     event_name: 'add_to_cart',
 *     step_name: 'product_selection',
 *     selected_value: 'product_id_123',
 *   },
 * });
 */
export const pushEvent = (event: Event, payload: Payload) => {
  if (!globalWindow?.dataLayer) {
    throw new GA4Error('globalWindow.dataLayer is not available')
  }
  globalWindow?.dataLayer.push({
    ...payload,
    ...(event !== '' ? { event } : {}),
  })
}

/**
 * Parses GA4 data provided as a JSON string.
 *
 * @param {string | null} ga4Data - The GA4 data in JSON format.
 * @returns {Record<string, unknown>} - An object representing the parsed GA4 data.
 * @throws {GA4Error} - Throws an error if the input data is missing or empty.
 */
export const parseGA4Data = <T>(ga4Data: string | null): T => {
  if (!ga4Data) {
    throw new GA4Error('Missing ga4Data in product')
  }

  const parsedData = JSON.parse(ga4Data)

  if (Object.keys(parsedData).length === 0) {
    throw new GA4Error('Empty ga4Data in product')
  }

  return parsedData
}

/**
 * Maps a CartEventItemPayload to a GA4 item object.
 *
 * @param {CartEventItemPayload} product - The CartEventItemPayload to be mapped.
 * @param {number} index - The index of the item.
 * @returns {Record<string, unknown>} - An object representing the mapped GA4 item.
 */
const mapCartEventItemToItem = (
  product: CartEventItemPayload,
  index: number,
): Record<string, unknown> => ({
  ...parseGA4Data(product.ga4Data),
  index,
})

/**
 * Maps an array of CartEventItemPayload to an array of GA4 item objects.
 *
 * @param {CartEventItemPayload[]} products - An array of CartEventItemPayload objects to be mapped.
 * @returns {Record<string, unknown>[]} - An array of objects representing the mapped GA4 items.
 */
export const mapCartEventItemsToItems = (
  products: CartEventItemPayload[],
): Array<Record<string, unknown>> => products.map(mapCartEventItemToItem)
