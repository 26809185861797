import React, { SVGProps } from 'react'

export const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <path
          d="M43.344 549c.187 0 .343-.063.468-.188.126-.125.188-.28.188-.468V546c0-.188-.063-.344-.188-.469-.124-.125-.28-.187-.468-.187-.771 0-1.563-.125-2.375-.375-.292-.084-.51-.032-.657.156l-1.468 1.469c-1.938-1-3.407-2.469-4.407-4.407l1.47-1.468c.187-.188.239-.407.156-.657-.271-.75-.407-1.552-.407-2.406 0-.187-.062-.343-.187-.468S35.187 537 35 537h-2.344c-.187 0-.343.063-.468.188-.126.125-.188.28-.188.468 0 3.146 1.104 5.823 3.313 8.032 2.208 2.208 4.885 3.312 8.03 3.312z"
          transform="translate(-32 -537)"
        />
      </g>
    </g>
  </svg>
)
