import { makeVar, useReactiveVar } from '@apollo/client'
import { getVaimoCartId } from '@shared/cart/useCartId'
import { isSwissStoreView } from '@src/storeViews'

import { syncReactiveVar } from '@emico/reactive-var-utils'
import { useActiveStoreView } from '@emico/storeviews'

const localStorageKey = 'vaimo/sf'

export type VaimoStorefront = boolean
export const vaimoStorefrontVar = syncReactiveVar(
  makeVar<VaimoStorefront | undefined>(undefined),
  localStorageKey,
)

export default function useVaimoStorefront(): boolean {
  const vaimoStorefrontStorageValue = useReactiveVar(vaimoStorefrontVar)
  const storeView = useActiveStoreView()
  const vaimoStorefront =
    (isSwissStoreView(storeView) && storeView.vaimoStorefront) ?? false
  if (vaimoStorefront) {
    return vaimoStorefront
  }
  if (vaimoStorefrontStorageValue) {
    return vaimoStorefrontStorageValue
  }
  const vaimoCartId = getVaimoCartId()
  if (vaimoCartId) {
    vaimoStorefrontVar(true)
    return true
  }

  return false
}
