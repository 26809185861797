import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { ShoppingCartProvider } from '@shared/cart/ShoppingCartContext'
import { BarsUnevenIcon } from '@shared/icons'
import { ShoppingCart } from '@src/features/shopping-cart/ShoppingCart'
import useRoutes from '@src/routes'
import { Maybe, PrismicTypeHeader, Scalars } from '@src/schema.generated'
import theme from '@src/theme'
import React, { useState } from 'react'

import { CheckIcon, SearchIcon } from '@emico/icons'
import { ExternalLink } from '@emico/link'
import { usePrismicDocument } from '@emico/prismic'
import { default as SearchBar } from '@emico/search-bar'
import StoreViewSelector from '@emico/storeview-selector'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled, Container, useBreakpoints } from '@emico/ui'

import { HeaderCart } from './HeaderCart'
import { HeaderLogo } from './HeaderLogo'
import { HeaderMenu } from './HeaderMenu'
import { HeaderShopLocator } from './HeaderShopLocator'
import { HeaderUsp } from './HeaderUsp'

const SLIDEOUT_CART_ENABLED = process.env.REACT_APP_SLIDEOUT_CART_ENABLED

export const Bar = styled.header`
  background: ${theme.primaryColor};
  color: ${theme.onPrimaryColor};
  position: sticky;
  z-index: 90;
  top: 0;

  @media ${minWidth('md')} {
    top: -39px;
  }
`

const SearchBarWrapper = styled('div', {
  shouldForwardProp: (prop: string) => !['isActive'].includes(prop),
})<{ isActive: boolean }>(
  ({ isActive }) => css`
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    ${isActive &&
    css`
      display: block;
    `} @media ${minWidth('lg')} {
      display: block;
      position: relative;
      top: auto;
      flex: 1;
      max-width: 60%;
      margin: 0 ${theme.spacing.x3}px;
    }
  `,
)

const StyledSearchIcon = styled(SearchIcon)`
  color: ${theme.secondaryColor};
  width: 20px;
  height: 20px;

  @media ${minWidth('md')} {
    top: 10px;
    right: 90px;
  }
`

const Top = styled.div`
  padding: 7px 15px;
  display: none;

  @media ${minWidth('md')} {
    display: flex;
  }
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.x3}px ${theme.spacing.x2}px;

  @media ${minWidth('md')} {
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
  }
`

export const StyledCheckIcon = styled(CheckIcon)`
  width: 1em;
  margin-right: 0.3em;
  color: ${theme.greenColor};
  margin-top: -4px;
`

export const ActionButton = styled(ButtonUnstyled)`
  color: ${theme.onPrimaryColor};
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    color: ${theme.onPrimaryColor};
  }

  @media ${minWidth('md')} {
    flex-direction: row;
  }
`

export const ActionLabel = styled.span`
  font-size: ${theme.fontSizes.xxSmall};
  order: 3;
  margin-top: 2px;

  @media ${minWidth('md')} {
    font-size: initial;
    order: 1;
    display: inline;
  }
`

export const SearchLabel = styled.span`
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.xxSmall};
  position: absolute;
  font-weight: 500;
  top: 19px;
  right: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledBarsUnevenIcon = styled(BarsUnevenIcon)`
  color: ${theme.secondaryColor};
  width: 20px;
  height: 20px;
`

const Right = styled.div`
  margin-left: auto;
`

const MobileStoreViewSelector = styled(StoreViewSelector, {
  shouldForwardProp: (prop: string) => !['isAbsolute'].includes(prop),
})<{ isAbsolute: boolean }>(
  ({ isAbsolute }) => css`
    position: ${isAbsolute ? 'absolute' : 'unset'};
    top: 20px;
    left: 75px;
  `,
)

interface Props {
  hideSearch?: boolean
  hideMainMenu?: boolean
  hideHeaderStoreLocator?: boolean
}

const Header = ({
  hideMainMenu,
  hideSearch,
  hideHeaderStoreLocator,
}: Props) => {
  const routes = useRoutes()
  const { isMobile, isDesktop } = useBreakpoints()
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const toggleMobileMenu = () => setMenuIsOpen((menuIsOpen) => !menuIsOpen)
  const [isSearchBarVisible, setSearchBarVisible] = useState(false)
  const [shoppingCartIsOpen, setShoppingCartOpen] = useState<boolean>(false)

  const { data: headerData } = usePrismicDocument<PrismicTypeHeader>({
    key: 'document.type',
    value: 'header',
  })

  const searchBarSubmit = (searchQuery: string) =>
    `${routes.search}?q=${searchQuery}`

  return (
    <Bar>
      <Container>
        <Top>
          {headerData?.usp?.map((usp: Maybe<Scalars['Json']>, i: number) => (
            <HeaderUsp key={i} usp={usp} />
          ))}

          <Right>
            <StoreViewSelector />
          </Right>
        </Top>
        <Bottom>
          {!isDesktop && !hideMainMenu && (
            <>
              <ActionButton
                title={t({ id: 'header.menuLabel', message: 'Menu' })}
                analyticsName="Toggle"
                analyticsContext="header.menu.toggle"
                onClick={toggleMobileMenu}
              >
                <ActionLabel>
                  <Trans id="header.menu">Menu</Trans>
                </ActionLabel>
                <StyledBarsUnevenIcon />
              </ActionButton>
            </>
          )}

          {isMobile && (
            <>
              {hideHeaderStoreLocator ? (
                <MobileStoreViewSelector isAbsolute={Boolean(!hideMainMenu)} />
              ) : (
                <HeaderShopLocator />
              )}
            </>
          )}

          <ExternalLink
            to="/"
            analyticsContext="header"
            analyticsName="Logo link"
          >
            <HeaderLogo />
          </ExternalLink>

          {!isDesktop && !hideSearch && (
            <SearchLabel>
              <StyledSearchIcon
                onClick={() => setSearchBarVisible(!isSearchBarVisible)}
              />
              <Trans>Search</Trans>
            </SearchLabel>
          )}

          {!hideSearch && (
            <SearchBarWrapper isActive={isSearchBarVisible}>
              <SearchBar
                placeholder={t({
                  id: 'header.searchBar.placeholder',
                  message: 'What are you looking for?',
                })}
                onSearch={searchBarSubmit}
              />
            </SearchBarWrapper>
          )}
          <ShoppingCartProvider forceGet>
            <HeaderCart setShoppingCartOpen={setShoppingCartOpen} />
          </ShoppingCartProvider>
        </Bottom>

        {SLIDEOUT_CART_ENABLED === 'true' && isDesktop && (
          <ShoppingCartProvider>
            <ShoppingCart
              isOpen={shoppingCartIsOpen}
              setOpen={setShoppingCartOpen}
            />
          </ShoppingCartProvider>
        )}
      </Container>
      {!hideMainMenu && (
        <HeaderMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      )}
    </Bar>
  )
}

export default Header
