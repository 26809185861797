import styled from '@emotion/styled'

export const AdyenContainer = styled.div`
  .adyen-checkout__threeds2__challenge {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }

  .adyen-checkout__iframe {
    position: fixed;
    background: white;
    padding: 3rem;
    box-shadow: 3px 3px 3px black;
    border-radius: 8px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
`
