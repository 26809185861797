import styled from '@emotion/styled'
import { Button } from '@shared/button'
import { darken } from 'polished'

export const ButtonPink = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active {
    background: #ef8b73;
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: ${darken(0.2, '#ef8b73')};
  }
`
