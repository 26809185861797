import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { GlobeIcon } from '@shared/icons'
import { ToggleSwitch } from '@src/components/ToggleSwitch'
import theme from '@src/theme'
import { Option } from 'informed'
import React from 'react'

import { ChevronDownIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'
import { RadioGroup, SelectUnstyled } from '@emico/ui'

import CheckboxRadio from './CheckBoxRadio'
import { SettingType, Channel, Language } from './constants'

const SettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  &:nth-of-type(odd) {
    background: ${theme.gray050};
  }

  @media ${minWidth('md')} {
    padding: 25px 30px;
  }
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0;

  @media ${minWidth('md')} {
    font-size: 24px;
  }
`

const Description = styled.p`
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  color: ${theme.gray500};
  line-height: 1.1;
  margin-bottom: 0;

  @media ${minWidth('md')} {
    font-size: 16px;
  }
`

const SelectWrapper = styled.div`
  position: relative;
  margin-left: 15px;
`

const StyledGlobeIcon = styled(GlobeIcon)`
  color: ${theme.gray500};
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`

const Select = styled(SelectUnstyled)`
  font-weight: 600;
  width: auto;
  height: 40px;
  border: 1px solid ${theme.gray500};
  cursor: pointer;
  border-radius: 4px;
  padding: 0 35px 0 45px;
  margin: 0;

  @media ${minWidth('md')} {
    font-size: 16px;
    padding: 1px 40px 0 50px;
  }
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  color: ${theme.gray500};
  position: absolute;
  top: calc(50% + 1px);
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;

  @media ${minWidth('md')} {
    right: 15px;
  }
`

const RadioWrapper = styled.div`
  min-width: 140px;
  display: flex;
  justify-content: space-between;
`

interface Props {
  field: string
  title?: string
  description?: string
  value?: string | boolean
}

const { LANGUAGE, PREFERRED_CHANNEL } = SettingType
const { PHONE, EMAIL } = Channel
const { NL, DE } = Language

const Setting = ({ field, title, description, value }: Props) => (
  <SettingWrapper>
    <div>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </div>

    {field !== LANGUAGE && field !== PREFERRED_CHANNEL && (
      <ToggleSwitch field={field} value={0} initialValue={value} />
    )}

    {field === PREFERRED_CHANNEL && (
      <RadioWrapper>
        <RadioGroup field={field} initialValue={value}>
          <CheckboxRadio
            label={t({
              id: 'contactPreferences.settings.label.phone',
              message: 'Telephone',
            })}
            value={PHONE}
          />
          <CheckboxRadio
            label={t({
              id: 'contactPreferences.settings.label.email',
              message: 'Email',
            })}
            value={EMAIL}
          />
        </RadioGroup>
      </RadioWrapper>
    )}

    {field === LANGUAGE && (
      <SelectWrapper>
        <StyledGlobeIcon width="20px" height="20px" />
        <Select field={field} initialValue={value}>
          <Option value={NL}>
            {t({
              id: 'contactPreferences.settings.label.dutch',
              message: 'Dutch',
            })}
          </Option>
          <Option value={DE}>
            {t({
              id: 'contactPreferences.settings.label.german',
              message: 'German',
            })}
          </Option>
        </Select>
        <StyledChevronDownIcon />
      </SelectWrapper>
    )}
  </SettingWrapper>
)

export default Setting
