import { globalWindow } from '@emico/ssr-utils'

type AppType =
  typeof import('@src/features/omnichannel-menu/SessionsManagerMounter')

async function load() {
  return import(
    /* webpackPrefetch: true, webpackChunkName: "session-manager" */ '@src/features/omnichannel-menu/SessionsManagerMounter'
  )
}

declare global {
  interface Window {
    sessionManagement: number
  }
}

export default function init() {
  let _app: AppType

  const getApp = async () => {
    if (!_app) {
      _app = await load()
    }
    return _app
  }

  let _root: HTMLElement
  const getRoot = () => {
    if (!_root && globalWindow) {
      _root = globalWindow.document.createElement('div')
      globalWindow.document.body.appendChild(_root)
    }
    return _root
  }

  const _init = async () => {
    if (
      globalWindow?.location.pathname.includes('checkout') &&
      Boolean(globalWindow.sessionManagement)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(await getApp()).render(getRoot())
    }
  }

  globalWindow?.addEventListener('load', () => {
    _init()
  })
}
