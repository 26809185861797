import styled from '@emotion/styled'

import {
  Highlight,
  KeywordSuggestion,
  Label,
  SuggestionsContainer,
} from '@emico/search-bar-autocomplete'
import { maxWidth } from '@emico/styles'

import theme from '../theme'

const StyledSuggestionsContainer = styled(SuggestionsContainer.original)`
  @media ${maxWidth('sm')} {
    border: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const StyledKeywordSuggestion = styled(KeywordSuggestion.original)`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colorBlueGray};
  transition: none;
  padding: 0;
  margin-top: 10px;

  @media ${maxWidth('sm')} {
    font-size: 14px;
    font-family: ${theme.fontFamilySecondary};
    text-align: center;
    width: 48%;
    border: 1px solid ${theme.gray300};
    padding: 9px 10px;
  }

  &:after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    font-weight: 600;
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 10px 0 0;

    @media ${maxWidth('sm')} {
      padding: 9px 10px;
    }
  }
`

const StyledHighlight = styled(Highlight.original)`
  font-weight: 600;
`

const StyledLabel = styled(Label.original)`
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  color: ${theme.gray600};
  padding: 0;

  @media ${maxWidth('sm')} {
    padding: 0;
    flex: 0 0 100%;
  }
`

KeywordSuggestion.override(
  StyledKeywordSuggestion as typeof KeywordSuggestion.original,
)
Highlight.override(StyledHighlight as typeof Highlight.original)
Label.override(StyledLabel as typeof Label.original)
SuggestionsContainer.override(
  StyledSuggestionsContainer as typeof SuggestionsContainer.original,
)
