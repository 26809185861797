import { setContext } from '@apollo/client/link/context'

import { globalWindow } from '@emico/ssr-utils'
import { StoreView } from '@emico/storeviews'

import { getAdminAuthentication } from '../components/getAdminAuthentication'
import { getMagentoSession } from '../components/MagentoSessionContext'
import isOmnichannelStoreView from './isOmnichannelStoreView'

// eslint-disable-next-line
export const setLinkContext = (storeView: StoreView, sessionKey: string) =>
  setContext(async (_, { headers }) => {
    // Typing comes from Apollo package
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context: any = {
      headers: {
        ...headers,
        phpSessionId: (await getMagentoSession())?.sessionId,
        domain: globalWindow?.location.origin,
        sessionkey: sessionKey,
        Store: storeView.code,
      },
    }

    if (isOmnichannelStoreView(storeView)) {
      const admin = await getAdminAuthentication()

      if (admin.id) {
        context.headers.adminId = admin.id
      }

      if (admin.token) {
        context.headers.admintoken = admin.token
      }
    }

    return context
  })
