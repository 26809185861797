import { gql, TypedDocumentNode, useApolloClient } from '@apollo/client'

import { getAuthorizationContext } from '@emico/login-token'
import { Unmaybe } from '@emico/utils'

import {
  AdyenPaymentStatusQuery,
  AdyenPaymentStatusQueryVariables,
} from './useAdyenPaymentStatus.generated'

const ADYEN_PAYMENT_STATUS = gql`
  query AdyenPaymentStatus($orderNumber: String!, $cartId: String!) {
    adyenPaymentStatus(orderNumber: $orderNumber, cartId: $cartId) {
      action
      resultCode
    }
  }
` as TypedDocumentNode<
  AdyenPaymentStatusQuery,
  AdyenPaymentStatusQueryVariables
>

type AdyenPaymentStatus = Unmaybe<
  Unmaybe<AdyenPaymentStatusQuery, 'adyenPaymentStatus'>
>

const parseData = (
  data: AdyenPaymentStatusQuery | undefined,
): AdyenPaymentStatus | null => {
  if (!data) {
    return null
  }

  return data?.adyenPaymentStatus
}

export const useAdyenPaymentStatus = () => {
  const client = useApolloClient()

  const getAdyenPaymentStatus = async (
    variables: Partial<AdyenPaymentStatusQueryVariables>,
  ) => {
    const { data, ...rest } = await client.query({
      query: ADYEN_PAYMENT_STATUS,
      context: getAuthorizationContext(),
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables,
    })

    const parsedData = parseData(data)

    return {
      adyenPaymentStatus: parsedData,
      ...rest,
    }
  }

  return {
    getAdyenPaymentStatus,
  }
}
