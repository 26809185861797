import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useShoppingCartContext } from '@shared/cart/ShoppingCartContext'
import React, { Dispatch } from 'react'

import { CrossIcon } from '@emico/icons'
import { theme, maxWidth } from '@emico/styles'
import { H2, H3 } from '@emico/ui'

const Header = styled.div`
  background: ${theme.gray050};
  height: 120px;
  width: 100%;

  @media ${maxWidth('lg')} {
    height: 90px;
  }
`

const Title = styled(H2)`
  margin: 0;
  padding: ${theme.spacing.x4}px ${theme.spacing.x5}px 0;
  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;

  @media ${maxWidth('lg')} {
    font-size: 22px;
    padding: ${theme.spacing.x2}px ${theme.spacing.x4}px 0;
  }
`

const SubTitle = styled(H3)`
  margin: 0;
  padding: 0 ${theme.spacing.x5}px ${theme.spacing.x4}px ${theme.spacing.x5}px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.gray500};
  letter-spacing: 0.01em;

  @media ${maxWidth('lg')} {
    padding: 0 ${theme.spacing.x4}px;
  }
`

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 48px;
  right: 32px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: ${theme.primaryColor};
  cursor: pointer;

  @media ${maxWidth('lg')} {
    top: 28px;
    right: 38px;
  }
`

interface Props {
  setOpen: Dispatch<boolean>
}

const ProductQuantity = (quantity: number) => {
  switch (quantity) {
    case 1:
      return (
        <SubTitle>
          {quantity}{' '}
          <Trans id="header.cart.slideout.button.singular">product</Trans>
        </SubTitle>
      )
    default:
      return (
        <SubTitle>
          {quantity}{' '}
          <Trans id="header.cart.slideout.button.plural">producten</Trans>
        </SubTitle>
      )
  }
}

export const ShoppingCartHeader = ({ setOpen }: Props) => {
  const { cart } = useShoppingCartContext()

  const quantity = cart?.totalQuantity ?? 0

  return (
    <Header>
      <Title>
        <Trans id="header.cart.slideout.header">Jouw Winkelmand</Trans>
      </Title>

      {ProductQuantity(quantity)}

      <StyledCrossIcon
        onClick={() => {
          setOpen(false)
        }}
      />
    </Header>
  )
}
