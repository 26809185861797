import { getAddress } from '@checkout/address/utils'
import { useAdyenPaymentMethods } from '@checkout/adyen'
import { useGuestCartId } from '@checkout/cart'
import { SubtotalLine } from '@checkout/cart-summary/CartSummaryDetails'
import { CartSummaryLine } from '@checkout/cart-summary/CartSummaryLine'
import { useRemoveCouponFromCart } from '@checkout/coupon'
import { useFlowEnhancers } from '@checkout/flow'
import { usePaymentMethods } from '@checkout/payment-methods/usePaymentMethods'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { CartCoupon, useCart } from '@shared/cart/useCart'
import { BinIcon } from '@shared/icons'
import theme from '@src/theme'
import React, { useState } from 'react'

import { stripMaybes } from '@emico/utils'

const StyledRemoveCoupon = styled.a`
  cursor: pointer;

  &:hover {
    color: ${theme.primaryColor} !important;
    text-decoration: underline;
  }
`

export const CartSummaryCoupons = () => {
  const guestCartId = useGuestCartId()
  const [loading, setLoading] = useState(false)
  const { cart, refetch } = useCart(guestCartId)
  const { removeCouponFromCart } = useRemoveCouponFromCart()
  const { getPaymentMethods } = usePaymentMethods(false)
  const { getAdyenPaymentMethods } = useAdyenPaymentMethods(false)
  const { viewOrder } = useFlowEnhancers()

  if (!cart?.appliedCoupons || cart.appliedCoupons.length <= 0) {
    return null
  }

  async function handleClick() {
    setLoading(true)

    await removeCouponFromCart(cart?.id)

    const addressForPaymentMethods = cart && getAddress({}, {}, cart, 'billing')

    await refetch()

    if (addressForPaymentMethods) {
      await getPaymentMethods()
      await getAdyenPaymentMethods()
    }

    setLoading(false)
  }

  const summaryCouponsLabel = (coupons: CartCoupon[]) =>
    t({
      message: `Coupons: ${coupons.map((coupon) => coupon.code).join(', ')}`,
    })

  return (
    <CartSummaryLine price={0} noPrice>
      {viewOrder ? (
        <SubtotalLine>
          {summaryCouponsLabel(cart?.appliedCoupons.filter(stripMaybes))}
        </SubtotalLine>
      ) : (
        <SubtotalLine>
          {!loading && (
            <StyledRemoveCoupon onClick={handleClick}>
              <BinIcon width={15} height={15} /> <Trans>Remove</Trans> <br />
              {summaryCouponsLabel(cart?.appliedCoupons.filter(stripMaybes))}
            </StyledRemoveCoupon>
          )}
          {loading && <Trans>Removing Coupons...</Trans>}
        </SubtotalLine>
      )}
    </CartSummaryLine>
  )
}
