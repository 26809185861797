import { makeVar, useReactiveVar } from '@apollo/client'

type Status = 'idle' | 'busy' | 'block'

const statusState = makeVar<Status>('idle')

export const useStatusVar = () => {
  const statusVar = useReactiveVar(statusState)

  const getStatusVar = () => statusState()

  const setStatusVar = (value: Status) => {
    statusState(value)
  }

  return {
    statusVar,
    getStatusVar,
    setStatusVar,
  }
}
