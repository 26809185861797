import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { MapMarkerIcon } from '@shared/icons'
import useRoutes from '@src/routes'
import theme from '@src/theme'
import React from 'react'

import { Link } from '@emico/link'
import { minWidth } from '@emico/styles'

export const ShopLocatorIcon = styled(MapMarkerIcon)`
  color: ${theme.secondaryColor};
  height: 18px;
  width: auto;
  margin-bottom: 2px;
`
export const ShopLocator = styled('span')`
  color: ${theme.onPrimaryColor};
  font-family: ${theme.fontFamilySecondary};
  font-size: ${theme.fontSizes.xxSmall};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 60px;
  font-weight: 500;
  top: 20px;

  @media ${minWidth('md')} {
    display: none;
  }
`

export const HeaderShopLocator = () => (
  <ShopLocator>
    <Link
      analyticsContext="header"
      analyticsName="stores locator"
      to={useRoutes().stores}
    >
      <ShopLocatorIcon />
    </Link>
    <Trans id="header.shops">Winkels</Trans>
  </ShopLocator>
)
