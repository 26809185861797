import styled from '@emotion/styled'

import { Link as RCLink } from '@emico/link'
import { theme } from '@emico/styles'

RCLink.override(
  styled(RCLink.original)`
    color: ${theme.secondaryColor};
    text-decoration: none;
  ` as typeof RCLink.original,
)
