import React, { SVGProps } from 'react'

export const PinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 10 14" {...props}>
    <path
      fill="currentColor"
      d="M5.376 13.363C9.066 8.015 9.75 7.466 9.75 5.5A4.875 4.875 0 100 5.5c0 1.966.685 2.514 4.374 7.863a.61.61 0 001.002 0zM4.875 7.53a2.031 2.031 0 110-4.062 2.031 2.031 0 010 4.062z"
    />
  </svg>
)
