import { useFlowEnhancers } from '@checkout/flow'
import { useCart } from '@shared/cart/useCart'
import useCartId from '@shared/cart/useCartId'

/**
 * Hook that returns if the cart is empty
 */
export const useCartEmpty = () => {
  const cartId = useCartId()
  const { cart, loading } = useCart()
  const { viewOrder } = useFlowEnhancers()

  if (cart === null && loading) {
    return null
  }

  return (cart === null && !viewOrder) || (!cartId && !viewOrder)
}
