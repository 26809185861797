import { useEffect } from 'react'
import {
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'

interface UseVarChangeProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  names: TName[]
  setValue: UseFormSetValue<TFieldValues>
  setState: (stateValue: Partial<unknown>) => void
  setVarValue: (key: TName, value: PathValue<TFieldValues, TName>) => void
}

export const useVarListenAndUpdateForFields = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    names,
    setValue,
    setState,
    setVarValue,
  }: UseVarChangeProps<TFieldValues, TName>,
  [reactiveVar]: [TFieldValues],
) => {
  useEffect(() => {
    if (reactiveVar.forceClear) {
      names.forEach((name) => {
        if (name in reactiveVar) {
          setValue(name, '' as PathValue<TFieldValues, TName>)
        }
      })
      setState({})
      return
    }

    if (
      !reactiveVar ||
      Object.keys(reactiveVar).length === 0 ||
      !reactiveVar.shouldUpdate
    ) {
      return
    }

    names.forEach((name) => {
      if (name in reactiveVar) {
        setValue(name, reactiveVar[name as keyof typeof reactiveVar])
      }
    })

    setVarValue(
      'shouldUpdate' as TName,
      false as PathValue<TFieldValues, TName>,
    )
  }, [names, reactiveVar, setValue, setState, setVarValue])
}
