import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { PrismicSliceMenuVisual } from '@src/components/PrismicSliceMenuVisual'
import { PrismicSliceSubmenuAccessoryTile } from '@src/components/PrismicSliceSubmenuAccessoryTile'
import { PrismicSliceSubmenuSection } from '@src/components/PrismicSliceSubmenuSection'
import { PrismicTypeSubmenu, SubmenuBody } from '@src/schema.generated'
import React from 'react'

import { theme, minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

const StyledContainer = styled(Container)`
  box-sizing: border-box;

  @media ${minWidth('lg')} {
    max-width: 1140px;
  }
`

const SubmenuWrapper = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'isActive',
})<{ isActive: boolean }>(
  ({ isActive }) => css`
    display: none;
    position: relative;
    background: ${theme.backgroundColor};
    color: ${theme.onBackgroundColor};
    box-shadow: 0 17px 14px 0 rgba(0, 0, 0, 0.2);
    z-index: ${theme.zIndex.modal};

    @media ${minWidth('lg')} {
      &:hover {
        display: block;
      }

      ${isActive &&
      css`
        display: block;
      `}
    }
  `,
)

const SubmenuContent = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'columns',
})<{ columns: number }>(
  ({ columns }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    gap: 20px;
    padding: 30px 0;

    @media ${minWidth('lg')} {
      padding: 50px 0;
    }
  `,
)

function renderSlice(slice: SubmenuBody, title: string, columnId: number) {
  switch (slice.__typename) {
    case 'SubmenuBodySubmenuSection':
      return (
        <PrismicSliceSubmenuSection
          slice={slice}
          submenuTitle={title}
          columnId={columnId}
        />
      )
    case 'SubmenuBodyMenuVisual':
      return (
        <PrismicSliceMenuVisual
          slice={slice}
          submenuTitle={title}
          columnId={columnId}
        />
      )
    case 'SubmenuBodyAccessoryTile':
      return (
        <PrismicSliceSubmenuAccessoryTile
          slice={slice}
          submenuTitle={title}
          columnId={columnId}
        />
      )
    default:
      // Every option should be implemented, so slice should be a `never`
      console.warn(`Slice type "${slice.__typename}" not implemented`)
      return null
  }
}

interface Props {
  submenu: PrismicTypeSubmenu
  activeItem: string
}

export const Submenu = ({ submenu, activeItem }: Props) => {
  const submenuVisuals = submenu.body?.filter(
    (slice) => slice.__typename === 'SubmenuBodyMenuVisual',
  )

  const visualsCheck = Boolean(
    submenuVisuals?.length && submenuVisuals?.length > 1,
  )

  const columns = visualsCheck ? 4 : 5

  return (
    <SubmenuWrapper
      data-testid="submenu.wrapper"
      isActive={submenu.key === activeItem}
    >
      <StyledContainer>
        <SubmenuContent columns={columns}>
          {submenu.body?.map((slice, index) => (
            <div key={index}>
              {renderSlice(slice, submenu.title ?? '', index + 1)}
            </div>
          ))}
        </SubmenuContent>
      </StyledContainer>
    </SubmenuWrapper>
  )
}
