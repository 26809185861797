export enum SettingType {
  NEWSLETTER = 'newsletter',
  ADVICE = 'advice',
  PROMOTIONAL = 'promotional',
  LANGUAGE = 'language',
  PREFERRED_CHANNEL = 'preferredChannel',
}

export enum Language {
  NL = 'nl',
  DE = 'de',
}

export enum Channel {
  PHONE = 'phone',
  EMAIL = 'email',
}
