import React, { ComponentProps, ReactNode, useMemo } from 'react'

import { Link } from '@emico/link'
import {
  PrismicHyperlink,
  PrismicDocumentLink,
  PrismicLink as BasePrismicLink,
} from '@emico/prismic'

interface Props
  extends Omit<ComponentProps<typeof Link>, 'to' | 'analyticsName'> {
  children: ReactNode
  to: PrismicHyperlink | null
  analyticsName?: ComponentProps<typeof Link>['analyticsName']
  external?: boolean
}

const REACT_APP_PRISMIC_LINKS_RELATIVE_FOR_TESTING =
  process.env.REACT_APP_PRISMIC_LINKS_RELATIVE_FOR_TESTING === 'true'

const PrismicLink = ({ children, to, ...others }: Props) => {
  const removeDomainFromUrl = useMemo(() => {
    if (to?.linkType !== 'Web') {
      return
    }

    if (REACT_APP_PRISMIC_LINKS_RELATIVE_FOR_TESTING) {
      const regex = new RegExp('https://www.swisssense.([a-z]{2})')
      return to.url.replace(regex, '')
    }

    return to.url
  }, [to])

  if (to?.linkType === 'Web') {
    return (
      <Link
        external={REACT_APP_PRISMIC_LINKS_RELATIVE_FOR_TESTING}
        to={removeDomainFromUrl ?? ''}
        target={to?.target ? '_blank' : undefined}
        analyticsName={to.url}
        {...others}
      >
        {children}
      </Link>
    )
  }

  if (to?.linkType === 'Media') {
    return (
      <Link to={to.url} analyticsName={to.url} {...others}>
        {children}
      </Link>
    )
  }

  if (to?.linkType === 'Document') {
    return (
      <PrismicDocumentLink to={to} analyticsName={to.slug} {...others}>
        {children}
      </PrismicDocumentLink>
    )
  }

  return <>{children}</>
}

BasePrismicLink.override(PrismicLink)
