import styled from '@emotion/styled'

import { VisualRadio, createVisualRadio } from '@emico/ui'

VisualRadio.override(
  styled(
    createVisualRadio({
      size: 20,
      gutter: 3,
      inactiveColor: '#d4d0d0',
      activeColor: '#000',
    }),
  )`
    vertical-align: middle;
    margin-right: 1em;

    input:focus + & {
      box-shadow: none;
    }
  ` as typeof VisualRadio.original,
)
