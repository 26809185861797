import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React from 'react'

import { ChevronLeftIcon, CrossIcon } from '@emico/icons'
import { PrismicImage, PrismicImageType } from '@emico/prismic'
import { theme, minWidth } from '@emico/styles'
import { ButtonUnstyled, H2 } from '@emico/ui'

import { DEFAULT_MENU_PANEL } from './MobileMenuModal'

const Header = styled.header`
  min-height: 50px;
  position: relative;
  margin-bottom: ${theme.spacing.x3}px;
  background-color: ${theme.backgroundColor};
`

const Background = styled(PrismicImage)`
  width: 100%;
  object-fit: cover;
  display: block;
`

const Title = styled(H2)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 ${theme.spacing.x4}px ${theme.spacing.x1}px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  font-family: ${theme.fontFamilySecondary};
  color: ${theme.onPrimaryColor};
  letter-spacing: 0.01em;

  @media ${minWidth('md')} {
    font-size: 60px;
    line-height: 1.6em;
    padding: 0 ${theme.spacing.x4}px ${theme.spacing.x1}px 80px;
  }
`

const StyledButtonIcon = styled(ButtonUnstyled)`
  position: absolute;
  top: ${theme.spacing.x3}px;
  right: ${theme.spacing.x3}px;
  width: ${theme.spacing.x5}px;
  height: ${theme.spacing.x5}px;
  color: ${theme.primaryColor};
  background: rgba(255, 255, 255, 0.8);
  border-radius: ${theme.spacing.x5}px;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.primaryColor};
    background: rgba(255, 255, 255, 0.8);
    border: 0;
  }
`

const StyledCrossIcon = styled(CrossIcon)`
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
`

interface MobileMenuHeaderProps {
  uid: string
  close: () => void
  title?: string | null
  image?: PrismicImageType | null
}

export const MobileMenuHeader = ({
  uid,
  title,
  image,
  close,
}: MobileMenuHeaderProps) => (
  <Header>
    {image && <Background image={image}></Background>}

    <StyledButtonIcon
      onClick={close}
      analyticsName="close"
      analyticsContext="header.mobileMenu"
      title={t({ id: 'header.mobileMenu.close', message: 'Close menu' })}
    >
      {uid === DEFAULT_MENU_PANEL ? (
        <StyledCrossIcon />
      ) : (
        <StyledChevronLeftIcon />
      )}
    </StyledButtonIcon>

    {title && <Title>{title}</Title>}
  </Header>
)
