import styled from '@emotion/styled'

import {
  ArrowButton,
  PrevArrow,
  NextArrow,
  SliderPrevArrowIcon,
  SliderNextArrowIcon,
} from '@emico/component-slider'
import { ArrowLeftIcon } from '@emico/icons'
import { overrideOptional } from '@emico/overrides'
import { ButtonUnstyled } from '@emico/ui'

const StyledArrowButton = overrideOptional(styled(ButtonUnstyled)`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: white;
  padding: 14px;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.25s;

  &:not(:disabled):hover {
    background: white;
    opacity: 1;
  }
  &:disabled {
    background: white;
    opacity: 0;
  }
`)

const StyledPrevArrow = overrideOptional(styled(ArrowButton)`
  left: 5%;
`)

const StyledNextArrow = overrideOptional(styled(ArrowButton)`
  right: 5%;
`)

const StyledSliderPrevArrowIcon = overrideOptional(styled(ArrowLeftIcon)`
  width: 2em;
  height: 2em;
`)

const StyledSliderNextArrowIcon = overrideOptional(styled(ArrowLeftIcon)`
  width: 2em;
  height: 2em;
  transform: rotate(180deg);
`)

ArrowButton.override(StyledArrowButton as typeof ArrowButton.original)

PrevArrow.override(StyledPrevArrow as typeof PrevArrow.original)

NextArrow.override(StyledNextArrow as typeof NextArrow.original)

SliderPrevArrowIcon.override(
  StyledSliderPrevArrowIcon as typeof SliderPrevArrowIcon.original,
)

SliderNextArrowIcon.override(
  StyledSliderNextArrowIcon as typeof SliderNextArrowIcon.original,
)
