import React, { SVGProps } from 'react'

export const HeadsetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 37"
    aria-hidden="true"
    {...props}
  >
    <path d="M31.2 12.914c-.012 0-.024.005-.036.005C30.78 5.804 24.96 0 18 0S5.22 5.804 4.836 12.919c-.012 0-.024-.005-.036-.005-2.64 0-4.8 2.235-4.8 4.966v4.967c0 2.732 2.16 4.967 4.8 4.967 2.64 0 4.8-2.235 4.8-4.967V17.88a4.989 4.989 0 00-2.395-4.269C7.23 7.425 12.017 2.483 18 2.483s10.769 4.942 10.795 11.128a4.992 4.992 0 00-2.395 4.27v4.966a4.98 4.98 0 002.611 4.39c-1.325 1.684-3.924 4.32-7.428 4.734-.535-1.383-1.862-2.421-3.343-2.421-1.92 0-3.6 1.738-3.6 3.725S16.32 37 18.24 37c1.713 0 2.962-1.1 3.408-2.545 5.369-.512 8.827-4.952 10.034-6.693C34.094 27.506 36 25.408 36 22.847v-4.966c0-2.732-2.16-4.967-4.8-4.967zm-24 9.933c0 1.49-.96 2.483-2.4 2.483s-2.4-.993-2.4-2.483V17.88c0-1.49.96-2.483 2.4-2.483s2.4.993 2.4 2.483v4.967zM18.24 34.52c-.72 0-1.2-.497-1.2-1.242s.48-1.241 1.2-1.241 1.2.496 1.2 1.241c0 .745-.48 1.242-1.2 1.242zM33.6 22.847c0 1.49-.96 2.483-2.4 2.483s-2.4-.993-2.4-2.483V17.88c0-1.49.96-2.483 2.4-2.483s2.4.993 2.4 2.483v4.967z" />
  </svg>
)
