import styled from '@emotion/styled'
import { Price } from '@src/components/Price'
import theme from '@src/theme'
import React from 'react'

import { Image } from '@emico/ui'

const TicketLine = styled('div', {
  shouldForwardProp: (prop: string) => !['isBold', 'mode'].includes(prop),
})<{
  isBold: boolean
  mode: 'big' | 'small'
}>`
  font-family: ${theme.fontFamilySecondary};
  display: flex;
  padding-bottom: 4px;
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  font-size: 12px;
  letter-spacing: 0.01em;
  margin-bottom: ${({ mode }) => mode === 'big' && '12px'};
`

const TicketLineImage = styled.div`
  width: 55px;
  margin-right: 23px;
`

const TicketLineContent = styled('div', {
  shouldForwardProp: (prop: string) => !['mode', 'type'].includes(prop),
})<{
  mode: 'big' | 'small'
  type: 'sub' | 'total'
}>`
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
  margin: 0;
  line-height: normal;
  ${({ mode }) =>
    mode === 'big'
      ? `
  
  letter-spacing: 0.01em;
    font-family: ${theme.fontFamilySecondary};
  `
      : `

  `};

  ${({ type, mode }) =>
    type === 'total'
      ? `
        font-size: ${mode === 'big' ? `18px` : '12px'};
        color: ${mode === 'big' ? theme.primaryColor : '#615e5e'};
  `
      : `
      font-size: ${mode === 'big' ? `14px` : '12px'};
    color: #615e5e;
  `};
`

const TicketLinePrice = styled('div', {
  shouldForwardProp: (prop: string) => !['mode', 'type'].includes(prop),
})<{
  mode: 'big' | 'small'
  type: 'sub' | 'total'
}>`
  font-weight: 500;
  text-align: right;
  margin: 0;

  ${({ mode, type }) =>
    mode === 'big'
      ? `
      font-size: ${type === 'total' ? `18px` : `14px`};
      font-family: ${theme.fontFamilySecondary};
  color: ${theme.primaryColor};
  letter-spacing: 0.01em;
  `
      : ``};
`

interface CartTotalLineProps {
  mode?: 'small' | 'big'
  type?: 'sub' | 'total'
  src?: string
  alt?: string
  price: number
  children: React.ReactNode
  stylizeFree?: boolean
  noPrice?: boolean
  isBold?: boolean
}

export const CartTotalLine = ({
  mode = 'big',
  type = 'sub',
  src,
  price,
  children,
  stylizeFree,
  noPrice,
  isBold = true,
  alt = '',
}: CartTotalLineProps) => (
  <TicketLine isBold={isBold} mode={mode}>
    {mode === 'small' ? (
      <TicketLineImage>
        {src ? <Image url={src} width={55} alt={alt} /> : null}
      </TicketLineImage>
    ) : null}
    <TicketLineContent mode={mode} type={type}>
      {children}
    </TicketLineContent>
    <TicketLinePrice mode={mode} type={type}>
      {noPrice !== true && <Price stylizeFree={stylizeFree} value={price} />}
    </TicketLinePrice>
  </TicketLine>
)
