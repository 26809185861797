import styled from '@emotion/styled'
import React from 'react'

import { theme } from '@emico/styles'

const SVG = styled.svg`
  width: 75px;
  height: 75px;
`

const Path = styled.path`
  fill: none;
  stroke: transparent;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  animation: loadingDash 3s linear 1s infinite forwards;

  + path {
    stroke: white;
    stroke-width: 4;
    animation: loadingDashGhost 3s linear 1s infinite forwards;
  }

  @keyframes loadingDash {
    10% {
      stroke: ${theme.secondaryColor};
    }
    50% {
      stroke-dashoffset: 0;
      stroke: ${theme.secondaryColor};
    }
    90% {
      stroke-dashoffset: 0;
      stroke: ${theme.secondaryColor};
    }
    100% {
      stroke-dashoffset: 0;
      stroke: transparent;
    }
  }

  @keyframes loadingDashGhost {
    50% {
      stroke-dashoffset: 250;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
`

export const PillowLoader = (props: unknown) => (
  <SVG viewBox="0 0 100 100" {...props}>
    <Path d="M 20 20 q 30 -5 60 0 q 5 30 0 60 q -30 5 -60 0 q -5 -30 0 -60 Z" />
    <Path d="M 20 20 q 30 -5 60 0 q 5 30 0 60 q -30 5 -60 0 q -5 -30 0 -60 Z" />
  </SVG>
)
