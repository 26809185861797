import { makeVar, useReactiveVar } from '@apollo/client'
import { AddressFormValues, BillingFields } from '@checkout/address-form'
import { Gender } from '@checkout/gender/helpers'

import { AddressFields } from '@emico/address-form'

export interface AddressVarState extends Partial<AddressFormValues> {
  forceClear?: boolean
  shouldUpdate?: boolean
}

const addressState = makeVar<AddressVarState>({})

export const useAddressVar = () => {
  const addressVar = useReactiveVar(addressState)

  const getAddressVar = () => addressState()

  const setAddressVarState = (stateValue: Partial<AddressVarState>) => {
    addressState(stateValue)
  }

  const setAddressVarValue = (
    key: keyof AddressVarState,
    value:
      | string
      | Gender
      | AddressFields
      | BillingFields
      | boolean
      | number
      | null,
  ) => {
    if (addressVar[key] === value) {
      return
    }
    addressState({
      ...addressState(),
      [key as string]: value,
    })
  }

  return {
    addressVar,
    getAddressVar,
    setAddressVarState,
    setAddressVarValue,
  }
}
