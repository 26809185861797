import { TypedDocumentNode, useApolloClient } from '@apollo/client'
import {
  RemoveGiftCardFromCartMutation,
  RemoveGiftCardFromCartMutationVariables,
} from '@checkout/gift-card/useRemoveGiftCard.generated'
import gql from 'graphql-tag'

export const REMOVE_GIFT_CARD_FROM_CART = gql`
  mutation RemoveGiftCardFromCart($gift_card_code: String!, $cart_id: String!) {
    removeGiftCardFromCart(
      input: { gift_card_code: $gift_card_code, cart_id: $cart_id }
    ) {
      cart {
        id
      }
    }
  }
` as TypedDocumentNode<
  RemoveGiftCardFromCartMutation,
  RemoveGiftCardFromCartMutationVariables
>

const parseData = (
  data?: RemoveGiftCardFromCartMutation | null,
): boolean | null => {
  if (!data) {
    return null
  }

  return (
    data.removeGiftCardFromCart?.cart.id !== undefined &&
    data.removeGiftCardFromCart?.cart.id !== null
  )
}

export const useRemoveGiftCard = () => {
  const client = useApolloClient()

  const removeGiftCard = async (
    variables: RemoveGiftCardFromCartMutationVariables,
  ) => {
    const { data, ...rest } = await client.mutate({
      mutation: REMOVE_GIFT_CARD_FROM_CART,
      variables,
    })

    return {
      removed: parseData(data),
      ...rest,
    }
  }

  return {
    removeGiftCard,
  }
}
