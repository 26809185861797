import { ButtonTransparent } from '@configurator/ui'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { theme } from '@emico/styles'

const StyledButton = styled(ButtonTransparent)`
  font-family: ${theme.fontFamilySecondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #fff;

  &.loading {
    color: ${(props) => (props.loading ? 'transparent' : 'white')};
  }
`

type BaseProps = Partial<ComponentProps<typeof StyledButton>> &
  Pick<ComponentProps<typeof StyledButton>, 'analyticsName' | 'children'>
interface RightPaneButtonProps extends BaseProps {
  onClick: () => void
}

export const RightPaneButton = ({
  analyticsName,
  onClick,
  children,
  ...others
}: RightPaneButtonProps) => (
  <StyledButton
    className={others.loading ? 'loading' : ''}
    analyticsName={analyticsName}
    analyticsContext="configurator.footer"
    onClick={onClick}
    {...others}
  >
    {children}
  </StyledButton>
)
