import styled from '@emotion/styled'
import theme from '@src/theme'
import {
  createContext,
  default as React,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { CheckIcon } from '@emico/icons'
import { maxWidth } from '@emico/styles'

const SuccessMessageContainer = styled.div`
  font-family: ${theme.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colorGreenPrimary};
  background: ${theme.colorGreenLight};
  border: 1px solid ${theme.colorGreenPrimary};
  border-radius: 4px;
  padding: 15px 15px 14px 15px;
  margin-bottom: 15px;

  @media ${maxWidth('sm')} {
    margin-right: 20px;
    margin-left: 20px;
  }
`

const CheckIconHolder = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  background: ${theme.colorGreenPrimary};
  border-radius: 12px;
  position: relative;
  float: left;
  margin-right: 20px;
`

const StyledCheckIcon = styled(CheckIcon)`
  width: 9px;
  color: ${theme.onPrimaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface ContextType {
  message?: string

  setMessage(value: string): void
}

const context = createContext<ContextType>({
  message: undefined,
  setMessage: (_: string) => undefined,
})

interface SuccessMessageProviderProps {
  children: ReactNode
}

export const SuccessMessageProvider = ({
  children,
}: SuccessMessageProviderProps) => {
  const Provider = context.Provider
  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (message !== undefined) {
      setTimeout(() => {
        setMessage(undefined)
      }, 4000)
    }
  }, [message])

  function setSuccessMessage(message: string) {
    setMessage(message)
  }

  return (
    <Provider value={{ message, setMessage: setSuccessMessage }}>
      {children}
    </Provider>
  )
}

export const useSuccessMessage = () => useContext(context)

export const SuccessMessage = () => {
  const { message } = useSuccessMessage()

  if (message === undefined) {
    return null
  }

  return (
    <SuccessMessageContainer>
      <CheckIconHolder>
        <StyledCheckIcon />
      </CheckIconHolder>
      <span>{message}</span>
    </SuccessMessageContainer>
  )
}
