import styled from '@emotion/styled'
import {
  createContext,
  default as React,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { CrossIcon } from '@emico/icons'
import { maxWidth } from '@emico/styles'

import theme from '../theme'

const ErrorMessageContainer = styled.div`
  font-family: ${theme.fontFamilySecondary};
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colorRed};
  background: ${theme.colorRedLight};
  border: 1px solid ${theme.colorRed};
  border-radius: 4px;
  padding: 15px 15px 14px 15px;
  margin-bottom: 15px;

  @media ${maxWidth('sm')} {
    margin-right: 20px;
    margin-left: 20px;
  }
`

const CrossIconHolder = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  background: ${theme.colorRed};
  border-radius: 12px;
  position: relative;
  float: left;
  margin-right: 20px;
`

const StyledCrossIcon = styled(CrossIcon)`
  color: ${theme.onPrimaryColor};
  width: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface ContextType {
  message?: string
  setMessage(value: string): void
}

const context = createContext<ContextType>({
  message: undefined,
  setMessage: (_: string) => undefined,
})

interface ErrorMessageProviderProps {
  children: ReactNode
}

export const ErrorMessageProvider = ({
  children,
}: ErrorMessageProviderProps) => {
  const Provider = context.Provider
  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (message !== undefined) {
      setTimeout(() => {
        setMessage(undefined)
      }, 5000)
    }
  }, [message])

  function setErrorMessage(message: string) {
    setMessage(message)
  }

  return (
    <Provider value={{ message, setMessage: setErrorMessage }}>
      {children}
    </Provider>
  )
}

export const useErrorMessage = () => useContext(context)

export const ErrorMessage = () => {
  const { message } = useErrorMessage()

  if (message === undefined) {
    return null
  }

  return (
    <ErrorMessageContainer>
      <CrossIconHolder>
        <StyledCrossIcon />
      </CrossIconHolder>
      <span>{message}</span>
    </ErrorMessageContainer>
  )
}
